import { Navigate, Outlet, type RouteProps } from 'react-router-dom';
import PageRouteEnum from '../../enums/PageRouteEnum';
import { type IUserContext } from '../../contexts/UserContext';
import UnauthorizedLayout from '../../layouts/UnauthorizedLayout';

type TUnauthenticatedRouteProps = RouteProps & Pick<
  IUserContext, 'isAuthenticated'
> & {
  defaultAuthorizedRoute: PageRouteEnum;
}

const UnauthenticatedRoute = (props: TUnauthenticatedRouteProps) => {
  const {
    isAuthenticated,
    defaultAuthorizedRoute,
  } = props;

  if (isAuthenticated) {
    return <Navigate to={defaultAuthorizedRoute} />;
  }

  return (
    <UnauthorizedLayout>
      <Outlet />
    </UnauthorizedLayout>
  );
};

export default UnauthenticatedRoute;
