import { MoveVaultFromGroupToEmployeePayload } from '@uniqkey-backend-organization-web/api-client';
import {
  decryptAsymmetric, decryptSymmetric, encryptAsymmetric,
} from '@uniqkey-frontend/shared-app';
import type { GetPublicKeyResponse } from '@uniqkey-backend-organization-mobile/api-client';
import type { ITrustedPortalHandlerParams } from '../../../interfaces';
import APIClientsProvider from '../../../apiClientsProvider';
import { dataExtractor } from '../../../../../helpers/apiClients';

const ERROR_PREFIX = 'TrustedPortal/handleMoveVaultFromGroupToEmployeeEvent';

const handleMoveVaultFromGroupToEmployeeEvent = async (
  params: ITrustedPortalHandlerParams<MoveVaultFromGroupToEmployeePayload>,
) => {
  const {
    parsedEvent,
    axiosInstance,
    organizationPrivateKey,
    organizationPublicKey,
  } = params;

  const { parsedPayload, queueMessageId } = parsedEvent;
  const {
    vaultSymmetricKey,
    employeeAccountId,
    groupSecret,
  } = parsedPayload;

  if (!vaultSymmetricKey) {
    throw new Error(`${ERROR_PREFIX} no vaultSymmetricKey in event`);
  }

  if (!employeeAccountId) {
    throw new Error(`${ERROR_PREFIX} no employeeAccountId in event`);
  }

  if (!groupSecret) {
    throw new Error(`${ERROR_PREFIX} no groupSecret in event`);
  }

  const decryptedGroupSecret = await decryptAsymmetric({
    cipher: groupSecret,
    privateKey: organizationPrivateKey,
    publicKey: organizationPublicKey,
  });

  const decryptedSymmetricKey = decryptSymmetric({
    key: decryptedGroupSecret,
    cipher: vaultSymmetricKey,
  });

  const { employeeAccountKeyPairPublic } = await APIClientsProvider.Mobile
    .getEmployeeAccountsAPIClient(axiosInstance)
    .apiV1EmployeeAccountsGetPublicKeyEmployeeAccountIdGet(employeeAccountId)
    .then(dataExtractor<GetPublicKeyResponse>());

  const encryptedSymmetricKey = await encryptAsymmetric({
    string: decryptedSymmetricKey,
    publicKey: employeeAccountKeyPairPublic,
  });

  await APIClientsProvider.Mobile.getQueueAPIClient(axiosInstance)
    .apiV1QueueMoveVaultFromGroupToEmployeeApprovePost({
      queueMessageId,
      vaultSymmetricKey: encryptedSymmetricKey,
    });
};

export default handleMoveVaultFromGroupToEmployeeEvent;
