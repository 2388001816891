import { type ReactNode, useEffect } from 'react';
import { useCompanionApplicationContext } from '../../contexts/CompanionApplicationContext';
import { initWebSockets } from '../../../services/webSocketsManager';

interface IInitializationContainerProps {
  children: ReactNode;
}
const InitializationContainer = (props: IInitializationContainerProps) => {
  const { children } = props;

  const { guid } = useCompanionApplicationContext();

  useEffect(() => {
    initWebSockets(guid);
  }, [guid]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment,react/react-in-jsx-scope
    <>{children}</>
  );
};

export default InitializationContainer;
