/* eslint-disable max-len */
import { BaseEitherError } from '@uniqkey-frontend/shared-app';

import { type TJSONParseOperationErrors } from '../jsonParseOperation/errors';

export class DecryptTrustedActionPayloadCommonDecryptionError extends BaseEitherError {}

export class DecryptTrustedActionPayloadInitialDecryptionError extends BaseEitherError {}

export class DecryptTrustedActionPayloadOrganizationPrivateDecryptionError extends BaseEitherError {}

export type TDecryptTrustedActionPayloadOperationErrors = DecryptTrustedActionPayloadCommonDecryptionError
  | DecryptTrustedActionPayloadInitialDecryptionError
  | DecryptTrustedActionPayloadOrganizationPrivateDecryptionError
  | TJSONParseOperationErrors;
