import {
  useCallback, useMemo, useState, useEffect,
} from 'react';
import {
  Box,
  SideMenuSelect,
  usePubSub,
  TSelectProps,
  SideMenuSelectOption,
  SideMenuSelectOptionAvatar,
  SideMenuSelectOptionLabel,
} from '@uniqkey-frontend/shared-app';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useUser } from '../../../../../contexts/UserContext';
import {
  getActiveOrganizationId, setActiveOrganizationId,
} from '../../../../../services/organizationService';
import PageRouteEnum from '../../../../../enums/PageRouteEnum';
import PubSubEventEnum from '../../../../../enums/PubSubEventEnum';
import LocalStorageKeyEnum from '../../../../../enums/LocalStorageKeyEnum';
import { getEmployeesTokens } from '../../../../../services/authService';
import {
  selectCoreReactQueries,
  selectNonCoreReactQueries,
} from '../../../../../helpers/reactQuery';

const MENU_PROPS = { sx: { maxWidth: 248 } };

const OrganizationSelector = () => {
  const [organizationId, setOrganizationId] = useState<string | null>(
    () => getActiveOrganizationId(),
  );
  const { employeeTokens, isSupporter } = useUser();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleQueriesReset = useCallback(() => {
    queryClient.invalidateQueries({ predicate: selectCoreReactQueries });
    queryClient.removeQueries({ predicate: selectNonCoreReactQueries });
  }, [queryClient]);

  const organizations = useMemo(
    () => Object
      .values(employeeTokens ?? {})
      .sort(
        (orgA, orgB) => orgA.organizationName.localeCompare(orgB.organizationName),
      ), // TODO: NEXTGEN-7567 move sorting to a reusable function
    [employeeTokens],
  );
  const changeOrganization = useCallback((orgId: string) => {
    setOrganizationId(orgId);
    setActiveOrganizationId(orgId);
    handleQueriesReset();
    navigate(PageRouteEnum.Dashboard);
  }, [handleQueriesReset, navigate]);
  const handleOrganizationChange = useCallback<
    NonNullable<TSelectProps['onChange']>
  >((event) => {
    changeOrganization(event.target.value as string);
  }, [changeOrganization]);
  const menuItems = useMemo(() => organizations.map((org) => (
    <SideMenuSelectOption value={org.organizationId} key={org.organizationId}>
      <SideMenuSelectOptionAvatar value={org.organizationName} />
      <SideMenuSelectOptionLabel value={org.organizationName} />
    </SideMenuSelectOption>
  )), [organizations]);

  const handleTokensRefreshed = useCallback(() => {
    if (!organizationId) {
      return;
    }
    const newTokens = getEmployeesTokens();
    if (!newTokens) {
      return;
    }
    if (!newTokens[organizationId]) {
      changeOrganization(organizations[0].organizationId);
    }
  }, [organizations, organizationId, changeOrganization]);

  usePubSub(PubSubEventEnum.TOKENS_REFRESHED, handleTokensRefreshed);

  useEffect(() => {
    const handler = (event: StorageEvent) => {
      if (event.key === LocalStorageKeyEnum.ActiveOrganizationId) {
        setOrganizationId(event.newValue);
        handleQueriesReset();
        navigate(PageRouteEnum.Dashboard);
      }
    };
    // NOTE: add listener storage - not working on the current tab
    window.addEventListener('storage', handler);
    return () => {
      window.removeEventListener('storage', handler);
    };
  }, [handleQueriesReset, navigate, queryClient]);

  if (isSupporter || !organizations.length) {
    return null;
  }

  return (
    <Box ml={1}>
      <SideMenuSelect
        fullWidth
        disableMinWidth
        value={organizationId}
        onChange={handleOrganizationChange}
        MenuProps={MENU_PROPS}
        disabled={organizations.length === 1}
      >
        {menuItems}
      </SideMenuSelect>
    </Box>
  );
};

export default OrganizationSelector;
