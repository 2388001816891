import { useMemo, memo } from 'react';
import {
  Table,
  useTranslations,
  IUseTableReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  TypographyWithTooltip,
  IMaterialTableOptions,
  getTimeFromNow,
  Grid,
  CreditCardAvatar,
  maskCardNumber,
} from '@uniqkey-frontend/shared-app';
import { OWNERSHIP_COLORS, OWNERSHIP_TRANSLATION_KEYS } from '../../../constants';
import {
  IOrganizationCreditCardsTableRow,
} from '../../../hooks/tables/useOrganizationCreditCardsTable';
import { TGetVaultCreditCardsMethod } from '../../../hooks/useVaultsAPI/interfaces';

interface IOrganizationCreditCardsTableProps extends
  Omit<IUseTableReturn<IOrganizationCreditCardsTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Omit<
    IUseQueryTableFetchReturn<TGetVaultCreditCardsMethod>,
    'data' | 'resetQuery' | 'isFetchedAfterMount'
  >
{
  creditCards: IUseQueryTableFetchReturn<TGetVaultCreditCardsMethod>['data'];
  selectedCreditCards: IUseTableReturn<IOrganizationCreditCardsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

const TABLE_OPTIONS: IMaterialTableOptions<IOrganizationCreditCardsTableRow> = {
  selection: true,
};

const TOOLTIP_PROPS = { cursorPointer: true };

const OrganizationCreditCardsTable = (props: IOrganizationCreditCardsTableProps) => {
  const {
    creditCards: preCreditCards,
    isLoading,
    total,
    localization,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    columnOrderBy,
    onColumnOrderByChange,
    columnOrderDirection,
    onColumnOrderDirectionChange,
    selectedCreditCards,
    onRowSelectionChange,
    createColumns,
  } = props;

  const { t, currentLanguage } = useTranslations();

  const creditCards = useMemo(() => preCreditCards.map((creditCard) => ({
    ...creditCard,
    lastActivity: getTimeFromNow({
      date: creditCard.activityAt,
      locale: currentLanguage,
    }),
    // TODO: remove 'as string' when BE updates interface
    maskedCardNumber: maskCardNumber(creditCard.lastDigits as string),
  })), [preCreditCards, currentLanguage]);

  const columns = useMemo(() => createColumns([
    {
      title: t('creditCardsTab.table.columns.number'),
      field: 'lastDigits',
      render: (rowData) => (
        <Grid container flexWrap="nowrap" alignItems="center">
          <CreditCardAvatar
            width={32}
            height={20}
            type={rowData.paymentSystem!}
          />
          <TypographyWithTooltip variant="caption">
            {rowData.maskedCardNumber}
          </TypographyWithTooltip>
        </Grid>
      ),
      width: '20%',
      sorting: false,
    },
    {
      title: t('creditCardsTab.table.columns.name'),
      field: 'name',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.name}
        </TypographyWithTooltip>
      ),
      width: '40%',
    },
    {
      title: t('creditCardsTab.table.columns.managedBy'),
      field: 'ownership',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2" color={OWNERSHIP_COLORS[rowData.ownership]}>
          {t(OWNERSHIP_TRANSLATION_KEYS[
            rowData.ownership as keyof typeof OWNERSHIP_TRANSLATION_KEYS
          ])}
        </TypographyWithTooltip>
      ),
      width: '20%',
    },
    {
      title: (
        <TypographyWithTooltip
          variant="subtitle1"
          tooltipProps={TOOLTIP_PROPS}
          lineClamp={5}
          title={t('creditCardsTab.table.columns.lastActivity.columnTooltip')}
        >
          {t('creditCardsTab.table.columns.lastActivity')}
        </TypographyWithTooltip>
      ),
      field: 'activityAt',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.lastActivity}
        </TypographyWithTooltip>
      ),
      width: '20%',
    },
  ]), [createColumns, t]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={creditCards}
      isLoading={isLoading}
      options={TABLE_OPTIONS}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      columnOrderBy={columnOrderBy}
      onColumnOrderByChange={onColumnOrderByChange}
      columnOrderDirection={columnOrderDirection}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedCreditCards}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      rowSelectByKey="vaultId"
      rowHeight={51}
    />
  );
};

export default memo(OrganizationCreditCardsTable);
