import { memo } from 'react';
import {
  Typography,
  useTranslations,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@uniqkey-frontend/shared-app';
import { CheckStatusResponseModel } from '@uniqkey-backend-organization-web/api-client';
import NotMigratedDataTable from '../NotMigratedDataTable';
import VaultTypeEnum from '../../../../../enums/VaultTypeEnum';
import VaultTypeNameEnum from '../../../../../enums/VaultTypeNameEnum';

interface INotMigratedDataAccordionProps {
  type: VaultTypeEnum;
  data: CheckStatusResponseModel[];
  organizationName: string;
}

const NotMigratedDataAccordion = (props: INotMigratedDataAccordionProps) => {
  const { type, data, organizationName } = props;
  const { t } = useTranslations();

  const vaultType = VaultTypeNameEnum[VaultTypeEnum[type] as keyof typeof VaultTypeEnum];

  const dataLength = data.length;

  return (
    <Accordion>
      <AccordionSummary>
        <Typography>
          {t(`migration.notMigratedDataModal.${vaultType}`, { count: dataLength })}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <NotMigratedDataTable
          type={type}
          data={data}
          dataLength={dataLength}
          organizationName={organizationName}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(NotMigratedDataAccordion);
