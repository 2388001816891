import type { Either } from '@uniqkey-frontend/shared-app';
import type { GetPartnerKeysResponse } from '@uniqkey-backend-organization-web/api-client';
import {
  SupportJumpGetPartnerKeysFetchError,
  type TSupportJumpGetPartnerKeysOperationErrors,
} from './errors';
import { SupportJumpAPIIncorrectAxiosInstanceError } from '../../common/errors';
import APIClientsProvider from '../../apiClientsProvider';
import SupportJumpStoreActions from '../../store/actions';
import { dataExtractor } from '../../../../helpers/apiClients';

export interface IGetPartnerKeysOperationParams {
  token: string;
  organizationId: string;
}
const getPartnerKeysOperation = async (
  params: IGetPartnerKeysOperationParams,
): Promise<Either<GetPartnerKeysResponse, TSupportJumpGetPartnerKeysOperationErrors>> => {
  try {
    const { token, organizationId } = params;

    const instance = SupportJumpStoreActions.getAxiosInstance();
    if (!instance) {
      return new SupportJumpAPIIncorrectAxiosInstanceError();
    }

    const result = await APIClientsProvider.getSupporterAPIClient(instance)
      .apiV1SupporterPartnerKeysGet(token, organizationId)
      .then(dataExtractor<GetPartnerKeysResponse>());
    return result;
  } catch (e) {
    return new SupportJumpGetPartnerKeysFetchError();
  }
};

export default getPartnerKeysOperation;
