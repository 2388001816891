import { TUseMutationOptions } from '@uniqkey-frontend/shared-app';
import {
  DeleteBulkVaultToGroupRequest, DeleteBulkVaultToGroupResponse,
} from '@uniqkey-backend-organization-web/api-client';
import { useMutation } from 'react-query';
import useVaultsToGroupsAPI from '../../useVaultsToGroupsAPI';

// TODO: remove this when more methods will be added
// eslint-disable-next-line import/prefer-default-export
export const useDeleteVaultsToGroups = (
  options: TUseMutationOptions<
    DeleteBulkVaultToGroupResponse,
    unknown,
    DeleteBulkVaultToGroupRequest,
    void
  > = {},
) => {
  const { deleteVaultsToGroups } = useVaultsToGroupsAPI();
  return useMutation((
    deleteBulkVaultToGroupRequest,
  ) => deleteVaultsToGroups(deleteBulkVaultToGroupRequest), options);
};
