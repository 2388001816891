import { useCallback, memo, useState } from 'react';
import {
  GetGroupByIdResponse,
  GetGroupsResponseModel,
} from '@uniqkey-backend-organization-web/api-client';
import { useSnackbar, useTranslations } from '@uniqkey-frontend/shared-app';
import EntitySelectorModal, {
  TEntitySelectorModalOnSubmit,
} from '../../../../components/EntitySelectorModal';
import useEmployeeGroupsAPI from '../../../../hooks/useEmployeeGroupsAPI';
import useEmployeeAccountsToGroupsAPI from '../../../../hooks/useEmployeeAccountsToGroupsAPI';
import { logException } from '../../../../services/sentryService';
import { getActiveOrganizationId } from '../../../../services/organizationService';
import { useTrustedPortalStore } from '../../../../modules/TrustedPortalModule/store';

interface IGroupsSelectorModalProps {
    isOpen: boolean;
    onClose: () => void;
    employeeAccountsIds: string[];
    onSubmit?: () => void;
}

interface IParsedEntity {
  id: GetGroupByIdResponse['groupId'];
  label: GetGroupByIdResponse['name'];
}

const GroupsSelectorModal = (props: IGroupsSelectorModalProps) => {
  const {
    isOpen, onClose, employeeAccountsIds, onSubmit,
  } = props;
  const [isGroupsSelectorModalLoading, setIsGroupsSelectorModalLoading] = useState(false);
  const [loadedGroupsCount, setLoadedGroupsCount] = useState<number>(0);
  const [totalLoadedGroupsCount, setTotalLoadedGroupsCount] = useState<number>(0);
  const [response, setResponse] = useState<GetGroupsResponseModel[]>([]);

  const { t } = useTranslations();
  const { showSuccess, showWarning, showError } = useSnackbar();
  const { getGroups } = useEmployeeGroupsAPI();
  const { inviteBulkToGroup } = useEmployeeAccountsToGroupsAPI();

  const activeOrganizationId = getActiveOrganizationId();
  const isTrustedPortalEnabled = useTrustedPortalStore.useIsEnabledByOrganizationId()[
    activeOrganizationId!
  ] ?? false;

  const handleAutocompleteRequest = useCallback(async (searchQuery: string) => {
    try {
      const { data, total } = await getGroups({ page: 1, pageLength: 200, searchQuery });
      setLoadedGroupsCount(data.length);
      setTotalLoadedGroupsCount(total);
      setResponse(data);
      return data;
    } catch (e) {
      setLoadedGroupsCount(0);
      setTotalLoadedGroupsCount(0);
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'GroupsSelectorModal/handleAutocompleteRequest exception',
      });
      return [];
    }
  }, [getGroups, showError, t]);

  const handleSubmitEntitySelectorModal = useCallback<
    TEntitySelectorModalOnSubmit<IParsedEntity[]>
  >(async (value) => {
    try {
      const groupsIds = value.map((el) => el.id);
      setIsGroupsSelectorModalLoading(true);
      await inviteBulkToGroup({ employeeAccountsIds, groupsIds });
      if (isTrustedPortalEnabled) {
        showSuccess({
          text: t('trustedPortalSuccessNotifications.membersAddedToGroup', {
            count: employeeAccountsIds.length,
          }),
        });
      } else {
        showWarning({
          text: t('employeesPage.addingEmployeesToGroup', { count: employeeAccountsIds.length }),
        });
      }
      if (onSubmit) {
        onSubmit();
      }
      setIsGroupsSelectorModalLoading(false);
      onClose();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      setIsGroupsSelectorModalLoading(false);
      logException(e, {
        message: 'GroupsSelectorModal/handleSubmitEntitySelectorModal exception',
      });
    }
  }, [
    inviteBulkToGroup,
    employeeAccountsIds,
    isTrustedPortalEnabled,
    onSubmit,
    onClose,
    showSuccess,
    showWarning,
    showError,
    t,
  ]);

  const handleResponseEntitySelectorModal = useCallback(
    (groupsToParse: GetGroupByIdResponse[]) => groupsToParse.map(
      (group: GetGroupByIdResponse) => ({
        id: group.groupId,
        label: group.name,
      }),
    ),
    [],
  );

  return (
    <EntitySelectorModal
      data={response}
      dataKey="groupId"
      isOpen={isOpen}
      title={t('employeesPage.addToGroup.title')}
      placeholder={t('employeesPage.selectGroup')}
      onSubmit={handleSubmitEntitySelectorModal}
      request={handleAutocompleteRequest}
      responseParser={handleResponseEntitySelectorModal}
      onClose={onClose}
      isLoading={isGroupsSelectorModalLoading}
      loadedOptionsCount={loadedGroupsCount}
      totalLoadedOptionsCount={totalLoadedGroupsCount}
    />
  );
};

export default memo(GroupsSelectorModal);
