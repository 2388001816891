import {
  useState, memo, useCallback, useMemo,
} from 'react';
import {
  Button,
  cardNumberValidator,
  cardCvvValidator,
  cardExpirationDateFormatValidator,
  CreditCard,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useTranslations,
  parseCardExpirationDate,
  ExpiryDateTextField,
  NumericTextField,
  getCardType,
  emptyStringValidator,
  ReactHookFormTextField,
  IExpiryDateTextFieldProps,
  INumericTextFieldProps,
  TTextFieldProps,
  buildInvalidFieldTranslation,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import { UNIQKEY_FINDER_IGNORE_CLASSNAME_PROP } from '../../constants';

export interface ICreateCreditCardReturnValue {
  name: string;
  cardNumber: string;
  expiryDate: string;
  cvv: string;
  cardHolderName: string;
}

interface ICreateCreditCardModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (params: ICreateCreditCardReturnValue) => Promise<void>;
  isLoading: boolean;
}

const CreateCreditCardModal = (props: ICreateCreditCardModalProps) => {
  const {
    isOpen, onClose, onSubmit, isLoading,
  } = props;
  const [isFlip, setIsFlip] = useState<boolean>(false);
  const { t } = useTranslations();
  const {
    register, setValue, watch, handleSubmit, formState: { errors, dirtyFields }, control,
  } = useForm<ICreateCreditCardReturnValue>({
    mode: 'all',
    defaultValues: {
      name: '',
      cardNumber: '',
      expiryDate: '',
      cvv: '',
      cardHolderName: '',
    },
  });

  const [
    cardNumber, cardHolder, expiryDate, cvv,
  ] = watch(['cardNumber', 'cardHolderName', 'expiryDate', 'cvv']);
  const { month: expirationMonth, year: expirationYear } = useMemo(
    () => parseCardExpirationDate(expiryDate),
    [expiryDate],
  );

  const handleFlip = useCallback(() => setIsFlip((prevState) => !prevState), []);

  const showBackSide = useCallback(() => {
    setIsFlip(true);
  }, []);

  const showFrontSide = useCallback(() => {
    setIsFlip(false);
  }, []);

  const handleExpiryDateChange = useCallback<
    NonNullable<IExpiryDateTextFieldProps['onChange']>
  >((event) => {
    setValue('expiryDate', event.target.value);
  }, [setValue]);

  const handleCardHolderNameChange = useCallback<
    NonNullable<TTextFieldProps['onChange']>
  >((event) => {
    setValue('cardHolderName', event.target.value);
  }, [setValue]);

  const handleCvvChange = useCallback<
    NonNullable<INumericTextFieldProps['onChange']>
  >((event) => {
    setValue('cvv', event.target.value);
  }, [setValue]);

  const handleCardNumberChange = useCallback<
    NonNullable<INumericTextFieldProps['onChange']>
  >((event) => {
    setValue('cardNumber', event.target.value);
  }, [setValue]);

  const cardNumberType = useMemo(() => getCardType(cardNumber), [cardNumber]);

  const isSubmitButtonDisabled = useMemo(
    () => !dirtyFields.name || !dirtyFields.cardNumber,
    [dirtyFields.name, dirtyFields.cardNumber],
  );

  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      fullWidth
      maxWidth="sm"
      clickOutsideToClose={!isLoading}
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogTitle isLoading={isLoading} onClose={onClose}>
          {t('createCreditCardModal.title')}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" rowSpacing={4}>
            <Grid item xs={12} alignSelf="center">
              <CreditCard
                flip={isFlip}
                onClick={handleFlip}
                cardNumber={cardNumber}
                cardHolder={cardHolder}
                cardNumberType={cardNumberType}
                expirationMonth={expirationMonth}
                expirationYear={expirationYear}
                cvv={cvv}
                monthTranslationKey={t('common.month')}
                yearTranslationKey={t('common.year')}
              />
            </Grid>
            <Grid item xs={12}>
              <ReactHookFormTextField
                name="name"
                control={control}
                autoFocus
                fullWidth
                error={!!errors.name}
                label={`${t('createCreditCardModal.name.label')}*`}
                placeholder={t('createCreditCardModal.name.placeholder')}
                helperText={errors.name?.message}
                rules={{
                  required: t('validation.required'),
                  validate: (value) => (
                    emptyStringValidator(value)
                      ? buildInvalidFieldTranslation('createCreditCardModal.name.label') : true
                  ),
                }}
                onFocus={showFrontSide}
              />
            </Grid>
            <Grid item xs={12}>
              <ReactHookFormTextField
                name="cardHolderName"
                control={control}
                fullWidth
                error={!!errors.cardHolderName}
                label={t('createCreditCardModal.cardHolderName.label')}
                placeholder={t('createCreditCardModal.cardHolderName.placeholder')}
                helperText={errors.cardHolderName?.message}
                value={cardHolder}
                onChange={handleCardHolderNameChange}
                onFocus={showFrontSide}
              />
            </Grid>
            <Grid item xs={12}>
              <NumericTextField
                decimalSeparator=""
                allowNegative={false}
                fullWidth
                error={!!errors.cardNumber}
                label={`${t('createCreditCardModal.cardNumber.label')}*`}
                placeholder={t('createCreditCardModal.cardNumber.placeholder')}
                helperText={errors.cardNumber?.message}
                value={cardNumber}
                onChange={handleCardNumberChange}
                onFocus={showFrontSide}
                inputProps={{
                  maxLength: 19,
                  ...UNIQKEY_FINDER_IGNORE_CLASSNAME_PROP,
                  ...register('cardNumber', {
                    required: t('validation.required'),
                    validate: (value) => (
                      cardNumberValidator(value)
                        ? true
                        : buildInvalidFieldTranslation('createCreditCardModal.cardNumber.label')
                    ),
                  }),
                }}
              />
            </Grid>
            <Grid item container columnSpacing={2}>
              <Grid item xs={6}>
                <ExpiryDateTextField
                  fullWidth
                  error={!!errors.expiryDate}
                  label={t('createCreditCardModal.expiryDate.label')}
                  placeholder={t('createCreditCardModal.expiryDate.placeholder')}
                  helperText={errors.expiryDate?.message}
                  value={expiryDate}
                  onChange={handleExpiryDateChange}
                  onFocus={showFrontSide}
                  inputProps={{
                    ...register('expiryDate', {
                      validate: (value) => {
                        if (!value) return true;
                        const { isValid } = cardExpirationDateFormatValidator(value);
                        return isValid
                          ? true
                          : buildInvalidFieldTranslation('createCreditCardModal.expiryDate.label');
                      },
                    }),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <NumericTextField
                  decimalSeparator=""
                  allowNegative={false}
                  fullWidth
                  type="text"
                  error={!!errors.cvv}
                  label={t('createCreditCardModal.cvv.label')}
                  placeholder={t('createCreditCardModal.cvv.placeholder')}
                  helperText={errors.cvv?.message}
                  value={cvv}
                  onChange={handleCvvChange}
                  onFocus={showBackSide}
                  inputProps={{
                    ...register('cvv', {
                      validate: (value) => {
                        if (!value) return true;
                        return cardCvvValidator(value)
                          ? true : buildInvalidFieldTranslation('createCreditCardModal.cvv.label');
                      },
                    }),
                    maxLength: 4,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <Button
                isLoading={isLoading}
                disabled={isSubmitButtonDisabled}
                fullWidth
                type="submit"
              >
                {t('common.create')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                disabled={isLoading}
                fullWidth
                onClick={onClose}
              >
                {t('common.cancel')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(CreateCreditCardModal);
