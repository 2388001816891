import {
  BaseEitherError,
  decryptSymmetric,
  type Either,
} from '@uniqkey-frontend/shared-app';
import type {
  GetAllTrustedActionsResponseData,
} from '@uniqkey-backend-organization-web/api-client';
import type {
  ApproveTrustedPortalRequestedRequest,
} from '@uniqkey-backend-organization-mobile/api-client';
import type { ICompanionApplicationData } from '../../../services/companionApplicationService';
import {
  DecryptTrustedActionPayloadCommonDecryptionError,
  DecryptTrustedActionPayloadInitialDecryptionError,
  DecryptTrustedActionPayloadOrganizationPrivateDecryptionError,
  type TDecryptTrustedActionPayloadOperationErrors,
} from './errors';
import Operations from '..';

interface IDecryptTrustedActionPayloadOperationParams {
  payload: GetAllTrustedActionsResponseData['payload'];
  symmetricKey: ICompanionApplicationData['symmetricKey'];
}

interface IDecryptTrustedActionPayloadOperationReturn extends Pick<
  ApproveTrustedPortalRequestedRequest, 'mobileToken' | 'refreshToken'
>{
  organizationPrivateKey: ApproveTrustedPortalRequestedRequest['organizationPrivate'];
}

const decryptTrustedActionPayloadOperation = (
  params: IDecryptTrustedActionPayloadOperationParams,
): Either<
  IDecryptTrustedActionPayloadOperationReturn,
  TDecryptTrustedActionPayloadOperationErrors
> => {
  try {
    const { payload, symmetricKey } = params;

    const decryptedPayload = decryptSymmetric({
      cipher: payload,
      key: symmetricKey,
    });
    if (!decryptedPayload) {
      return new DecryptTrustedActionPayloadInitialDecryptionError();
    }

    const parsedDecryptedPayloadResult = Operations.jsonParseOperation<
      ApproveTrustedPortalRequestedRequest
    >(decryptedPayload);
    if (parsedDecryptedPayloadResult instanceof BaseEitherError) {
      return parsedDecryptedPayloadResult;
    }

    const decryptedOrganizationPrivateKey = decryptSymmetric({
      cipher: parsedDecryptedPayloadResult.organizationPrivate,
      key: symmetricKey,
    });
    if (!decryptedOrganizationPrivateKey) {
      return new DecryptTrustedActionPayloadOrganizationPrivateDecryptionError();
    }

    const { mobileToken, refreshToken } = parsedDecryptedPayloadResult;

    return {
      organizationPrivateKey: decryptedOrganizationPrivateKey,
      mobileToken,
      refreshToken,
    };
  } catch (e) {
    return new DecryptTrustedActionPayloadCommonDecryptionError();
  }
};

export default decryptTrustedActionPayloadOperation;
