import { decryptAsymmetric, encryptAsymmetric } from '@uniqkey-frontend/shared-app';
import { MoveVaultBetweenEmployeesPayload } from '@uniqkey-backend-organization-web/api-client';
import type { GetPublicKeyResponse } from '@uniqkey-backend-organization-mobile/api-client';
import APIClientsProvider from '../../../apiClientsProvider';
import type { ITrustedPortalHandlerParams } from '../../../interfaces';
import { dataExtractor } from '../../../../../helpers/apiClients';

const ERROR_PREFIX = 'TrustedPortal/handleMoveVaultBetweenEmployeesEvent';

const handleMoveVaultBetweenEmployeesEvent = async (
  params: ITrustedPortalHandlerParams<MoveVaultBetweenEmployeesPayload>,
) => {
  const {
    parsedEvent,
    axiosInstance,
    organizationPrivateKey,
    organizationPublicKey,
  } = params;

  const { parsedPayload, queueMessageId } = parsedEvent;
  const {
    vaultSymmetricKeyBackup,
    targetEmployeeAccountId,
  } = parsedPayload;

  if (!vaultSymmetricKeyBackup) {
    throw new Error(`${ERROR_PREFIX} no vaultSymmetricKeyBackup in event`);
  }

  if (!targetEmployeeAccountId) {
    throw new Error(`${ERROR_PREFIX} no targetEmployeeAccountId in event`);
  }

  const vaultSymmetricKey = await decryptAsymmetric({
    cipher: vaultSymmetricKeyBackup,
    privateKey: organizationPrivateKey,
    publicKey: organizationPublicKey,
  });

  const { employeeAccountKeyPairPublic } = await APIClientsProvider.Mobile
    .getEmployeeAccountsAPIClient(axiosInstance)
    .apiV1EmployeeAccountsGetPublicKeyEmployeeAccountIdGet(targetEmployeeAccountId)
    .then(dataExtractor<GetPublicKeyResponse>());

  const encryptedSymmetricKey = await encryptAsymmetric({
    string: vaultSymmetricKey,
    publicKey: employeeAccountKeyPairPublic,
  });

  await APIClientsProvider.Mobile.getQueueAPIClient(axiosInstance)
    .apiV1QueueMoveVaultBetweenEmployeesApprovePost({
      queueMessageId,
      vaultSymmetricKey: encryptedSymmetricKey,
    });
};

export default handleMoveVaultBetweenEmployeesEvent;
