import { TUseMutationOptions } from '@uniqkey-frontend/shared-app';
import {
  DeleteBulkVaultToEmployeeAccountRequest, DeleteBulkVaultToEmployeeAccountResponse,
} from '@uniqkey-backend-organization-web/api-client';
import { useMutation } from 'react-query';
import useVaultsToEmployeeAccountsAPI from '../../useVaultsToEmployeeAccountsAPI';

// TODO: remove this when more methods will be added
// eslint-disable-next-line import/prefer-default-export
export const useDeleteVaultsToEmployeeAccounts = (
  options: TUseMutationOptions<
    DeleteBulkVaultToEmployeeAccountResponse,
    unknown,
    DeleteBulkVaultToEmployeeAccountRequest,
    void
  > = {},
) => {
  const { deleteVaultsToEmployeeAccounts } = useVaultsToEmployeeAccountsAPI();
  return useMutation((
    deleteBulkVaultToEmployeeAccountRequest,
  ) => deleteVaultsToEmployeeAccounts(deleteBulkVaultToEmployeeAccountRequest), options);
};
