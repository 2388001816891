import {
  Dispatch, SetStateAction, useCallback, useState,
} from 'react';
import { upperFirst } from 'lodash';
import {
  useSnackbar,
  useTable,
  useQueryTableFetch,
  useTranslations,
  useTableLocalization,
  useFilterButton,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  useTablePreserverDecoderCustomConfigMerger,
  usePubSub,
  buildTablePreserverDecoderConfigItem,
  TablePreserverCommonInitialTableValues,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  IUseFilterButtonReturn,
  TUseTablePreserverDecoderConfigs,
  TablePreserverDecoderConfigItemTypeEnum,
  TUseTablePreserverDecoderCustomConfig,
  TSetSearchParams,
} from '@uniqkey-frontend/shared-app';
import {
  GroupOrderProperty,
} from '@uniqkey-backend-organization-web/api-client';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import { IGetGroupsParams, TGetGroupsMethod } from '../../useEmployeeGroupsAPI/interfaces';
import {
  IGroupsListFilterSubmitResult,
} from '../../../pages/GroupsPage/components/GroupsListFilter';
import {
  IEmployeeGroupsTabFilterSubmitResult,
// eslint-disable-next-line max-len
} from '../../../pages/EmployeePage/components/EmployeeGroupsTab/components/EmployeeGroupsTabFilter';
import useEmployeeGroupsAPI from '../../useEmployeeGroupsAPI';
import { IGroupsTableRow } from '../../../components/tables/GroupsTable';

export const REACT_QUERY_GROUPS_KEY = [ReactQueryKeyEnum.Groups];

interface IPersistentFilters extends Pick<IGetGroupsParams, 'employeeAccountId'> {}

export interface IUseGroupsTableParams {
  persistentFilters?: IPersistentFilters;
  customPreservationConfig?: ICustomPreservationConfig;
  noDataMessageKey: string;
}

export interface IUseGroupsTableReturn extends
  Omit<IUseTableReturn<IGroupsTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage' | 'resetSelectedRows'>,
  Omit<IUseQueryTableFetchReturn<TGetGroupsMethod>, 'data' | 'isFetchedAfterMount'>,
  IUseFilterButtonReturn
{
  groups: IUseQueryTableFetchReturn<TGetGroupsMethod>['data'];
  selectedGroups: IUseTableReturn<IGroupsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  searchQuery: IGetGroupsParams['searchQuery'];
  setSearchQuery: Dispatch<SetStateAction<IGetGroupsParams['searchQuery']>>;
  filterValues: IGroupsListFilterSubmitResult | IEmployeeGroupsTabFilterSubmitResult;
  setFilterValues: Dispatch<SetStateAction<
    IGroupsListFilterSubmitResult | IEmployeeGroupsTabFilterSubmitResult
  >>;
}

interface ITablePreservationConfigs extends
  Omit<IGetGroupsParams,
    'page' | 'pageLength' | 'employeeAccountId' | 'orderPropertyName' | 'isDescending'
  >,
  Pick<IUseTableReturn<IGroupsTableRow>, 'columnOrderBy' | 'columnOrderDirection'>
{
  activePage: IGetGroupsParams['page'];
  perPage: IGetGroupsParams['pageLength'];
  employeeAccount: IGroupsListFilterSubmitResult['employeeAccount'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  searchQuery: true,
  columnOrderBy: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: GroupOrderProperty },
  ),
  columnOrderDirection: true,
  canManageVaults: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.BOOLEAN,
  ),
  employeeAccount: true,
};

interface ICustomPreservationConfig extends TUseTablePreserverDecoderCustomConfig<
  ITablePreservationConfigs, 'employeeAccount'
> {}

const useGroupsTable = (
  params: IUseGroupsTableParams,
): IUseGroupsTableReturn => {
  const {
    persistentFilters,
    customPreservationConfig,
    noDataMessageKey,
  } = params;

  const queryClient = useQueryClient();
  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getGroups } = useEmployeeGroupsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const preservationConfig = useTablePreserverDecoderCustomConfigMerger<
    ITablePreservationConfigs, ICustomPreservationConfig
  >(PRESERVATION_CONFIGS, customPreservationConfig);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    canManageVaults: initialCanManageVaults,
    employeeAccount: initialEmployeeAccount,
    searchQuery: initialSearchQuery,
    columnOrderBy: initialColumnOrderBy,
    columnOrderDirection: initialColumnOrderDirection,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, preservationConfig);

  const [searchQuery, setSearchQuery] = useState<
    IGetGroupsParams['searchQuery']
  >(() => initialSearchQuery);
  const [filterValues, setFilterValues] = useState<
    IGroupsListFilterSubmitResult | IEmployeeGroupsTabFilterSubmitResult
  >({
    canManageVaults: initialCanManageVaults,
    employeeAccount: initialEmployeeAccount,
  });

  const { isFilterActive, numberOfActiveFilters } = useFilterButton(filterValues);

  const {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedRows: selectedGroups,
    resetTableMethods,
    ...restTableProps
  } = useTable<IGroupsTableRow>({
    initialActivePage,
    initialPerPage,
    initialColumnOrderBy,
    initialColumnOrderDirection,
  });

  const {
    resetTable,
    resetActivePage,
    resetSelectedRows,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
    setSearchQuery(TablePreserverCommonInitialTableValues.searchQuery);
    setFilterValues({
      canManageVaults: undefined,
      employeeAccount: undefined,
    });
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: groups, isLoading, isFetchedAfterMount, total, resetQuery,
  } = useQueryTableFetch({
    queryKey: REACT_QUERY_GROUPS_KEY,
    queryClient,
    request: getGroups,
    params: {
      page: activePage,
      pageLength: perPage,
      canManageVaults: filterValues.canManageVaults,
      employeeAccountId: (filterValues as IGroupsListFilterSubmitResult).employeeAccount?.value,
      searchQuery,
      orderPropertyName: GroupOrderProperty[
        upperFirst(columnOrderBy) as keyof typeof GroupOrderProperty
      ],
      isDescending: columnOrderDirection ? columnOrderDirection === 'desc' : undefined,
      ...persistentFilters,
    },
    onRequestError: handleOnRequestError,
  });

  const { localization } = useTableLocalization({
    searchQuery,
    isFilterActive,
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    searchQuery,
    columnOrderBy,
    columnOrderDirection,
    canManageVaults: filterValues.canManageVaults,
    employeeAccount: {
      value: (filterValues as IGroupsListFilterSubmitResult).employeeAccount,
      parser: JSON.stringify,
    },
  });

  return {
    groups,
    isLoading,
    total,
    resetQuery,
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedGroups,
    localization,
    searchQuery,
    setSearchQuery,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetActivePage,
    resetSelectedRows,
    ...restTableProps,
  };
};

export default useGroupsTable;
