import { useQuery, type QueryKey } from 'react-query';
import { TUseQueryOptions } from '@uniqkey-frontend/shared-app';
import { GetUnprocessedInfoResponse } from '@uniqkey-backend-organization-web/api-client';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import useQueueAPI from '../../useQueueAPI';

export const REACT_QUERY_UNPROCESSED_INFO_KEY = [ReactQueryKeyEnum.UnprocessedInfo];

interface IUseGetUnprocessedInfoParams {
  activeOrganizationId: string | null;
}

export const useGetUnprocessedInfo = (
  params: IUseGetUnprocessedInfoParams,
  options: TUseQueryOptions<GetUnprocessedInfoResponse> = {},
) => {
  const { activeOrganizationId } = params;
  const { getUnprocessedInfo } = useQueueAPI();
  return useQuery<GetUnprocessedInfoResponse>(
    (REACT_QUERY_UNPROCESSED_INFO_KEY as QueryKey[]).concat([activeOrganizationId]),
    ({ signal }) => getUnprocessedInfo({ signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};
