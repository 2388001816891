import { type Query, type QueryKey } from 'react-query';
import ReactQueryKeyEnum from '../../enums/ReactQueryKeyEnum';

const CORE_QUERIES: Set<QueryKey> = new Set([
  ReactQueryKeyEnum.CurrentUser,
  ReactQueryKeyEnum.CurrentEmployee,
]);

const isCoreQuery = (queryKey: QueryKey) => {
  if (Array.isArray(queryKey)) {
    return queryKey.some((queryKeyPart) => CORE_QUERIES.has(queryKeyPart));
  }
  return CORE_QUERIES.has(queryKey);
};

export const selectCoreReactQueries = (query: Query) => {
  const { queryKey } = query;
  return isCoreQuery(queryKey);
};

export const selectNonCoreReactQueries = (query: Query) => {
  const { queryKey } = query;
  return !isCoreQuery(queryKey);
};
