import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { isNumber } from 'lodash';
import {
  useTranslations, WidgetContainer, Typography, SecurityScoreWidget, Grid, Box, S5, S6,
} from '@uniqkey-frontend/shared-app';
import { EmployeeAccountStatus } from '@uniqkey-backend-organization-web/api-client';
import {
  useGetEmployeeAccountById, useGetEmployeeAverageSecurityScore,
} from '../../../../hooks/reactQuery';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import { EmployeeModuleTabEnum } from '../EmployeeModule';
import { parseSecurityScore } from '../../../../helpers/employee';

interface IEmployeeDetailsSecurityScoreWidgetProps {
  employeeAccountId: string;
}

const EmployeeDetailsSecurityScoreWidget = (props: IEmployeeDetailsSecurityScoreWidgetProps) => {
  const { employeeAccountId } = props;
  const { t } = useTranslations();
  const navigate = useNavigate();
  const {
    data: employeeAverageSecurityScore,
    isLoading: isGetEmployeeAverageSecurityScoreLoading,
    isError: isGetEmployeeAverageSecurityScoreError,
  } = useGetEmployeeAverageSecurityScore({ employeeAccountId });
  const { averageSecurityScore } = employeeAverageSecurityScore ?? {};

  const {
    data: employeeAccount,
    isLoading: isGetEmployeeAccountByIdLoading,
    isError: isGetEmployeeAccountByIdError,
  } = useGetEmployeeAccountById(
    { employeeAccountId },
  );
  const { employeeAccountStatus } = employeeAccount ?? {};
  const canHaveSecurityScore = employeeAccountStatus === EmployeeAccountStatus.Active
    || employeeAccountStatus === EmployeeAccountStatus.Archived;
  const handleViewLoginsClick = useCallback(() => {
    navigate(`${PageRouteEnum.Employees}/${employeeAccountId}/${EmployeeModuleTabEnum.Logins}`);
  }, [employeeAccountId, navigate]);

  const isLoading = isGetEmployeeAverageSecurityScoreLoading || isGetEmployeeAccountByIdLoading;
  const isError = isGetEmployeeAverageSecurityScoreError || isGetEmployeeAccountByIdError;

  if (isError) {
    return null;
  }

  if (!canHaveSecurityScore) {
    return (
      <WidgetContainer withShadow isLoading={isLoading}>
        <Typography variant="subtitle1" color={S6}>{t('common.securityScore')}</Typography>
        <Typography variant="caption" color={S5}>
          {t('employeeDetailsSecurityScoreWidget.userShouldBeInActiveStatus')}
        </Typography>
      </WidgetContainer>
    );
  }

  if (!isNumber(averageSecurityScore)) {
    return (
      <WidgetContainer withShadow isLoading={isLoading}>
        <Typography variant="subtitle1" color={S6}>{t('common.securityScore')}</Typography>
        <Typography variant="caption" color={S5}>
          {t('employeeDetailsSecurityScoreWidget.noSecurityScore')}
        </Typography>
      </WidgetContainer>
    );
  }

  return (
    <WidgetContainer container withShadow isLoading={isLoading} justifyContent="space-between">
      <Grid item xs={6}>
        <Typography variant="subtitle1" color={S6}>{t('common.securityScore')}</Typography>
        <Box mt={1} />
        <Typography variant="body1" color={S5}>
          {t('employeeDetailsSecurityScoreWidget.calculatedBased')}
        </Typography>
        <Box mt={2} />
        <Typography asLink variant="subtitle1" onClick={handleViewLoginsClick}>
          {t('employeeDetailsSecurityScoreWidget.viewLogins')}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Box pl={2} pb={2} pr={4} pt={4} display="flex" justifyContent="center">
          <SecurityScoreWidget percentage={parseSecurityScore(averageSecurityScore)} t={t} />
        </Box>
      </Grid>
    </WidgetContainer>
  );
};

export default memo(EmployeeDetailsSecurityScoreWidget);
