import PubSub from 'pubsub-js';
import { EmployeeTokenInfo, TokenSet } from '@uniqkey-backend-organization-web/api-client';
import { keyBy } from 'lodash';
import { decode, type JwtPayload } from 'jsonwebtoken';
import {
  storeCompanionApplicationData,
  removeCompanionApplicationData,
  getCompanionApplicationId,
  type ICompanionApplicationData,
} from '../companionApplicationService';
import LocalStorageKeyEnum from '../../enums/LocalStorageKeyEnum';
import PubSubEventEnum from '../../enums/PubSubEventEnum';
import { Dictionary } from '../../types/common';
import { clearContexts, logException } from '../sentryService';
import { logoutUser } from '../../api/userApi';

export interface ITokens {
  token: string;
  refreshToken: string;
}

export interface ILogoutParams {
  showMessage: boolean;
}

interface ILoginParams extends ICompanionApplicationData {
  tokens: TokenSet;
}

export const getEmployeesTokens = (): Dictionary<EmployeeTokenInfo> | null => {
  try {
    const tokens = localStorage.getItem(LocalStorageKeyEnum.EmployeesTokens);

    if (!tokens) return null;

    return JSON.parse(
      tokens,
    ) as Dictionary<EmployeeTokenInfo>;
  } catch (e) {
    logException(e, {
      message: 'getEmployeesTokens exception',
    });
    return null;
  }
};
export const setEmployeesTokens = (tokens: Dictionary<EmployeeTokenInfo> | null): void => {
  if (!tokens) {
    localStorage.removeItem(LocalStorageKeyEnum.EmployeesTokens);
    return;
  }
  localStorage.setItem(LocalStorageKeyEnum.EmployeesTokens, JSON.stringify(tokens));
};

export const getUserTokens = (): ITokens | null => {
  try {
    const tokens = localStorage.getItem(LocalStorageKeyEnum.UserTokens);

    if (!tokens) return null;

    return JSON.parse(
      tokens,
    ) as ITokens;
  } catch (e) {
    return null;
  }
};
export const setUserTokens = (userTokens: ITokens | null): void => {
  if (!userTokens) {
    localStorage.removeItem(LocalStorageKeyEnum.UserTokens);
    return;
  }
  localStorage.setItem(LocalStorageKeyEnum.UserTokens, JSON.stringify(userTokens));
};

const initShouldShowUnprocessedWarnings = (tokens: Dictionary<EmployeeTokenInfo> | null): void => {
  if (!tokens) return;
  const initialShouldShowUnprocessedWarnings = Object.keys(tokens)
    .reduce((acc: Record<string, boolean>, id) => {
      acc[id] = true;
      return acc;
    }, {});
  const stringifiedState = JSON.stringify(initialShouldShowUnprocessedWarnings);
  localStorage.setItem(
    LocalStorageKeyEnum.ShouldShowUnprocessedEventsWarning,
    stringifiedState,
  );
  localStorage.setItem(
    LocalStorageKeyEnum.ShouldShowUnprocessedEmployeesWarning,
    stringifiedState,
  );
};

export const getShouldShowUnprocessedEmployeesWarningByOrganizationId = (organizationId: string)
  : boolean => {
  try {
    const shouldShowUnprocessedEmployeesWarning = localStorage.getItem(
      LocalStorageKeyEnum.ShouldShowUnprocessedEmployeesWarning,
    );
    if (!shouldShowUnprocessedEmployeesWarning) return false;
    return JSON.parse(shouldShowUnprocessedEmployeesWarning)[organizationId];
  } catch (e) {
    return false;
  }
};

export const setShouldShowUnprocessedEmployeesWarningByOrganizationId = (
  organizationId: string,
  isShown: boolean,
): void => {
  try {
    const shouldShowUnprocessedEmployeesWarning = localStorage.getItem(
      LocalStorageKeyEnum.ShouldShowUnprocessedEmployeesWarning,
    );
    const shouldShowUnprocessedEmployeesWarningParsed = shouldShowUnprocessedEmployeesWarning
      ? JSON.parse(shouldShowUnprocessedEmployeesWarning) : {};
    localStorage.setItem(
      LocalStorageKeyEnum.ShouldShowUnprocessedEmployeesWarning,
      JSON.stringify({ ...shouldShowUnprocessedEmployeesWarningParsed, [organizationId]: isShown }),
    );
  } catch (e) {
    localStorage.setItem(
      LocalStorageKeyEnum.ShouldShowUnprocessedEmployeesWarning,
      JSON.stringify({ [organizationId]: isShown }),
    );
  }
};

export const getShouldShowUnprocessedEventsWarningByOrganizationId = (organizationId: string)
  : boolean => {
  try {
    const shouldShowUnprocessedEventsWarning = localStorage.getItem(
      LocalStorageKeyEnum.ShouldShowUnprocessedEventsWarning,
    );
    if (!shouldShowUnprocessedEventsWarning) return false;
    return JSON.parse(shouldShowUnprocessedEventsWarning)[organizationId];
  } catch (e) {
    return false;
  }
};

export const setShouldShowUnprocessedEventsWarningByOrganizationId = (
  organizationId: string,
  isShown: boolean,
): void => {
  try {
    const shouldShowUnprocessedEventsWarning = localStorage.getItem(
      LocalStorageKeyEnum.ShouldShowUnprocessedEventsWarning,
    );
    const shouldShowUnprocessedEventsWarningParsed = shouldShowUnprocessedEventsWarning
      ? JSON.parse(shouldShowUnprocessedEventsWarning) : {};
    localStorage.setItem(
      LocalStorageKeyEnum.ShouldShowUnprocessedEventsWarning,
      JSON.stringify({ ...shouldShowUnprocessedEventsWarningParsed, [organizationId]: isShown }),
    );
  } catch (e) {
    localStorage.setItem(
      LocalStorageKeyEnum.ShouldShowUnprocessedEventsWarning,
      JSON.stringify({ [organizationId]: isShown }),
    );
  }
};

const clearShouldShowUnprocessedWarnings = (): void => {
  localStorage.removeItem(LocalStorageKeyEnum.ShouldShowUnprocessedEventsWarning);
  localStorage.removeItem(LocalStorageKeyEnum.ShouldShowUnprocessedEmployeesWarning);
};

export const logoutMe = async () => {
  try {
    const parsedUserToken = getDecodedUserToken();
    if (!parsedUserToken) {
      return;
    }
    const companionApplicationId = getCompanionApplicationId();
    if (!companionApplicationId) {
      return;
    }
    const { id: userId } = parsedUserToken;
    await logoutUser({ userId, companionApplicationId });
  } catch (e) {
    // do not throw an error on failed logout
  }
};

export const logout = (params?: ILogoutParams) => {
  setEmployeesTokens(null);
  setUserTokens(null);
  clearShouldShowUnprocessedWarnings();
  removeCompanionApplicationData();
  clearContexts();
  PubSub.publish(PubSubEventEnum.LOGOUT, params);
};

export const login = (params: ILoginParams) => {
  const { tokens, guid, symmetricKey } = params;
  const { employeeTokens, userToken } = tokens;
  if (!employeeTokens?.length) {
    throw new Error('no employee tokens found');
  }
  const employeeTokensByOrganizationId = keyBy<EmployeeTokenInfo>(employeeTokens, 'organizationId');
  storeCompanionApplicationData({ guid, symmetricKey });
  setEmployeesTokens(employeeTokensByOrganizationId);
  setUserTokens(userToken);
  initShouldShowUnprocessedWarnings(employeeTokensByOrganizationId);
  PubSub.publish(PubSubEventEnum.LOGIN);
};

export const getCurrentTimeInSeconds = (): number => Math.floor(Date.now() / 1000);

export const getLastActivityTS = (): number => {
  const lastActivityTS = localStorage.getItem(LocalStorageKeyEnum.LastActivityTS);
  const parsedLastActivityTS = parseInt(lastActivityTS as string, 10);
  return Number.isNaN(parsedLastActivityTS) ? 0 : parsedLastActivityTS;
};

export const setLastActivityTS = (time?: number): void => {
  const now = time ?? getCurrentTimeInSeconds();
  localStorage.setItem(LocalStorageKeyEnum.LastActivityTS, now.toString());
};

export const removeLastActivityTS = (): void => {
  localStorage.removeItem(LocalStorageKeyEnum.LastActivityTS);
};

export const getDecodedUserToken = (): JwtPayload | null => {
  const tokens = getUserTokens();
  if (!tokens) {
    return null;
  }
  const { token } = tokens;
  return decode(token) as JwtPayload;
};

export const isAuthenticated = () => !!getUserTokens();
