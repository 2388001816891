import {
  EmployeeAccountStatus, EmployeeAccountType,
} from '@uniqkey-backend-organization-web/api-client';

// TODO: remove this when more methods will be added
// eslint-disable-next-line import/prefer-default-export
export const isSupporter = (role?: EmployeeAccountType) => role === EmployeeAccountType.Supporter;

export const canHaveSecurityScore = (employeeAccountStatus: EmployeeAccountStatus) => (
  employeeAccountStatus === EmployeeAccountStatus.Active
  || employeeAccountStatus === EmployeeAccountStatus.Archived);

export const parseSecurityScore = (securityScore: number) => Math.floor(securityScore);
