import { useCallback, useMemo, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  useTranslations,
  IUseTableReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  TypographyWithTooltip,
  getTimeFromNow,
  IMaterialTableOptions,
  IMaterialTableProps,
} from '@uniqkey-frontend/shared-app';
import { GetGroupsByVaultIdResponseModel } from '@uniqkey-backend-organization-web/api-client';
import PageRouteEnum from '../../../enums/PageRouteEnum';
import { TGetGroupsByVaultIdMethod } from '../../../hooks/useEmployeeGroupsAPI/interfaces';

export interface IOrganizationLoginGroupsTableRow extends GetGroupsByVaultIdResponseModel {
  lastActivity: string | null;
}

interface IOrganizationLoginGroupsTableProps extends
  Omit<IUseTableReturn<IOrganizationLoginGroupsTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Omit<
    IUseQueryTableFetchReturn<TGetGroupsByVaultIdMethod>,
    'data' | 'resetQuery' | 'isFetchedAfterMount'
  >
{
  groups: IUseQueryTableFetchReturn<TGetGroupsByVaultIdMethod>['data'];
  selectedGroups: IUseTableReturn<IOrganizationLoginGroupsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

const TABLE_OPTIONS: IMaterialTableOptions<IOrganizationLoginGroupsTableRow> = {
  selection: false,
};

const TOOLTIP_PROPS = { cursorPointer: true };

const OrganizationLoginGroupsTable = (props: IOrganizationLoginGroupsTableProps) => {
  const {
    groups: preGroups,
    isLoading,
    total,
    localization,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    columnOrderBy,
    onColumnOrderByChange,
    columnOrderDirection,
    onColumnOrderDirectionChange,
    selectedGroups,
    onRowSelectionChange,
    createColumns,
  } = props;

  const { t, currentLanguage } = useTranslations();
  const navigate = useNavigate();

  const groups = useMemo(() => preGroups.map((group) => ({
    ...group,
    lastActivity: getTimeFromNow({
      date: group.activityAt,
      locale: currentLanguage,
    }),
  })), [preGroups, currentLanguage]);

  const columns = useMemo(() => createColumns([
    {
      title: t('organizationLoginPage.groupsTab.table.columns.name'),
      field: 'name',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.name}
        </TypographyWithTooltip>
      ),
      width: '55%',
    },
    {
      title: t('organizationLoginPage.groupsTab.table.columns.members'),
      field: 'membersCount',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.membersCount}
        </TypographyWithTooltip>
      ),
      width: '20%',
    },
    {
      title: (
        <TypographyWithTooltip
          variant="subtitle1"
          tooltipProps={TOOLTIP_PROPS}
          lineClamp={5}
          title={t('organizationLoginPage.groupsTab.table.columns.lastActivity.columnTooltip')}
        >
          {t('organizationLoginPage.groupsTab.table.columns.lastActivity')}
        </TypographyWithTooltip>
      ),
      field: 'activityAt',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.lastActivity}
        </TypographyWithTooltip>
      ),
      width: '25%',
    },
  ]), [createColumns, t]);

  const handleGroupRowClick = useCallback<
    NonNullable<IMaterialTableProps<IOrganizationLoginGroupsTableRow>['onRowClick']>
  >((event, group) => {
    navigate(`${PageRouteEnum.Groups}/${group!.groupId}`);
  }, [navigate]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={groups}
      isLoading={isLoading}
      options={TABLE_OPTIONS}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      columnOrderBy={columnOrderBy}
      onColumnOrderByChange={onColumnOrderByChange}
      columnOrderDirection={columnOrderDirection}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedGroups}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      onRowClick={handleGroupRowClick}
      rowSelectByKey="groupId"
      rowHeight={51}
    />
  );
};

export default memo(OrganizationLoginGroupsTable);
