import type { AxiosInstance } from 'axios';
import type { RefreshTokenResponse } from '@uniqkey-backend-organization-web/api-client';
import Helpers from '../../helpers';
import ApiClientsProvider from '../../../../common/apiClientsProvider';
import SupportJumpStoreActions from '../../store/actions';
import { dataExtractor } from '../../../../helpers/apiClients';
import {
  SupportJumpRefreshSupportTokensFetchError,
  SupportJumpRefreshSupportTokensMissingUserTokenError,
} from './errors';

const refreshSupportTokensOperation = (instance: AxiosInstance) => async (
  failedRequest: any,
) => {
  try {
    const userToken = SupportJumpStoreActions.getUserToken();
    if (!userToken) {
      Helpers.handleError(new SupportJumpRefreshSupportTokensMissingUserTokenError());
      return Promise.reject();
    }

    const { tokens } = await ApiClientsProvider.getUserAPIClient(instance)
      .apiV1UserRefreshTokenPost(userToken)
      .then(dataExtractor<RefreshTokenResponse>());

    Helpers.setTokens(tokens);

    const employeeToken = SupportJumpStoreActions.getEmployeeToken();
    if (employeeToken) {
      // eslint-disable-next-line no-param-reassign
      failedRequest.response.config.headers.Authorization = `Bearer ${employeeToken.token}`;
    }
    return Promise.resolve();
  } catch (e) {
    Helpers.handleError(new SupportJumpRefreshSupportTokensFetchError());
    return Promise.reject();
  }
};

export default refreshSupportTokensOperation;
