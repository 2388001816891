import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';
import {
  ApplicationGetResponse,
  ApplicationGetForGroupResponse,
  ApplicationGetByIdV2Response,
  ApplicationGetListResponse,
  GetDomainRulesAndDesktopApplicationsByApplicationIdResponse,
} from '@uniqkey-backend-organization-web/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import {
  TGetServicesListMethod,
  TGetServicesListForGroupMethod,
  TGetApplicationsMethod,
} from './interfaces';
import { dataExtractor } from '../../helpers/apiClients';

const useApplicationsAPI = () => {
  const { applicationsAPIClient } = useApiClients();

  const getServicesList = useCallback<TGetServicesListMethod>(
    (params, axiosRequestConfig) => {
      const {
        page,
        pageLength,
        minSecurityScore = undefined,
        maxSecurityScore = undefined,
        searchQuery = undefined,
        orderPropertyName = undefined,
        isSecurityScoreEmpty = undefined,
        isDescending = undefined,
      } = params;
      return applicationsAPIClient.apiV2ApplicationsGetServiceListGet(
        page,
        pageLength,
        minSecurityScore,
        maxSecurityScore,
        searchQuery,
        orderPropertyName,
        isDescending,
        isSecurityScoreEmpty,
        axiosRequestConfig,
      ).then(dataExtractor<ApplicationGetResponse>());
    },
    [applicationsAPIClient],
  );

  const getServicesListForGroup = useCallback<TGetServicesListForGroupMethod>(
    (params, axiosRequestConfig) => {
      const {
        page,
        pageLength,
        groupId,
        minStrength = undefined,
        maxStrength = undefined,
        searchQuery = undefined,
        orderPropertyName = undefined,
        isDescending = undefined,
      } = params;
      return applicationsAPIClient.apiV2ApplicationsGetServiceListForGroupGet(
        page,
        pageLength,
        groupId,
        minStrength,
        maxStrength,
        searchQuery,
        orderPropertyName,
        isDescending,
        axiosRequestConfig,
      ).then(dataExtractor<ApplicationGetForGroupResponse>());
    },
    [applicationsAPIClient],
  );

  const getApplicationById = useCallback((applicationId: string, options?: AxiosRequestConfig)
    :Promise<ApplicationGetByIdV2Response> => applicationsAPIClient
    .apiV2ApplicationsApplicationIdGet(applicationId, options)
    .then(dataExtractor<ApplicationGetByIdV2Response>()), [applicationsAPIClient]);

  const getApplications = useCallback<TGetApplicationsMethod>(
    (params, axiosRequestConfig) => {
      const {
        page,
        pageLength,
        searchQuery = undefined,
      } = params;
      return applicationsAPIClient.apiV1ApplicationsGet(
        page,
        pageLength,
        searchQuery,
        axiosRequestConfig,
      ).then(dataExtractor<ApplicationGetListResponse>());
    },
    [applicationsAPIClient],
  );

  const getServicesByApplicationId = useCallback(
    (applicationId: string, options?: AxiosRequestConfig)
    :Promise<GetDomainRulesAndDesktopApplicationsByApplicationIdResponse> => applicationsAPIClient
      .apiV1ApplicationsGetDomainRulesAndDesktopApplicationsByApplicationIdApplicationIdGet(
        applicationId,
        options,
      )
      .then(dataExtractor<GetDomainRulesAndDesktopApplicationsByApplicationIdResponse>()),
    [applicationsAPIClient],
  );

  return {
    getServicesList,
    getServicesListForGroup,
    getApplicationById,
    getApplications,
    getServicesByApplicationId,
  };
};

export default useApplicationsAPI;
