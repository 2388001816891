import { memo } from 'react';
import { Grid, PanelContent } from '@uniqkey-frontend/shared-app';
import ServiceDetailsWidget from '../ServiceDetailsWidget';
import ServiceDetailsSecurityScoreWidget from '../ServiceDetailsSecurityScoreWidget';

interface IServiceDetailsTabProps {
  applicationId: string;
}

const ServiceDetailsTab = (props: IServiceDetailsTabProps) => {
  const { applicationId } = props;

  return (
    <PanelContent p={3} pt={1} className="height-100-percent">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ServiceDetailsWidget applicationId={applicationId} />
        </Grid>
        <Grid item xs={6}>
          <ServiceDetailsSecurityScoreWidget applicationId={applicationId} />
        </Grid>
      </Grid>
    </PanelContent>
  );
};

export default memo(ServiceDetailsTab);
