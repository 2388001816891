import { omit } from 'lodash';
import {
  useTrustedPortalStore,
  INITIAL_TRUSTED_PORTAL_STORE_STATE,
  type ITrustedPortalStore,
} from '../index';
import Helpers from '../../helpers';

const LOG_COLOR = 'magenta';

const getIsInitializing = () => useTrustedPortalStore.getState().isInitializing;

const setIsInitializing = (
  isInitializing: ITrustedPortalStore['isInitializing'],
) => {
  Helpers.tpLogMessage({
    messages: [
      '[STORE] change isInitializing',
      `\n\tisInitializing : ${isInitializing}`,
    ],
    color: LOG_COLOR,
  });
  useTrustedPortalStore.setState({ isInitializing });
};

const getIsInitialized = () => useTrustedPortalStore.getState().isInitialized;

const setIsInitialized = (
  isInitialized: ITrustedPortalStore['isInitialized'],
) => {
  Helpers.tpLogMessage({
    messages: [
      '[STORE] change isInitialized',
      `\n\tisInitialized : ${isInitialized}`,
    ],
    color: LOG_COLOR,
  });
  useTrustedPortalStore.setState({ isInitialized });
};

const getIsEnabledByOrganizationId = (organizationId: string) => {
  const state = useTrustedPortalStore.getState();
  return state.isEnabledByOrganizationId[organizationId] ?? false;
};

const setIsEnabledByOrganizationId = (
  organizationId: string,
  isEnabled: boolean,
) => {
  Helpers.tpLogMessage({
    messages: [
      '[STORE] change isEnabled',
      `\n\torganizationId : ${organizationId}`,
      `\n\tisEnabled   : ${isEnabled}`,
    ],
    color: LOG_COLOR,
  });
  useTrustedPortalStore.setState((state) => ({
    isEnabledByOrganizationId: {
      ...state.isEnabledByOrganizationId,
      [organizationId]: isEnabled,
    },
  }));
};

const removeIsEnabledByOrganizationId = (organizationId: string) => {
  Helpers.tpLogMessage({
    messages: [
      '[STORE] remove from store: isEnabled',
      `\n\torganizationId : ${organizationId}`,
    ],
    color: LOG_COLOR,
  });
  useTrustedPortalStore.setState((state) => ({
    isEnabledByOrganizationId: omit(state.isEnabledByOrganizationId, organizationId),
  }));
};

const getIsProcessingByOrganizationId = (organizationId: string) => {
  const state = useTrustedPortalStore.getState();
  return state.isProcessingByOrganizationId[organizationId] ?? false;
};

const setIsProcessingByOrganizationId = (
  organizationId: string,
  isProcessing: boolean,
) => {
  Helpers.tpLogMessage({
    messages: [
      '[STORE] change isProcessing',
      `\n\torganizationId : ${organizationId}`,
      `\n\tisProcessing   : ${isProcessing}`,
    ],
    color: LOG_COLOR,
  });
  useTrustedPortalStore.setState((state) => ({
    isProcessingByOrganizationId: {
      ...state.isProcessingByOrganizationId,
      [organizationId]: isProcessing,
    },
  }));
};

const removeIsProcessingByOrganizationId = (organizationId: string) => {
  Helpers.tpLogMessage({
    messages: [
      '[STORE] remove from store: isProcessing',
      `\n\torganizationId : ${organizationId}`,
    ],
    color: LOG_COLOR,
  });
  useTrustedPortalStore.setState((state) => ({
    isProcessingByOrganizationId: omit(state.isProcessingByOrganizationId, organizationId),
  }));
};

const resetStore = (initialStoreOverrides: Partial<ITrustedPortalStore> = {}) => {
  const state = useTrustedPortalStore.getState();
  const newState: ITrustedPortalStore = {
    ...INITIAL_TRUSTED_PORTAL_STORE_STATE,
    ...initialStoreOverrides,
    isInitializing: state.isInitializing,
    isInitialized: state.isInitialized,
  };
  Helpers.tpLogMessage({
    messages: [
      '[STORE] reset',
      `\nnew state: ${JSON.stringify(newState, null, 2)}`,
    ],
    color: LOG_COLOR,
  });
  useTrustedPortalStore.setState(newState, true);
};

export default {
  getIsInitializing,
  setIsInitializing,
  getIsInitialized,
  setIsInitialized,
  getIsEnabledByOrganizationId,
  setIsEnabledByOrganizationId,
  removeIsEnabledByOrganizationId,
  getIsProcessingByOrganizationId,
  setIsProcessingByOrganizationId,
  removeIsProcessingByOrganizationId,
  resetStore,
};
