import { type ReactNode, useEffect } from 'react';
import { useUser } from '../../contexts/UserContext';
import { useCompanionApplicationContext } from '../../contexts/CompanionApplicationContext';
import { initWebSockets } from '../../services/webSocketsManager';
import TrustedPortalModule from '../../modules/TrustedPortalModule';

interface IInitializationContainerProps {
  children: ReactNode;
}
const InitializationContainer = (props: IInitializationContainerProps) => {
  const { children } = props;

  const { isAuthenticated } = useUser();
  const { guid } = useCompanionApplicationContext();

  useEffect(() => {
    initWebSockets(guid);
  }, [guid]);

  useEffect(() => {
    if (isAuthenticated) {
      TrustedPortalModule.initModule().catch(() => {});
    } else {
      TrustedPortalModule.resetTrustedPortal();
    }
  }, [isAuthenticated]);

  return children;
};

export default InitializationContainer;
