import { ChangeEvent, memo } from 'react';
import {
  Button,
  DialogActions,
  MenuItem,
  Select,
  Typography,
  useTranslations,
  Grid,
  TSelectProps,
} from '@uniqkey-frontend/shared-app';

import InviteEmployeesRadioGroup from '../InviteEmployeesRadioGroup';
import { useLanguages } from '../../../../../../hooks/reactQuery';

interface IInviteEmployeesDialogActionsProps {
  isDisabled: boolean;
  onLanguageChange: TSelectProps['onChange'];
  language: string;
  onSubmit: () => void;
  isLoading: boolean;
  onRadioGroupChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const InviteEmployeesDialogActions = (props: IInviteEmployeesDialogActionsProps) => {
  const {
    onLanguageChange, language, onSubmit, isDisabled, isLoading, onRadioGroupChange,
  } = props;

  const { languages, isLoading: areLanguagesLoading } = useLanguages({
    includeOnlyAllowedLanguages: false,
  });

  const { t } = useTranslations();

  return (
    <DialogActions paddingTop={1}>
      <Grid container rowGap={2}>
        <Grid item xs={12}>
          <InviteEmployeesRadioGroup onRadioGroupChange={onRadioGroupChange} />
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4} textAlign="end">
            <Typography color="primary">
              {t('inviteEmployeesModal.langSelectorLabel')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            {
              !areLanguagesLoading && (
                <Select
                  fullWidth
                  value={language}
                  label={t('inviteEmployeesModal.selectLang')}
                  onChange={onLanguageChange}
                >
                  {
                    languages.map((currLanguage) => (
                      <MenuItem key={currLanguage.code} value={currLanguage.code}>
                        {currLanguage.localized_name}
                      </MenuItem>
                    ))
                  }
                </Select>
              )
            }
          </Grid>
          <Grid item xs={4}>
            <Button
              disabled={isDisabled}
              type="submit"
              onClick={onSubmit}
              isLoading={isLoading}
              fullWidth
            >
              {t('common.add')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </DialogActions>
  );
};

export default memo(InviteEmployeesDialogActions);
