import { decryptAsymmetric, decryptSymmetric } from '@uniqkey-frontend/shared-app';
import {
  type GetPrivateBackupFromMigrationResponse,
} from '@uniqkey-backend-organization-mobile/api-client';
import {
  type ApproveMasterPasswordResetRequestPayload,
} from '@uniqkey-backend-organization-web/api-client';
import type { ITrustedPortalHandlerParams } from '../../../../interfaces';
import APIClientsProvider from '../../../../apiClientsProvider';
import { dataExtractor } from '../../../../../../helpers/apiClients';

const ERROR_PREFIX = 'TrustedPortal/getEmployeePrivateBackupFromMigration';

type TParsedPayload = ITrustedPortalHandlerParams<
  ApproveMasterPasswordResetRequestPayload
>['parsedEvent']['parsedPayload'];

interface IGetEmployeePrivateBackupFromMigrationParams extends Pick<
  ITrustedPortalHandlerParams<ApproveMasterPasswordResetRequestPayload>,
  'axiosInstance' | 'organizationPrivateKey' | 'organizationPublicKey'
>{
  employeeAccountId: NonNullable<TParsedPayload['employeeAccountId']>;
}

interface IGetEmployeePrivateBackupFromMigrationReturn {
  organizationId: string;
  employeeAccountId: string;
  employeeAccountPrivateKey: string;
}

const getEmployeePrivateBackupFromMigration = async (
  params: IGetEmployeePrivateBackupFromMigrationParams,
): Promise<IGetEmployeePrivateBackupFromMigrationReturn[]> => {
  const {
    axiosInstance,
    organizationPrivateKey,
    organizationPublicKey,
    employeeAccountId,
  } = params;

  const { backups } = await APIClientsProvider.Mobile
    .getEmployeeAccountsAPIClient(axiosInstance)
    .apiV1EmployeeAccountsGetPrivateBackupFromMigrationEmployeeAccountIdGet(employeeAccountId)
    .then(dataExtractor<GetPrivateBackupFromMigrationResponse>());

  if (!backups) {
    throw new Error(`${ERROR_PREFIX} backups not found`);
  }

  return Promise.all(backups.map(async (backup) => {
    const { organizationId, employeeAccountPrivateKey, privateKeyBackupSymmetricKey } = backup;
    if (!organizationId) {
      throw new Error(`${ERROR_PREFIX} organizationId not found`);
    }
    if (!employeeAccountPrivateKey) {
      throw new Error(`${ERROR_PREFIX} employeeAccountPrivateKey not found`);
    }
    if (!privateKeyBackupSymmetricKey) {
      throw new Error(`${ERROR_PREFIX} privateKeyBackupSymmetricKey not found`);
    }
    if (!backup.employeeAccountId) {
      throw new Error(`${ERROR_PREFIX} employeeAccountId not found`);
    }
    const decryptedPrivateKeyBackupSymmetricKey = await decryptAsymmetric({
      publicKey: organizationPublicKey,
      privateKey: organizationPrivateKey,
      cipher: privateKeyBackupSymmetricKey,
    });
    const decryptedEmployeeAccountPrivateKey = decryptSymmetric({
      key: decryptedPrivateKeyBackupSymmetricKey,
      cipher: employeeAccountPrivateKey,
    });
    if (!decryptedEmployeeAccountPrivateKey) {
      throw new Error(`${
        ERROR_PREFIX
      } decryptedEmployeeAccountPrivateKey not found or not decrypted`);
    }
    return {
      organizationId,
      employeeAccountId: backup.employeeAccountId,
      employeeAccountPrivateKey: decryptedEmployeeAccountPrivateKey,
    };
  }));
};

export default getEmployeePrivateBackupFromMigration;
