import { useMemo, ReactNode } from 'react';
import {
  EmployeeAccountsApi,
  EmployeeAccountsToGroupsApi,
  GroupsApi,
  OrganizationApi,
  VaultsApi,
  SecuritySettingsApi,
  ScimSettingsApi,
  VaultsToEmployeeAccountsApi,
  VaultsToGroupsApi,
  VerifiedDomainsApi,
  ApplicationsApi,
  GroupRestrictionsApi,
  IpGroupRestrictionsApi,
  TimeOfDayGroupRestrictionsApi,
  GeolocationGroupRestrictionsApi,
  AuditLogsApi,
  DashboardApi,
  MasterPasswordResetRequestsApi,
  PairingCodesApi,
  DomainRulesApi,
  DesktopApplicationsApi,
  QueueApi,
  TrustedActionsApi,
  MigrationApi,
  PartnerSupportRequestsApi,
  OrganizationSettingsApi,
} from '@uniqkey-backend-organization-web/api-client';
import {
  employeeAxiosInstance,
  publicAxiosInstance,
  userAxiosInstance,
} from '../../axios';
import APIClientsContext from '.';
import config from '../../config';
import ApiClientsProvider from '../../common/apiClientsProvider';

const WEB_API_BASE_URL = config.getWebApiUrl();

const APIClientsProviderContext = ({ children }: { children: ReactNode }): JSX.Element => {
  const value = useMemo(() => ({
    employeeAxiosInstance,
    userAxiosInstance,
    employeeAccountsAPIClient: new EmployeeAccountsApi(
      undefined,
      WEB_API_BASE_URL,
      employeeAxiosInstance,
    ),
    employeeGroupsAPIClient: new GroupsApi(
      undefined,
      WEB_API_BASE_URL,
      employeeAxiosInstance,
    ),
    employeeAccountsToGroupsAPIClient: new EmployeeAccountsToGroupsApi(
      undefined,
      WEB_API_BASE_URL,
      employeeAxiosInstance,
    ),
    organizationAPIClient: new OrganizationApi(
      undefined,
      WEB_API_BASE_URL,
      employeeAxiosInstance,
    ),
    vaultsAPIClient: new VaultsApi(
      undefined,
      WEB_API_BASE_URL,
      employeeAxiosInstance,
    ),
    organizationSecuritySettingsAPIClient: new SecuritySettingsApi(
      undefined,
      WEB_API_BASE_URL,
      employeeAxiosInstance,
    ),
    organizationScimSettingsAPIClient: new ScimSettingsApi(
      undefined,
      WEB_API_BASE_URL,
      employeeAxiosInstance,
    ),
    vaultsToEmployeeAccountsAPIClient: new VaultsToEmployeeAccountsApi(
      undefined,
      WEB_API_BASE_URL,
      employeeAxiosInstance,
    ),
    vaultsToGroupsAPIClient: new VaultsToGroupsApi(
      undefined,
      WEB_API_BASE_URL,
      employeeAxiosInstance,
    ),
    verifiedDomainsAPIClient: new VerifiedDomainsApi(
      undefined,
      WEB_API_BASE_URL,
      employeeAxiosInstance,
    ),
    applicationsAPIClient: new ApplicationsApi(
      undefined,
      WEB_API_BASE_URL,
      employeeAxiosInstance,
    ),
    groupRestrictionsAPIClient: new GroupRestrictionsApi(
      undefined,
      WEB_API_BASE_URL,
      employeeAxiosInstance,
    ),
    ipGroupRestrictionsAPIClient: new IpGroupRestrictionsApi(
      undefined,
      WEB_API_BASE_URL,
      employeeAxiosInstance,
    ),
    timeOfDayGroupRestrictionsAPIClient: new TimeOfDayGroupRestrictionsApi(
      undefined,
      WEB_API_BASE_URL,
      employeeAxiosInstance,
    ),
    geolocationGroupRestrictionsAPIClient: new GeolocationGroupRestrictionsApi(
      undefined,
      WEB_API_BASE_URL,
      employeeAxiosInstance,
    ),
    auditLogsAPIClient: new AuditLogsApi(
      undefined,
      WEB_API_BASE_URL,
      employeeAxiosInstance,
    ),
    dashboardAPIClient: new DashboardApi(
      undefined,
      WEB_API_BASE_URL,
      employeeAxiosInstance,
    ),
    userAPIClient: ApiClientsProvider.getUserAPIClient(userAxiosInstance),
    masterPasswordResetRequestsAPIClient: new MasterPasswordResetRequestsApi(
      undefined,
      WEB_API_BASE_URL,
      employeeAxiosInstance,
    ),
    pairingCodesAPIClient: new PairingCodesApi(
      undefined,
      WEB_API_BASE_URL,
      publicAxiosInstance,
    ),
    domainRulesAPIClient: new DomainRulesApi(
      undefined,
      WEB_API_BASE_URL,
      employeeAxiosInstance,
    ),
    desktopApplicationsAPIClient: new DesktopApplicationsApi(
      undefined,
      WEB_API_BASE_URL,
      employeeAxiosInstance,
    ),
    queueAPIClient: new QueueApi(
      undefined,
      WEB_API_BASE_URL,
      employeeAxiosInstance,
    ),
    trustedActionsAPIClient: new TrustedActionsApi(
      undefined,
      WEB_API_BASE_URL,
      userAxiosInstance,
    ),
    supportedUILanguagesApi: ApiClientsProvider.getSupportedUILanguagesAPIClient(
      publicAxiosInstance,
    ),
    migrationAPIClient: new MigrationApi(
      undefined,
      WEB_API_BASE_URL,
      employeeAxiosInstance,
    ),
    partnerSupportRequestsAPIClient: new PartnerSupportRequestsApi(
      undefined,
      WEB_API_BASE_URL,
      employeeAxiosInstance,
    ),
    organizationSettingsAPIClient: new OrganizationSettingsApi(
      undefined,
      WEB_API_BASE_URL,
      employeeAxiosInstance,
    ),
  }), []);

  return (
    <APIClientsContext.Provider value={value}>
      {children}
    </APIClientsContext.Provider>
  );
};

export default APIClientsProviderContext;
