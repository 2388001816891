import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';
import { BulkSendInviteEmailsResponse } from '@uniqkey-backend-organization-web/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import { dataExtractor } from '../../helpers/apiClients';

const useMigrationAPI = () => {
  const { migrationAPIClient } = useApiClients();

  const sendInviteBulk = useCallback(
    (employeeAccountIds: string[], options?: AxiosRequestConfig)
      : Promise<BulkSendInviteEmailsResponse> => migrationAPIClient
      .apiV1MigrationBulkSendInviteEmailsPost({ employeeAccountIds }, options)
      .then(dataExtractor<BulkSendInviteEmailsResponse>()),
    [migrationAPIClient],
  );

  return { sendInviteBulk };
};

export default useMigrationAPI;
