import { TUseQueryOptions } from '@uniqkey-frontend/shared-app';
import {
  NoContentResult, GetOrganizationsResponse, CheckStatusResponse,
} from '@uniqkey-backend-organization-web/api-client';
import { useQuery } from 'react-query';
import useMigrationAPI from '../../useMigrationAPI';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import { getCompanionApplicationId } from '../../../services/companionApplicationService';

interface IUseGetOrganizationsParams {
  masterPasswordHash: string,
}

export const useGetOrganizations = (
  params: IUseGetOrganizationsParams,
  options: TUseQueryOptions<GetOrganizationsResponse> = {},
) => {
  const { masterPasswordHash } = params;
  const { getOrganizations } = useMigrationAPI();
  return useQuery<GetOrganizationsResponse>(
    ReactQueryKeyEnum.Organizations,
    ({ signal }) => getOrganizations(masterPasswordHash, { signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

interface IUseGetEmployeesParams {
  organizationId: string;
  masterPasswordHash: string;
}

export const useGetEmployees = (
  params: IUseGetEmployeesParams,
  options: TUseQueryOptions<NoContentResult> = {},
) => {
  const { organizationId, masterPasswordHash } = params;
  const { check } = useMigrationAPI();
  const companionApplicationId = getCompanionApplicationId();
  return useQuery<NoContentResult>(
    [ReactQueryKeyEnum.Employees, organizationId],
    ({ signal }) => check(
      organizationId,
      companionApplicationId!,
      masterPasswordHash,
      { signal },
    ),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

interface IUseCheckStatusParams {
  currentGenOrganizationId: string;
}

export const useCheckStatus = (
  params: IUseCheckStatusParams,
  options: TUseQueryOptions<CheckStatusResponse> = {},
) => {
  const { currentGenOrganizationId } = params;
  const { checkStatus } = useMigrationAPI();
  return useQuery<CheckStatusResponse>(
    [ReactQueryKeyEnum.Status, currentGenOrganizationId],
    ({ signal }) => checkStatus(
      currentGenOrganizationId,
      { signal },
    ),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};
