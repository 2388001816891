import {
  HttpTransportType,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
  type HubConnection,
} from '@microsoft/signalr';
import RealtimeAPIEventTypeEnum from '../../enums/RealtimeAPIEventTypeEnum';
import TrustedPortalRealtimeAPIEventTypeEnum
  from '../../modules/TrustedPortalModule/enums/RealtimeAPIEventTypeEnum';
import SupportJumpRealtimeAPIEventTypeEnum
  from '../../modules/SupportJumpModule/enums/RealtimeAPIEventTypeEnum';
import config from '../../config';

type TSetTimeoutReturn = ReturnType<typeof setTimeout>;

interface IConnectionData {
  connection: HubConnection;
  latestGUID: string;
  timeoutID: TSetTimeoutReturn | null;
}

const WEB_API_URL = config.getWebApiUrl();

const CONNECTIONS = new Map<string, IConnectionData>();

const DEFAULT_APP_CONNECTION_ID = 'DEFAULT_APP_CONNECTION_ID'; // used for most of the operations

const RECONNECT_TIMEOUT = 3 * 1000;

const getURL = (guid: string) => `${WEB_API_URL}/notifications?clientId=${guid}`;

const getWebSocketConnection = (
  connectionID: string,
) => CONNECTIONS.get(connectionID) ?? null;

const LOG_LEVEL = config.isProduction() ? LogLevel.Critical : LogLevel.Information;

const createWebSocketConnection = (connectionID: string): IConnectionData => {
  const prevConnectionData = getWebSocketConnection(connectionID);
  if (prevConnectionData) {
    return prevConnectionData;
  }

  const newConnection = new HubConnectionBuilder()
    .configureLogging(LOG_LEVEL)
    .withUrl(
      getURL(''),
      {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      },
    )
    .withAutomaticReconnect({
      nextRetryDelayInMilliseconds: () => RECONNECT_TIMEOUT,
    })
    .build();

  const newConnectionData = {
    latestGUID: '',
    connection: newConnection,
    timeoutID: null,
  };

  CONNECTIONS.set(connectionID, newConnectionData);

  return newConnectionData;
};

export const destroyWebSocketConnection = (connectionID: string) => {
  const connectionData = getWebSocketConnection(connectionID);
  if (!connectionData) {
    return;
  }
  const { connection } = connectionData;
  if (connection.state !== HubConnectionState.Disconnected) {
    // no 'await' - to stop the connection in the background
    // 'catch' - not to throw an error on failed '.stop'
    connection.stop().catch(() => {});
  }
  CONNECTIONS.delete(connectionID);
};

/**
 * @returns true -> successful update
 * @returns false -> connection not found
 * */
const updateConnection = (
  connectionID: string,
  newConnectionData: Partial<IConnectionData>,
): boolean => {
  const prevConnectionData = getWebSocketConnection(connectionID);
  if (!prevConnectionData) {
    return false;
  }
  CONNECTIONS.set(connectionID, { ...prevConnectionData, ...newConnectionData });
  return true;
};

async function start(connectionID: string) {
  try {
    const connectionData = getWebSocketConnection(connectionID);
    if (!connectionData) {
      return;
    }
    const { latestGUID, connection } = connectionData;
    const newBaseUrl = getURL(latestGUID);
    if (connection.baseUrl === newBaseUrl && connection.state === HubConnectionState.Connected) {
      return;
    }
    await connection.stop();
    connection.baseUrl = newBaseUrl;
    await connection.start();
  } catch (err: any) {
    const connectionData = getWebSocketConnection(connectionID);
    if (!connectionData) {
      return;
    }
    clearTimeout(connectionData.timeoutID as TSetTimeoutReturn);
    const newTimeoutID = setTimeout(() => start(connectionID), RECONNECT_TIMEOUT);
    updateConnection(connectionID, { timeoutID: newTimeoutID });
  }
}

export const initWebSockets = async (
  guid: string,
  connectionID: string = DEFAULT_APP_CONNECTION_ID,
) => {
  let connectionData = getWebSocketConnection(connectionID);
  if (!connectionData) {
    connectionData = createWebSocketConnection(connectionID);
  }
  clearTimeout(connectionData.timeoutID as TSetTimeoutReturn);
  updateConnection(connectionID, { latestGUID: guid, timeoutID: null });
  await start(connectionID);
};

export type TSubscribeToRealtimeAPIEventEvent = RealtimeAPIEventTypeEnum
  | TrustedPortalRealtimeAPIEventTypeEnum
  | SupportJumpRealtimeAPIEventTypeEnum

export type TSubscribeToRealtimeAPIEventCallback<TPayload> = (
  payload: TPayload
) => void | Promise<void>;

export type TSubscribeToRealtimeAPIEventReturn = () => void;

export const subscribeToRealtimeAPIEvent = <TPayload>(
  event: TSubscribeToRealtimeAPIEventEvent,
  cb: TSubscribeToRealtimeAPIEventCallback<TPayload>,
  connectionID: string = DEFAULT_APP_CONNECTION_ID,
): TSubscribeToRealtimeAPIEventReturn => {
  const connectionData = getWebSocketConnection(connectionID);
  const { connection } = connectionData ?? {};
  if (!connection) {
    return () => {};
  }
  connection.on(event, cb);
  return () => {
    connection.off(event, cb);
  };
};
