import {
  useCallback, useState, memo, useMemo,
} from 'react';
import {
  ActionButton,
  Box,
  Button,
  Divider,
  encryptSymmetric,
  Grid,
  PanelContent,
  PlusIcon,
  FilterButton,
  usePopper,
  useSnackbar,
  useTranslations,
  SearchableTextField,
  Tooltip,
  ShareIcon,
  ErrorIcon,
  useMapKeyValueExtractor,
  PromptModal,
  ISearchableTextFieldProps,
  IFilterButtonProps,
  MoveToEmployeeIcon,
  MoveToGroupIcon,
} from '@uniqkey-frontend/shared-app';
import useEmployeeGroupLoginsTable, {
  IEmployeeGroupLoginsTableRow,
} from '../../../../hooks/tables/useEmployeeGroupLoginsTable';
import GroupLoginsTable from '../../../../components/tables/GroupLoginsTable';
import { useCompanionApplicationContext } from '../../../../contexts/CompanionApplicationContext';
import useVaultsToGroupsAPI from '../../../../hooks/useVaultsToGroupsAPI';
import CreateLoginModal, { ICreateLoginReturnValue } from '../../../../components/CreateLoginModal';
import { useDeleteVaultsToGroups, useGetGroupById } from '../../../../hooks/reactQuery';
import GroupLoginsTabFilter, {
  IGroupLoginsTabFilterSubmitResult,
} from './components/GroupLoginsTabFilter';
import ShareVaultsToGroupModal from '../../../../components/ShareVaultsToGroupModal';
import VaultTypeEnum from '../../../../enums/VaultTypeEnum';
import { logException } from '../../../../services/sentryService';
import { getActiveOrganizationId } from '../../../../services/organizationService';
import HostTypeEnum from '../../../../enums/HostTypeEnum';
import { generateTooltipTitle } from '../../../../helpers/tooltips';
import { useTrustedPortalStore } from '../../../../modules/TrustedPortalModule/store';
import MoveVaultsToEmployeeModal from '../../../../components/MoveVaultsToEmployeeModal';
import MoveVaultsToGroupModal from '../../../../components/MoveVaultsToGroupModal';

interface IGroupLoginsTabProps {
  groupId: string;
}

const GroupLoginsTab = (props: IGroupLoginsTabProps) => {
  const { groupId } = props;
  const { t } = useTranslations();
  const [isCreateLoginLoading, setIsCreateLoginLoading] = useState(false);
  const [isDeleteLoginLoading, setIsDeleteLoginLoading] = useState(false);
  const [isShareLoginsModalOpen, setIsShareLoginsModalOpen] = useState(false);
  const [isCreateLoginModalOpen, setIsCreateLoginModalOpen] = useState(false);
  const [isDeleteLoginModalOpen, setIsDeleteLoginModalOpen] = useState(false);
  const [isMoveToEmployeeLoginsModalOpen, setIsMoveToEmployeeLoginsModalOpen] = useState(
    false,
  );
  const [isMoveToGroupLoginsModalOpen, setIsMoveToGroupLoginsModalOpen] = useState(
    false,
  );
  const {
    isOpen: isFilterOpen,
    anchorEl: filterAnchorEl,
    setPopperIsOpen: setIsFilterOpen,
  } = usePopper();
  const toggleIsFilterOpen = useCallback<NonNullable<IFilterButtonProps['onChange']>>(
    (event) => setIsFilterOpen(!isFilterOpen, event),
    [setIsFilterOpen, isFilterOpen],
  );
  const handleFilterClose = useCallback(() => setIsFilterOpen(false), [setIsFilterOpen]);

  const handleCreateLoginModalOpen = useCallback(() => setIsCreateLoginModalOpen(true), []);
  const handleCreateLoginModalClose = useCallback(() => setIsCreateLoginModalOpen(false), []);
  const handleDeleteLoginModalOpen = useCallback(() => setIsDeleteLoginModalOpen(true), []);
  const handleDeleteLoginModalClose = useCallback(() => setIsDeleteLoginModalOpen(false), []);
  const handleShareLoginsModalOpen = useCallback(() => setIsShareLoginsModalOpen(true), []);
  const handleShareLoginsModalClose = useCallback(() => setIsShareLoginsModalOpen(false), []);
  const handleMoveToEmployeeLoginsModalOpen = useCallback(
    () => setIsMoveToEmployeeLoginsModalOpen(true),
    [],
  );
  const handleMoveToEmployeeLoginsModalClose = useCallback(
    () => setIsMoveToEmployeeLoginsModalOpen(false),
    [],
  );
  const handleMoveToGroupLoginsModalOpen = useCallback(
    () => setIsMoveToGroupLoginsModalOpen(true),
    [],
  );
  const handleMoveToGroupLoginsModalClose = useCallback(
    () => setIsMoveToGroupLoginsModalOpen(false),
    [],
  );

  const {
    selectedLogins,
    searchQuery,
    setSearchQuery,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetActivePage,
    resetSelectedRows,
    ...restTableProps
  } = useEmployeeGroupLoginsTable({
    persistentFilters: { groupId },
    customPreservationConfig: { ownership: false },
    noDataMessageKey: 'loginsTab.table.noData',
  });
  const { data: group } = useGetGroupById(
    { groupId },
  );

  const { showError, showWarning, showSuccess } = useSnackbar();
  const { symmetricKey } = useCompanionApplicationContext();
  const { createVaultsToGroups } = useVaultsToGroupsAPI();
  const { mutate: mutateDeleteLogin } = useDeleteVaultsToGroups();
  const {
    values: selectedLoginsAsObjects, keys: selectedLoginsIds,
  } = useMapKeyValueExtractor<IEmployeeGroupLoginsTableRow>(selectedLogins);

  const activeOrganizationId = getActiveOrganizationId();
  const isTrustedPortalEnabled = useTrustedPortalStore.useIsEnabledByOrganizationId()[
    activeOrganizationId!
  ] ?? false;

  const handleCreateLogin = useCallback(async (params: ICreateLoginReturnValue) => {
    const {
      name, additionalInfo, username, password, hostType, website, desktopApp,
    } = params;
    setIsCreateLoginLoading(true);
    try {
      let payload = {
        name,
        username,
        groupId,
        additionalInfo,
        vaultType: VaultTypeEnum.Password,
        password: encryptSymmetric({
          key: symmetricKey,
          string: password,
          fallbackValue: null,
        }),
      };
      if (hostType === HostTypeEnum.Website) {
        payload = { ...payload, ...(website && { webAddress: website }) };
      }
      if (hostType === HostTypeEnum.DesktopApp) {
        payload = {
          ...payload,
          ...(desktopApp?.value && {
            desktopApplicationName: desktopApp.value,
            applicationId: desktopApp.id,
          }),
        };
      }
      await createVaultsToGroups(payload);
      if (isTrustedPortalEnabled) {
        showSuccess({
          text: t('trustedPortalSuccessNotifications.login.created'),
        });
      } else {
        showWarning({
          text: t('createLoginModal.approvalOnMobileToast'),
        });
      }
      handleCreateLoginModalClose();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'GroupLoginsTab/handleCreateLogin exception',
      });
    } finally {
      setIsCreateLoginLoading(false);
    }
  }, [
    groupId,
    symmetricKey,
    handleCreateLoginModalClose,
    createVaultsToGroups,
    isTrustedPortalEnabled,
    showSuccess,
    showWarning,
    showError,
    t,
  ]);

  const handleDeleteLogin = useCallback(async () => {
    setIsDeleteLoginLoading(true);
    mutateDeleteLogin({
      vaultIds: selectedLoginsIds, groupId,
    }, {
      onSuccess: ({ failCount, successCount }) => {
        if (successCount) {
          showSuccess({
            text: t('deleteLoginModal.successMessage', { count: successCount }),
          });
        }
        if (failCount) {
          showError({
            text: t('deleteLoginModal.errorMessage', { count: failCount }),
          });
        }
        handleDeleteLoginModalClose();
        resetSelectedRows();
        resetActivePage();
      },
      onError: () => showError({ text: t('common.somethingWentWrong') }),
      onSettled: () => setIsDeleteLoginLoading(false),
    });
  }, [
    groupId,
    selectedLoginsIds,
    mutateDeleteLogin,
    handleDeleteLoginModalClose,
    resetSelectedRows,
    resetActivePage,
    showError,
    showSuccess,
    t,
  ]);

  const handleSearchChange = useCallback<ISearchableTextFieldProps['onChange']>(
    (debouncedValue) => {
      setSearchQuery(debouncedValue);
      resetActivePage();
    },
    [setSearchQuery, resetActivePage],
  );

  const handleFilterSubmit = useCallback((updatedValues: IGroupLoginsTabFilterSubmitResult) => {
    setFilterValues(updatedValues);
    resetActivePage();
  }, [setFilterValues, resetActivePage]);

  const {
    shareTooltipTitle, removeTooltipTitle, moveToEmployeeTooltipTitle, moveToGroupTooltipTitle,
  } = useMemo(() => {
    const moveToEmployeeTitle = generateTooltipTitle({
      selectedDataLength: selectedLogins.size,
      t,
      isDisabled: false,
      key: 'loginsTab.moveToEmployee',
    });
    const moveToGroupTitle = generateTooltipTitle({
      selectedDataLength: selectedLogins.size,
      t,
      isDisabled: false,
      key: 'loginsTab.moveToGroup',
    });
    const shareTitle = generateTooltipTitle({
      selectedDataLength: selectedLogins.size,
      t,
      isDisabled: false,
      key: 'loginsTab.share',
    });
    const removeTitle = generateTooltipTitle({
      selectedDataLength: selectedLogins.size,
      t,
      isDisabled: false,
      key: 'loginsTab.remove',
    });

    return {
      moveToEmployeeTooltipTitle: moveToEmployeeTitle,
      moveToGroupTooltipTitle: moveToGroupTitle,
      shareTooltipTitle: shareTitle,
      removeTooltipTitle: removeTitle,
    };
  }, [selectedLogins.size, t]);

  return (
    <PanelContent p={0}>
      <Grid container justifyContent="space-between" alignItems="stretch" p={1}>
        <Grid item xs={4} container flexWrap="nowrap" spacing={1}>
          <Grid item>
            <Tooltip title={t('common.filter')}>
              <FilterButton
                isFilterActive={isFilterActive}
                numberOfActiveFilters={numberOfActiveFilters}
                selected={isFilterOpen}
                onChange={toggleIsFilterOpen}
              />
            </Tooltip>
          </Grid>
          <Grid item my={0.5}>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item alignSelf="center">
            <Tooltip title={moveToEmployeeTooltipTitle}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleMoveToEmployeeLoginsModalOpen}
                disabled={!selectedLogins.size}
              >
                <MoveToEmployeeIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
          <Grid item alignSelf="center">
            <Tooltip title={moveToGroupTooltipTitle}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleMoveToGroupLoginsModalOpen}
                disabled={!selectedLogins.size}
              >
                <MoveToGroupIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
          <Grid item alignSelf="center">
            <Tooltip title={shareTooltipTitle}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleShareLoginsModalOpen}
                disabled={!selectedLogins.size}
              >
                <ShareIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
          <Grid item alignSelf="center">
            <Tooltip title={removeTooltipTitle}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleDeleteLoginModalOpen}
                disabled={!selectedLogins.size}
              >
                <ErrorIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
          <Grid item my={0.5}>
            <Divider orientation="vertical" />
          </Grid>
        </Grid>
        <Grid item xs={8} container justifyContent="flex-end" flexWrap="nowrap">
          <Grid item>
            <SearchableTextField
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder={t('common.search')}
            />
          </Grid>
          <Box mr={3} />
          <Grid item>
            <Button
              icon={<PlusIcon />}
              onClick={handleCreateLoginModalOpen}
            >
              {t('loginsTab.createLoginButton')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <GroupLoginsTable
        selectedLogins={selectedLogins}
        {...restTableProps}
      />
      <GroupLoginsTabFilter
        isOpen={isFilterOpen}
        anchorEl={filterAnchorEl}
        onSubmit={handleFilterSubmit}
        onClose={handleFilterClose}
        initialValues={filterValues}
        groupId={groupId}
      />
      {isCreateLoginModalOpen && (
        <CreateLoginModal
          isOpen={isCreateLoginModalOpen}
          onClose={handleCreateLoginModalClose}
          onSubmit={handleCreateLogin}
          isLoading={isCreateLoginLoading}
        />
      )}
      {isDeleteLoginModalOpen && (
        <PromptModal
          open={isDeleteLoginModalOpen}
          onClose={handleDeleteLoginModalClose}
          onSubmit={handleDeleteLogin}
          title={t(
            'deleteLoginModal.deleteFromGroup.title',
            {
              count: selectedLogins.size,
              name: group?.name,
            },
          )}
          description={t('deleteLoginModal.deleteFromGroup.description')}
          list={selectedLoginsAsObjects}
          renderField="name"
          renderKey="vaultId"
          isLoading={isDeleteLoginLoading}
        />
      )}
      {isShareLoginsModalOpen && (
        <ShareVaultsToGroupModal
          isOpen={isShareLoginsModalOpen}
          onClose={handleShareLoginsModalClose}
          vaults={selectedLoginsAsObjects}
          type={VaultTypeEnum.Password}
          onSubmit={resetSelectedRows}
        />
      )}
      {isMoveToEmployeeLoginsModalOpen && (
        <MoveVaultsToEmployeeModal
          isOpen={isMoveToEmployeeLoginsModalOpen}
          onClose={handleMoveToEmployeeLoginsModalClose}
          vaults={selectedLoginsAsObjects}
          onSubmit={resetSelectedRows}
          type={VaultTypeEnum.Password}
        />
      )}
      {isMoveToGroupLoginsModalOpen && (
        <MoveVaultsToGroupModal
          isOpen={isMoveToGroupLoginsModalOpen}
          onClose={handleMoveToGroupLoginsModalClose}
          vaults={selectedLoginsAsObjects}
          onSubmit={resetSelectedRows}
          type={VaultTypeEnum.Password}
        />
      )}
    </PanelContent>
  );
};

export default memo(GroupLoginsTab);
