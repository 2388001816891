import { useCallback, useMemo, memo } from 'react';
import {
  ReactHookFormAutocomplete,
  Button,
  Grid,
  Popper,
  PopperActions,
  PopperContent,
  PopperTitle,
  Typography,
  useTranslations,
  usePubSub,
  buildFilterOptionsAsObject,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import {
  GetPartnerSupportRequestsRequestStatus,
} from '@uniqkey-backend-organization-web/api-client';
import {
  PARTNER_ACCESS_REQUESTS_TRANSLATION_KEYS,
} from '../../../../../../constants';
import PubSubEventEnum from '../../../../../../enums/PubSubEventEnum';
import {
  IGetPartnerAccessRequestsParams,
} from '../../../../../../hooks/usePartnerSupportRequestsAPI/interfaces';

export interface IPartnerAccessRequestsListFilterSubmitResult {
  statusFilter: IGetPartnerAccessRequestsParams['statusFilter'];
}

interface IPartnerAccessRequestsListFilterProps {
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  onSubmit: (result: IPartnerAccessRequestsListFilterSubmitResult) => void;
  onClose: () => void;
  initialValues: IPartnerAccessRequestsListFilterSubmitResult;
}

type TStatusFilterOption = {
  label: string;
  value: IPartnerAccessRequestsListFilterSubmitResult['statusFilter'];
} | null;

interface IFormValues {
  statusFilter: TStatusFilterOption;
}

const POPPER_MIN_WIDTH = { minWidth: 500 };

const PartnerAccessRequestsListFilter = (props: IPartnerAccessRequestsListFilterProps) => {
  const {
    isOpen, anchorEl, onSubmit, onClose, initialValues,
  } = props;
  const { t } = useTranslations();

  const [statusFilterOptions, statusFilterOptionsAsObject] = useMemo(() => {
    const options = [
      {
        label: t(
          PARTNER_ACCESS_REQUESTS_TRANSLATION_KEYS[GetPartnerSupportRequestsRequestStatus.Approved],
        ),
        value: GetPartnerSupportRequestsRequestStatus.Approved,
      },
      {
        label: t(
          PARTNER_ACCESS_REQUESTS_TRANSLATION_KEYS[GetPartnerSupportRequestsRequestStatus.Pending],
        ),
        value: GetPartnerSupportRequestsRequestStatus.Pending,
      },
      {
        label: t(
          PARTNER_ACCESS_REQUESTS_TRANSLATION_KEYS[GetPartnerSupportRequestsRequestStatus.Rejected],
        ),
        value: GetPartnerSupportRequestsRequestStatus.Rejected,
      },
    ];
    const optionsAsObject = buildFilterOptionsAsObject(options);
    return [options, optionsAsObject];
  }, [t]);

  const {
    handleSubmit, control, reset, setValue, formState: { isDirty },
  } = useForm<IFormValues>({
    defaultValues: {
      statusFilter: statusFilterOptionsAsObject[
        initialValues.statusFilter as keyof typeof statusFilterOptionsAsObject
      ] ?? null,
    },
  });

  const handleOnSubmit = useCallback((value: IFormValues): void => {
    const {
      statusFilter,
    } = value;
    onSubmit({
      statusFilter: statusFilter?.value,
    });
    onClose();
    reset(value); // set default values to the selected ones to reset isDirty
  }, [reset, onSubmit, onClose]);

  const clearAll = useCallback(() => {
    setValue('statusFilter', null, { shouldDirty: true });
  }, [setValue]);

  const handleOnFadeExited = useCallback(() => {
    reset(); // reset form values when Popper is closed and animation has finished
  }, [reset]);

  const handleReset = useCallback(() => {
    reset({
      statusFilter: null,
    });
  }, [reset]);
  usePubSub(PubSubEventEnum.RESET_FILTER, handleReset);

  return (
    <Popper
      anchorEl={anchorEl}
      offsetX={-8}
      offsetY={16}
      placement="bottom-start"
      open={isOpen}
      sx={POPPER_MIN_WIDTH}
      onFadeExited={handleOnFadeExited}
    >
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <PopperTitle onClose={onClose}>
          {t('common.filterBy')}
        </PopperTitle>
        <PopperContent>
          <Grid container direction="column" rowSpacing={2}>
            <Grid item container alignItems="center">
              <Grid item xs={3}>
                <Typography>
                  {t('partnerAccessRequestsFilter.status.label')}
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <ReactHookFormAutocomplete
                  name="statusFilter"
                  options={statusFilterOptions}
                  placeholder={t('partnerAccessRequestsFilter.status.placeholder')}
                  control={control}
                />
              </Grid>
            </Grid>
          </Grid>
        </PopperContent>
        <PopperActions>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button disabled={!isDirty} fullWidth type="submit">{t('common.apply')}</Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                onClick={clearAll}
              >
                {t('common.clearAll')}
              </Button>
            </Grid>
          </Grid>
        </PopperActions>
      </form>
    </Popper>
  );
};

export default memo(PartnerAccessRequestsListFilter);
