import {
  useState, memo, useMemo, EventHandler, MouseEvent,
} from 'react';
import { debounce } from 'lodash';
import {
  Grid,
  Switch,
  Tooltip,
  Typography,
  useSnackbar,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import { logException } from '../../../services/sentryService';

export interface IColumnWithSwitchParams {
  checked: boolean;
  dataId: string;
  onChange: (dataId: string, checked: boolean) => Promise<any>;
  checkedTranslationKey?: string;
  uncheckedTranslationKey?: string;
}

const handleStopPropagation: EventHandler<MouseEvent> = (event) => event.stopPropagation();

const ColumnWithSwitch = (params: IColumnWithSwitchParams) => {
  const {
    checked: preChecked,
    dataId,
    onChange,
    checkedTranslationKey = '',
    uncheckedTranslationKey = '',
  } = params;

  const { t } = useTranslations();
  const { showError } = useSnackbar();

  const [isChecked, setIsChecked] = useState(preChecked);

  const handleChange = useMemo(
    /*
      debounce changes (with disabled trailing) to prevent double-clicks
      use enabled leading to instantly show optimistic updates
    */
    () => debounce(async (event, newChecked) => {
      try {
        setIsChecked(newChecked);
        await onChange(dataId, newChecked);
      } catch (e) {
        setIsChecked(!newChecked);
        showError({ text: t('common.somethingWentWrong') });
        logException(e, {
          message: 'ColumnWithSwitch/handleChange exception',
        });
      }
    }, 200, { leading: true, trailing: false }),
    [onChange, dataId, showError, t],
  );

  const label = t(isChecked ? checkedTranslationKey : uncheckedTranslationKey);

  return (
    <Grid
      container
      onClick={handleStopPropagation}
      my={label ? -1 : undefined}
    >
      <Tooltip title={label}>
        <Grid
          container
          flexDirection="column"
          alignItems="center"
          rowGap={0.5}
          flex={0}
        >
          {label && <Typography variant="caption" noWrap>{label}</Typography>}
          <Grid item>
            <Switch checked={isChecked} onChange={handleChange} />
          </Grid>
        </Grid>
      </Tooltip>
    </Grid>
  );
};

export default memo(ColumnWithSwitch);
