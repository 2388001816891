import {
  wait,
  generateGUID,
  generateSymmetricKey,
  BaseEitherError,
  type Either,
} from '@uniqkey-frontend/shared-app';
import type {
  MobileSupporterTokensNotification,
} from '@uniqkey-backend-organization-web/api-client';
import PubSub from 'pubsub-js';
import RealtimeAPIEventTypeEnum from '../../enums/RealtimeAPIEventTypeEnum';
import SupportJumpRealtimeAPIEventTypeEnum from './enums/RealtimeAPIEventTypeEnum';
import SupportJumpPubSubEventEnum from './enums/PubSubEventEnum';
import type { IGetPartnerKeysOperationParams } from './operations/getPartnerKeysOperation';
import type { IOrganizationPortalSynchronizationPayload } from '../../helpers/dataSynchronization';
import type { TSupportJumpDeferred } from './common/interfaces';
import {
  SupportJumpCreateTrustedPortalTimeoutError,
  SupportJumpGetMobileTokensTimeoutError,
  SupportJumpMissingStoreDataError,
  SupportJumpUnknownError,
  type TSupportJumpErrors,
} from './common/errors';
import {
  SupportJumpInvalidPasswordError,
} from './operations/decryptOrganizationPrivateOperation/errors';
import {
  SupportJumpWrongOrganizationPortalSynchronizationEventError,
} from './operations/verifyTrustedActionOperation/errors';
import SupportJumpStoreActions from './store/actions';
import Operations from './operations';
import Helpers from './helpers';

interface IStartParams {
  token: IGetPartnerKeysOperationParams['token'];
  organizationId: IGetPartnerKeysOperationParams['organizationId'];
}

const start = async (
  params: IStartParams,
): Promise<Either<TSupportJumpDeferred['promise'], TSupportJumpErrors>> => {
  try {
    const { organizationId, token } = params;

    SupportJumpStoreActions.setIsInProgress(true);

    Helpers.createAxiosInstance();

    const getSupportTokenResult = await Operations.getPartnerKeysOperation({
      organizationId, token,
    });
    if (getSupportTokenResult instanceof BaseEitherError) {
      SupportJumpStoreActions.setIsInProgress(false);
      return getSupportTokenResult;
    }

    const companionApplicationId = generateGUID();
    const symmetricKey = generateSymmetricKey();

    const {
      organizationTokens,
      partnerUserId,
      partnerPublicKey,
      partnerPrivateKey,
      partnerUserPublicKey,
      partnerUserPrivateKey,
      organizationPrivateKey,
    } = getSupportTokenResult;

    Helpers.initStore({
      organizationId,
      companionApplicationId,
      symmetricKey,
      organizationTokens,
      partnerUserId,
      partnerPublicKey,
      partnerPrivateKey,
      partnerUserPublicKey,
      partnerUserPrivateKey,
      organizationPrivateKey,
    });

    await Helpers.createWebSocketConnection(companionApplicationId);
    listenEvents();

    await wait(400); // wait a bit so the user can see the first step

    PubSub.publish(SupportJumpPubSubEventEnum.PROVIDE_PASSWORD);

    const deferred = Helpers.createDeferred();
    return deferred.promise;
  } catch (e) {
    SupportJumpStoreActions.setIsInProgress(false);
    return new SupportJumpUnknownError();
  }
};

const listenEvents = () => {
  Helpers.createPubSubSubscription(
    SupportJumpPubSubEventEnum.PASSWORD_PROVIDED,
    async (_, password) => {
      const decryptResult = await Operations.decryptOrganizationPrivateOperation({ password });
      if (decryptResult instanceof SupportJumpInvalidPasswordError) {
        PubSub.publish(SupportJumpPubSubEventEnum.INCORRECT_PASSWORD);
        return;
      }
      if (decryptResult instanceof BaseEitherError) {
        Helpers.handleError(decryptResult);
        return;
      }

      SupportJumpStoreActions.setOrganizationPrivateKey(decryptResult);

      PubSub.publish(SupportJumpPubSubEventEnum.PASSWORD_VERIFIED);

      await Helpers.logoutPreviousUser();

      const pairSupporterResult = await Operations.pairSupporterOperation();
      if (pairSupporterResult instanceof BaseEitherError) {
        Helpers.handleError(pairSupporterResult);
        return;
      }

      const { organizationTokens } = pairSupporterResult;
      Helpers.setTokens(organizationTokens);

      Helpers.startTimer(new SupportJumpGetMobileTokensTimeoutError());
      const getMobileTokensResult = await Operations.getMobileTokensOperation();
      if (getMobileTokensResult instanceof BaseEitherError) {
        Helpers.clearTimer();
        Helpers.handleError(getMobileTokensResult);
      }
    },
  );

  Helpers.createWebSocketSubscription<MobileSupporterTokensNotification>(
    SupportJumpRealtimeAPIEventTypeEnum.MobileSupporterTokensNotification,
    async (payload) => {
      Helpers.clearTimer();
      const { token, refreshToken } = payload;

      const createTrustedActionPayloadResult = Operations.createTrustedActionPayloadOperation({
        token, refreshToken,
      });
      if (createTrustedActionPayloadResult instanceof BaseEitherError) {
        Helpers.handleError(createTrustedActionPayloadResult);
        return;
      }

      Helpers.startTimer(new SupportJumpCreateTrustedPortalTimeoutError());
      const createTrustedPortalResult = await Operations.createTrustedPortalOperation({
        payload: createTrustedActionPayloadResult,
      });
      if (createTrustedPortalResult instanceof BaseEitherError) {
        Helpers.clearTimer();
        Helpers.handleError(createTrustedPortalResult);
      }
    },
  );

  Helpers.createWebSocketSubscription<IOrganizationPortalSynchronizationPayload>(
    RealtimeAPIEventTypeEnum.OrganizationPortalSynchronization,
    async (payload) => {
      Helpers.clearTimer();
      const verificationResult = await Operations.verifyTrustedActionOperation({
        eventPayload: payload,
      });
      if (
        verificationResult instanceof SupportJumpWrongOrganizationPortalSynchronizationEventError
      ) {
        return; // skip other events
      }
      if (verificationResult instanceof BaseEitherError) {
        Helpers.handleError(verificationResult);
        return;
      }

      const deferred = SupportJumpStoreActions.getDeferred();
      const userToken = SupportJumpStoreActions.getUserToken();
      const employeeTokens = SupportJumpStoreActions.getEmployeeTokens();
      const companionApplicationId = SupportJumpStoreActions.getCompanionApplicationId();
      const symmetricKey = SupportJumpStoreActions.getSymmetricKey();

      if (!deferred || !userToken || !employeeTokens || !companionApplicationId || !symmetricKey) {
        Helpers.handleError(new SupportJumpMissingStoreDataError());
        return;
      }

      deferred.resolve({
        tokens: { userToken, employeeTokens },
        companionApplicationId,
        symmetricKey,
      });
    },
  );
};

const isInProgress = () => SupportJumpStoreActions.getIsInProgress();

const cleanup = () => {
  Helpers.unsubscribeSubscriptions();
  Helpers.destroyWSConnection();
  SupportJumpStoreActions.resetStore();
  SupportJumpStoreActions.setIsInProgress(false);
};

export default {
  start,
  isInProgress,
  cleanup,
};
