import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';
import {
  LoginRequest,
  LoginResponse,
  GetOrganizationsResponse,
  MigrateRequest,
  NoContentResult,
  CheckStatusResponse,
} from '@uniqkey-backend-organization-web/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import { dataExtractor } from '../../../helpers/apiClients';

const useMigrationAPI = () => {
  const { migrationAPIClient, migrationLoginAPIClient } = useApiClients();

  const login = useCallback(
    (loginRequest: LoginRequest, options?: AxiosRequestConfig)
      : Promise<LoginResponse> => migrationLoginAPIClient
      .apiV1MigrationLoginPost(loginRequest, options)
      .then(dataExtractor<LoginResponse>()),
    [migrationLoginAPIClient],
  );

  const getOrganizations = useCallback(
    (masterPasswordHash: string, options?: AxiosRequestConfig)
      : Promise<GetOrganizationsResponse> => migrationAPIClient
      .apiV1MigrationGetOrganizationsGet(masterPasswordHash, options)
      .then(dataExtractor<GetOrganizationsResponse>()),
    [migrationAPIClient],
  );

  const check = useCallback(
    (
      organizationId: string,
      companionApplicationId: string,
      masterPasswordHash: string,
      options?: AxiosRequestConfig,
    ) => migrationAPIClient.apiV1MigrationCheckGet(
      organizationId,
      companionApplicationId,
      masterPasswordHash,
      options,
    ).then(dataExtractor<NoContentResult>()),
    [migrationAPIClient],
  );

  const migrate = useCallback(
    (migrateRequest?: MigrateRequest, options?: AxiosRequestConfig)
      : Promise<NoContentResult> => migrationAPIClient
      .apiV1MigrationMigratePost(migrateRequest, options)
      .then(dataExtractor<NoContentResult>()),
    [migrationAPIClient],
  );

  const checkStatus = useCallback(
    (currentGenOrganizationId?: string, options?: AxiosRequestConfig)
      : Promise<CheckStatusResponse> => migrationAPIClient
      .apiV1MigrationCheckStatusGet(currentGenOrganizationId, options)
      .then(dataExtractor<CheckStatusResponse>()),
    [migrationAPIClient],
  );

  return ({
    login,
    getOrganizations,
    check,
    migrate,
    checkStatus,
  });
};

export default useMigrationAPI;
