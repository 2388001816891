import { Outlet, type RouteProps } from 'react-router-dom';
import UnauthorizedLayout from '../../layouts/UnauthorizedLayout';

type TPublicRouteProps = RouteProps & {
  withLayout: boolean;
}

const PublicRoute = (props: TPublicRouteProps) => {
  const {
    withLayout,
  } = props;

  if (withLayout) {
    return (
      <UnauthorizedLayout>
        <Outlet />
      </UnauthorizedLayout>
    );
  }

  return <Outlet />;
};

export default PublicRoute;
