import { Navigate, Outlet, type RouteProps } from 'react-router-dom';
import { Suspense } from 'react';
import PageRouteEnum from '../../enums/PageRouteEnum';
import { type IUserContext, useUser } from '../../contexts/UserContext';
import AuthorizedLayout from '../../layouts/AuthorizedLayout';

type TAuthenticatedRouteProps = RouteProps & Pick<
  IUserContext, 'isAuthenticated'
> & {
  withLayout: boolean;
}

const AuthenticatedRoute = (props: TAuthenticatedRouteProps) => {
  const {
    isAuthenticated,
    withLayout,
  } = props;

  const { isCurrentUserLoading } = useUser();

  if (!isAuthenticated) {
    return <Navigate to={PageRouteEnum.Login} />;
  }

  if (isCurrentUserLoading) {
    return <Suspense fallback />;
  }

  if (withLayout) {
    return (
      <AuthorizedLayout>
        <Outlet />
      </AuthorizedLayout>
    );
  }

  return (
    <Outlet />
  );
};

export default AuthenticatedRoute;
