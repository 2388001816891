import {
  PanelContent,
} from '@uniqkey-frontend/shared-app';
import usePartnerUsersWithAccessTable from
  '../../../../hooks/tables/usePartnerUsersWithAccessTable';
import PartnerUsersWithAccessTable, {
} from '../../../../components/tables/PartnerUsersWithAccessTable';

const PartnerUsersWithAccessTab = () => {
  const tableProps = usePartnerUsersWithAccessTable({
    noDataMessageKey: 'table.noData.default',
  });

  return (
    <PanelContent p={0}>
      <PartnerUsersWithAccessTable {...tableProps} />
    </PanelContent>
  );
};

export default PartnerUsersWithAccessTab;
