import { BaseEitherError } from '@uniqkey-frontend/shared-app';
import {
  SupportJumpAPIIncorrectAxiosInstanceError,
  SupportJumpMissingStoreDataError,
} from '../../common/errors';
import {
  type TGetAllTrustedPortalActionsOperationErrors,
} from '../../../../common/operations/getAllTrustedPortalActionsOperation/errors';
import {
  type TDecryptTrustedActionPayloadOperationErrors,
} from '../../../../common/operations/decryptTrustedActionPayloadOperation/errors';

export class SupportJumpTrustedActionVerificationFailedError extends BaseEitherError {}

export class SupportJumpWrongOrganizationPortalSynchronizationEventError extends BaseEitherError {}

export class SupportJumpNoTrustedActionError extends BaseEitherError {}

export type TSupportJumpVerifyTrustedActionOperationErrors =
  SupportJumpTrustedActionVerificationFailedError
  | SupportJumpWrongOrganizationPortalSynchronizationEventError
  | SupportJumpNoTrustedActionError
  | SupportJumpAPIIncorrectAxiosInstanceError
  | SupportJumpMissingStoreDataError
  | TGetAllTrustedPortalActionsOperationErrors
  | TDecryptTrustedActionPayloadOperationErrors;
