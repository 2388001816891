import { memo } from 'react';
import { Grid, PanelContent } from '@uniqkey-frontend/shared-app';
import EmployeeDetailsWidget from '../EmployeeDetailsWidget';
import EmployeeDetailsSecurityScoreWidget from '../EmployeeDetailsSecurityScoreWidget';
import UniqkeyAccessScoreWidget from '../UniqkeyAccessScoreWidget';

interface IEmployeeDetailsTabProps {
  employeeAccountId: string;
}

const EmployeeDetailsTab = (props: IEmployeeDetailsTabProps) => {
  const { employeeAccountId } = props;

  return (
    <PanelContent p={3} pt={1} className="height-100-percent">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <EmployeeDetailsWidget employeeAccountId={employeeAccountId} />
        </Grid>
        <Grid item xs={6}>
          <EmployeeDetailsSecurityScoreWidget employeeAccountId={employeeAccountId} />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <UniqkeyAccessScoreWidget employeeAccountId={employeeAccountId} />
        </Grid>
      </Grid>
    </PanelContent>
  );
};

export default memo(EmployeeDetailsTab);
