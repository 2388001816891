import {
  Dispatch, SetStateAction, useCallback, useState,
} from 'react';
import { upperFirst } from 'lodash';
import {
  useSnackbar,
  useTable,
  useQueryTableFetch,
  useTranslations,
  useTableLocalization,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  buildTablePreserverDecoderConfigItem,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  TUseTablePreserverDecoderConfigs,
  TablePreserverDecoderConfigItemTypeEnum,
  TSetSearchParams,
  TablePreserverCommonInitialTableValues,
  usePubSub,
} from '@uniqkey-frontend/shared-app';
import { VaultOrderProperty } from '@uniqkey-backend-organization-web/api-client';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import useVaultsAPI from '../../useVaultsAPI';
import {
  IEmployeeGroupLoginsTableRow,
  REACT_QUERY_LOGINS_KEY,
} from '../useEmployeeGroupLoginsTable';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import { IGetVaultPasswordsParams, TGetVaultPasswordsMethod } from '../../useVaultsAPI/interfaces';
import { IServiceLoginsTableRow } from '../../../components/tables/ServiceLoginsTable';

interface IPersistentFilters extends Pick<
  IGetVaultPasswordsParams, 'applicationName'
> {}

export interface IUseServiceLoginsTableParams {
  persistentFilters?: IPersistentFilters;
}

export interface IUseServiceLoginsTableReturn extends
  Omit<IUseTableReturn<IServiceLoginsTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage'>,
  Omit<IUseQueryTableFetchReturn<TGetVaultPasswordsMethod>, 'data' | 'isFetchedAfterMount'>
{
  logins: IUseQueryTableFetchReturn<TGetVaultPasswordsMethod>['data'];
  selectedLogins: IUseTableReturn<IServiceLoginsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  searchQuery: IGetVaultPasswordsParams['searchQuery'];
  setSearchQuery: Dispatch<SetStateAction<IGetVaultPasswordsParams['searchQuery']>>;
}

interface ITablePreservationConfigs extends Omit<IGetVaultPasswordsParams,
    'page' | 'pageLength' | 'groupId' | 'employeeAccountId' | 'orderPropertyName' | 'isDescending'
    | 'minStrength' | 'maxStrength' | 'minSecurityScore' | 'maxSecurityScore'
    | 'applicationName' | 'ownership' | 'password2FAStatus' | 'isReusedByUser'
    | 'isReusedByOrganization' | 'isPasswordEmpty'>,
  Pick<IUseTableReturn<IServiceLoginsTableRow>, 'columnOrderBy' | 'columnOrderDirection'>
{
  activePage: IGetVaultPasswordsParams['page'];
  perPage: IGetVaultPasswordsParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  searchQuery: true,
  columnOrderBy: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: VaultOrderProperty },
  ),
  columnOrderDirection: true,
};

const useServiceLoginsTable = (
  params: IUseServiceLoginsTableParams,
): IUseServiceLoginsTableReturn => {
  const { persistentFilters } = params;

  const queryClient = useQueryClient();
  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getVaultPasswords } = useVaultsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    searchQuery: initialSearchQuery,
    columnOrderBy: initialColumnOrderBy,
    columnOrderDirection: initialColumnOrderDirection,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const [searchQuery, setSearchQuery] = useState<
    IGetVaultPasswordsParams['searchQuery']
    >(() => initialSearchQuery);

  const {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedRows: selectedLogins,
    resetTableMethods,
    ...restTableProps
  } = useTable<IEmployeeGroupLoginsTableRow>({
    initialActivePage,
    initialPerPage,
    initialColumnOrderBy,
    initialColumnOrderDirection,
  });

  const {
    resetTable,
    resetActivePage,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
    setSearchQuery(TablePreserverCommonInitialTableValues.searchQuery);
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: logins, isLoading, isFetchedAfterMount, total, resetQuery,
  } = useQueryTableFetch({
    queryKey: REACT_QUERY_LOGINS_KEY,
    queryClient,
    request: getVaultPasswords,
    params: {
      page: activePage,
      pageLength: perPage,
      searchQuery,
      orderPropertyName: VaultOrderProperty[
        upperFirst(columnOrderBy) as keyof typeof VaultOrderProperty
      ],
      isDescending: columnOrderDirection ? columnOrderDirection === 'desc' : undefined,
      ...persistentFilters,
    },
    onRequestError: handleOnRequestError,
  });

  const { localization } = useTableLocalization({
    searchQuery,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    searchQuery,
    columnOrderBy,
    columnOrderDirection,
  });

  return {
    logins,
    isLoading,
    total,
    resetQuery,
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedLogins,
    localization,
    searchQuery,
    setSearchQuery,
    resetActivePage,
    ...restTableProps,
  };
};

export default useServiceLoginsTable;
