import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';
import {
  GetScimSettingsResponse,
  NoContentResult,
  UpdateScimSettingsRequest,
} from '@uniqkey-backend-organization-web/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import { dataExtractor } from '../../helpers/apiClients';

const useOrganizationScimSettingsAPI = () => {
  const { organizationScimSettingsAPIClient } = useApiClients();

  const getOrganizationScimSettings = useCallback(async (
    options?: AxiosRequestConfig,
  ): Promise<GetScimSettingsResponse> => organizationScimSettingsAPIClient
    .apiV1ScimSettingsGet(options)
    .then(dataExtractor<GetScimSettingsResponse>()), [organizationScimSettingsAPIClient]);

  const updateOrganizationScimSettings = useCallback(async (
    scimSettings: UpdateScimSettingsRequest,
    options?: AxiosRequestConfig,
  ): Promise<NoContentResult> => organizationScimSettingsAPIClient
    .apiV1ScimSettingsPut(scimSettings, options)
    .then(dataExtractor<NoContentResult>()), [organizationScimSettingsAPIClient]);

  return {
    getOrganizationScimSettings,
    updateOrganizationScimSettings,
  };
};

export default useOrganizationScimSettingsAPI;
