import { useMemo, memo } from 'react';
import {
  Table,
  useTranslations,
  IUseTableReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  TypographyWithTooltip,
  IMaterialTableOptions,
  formatDate,
  DateTimeFormatEnum,
} from '@uniqkey-frontend/shared-app';
import {
  GetPartnerUsersWithAccessResponseModel,
} from '@uniqkey-backend-organization-web/api-client';
import { TGetPartnerUsersAccessMethod } from '../../../hooks/useOrganizationSettingsAPI/interfaces';

export interface IPartnerUsersWithAccessTableRow extends GetPartnerUsersWithAccessResponseModel {
  processedDate: string | null;
}

interface IPartnerUsersWithAccessTableProps extends
  Omit<IUseTableReturn<IPartnerUsersWithAccessTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Omit<
    IUseQueryTableFetchReturn<TGetPartnerUsersAccessMethod>,
    'data' | 'resetQuery' | 'isFetchedAfterMount'
  >
{
  partnerUsers: IUseQueryTableFetchReturn<TGetPartnerUsersAccessMethod>['data'];
  selectedPartnerUsers: IUseTableReturn<IPartnerUsersWithAccessTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

const TABLE_OPTIONS: IMaterialTableOptions<IPartnerUsersWithAccessTableRow> = {
  selection: false,
};

const PartnerUsersWithAccessTable = (props: IPartnerUsersWithAccessTableProps) => {
  const {
    partnerUsers: prePartnerUsers,
    isLoading,
    total,
    localization,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    columnOrderBy,
    onColumnOrderByChange,
    columnOrderDirection,
    onColumnOrderDirectionChange,
    selectedPartnerUsers,
    onRowSelectionChange,
    createColumns,
  } = props;

  const { t } = useTranslations();

  const partnerUsers = useMemo(() => prePartnerUsers.map((partnerUser) => ({
    ...partnerUser,
    processedDate: formatDate({ date: partnerUser.accessDate }, DateTimeFormatEnum.FULL),
  })), [prePartnerUsers]);

  const columns = useMemo(() => createColumns([
    {
      title: t('settingsPage.partnerUsersWithAccessTab.table.columns.name'),
      field: 'partnerName',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.partnerName}
        </TypographyWithTooltip>
      ),
      width: '40%',
    },
    {
      title: t('settingsPage.partnerUsersWithAccessTab.table.columns.email'),
      field: 'partnerUserEmail',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.partnerUserEmail}
        </TypographyWithTooltip>
      ),
      width: '40%',
    },
    {
      title: t('settingsPage.partnerUsersWithAccessTab.table.columns.accessDate'),
      field: 'accessDate',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.processedDate}
        </TypographyWithTooltip>
      ),
      width: '20%',
      sorting: false,
    },
  ]), [createColumns, t]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={partnerUsers}
      isLoading={isLoading}
      options={TABLE_OPTIONS}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      columnOrderBy={columnOrderBy}
      onColumnOrderByChange={onColumnOrderByChange}
      columnOrderDirection={columnOrderDirection}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedPartnerUsers}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      rowSelectByKey="partnerUserId"
      rowHeight={51}
    />
  );
};

export default memo(PartnerUsersWithAccessTable);
