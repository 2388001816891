import {
  encryptSymmetric,
  type Either,
} from '@uniqkey-frontend/shared-app';
import type {
  MobileSupporterTokensNotification,
} from '@uniqkey-backend-organization-web/api-client';
import {
  SupportJumpFailedToEncryptPayloadError,
  type TSupportJumpCreateTrustedActionPayloadOperationErrors,
} from './errors';
import { SupportJumpMissingStoreDataError } from '../../common/errors';
import SupportJumpStoreActions from '../../store/actions';

interface ICreateTrustedActionPayloadOperationParams extends MobileSupporterTokensNotification {}

const createTrustedActionPayloadOperation = (
  params: ICreateTrustedActionPayloadOperationParams,
): Either<string, TSupportJumpCreateTrustedActionPayloadOperationErrors> => {
  const { token, refreshToken } = params;

  const symmetricKey = SupportJumpStoreActions.getSymmetricKey();
  const organizationPrivateKey = SupportJumpStoreActions.getOrganizationPrivateKey();
  if (!symmetricKey || !organizationPrivateKey) {
    return new SupportJumpMissingStoreDataError();
  }

  try {
    const encryptedOrganizationPrivateKey = encryptSymmetric({
      string: organizationPrivateKey,
      key: symmetricKey,
    });

    const payload = JSON.stringify({
      mobileToken: token,
      organizationPrivate: encryptedOrganizationPrivateKey,
      refreshToken,
    });

    const encryptedPayload = encryptSymmetric({
      string: payload,
      key: symmetricKey,
    });

    return encryptedPayload;
  } catch (e) {
    return new SupportJumpFailedToEncryptPayloadError();
  }
};

export default createTrustedActionPayloadOperation;
