import { memo } from 'react';
import {
  useTranslations,
  Typography,
  ArrowUpIcon,
  Grid,
  AR1,
  AG3,
  S5,
  usePasswordStrength,
  ArrowDownIcon,
} from '@uniqkey-frontend/shared-app';
import { Password2FaStatus } from '@uniqkey-backend-organization-web/api-client';
import { TWO_FA_FILTER_TRANSLATION_KEYS } from '../../../constants';

interface ISecurityScoreStatisticsTooltipContentProps {
  strength: number;
  password2FaStatus: Password2FaStatus;
  hasReusedPassword: boolean;
}

const SecurityScoreStatisticsTooltipContent = (
  props: ISecurityScoreStatisticsTooltipContentProps,
) => {
  const {
    strength, password2FaStatus, hasReusedPassword,
  } = props;
  const { t } = useTranslations();
  const { color } = usePasswordStrength({ strength });
  const isPassword2FAOff = password2FaStatus === Password2FaStatus.Off;

  return (
    <Grid container>
      <Grid item container justifyContent="space-between">
        <Grid item py={0.5}>
          <Typography variant="caption" color={S5}>
            {t('columnWithSecurityScore.passwordStrength')}
            :&nbsp;
            <Typography variant="caption" component="span" color={color}>
              {strength}
              %
            </Typography>
          </Typography>
        </Grid>
        <Grid item>
          {strength ? (
            <>
              <ArrowUpIcon color={AG3} height={11} />
              <Typography color={AG3} component="span">+</Typography>
              <Typography variant="caption" color={S5}>
                {Math.floor(strength * 0.7)}
                %
              </Typography>
            </>
          ) : (
            <Typography variant="caption" color={S5}>0%</Typography>
          )}
        </Grid>
      </Grid>
      <Grid item container justifyContent="space-between">
        <Grid item py={0.5}>
          <Typography variant="caption" color={S5}>
            {t('columnWithSecurityScore.2FALayer')}
            :&nbsp;
            <Typography
              variant="caption"
              component="span"
              color={isPassword2FAOff ? AR1 : AG3}
            >
              {t(TWO_FA_FILTER_TRANSLATION_KEYS[password2FaStatus!])}
            </Typography>
          </Typography>
        </Grid>
        <Grid item>
          {isPassword2FAOff ? (
            <Typography variant="caption" color={S5}>0%</Typography>
          ) : (
            <>
              <ArrowUpIcon color={AG3} height={11} />
              <Typography color={AG3} component="span">+</Typography>
              <Typography variant="caption" color={S5}>30%</Typography>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item container justifyContent="space-between">
        <Grid item py={0.5}>
          <Typography variant="caption" color={S5}>
            {t('columnWithSecurityScore.hasReusedPassword')}
            :&nbsp;
            <Typography
              variant="caption"
              component="span"
              color={hasReusedPassword ? AR1 : AG3}
            >
              {t(hasReusedPassword ? 'common.yes' : 'common.no')}
            </Typography>
          </Typography>
        </Grid>
        <Grid item>
          {hasReusedPassword && (
            <>
              <ArrowDownIcon color={AR1} />
              <Typography color={AR1} component="span">-</Typography>
              <Typography variant="caption" color={S5}>40%</Typography>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(SecurityScoreStatisticsTooltipContent);
