import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';
import {
  GetCurrentOrganizationResponse,
  NoContentResult,
  Operation,
} from '@uniqkey-backend-organization-web/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import { dataExtractor } from '../../helpers/apiClients';

const useOrganizationAPI = () => {
  const { organizationAPIClient } = useApiClients();

  const getCurrentOrganization = useCallback((
    options?: AxiosRequestConfig,
  ): Promise<GetCurrentOrganizationResponse> => organizationAPIClient
    .apiV1OrganizationsCurrentGet(options)
    .then(dataExtractor<GetCurrentOrganizationResponse>()), [organizationAPIClient]);

  const patchCurrentOrganization = useCallback((
    operations: Array<Operation>,
    options?: AxiosRequestConfig,
  ): Promise<NoContentResult> => organizationAPIClient
    .apiV1OrganizationsPatch(operations, options)
    .then(dataExtractor<NoContentResult>()), [organizationAPIClient]);

  return {
    getCurrentOrganization,
    patchCurrentOrganization,
  };
};

export default useOrganizationAPI;
