import { MoveVaultFromEmployeeToGroupPayload } from '@uniqkey-backend-organization-web/api-client';
import { decryptAsymmetric, encryptSymmetric } from '@uniqkey-frontend/shared-app';
import type { ITrustedPortalHandlerParams } from '../../../interfaces';
import APIClientsProvider from '../../../apiClientsProvider';

const ERROR_PREFIX = 'TrustedPortal/handleMoveVaultFromEmployeeToGroupEvent';

const handleMoveVaultFromEmployeeToGroupEvent = async (
  params: ITrustedPortalHandlerParams<MoveVaultFromEmployeeToGroupPayload>,
) => {
  const {
    parsedEvent,
    axiosInstance,
    organizationPrivateKey,
    organizationPublicKey,
  } = params;

  const { parsedPayload, queueMessageId } = parsedEvent;
  const {
    targetGroupSecret,
    vaultSymmetricKeyBackup,
  } = parsedPayload;

  if (!targetGroupSecret) {
    throw new Error(`${ERROR_PREFIX} no targetGroupSecret in event`);
  }

  if (!vaultSymmetricKeyBackup) {
    throw new Error(`${ERROR_PREFIX} no vaultSymmetricKeyBackup in event`);
  }

  const decryptedGroupSecret = await decryptAsymmetric({
    cipher: targetGroupSecret,
    privateKey: organizationPrivateKey,
    publicKey: organizationPublicKey,
  });

  const decryptedVaultSymmetricKey = await decryptAsymmetric({
    cipher: vaultSymmetricKeyBackup,
    publicKey: organizationPublicKey,
    privateKey: organizationPrivateKey,
  });

  const encryptedSymmetricKey = encryptSymmetric({
    string: decryptedVaultSymmetricKey,
    key: decryptedGroupSecret,
  });

  await APIClientsProvider.Mobile.getQueueAPIClient(axiosInstance)
    .apiV1QueueMoveVaultFromEmployeeToGroupApprovePost({
      queueMessageId,
      vaultSymmetricKey: encryptedSymmetricKey,
    });
};

export default handleMoveVaultFromEmployeeToGroupEvent;
