import { useCallback } from 'react';
import {
  useSnackbar,
  useTable,
  useQueryTableFetch,
  useTranslations,
  useTableLocalization,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  IUseTableReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  TUseTablePreserverDecoderConfigs,
  TSetSearchParams,
  IUseTableResetTableMethodsReturn,
} from '@uniqkey-frontend/shared-app';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import {
  IGetVerifiedDomainsParams,
  TGetVerifiedDomainsMethod,
} from '../../useVerifiedDomainsAPI/interfaces';
import { IVerifiedDomainsTableRow } from '../../../components/tables/VerifiedDomainsTable';
import useVerifiedDomainsAPI from '../../useVerifiedDomainsAPI';

export const REACT_QUERY_VERIFIED_DOMAINS_KEY = [ReactQueryKeyEnum.VerifiedDomains];

export interface IUseVerifiedDomainsTableParams {
  noDataMessageKey: string;
}

export interface IUseVerifiedDomainsTableReturn extends
  Omit<
    IUseTableReturn<IVerifiedDomainsTableRow>,
    'columnOrderBy' | 'columnOrderDirection' | 'selectedRows' | 'resetTableMethods'
  >,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage' | 'resetSelectedRows'>,
  Omit<IUseQueryTableFetchReturn<TGetVerifiedDomainsMethod>, 'data' | 'isFetchedAfterMount'>
{
  verifiedDomains: IUseQueryTableFetchReturn<TGetVerifiedDomainsMethod>['data'];
  selectedVerifiedDomains: IUseTableReturn<IVerifiedDomainsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

interface ITablePreservationConfigs extends
  Omit<IGetVerifiedDomainsParams, 'page' | 'pageLength'>
{
  activePage: IGetVerifiedDomainsParams['page'];
  perPage: IGetVerifiedDomainsParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
};

const useVerifiedDomainsTable = (
  params: IUseVerifiedDomainsTableParams,
): IUseVerifiedDomainsTableReturn => {
  const {
    noDataMessageKey,
  } = params;

  const queryClient = useQueryClient();
  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getVerifiedDomains } = useVerifiedDomainsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const {
    activePage,
    perPage,
    selectedRows: selectedVerifiedDomains,
    resetTableMethods,
    ...restTableProps
  } = useTable<IVerifiedDomainsTableRow>({
    initialActivePage,
    initialPerPage,
  });

  const {
    resetTable,
    resetActivePage,
    resetSelectedRows,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: verifiedDomains, isLoading, isFetchedAfterMount, total, resetQuery,
  } = useQueryTableFetch({
    queryKey: REACT_QUERY_VERIFIED_DOMAINS_KEY,
    queryClient,
    request: getVerifiedDomains,
    params: {
      page: activePage,
      pageLength: perPage,
    },
    onRequestError: handleOnRequestError,
  });

  const { localization } = useTableLocalization({
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
  });

  return {
    verifiedDomains,
    isLoading,
    total,
    resetQuery,
    activePage,
    perPage,
    selectedVerifiedDomains,
    localization,
    resetActivePage,
    resetSelectedRows,
    ...restTableProps,
  };
};

export default useVerifiedDomainsTable;
