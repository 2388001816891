import {
  type PartnerSupporterRequestApprovalPayload,
} from '@uniqkey-backend-organization-web/api-client';
import {
  encryptAsymmetric,
} from '@uniqkey-frontend/shared-app';
import type { ITrustedPortalHandlerParams } from '../../../interfaces';
import APIClientsProvider from '../../../apiClientsProvider';

const ERROR_PREFIX = 'TrustedPortal/handlePartnerSupporterRequestApprovalEvent';

const handlePartnerSupporterRequestApprovalEvent = async (
  params: ITrustedPortalHandlerParams<PartnerSupporterRequestApprovalPayload>,
) => {
  const {
    parsedEvent,
    axiosInstance,
    organizationPrivateKey,
  } = params;

  const { parsedPayload, queueMessageId } = parsedEvent;
  const { partnerPublicKey } = parsedPayload;

  if (!partnerPublicKey) {
    throw new Error(`${ERROR_PREFIX} no partnerPublicKey in event`);
  }

  const encryptedOrganizationPrivate = await encryptAsymmetric({
    string: organizationPrivateKey,
    publicKey: partnerPublicKey,
  });

  await APIClientsProvider.Mobile.getQueueAPIClient(axiosInstance)
    .apiV1QueuePartnerSupportRequestApprovePost(
      {
        queueMessageId,
        encryptedOrganizationPrivate,
      },
    );
};

export default handlePartnerSupporterRequestApprovalEvent;
