import { useCallback, memo, SyntheticEvent } from 'react';
import {
  Panel, PanelHeader, Tabs, Tab, useTranslations,
} from '@uniqkey-frontend/shared-app';
import { useNavigate } from 'react-router-dom';
import { publishTablePreservationReset } from '../../../../helpers/tablePreservation/reset';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import PartnerAccessRequestsTab from '../PartnerAccessRequestsTab';
import AccountRecoveryRequestsTab from '../AccountRecoveryRequestsTab';

export enum RequestsModuleTabEnum {
  AccountRecovery = 'account-recovery',
  PartnerAccess = 'partner-access',
}

interface IRequestsModuleProps {
  activeTab: RequestsModuleTabEnum;
}

const RequestsModule = (props: IRequestsModuleProps) => {
  const { activeTab } = props;
  const { t } = useTranslations();
  const navigate = useNavigate();

  const handleTabClick = useCallback((
    event: SyntheticEvent,
    nextActiveTab: RequestsModuleTabEnum,
  ) => {
    const nextPath = `${PageRouteEnum.Requests}/${nextActiveTab}`;
    if (activeTab !== nextActiveTab) {
      navigate(nextPath);
      return;
    }
    navigate(nextPath, { replace: true });
    publishTablePreservationReset();
  }, [activeTab, navigate]);

  return (
    <Panel className="height-100-percent flex-column">
      <PanelHeader px={3}>
        <Tabs value={activeTab}>
          <Tab
            label={t('requestsPage.tabs.accountRecoveryRequests')}
            value={RequestsModuleTabEnum.AccountRecovery}
            onClick={handleTabClick}
          />
          <Tab
            label={t('requestsPage.tabs.partnerAccessRequests')}
            value={RequestsModuleTabEnum.PartnerAccess}
            onClick={handleTabClick}
          />
        </Tabs>
      </PanelHeader>
      {activeTab === RequestsModuleTabEnum.AccountRecovery && <AccountRecoveryRequestsTab />}
      {activeTab === RequestsModuleTabEnum.PartnerAccess && <PartnerAccessRequestsTab />}
    </Panel>
  );
};

export default memo(RequestsModule);
