import {
  Dispatch, SetStateAction, useCallback, useState,
} from 'react';
import { upperFirst } from 'lodash';
import {
  useSnackbar,
  useTable,
  useQueryTableFetch,
  useTranslations,
  useTableLocalization,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  buildTablePreserverDecoderConfigItem,
  TablePreserverCommonInitialTableValues,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  TUseTablePreserverDecoderConfigs,
  TablePreserverDecoderConfigItemTypeEnum,
  TSetSearchParams,
} from '@uniqkey-frontend/shared-app';
import {
  GetManagedByGroupsByVaultIdV2OrderProperty,
} from '@uniqkey-backend-organization-web/api-client';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import {
  IGetEmployeesManagedByGroupsByVaultIdParams,
  TGetEmployeesManagedByGroupsByVaultIdMethod,
} from '../../useEmployeeAccountsAPI/interfaces';
import {
  IOrganizationLoginEmployeesToGroupsTableRow,
} from '../../../components/tables/OrganizationLoginEmployeesToGroupsTable';
import useEmployeeAccountsAPI from '../../useEmployeeAccountsAPI';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';

export const REACT_QUERY_ORGANIZATION_LOGIN_EMPLOYEES_TO_GROUPS_KEY = [
  ReactQueryKeyEnum.OrganizationLoginEmployeesToGroups,
];

interface IPersistentFilters extends Pick<
  IGetEmployeesManagedByGroupsByVaultIdParams, 'vaultId'
> {}

export interface IUseOrganizationLoginEmployeesToGroupsTableParams {
  persistentFilters: IPersistentFilters;
  noDataMessageKey: string;
}

export interface IUseOrganizationLoginEmployeesToGroupsTableReturn extends
  Omit<
    IUseTableReturn<IOrganizationLoginEmployeesToGroupsTableRow>,
    'selectedRows' | 'resetTableMethods'
  >,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage'>,
  Omit<
    IUseQueryTableFetchReturn<TGetEmployeesManagedByGroupsByVaultIdMethod>,
    'data' | 'isFetchedAfterMount'
  >
{
  employees: IUseQueryTableFetchReturn<TGetEmployeesManagedByGroupsByVaultIdMethod>['data'];
  selectedEmployees: IUseTableReturn<IOrganizationLoginEmployeesToGroupsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  searchQuery: IGetEmployeesManagedByGroupsByVaultIdParams['searchQuery'];
  setSearchQuery: Dispatch<SetStateAction<
    IGetEmployeesManagedByGroupsByVaultIdParams['searchQuery']>
  >;
}

interface ITablePreservationConfigs extends
  Omit<IGetEmployeesManagedByGroupsByVaultIdParams,
    'page' | 'pageLength' | 'vaultId' | 'orderPropertyName' | 'isDescending'
    | 'minUniqkeyAccessScore' | 'maxUniqkeyAccessScore' | 'isUniqkeyAccessScoreEmpty'
  >,
  Pick<
    IUseTableReturn<IOrganizationLoginEmployeesToGroupsTableRow>,
    'columnOrderBy' | 'columnOrderDirection'
  >
{
  activePage: IGetEmployeesManagedByGroupsByVaultIdParams['page'];
  perPage: IGetEmployeesManagedByGroupsByVaultIdParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  searchQuery: true,
  columnOrderBy: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: GetManagedByGroupsByVaultIdV2OrderProperty },
  ),
  columnOrderDirection: true,
};

const useOrganizationLoginEmployeesToGroupsTable = (
  params: IUseOrganizationLoginEmployeesToGroupsTableParams,
): IUseOrganizationLoginEmployeesToGroupsTableReturn => {
  const {
    persistentFilters,
    noDataMessageKey,
  } = params;

  const queryClient = useQueryClient();
  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getEmployeesManagedByGroupsByVaultId } = useEmployeeAccountsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    searchQuery: initialSearchQuery,
    columnOrderBy: initialColumnOrderBy,
    columnOrderDirection: initialColumnOrderDirection,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const [searchQuery, setSearchQuery] = useState<
    IGetEmployeesManagedByGroupsByVaultIdParams['searchQuery']
  >(() => initialSearchQuery);

  const {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedRows: selectedEmployees,
    resetTableMethods,
    ...restTableProps
  } = useTable<IOrganizationLoginEmployeesToGroupsTableRow>({
    initialActivePage,
    initialPerPage,
    initialColumnOrderBy,
    initialColumnOrderDirection,
  });

  const {
    resetTable,
    resetActivePage,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
    setSearchQuery(TablePreserverCommonInitialTableValues.searchQuery);
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: employees, isLoading, isFetchedAfterMount, total, resetQuery,
  } = useQueryTableFetch({
    queryKey: REACT_QUERY_ORGANIZATION_LOGIN_EMPLOYEES_TO_GROUPS_KEY,
    queryClient,
    request: getEmployeesManagedByGroupsByVaultId,
    params: {
      page: activePage,
      pageLength: perPage,
      searchQuery,
      orderPropertyName: GetManagedByGroupsByVaultIdV2OrderProperty[
        upperFirst(columnOrderBy) as keyof typeof GetManagedByGroupsByVaultIdV2OrderProperty
      ],
      isDescending: columnOrderDirection ? columnOrderDirection === 'desc' : undefined,
      ...persistentFilters,
    },
    onRequestError: handleOnRequestError,
  });

  const { localization } = useTableLocalization({
    searchQuery,
    isLoading,
    noDataMessageKey,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    searchQuery,
    columnOrderBy,
    columnOrderDirection,
  });

  return {
    employees,
    isLoading,
    total,
    resetQuery,
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedEmployees,
    localization,
    searchQuery,
    setSearchQuery,
    resetActivePage,
    ...restTableProps,
  };
};

export default useOrganizationLoginEmployeesToGroupsTable;
