import { useRef, useEffect, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import {
  getLastActivityTS,
  setLastActivityTS,
  removeLastActivityTS,
  getCurrentTimeInSeconds,
  getDecodedUserToken,
  logout,
  logoutMe,
} from '../../services/authService';
import { REACT_QUERY_CURRENT_USER_KEY } from '../reactQuery';

type TSetIntervalReturn = ReturnType<typeof setInterval>;

const INTERVAL_DELAY = 60 * 1000; // in milliseconds
const LOGOUT_AFTER = 30 * 60; // in seconds

/*
  'StrictMode' will run the 'useEffect' twice.
  In certain cases, this may cause two logout requests/two logout popups.
  For better local testing, disable 'StrictMode'.
*/
const useUserActivityChecker = (isAuthenticated: boolean): void => {
  const queryClient = useQueryClient();
  const intervalID = useRef<TSetIntervalReturn | null>(null);

  const handleActivityCheck = useCallback(async (): Promise<boolean> => {
    const parsedUserToken = getDecodedUserToken();
    if (!parsedUserToken) {
      logout({ showMessage: true });
      return false;
    }
    const now = getCurrentTimeInSeconds();
    const lastActivityTS = getLastActivityTS();
    if (now - lastActivityTS >= LOGOUT_AFTER) {
      await logoutMe();
      logout({ showMessage: true });
      return false;
    }
    return true;
  }, []);

  const handleMouseDown = useCallback(async (): Promise<void> => {
    const now = getCurrentTimeInSeconds();
    setLastActivityTS(now);
    const parsedUserToken = getDecodedUserToken();
    if (!parsedUserToken) {
      logout({ showMessage: true });
      return;
    }
    const { exp: expireAt } = parsedUserToken;
    if (typeof expireAt === 'number' && now >= expireAt) {
      // will trigger 'createAuthRefreshInterceptor' so other requests will work properly
      await queryClient.refetchQueries(REACT_QUERY_CURRENT_USER_KEY, { exact: true });
      clearInterval(intervalID.current as TSetIntervalReturn);
      intervalID.current = setInterval(handleActivityCheck, INTERVAL_DELAY);
    }
  }, [queryClient, handleActivityCheck]);

  useEffect(() => {
    const run = async () => {
      if (isAuthenticated) {
        if (getLastActivityTS() && !await handleActivityCheck()) {
          /*
            for the case when the user was authenticated and had the last activity, but he should be
            logged out because the activity exceeds the 'LOGOUT_AFTER' period;
            after that,'handleActivityCheck' will log him out, and the 'useEffect' will switch
            to the 'cleanup' logic
          */
          return;
        }
        // set the initial last activity on authentication/page reopen or refresh
        setLastActivityTS();
        document.addEventListener('mousedown', handleMouseDown, true);
        if (intervalID.current === null) {
          intervalID.current = setInterval(handleActivityCheck, INTERVAL_DELAY);
        }
      } else { // cleanup
        removeLastActivityTS();
      }
    };
    run();
    return () => {
      document.removeEventListener('mousedown', handleMouseDown, true);
      clearInterval(intervalID.current as TSetIntervalReturn);
      intervalID.current = null;
    };
  }, [isAuthenticated, handleActivityCheck, handleMouseDown]);
};

export default useUserActivityChecker;
