import {
  Dispatch, SetStateAction, useCallback, useState,
} from 'react';
import { upperFirst } from 'lodash';
import {
  useSnackbar,
  useTable,
  useQueryTableFetch,
  useTranslations,
  useTableLocalization,
  useFilterButton,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  useTablePreserverDecoderCustomConfigMerger,
  usePubSub,
  buildTablePreserverDecoderConfigItem,
  TablePreserverCommonInitialTableValues,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  IUseFilterButtonReturn,
  TUseTablePreserverDecoderConfigs,
  TablePreserverDecoderConfigItemTypeEnum,
  TUseTablePreserverDecoderCustomConfig,
  TSetSearchParams,
} from '@uniqkey-frontend/shared-app';
import {
  VaultSecureNotesOrderProperty,
  GetVaultSecureNotesResponseModel,
} from '@uniqkey-backend-organization-web/api-client';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import {
  IGetVaultSecureNotesParams,
  TGetVaultSecureNotesMethod,
} from '../../useVaultsAPI/interfaces';
import {
  IOrganizationSecureNotesTabFilterSubmitResult,
// eslint-disable-next-line max-len
} from '../../../pages/AllSecuredDataPage/components/OrganizationSecureNotesTab/components/OrganizationSecureNotesTabFilter';
import useVaultsAPI from '../../useVaultsAPI';
import tablePreservationConfigs from '../../../helpers/tablePreservation/configs';
import { REACT_QUERY_SECURE_NOTES_KEY } from '../useEmployeeGroupSecureNotesTable';

export interface IUseOrganizationSecureNotesTableParams {
  customPreservationConfig?: ICustomPreservationConfig;
  noDataMessageKey: string;
}

export interface IOrganizationSecureNotesTableRow extends GetVaultSecureNotesResponseModel {
  lastActivity: string | null;
}

export interface IUseOrganizationSecureNotesTableReturn extends
  Omit<IUseTableReturn<IOrganizationSecureNotesTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage' | 'resetSelectedRows'>,
  Omit<IUseQueryTableFetchReturn<TGetVaultSecureNotesMethod>, 'data' | 'isFetchedAfterMount'>,
  IUseFilterButtonReturn
{
  secureNotes: IUseQueryTableFetchReturn<TGetVaultSecureNotesMethod>['data'];
  selectedSecureNotes: IUseTableReturn<IOrganizationSecureNotesTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  searchQuery: IGetVaultSecureNotesParams['searchQuery'];
  setSearchQuery: Dispatch<SetStateAction<IGetVaultSecureNotesParams['searchQuery']>>;
  filterValues: IOrganizationSecureNotesTabFilterSubmitResult;
  setFilterValues: Dispatch<SetStateAction<IOrganizationSecureNotesTabFilterSubmitResult>>;
}

interface ITablePreservationConfigs extends
  Omit<IGetVaultSecureNotesParams,
    'page' | 'pageLength' | 'groupId' | 'employeeAccountId' | 'orderPropertyName' | 'isDescending'
  >,
  Pick<IUseTableReturn<IOrganizationSecureNotesTableRow>, 'columnOrderBy' | 'columnOrderDirection'>
{
  activePage: IGetVaultSecureNotesParams['page'];
  perPage: IGetVaultSecureNotesParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  searchQuery: true,
  columnOrderBy: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: VaultSecureNotesOrderProperty },
  ),
  columnOrderDirection: true,
  ownership: tablePreservationConfigs.ownershipWithoutUnmanaged,
};

interface ICustomPreservationConfig extends TUseTablePreserverDecoderCustomConfig<
  ITablePreservationConfigs, 'ownership'
> {}

const useOrganizationSecureNotesTable = (
  params: IUseOrganizationSecureNotesTableParams,
): IUseOrganizationSecureNotesTableReturn => {
  const {
    customPreservationConfig,
    noDataMessageKey,
  } = params;

  const queryClient = useQueryClient();
  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getVaultSecureNotes } = useVaultsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const preservationConfig = useTablePreserverDecoderCustomConfigMerger<
    ITablePreservationConfigs, ICustomPreservationConfig
  >(PRESERVATION_CONFIGS, customPreservationConfig);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    searchQuery: initialSearchQuery,
    columnOrderBy: initialColumnOrderBy,
    columnOrderDirection: initialColumnOrderDirection,
    ownership: initialOwnership,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, preservationConfig);

  const [searchQuery, setSearchQuery] = useState<
    IGetVaultSecureNotesParams['searchQuery']
  >(() => initialSearchQuery);
  const [filterValues, setFilterValues] = useState<
    IOrganizationSecureNotesTabFilterSubmitResult
  >({
    ownership: initialOwnership,
  });

  const { isFilterActive, numberOfActiveFilters } = useFilterButton(filterValues);

  const {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedRows: selectedSecureNotes,
    resetTableMethods,
    ...restTableProps
  } = useTable<IOrganizationSecureNotesTableRow>({
    initialActivePage,
    initialPerPage,
    initialColumnOrderBy,
    initialColumnOrderDirection,
  });

  const {
    resetTable,
    resetActivePage,
    resetSelectedRows,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
    setSearchQuery(TablePreserverCommonInitialTableValues.searchQuery);
    setFilterValues({
      ownership: undefined,
    });
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: secureNotes, isLoading, isFetchedAfterMount, total, resetQuery,
  } = useQueryTableFetch({
    queryKey: REACT_QUERY_SECURE_NOTES_KEY,
    queryClient,
    request: getVaultSecureNotes,
    params: {
      page: activePage,
      pageLength: perPage,
      ...filterValues,
      searchQuery,
      orderPropertyName: VaultSecureNotesOrderProperty[
        upperFirst(columnOrderBy) as keyof typeof VaultSecureNotesOrderProperty
      ],
      isDescending: columnOrderDirection ? columnOrderDirection === 'desc' : undefined,
    },
    onRequestError: handleOnRequestError,
  });

  const { localization } = useTableLocalization({
    searchQuery,
    isFilterActive,
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    searchQuery,
    columnOrderBy,
    columnOrderDirection,
    ownership: filterValues.ownership,
  });

  return {
    secureNotes,
    isLoading,
    total,
    resetQuery,
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedSecureNotes,
    localization,
    searchQuery,
    setSearchQuery,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetActivePage,
    resetSelectedRows,
    ...restTableProps,
  };
};

export default useOrganizationSecureNotesTable;
