import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';
import {
  CreateVaultPasswordForEmployeeAccountRequest,
  DeleteBulkVaultToEmployeeAccountResponse,
  DeleteBulkVaultToEmployeeAccountRequest,
  OkResult,
  CreateVaultSecureNoteForEmployeeAccountRequest,
  AddVaultsToEmployeeAccountsRequest,
  AddVaultsToEmployeeAccountsResponse,
  CreateVaultCreditCardForEmployeeRequest,
} from '@uniqkey-backend-organization-web/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import { dataExtractor } from '../../helpers/apiClients';

const useVaultsToEmployeeAccountsAPI = () => {
  const { vaultsToEmployeeAccountsAPIClient } = useApiClients();

  const createVaultsToEmployeeAccounts = useCallback(
    (
      createVaultPasswordForEmployeeAccountRequest: CreateVaultPasswordForEmployeeAccountRequest,
      options?: AxiosRequestConfig,
    ): Promise<OkResult> => vaultsToEmployeeAccountsAPIClient
      .apiV1VaultsToEmployeeAccountsCreateVaultPasswordPost(
        createVaultPasswordForEmployeeAccountRequest,
        options,
      )
      .then(dataExtractor<OkResult>()),
    [vaultsToEmployeeAccountsAPIClient],
  );

  const deleteVaultsToEmployeeAccounts = useCallback(
    (
      deleteBulkVaultToEmployeeAccountRequest?: DeleteBulkVaultToEmployeeAccountRequest,
      options?: AxiosRequestConfig,
    ): Promise<DeleteBulkVaultToEmployeeAccountResponse> => vaultsToEmployeeAccountsAPIClient
      .apiV1VaultsToEmployeeAccountsDeleteBulkPost(deleteBulkVaultToEmployeeAccountRequest, options)
      .then(dataExtractor<DeleteBulkVaultToEmployeeAccountResponse>()),
    [vaultsToEmployeeAccountsAPIClient],
  );

  const createVaultsSecureNoteToEmployeeAccounts = useCallback(
    (
      // eslint-disable-next-line max-len
      createVaultSecureNoteForEmployeeAccountRequest: CreateVaultSecureNoteForEmployeeAccountRequest,
      options?: AxiosRequestConfig,
    ): Promise<OkResult> => vaultsToEmployeeAccountsAPIClient
      .apiV1VaultsToEmployeeAccountsCreateVaultSecureNotePost(
        createVaultSecureNoteForEmployeeAccountRequest,
        options,
      )
      .then(dataExtractor<OkResult>()),
    [vaultsToEmployeeAccountsAPIClient],
  );

  const createVaultsCreditCardToEmployeeAccounts = useCallback(
    (
      createVaultCreditCardForEmployeeRequest: CreateVaultCreditCardForEmployeeRequest,
      options?: AxiosRequestConfig,
    ): Promise<OkResult> => vaultsToEmployeeAccountsAPIClient
      .apiV1VaultsToEmployeeAccountsCreateVaultCreditCardPost(
        createVaultCreditCardForEmployeeRequest,
        options,
      )
      .then(dataExtractor<OkResult>()),
    [vaultsToEmployeeAccountsAPIClient],
  );

  const shareVaultsToEmployee = useCallback(
    (
      addVaultsToEmployeeAccountsRequest: AddVaultsToEmployeeAccountsRequest,
      options?: AxiosRequestConfig,
    ): Promise<AddVaultsToEmployeeAccountsResponse> => vaultsToEmployeeAccountsAPIClient
      .apiV1VaultsToEmployeeAccountsAddPost(addVaultsToEmployeeAccountsRequest, options)
      .then(dataExtractor<AddVaultsToEmployeeAccountsResponse>()),
    [vaultsToEmployeeAccountsAPIClient],
  );

  return {
    createVaultsToEmployeeAccounts,
    deleteVaultsToEmployeeAccounts,
    createVaultsSecureNoteToEmployeeAccounts,
    createVaultsCreditCardToEmployeeAccounts,
    shareVaultsToEmployee,
  };
};

export default useVaultsToEmployeeAccountsAPI;
