import { useMemo, memo, useCallback } from 'react';
import {
  Table,
  Grid,
  LoginAvatar,
  useTranslations,
  IUseTableReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  TypographyWithTooltip,
  IMaterialTableOptions,
  IMaterialTableProps,
  getTimeFromNow,
} from '@uniqkey-frontend/shared-app';
import { useNavigate } from 'react-router-dom';
import { IEmployeeGroupLoginsTableRow } from '../../../hooks/tables/useEmployeeGroupLoginsTable';
import { TGetVaultPasswordsMethod } from '../../../hooks/useVaultsAPI/interfaces';
import PageRouteEnum from '../../../enums/PageRouteEnum';
import ColumnWithSecurityScore from '../ColumnWithSecurityScore';
import { TWO_FA_COLORS, TWO_FA_TRANSLATION_KEYS } from '../../../constants';

interface IGroupLoginsTableProps extends
  Omit<IUseTableReturn<IEmployeeGroupLoginsTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Omit<
    IUseQueryTableFetchReturn<TGetVaultPasswordsMethod>,
    'data' | 'resetQuery' | 'isFetchedAfterMount'
  >
{
  logins: IUseQueryTableFetchReturn<TGetVaultPasswordsMethod>['data'];
  selectedLogins: IUseTableReturn<IEmployeeGroupLoginsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

const TABLE_OPTIONS: IMaterialTableOptions<IEmployeeGroupLoginsTableRow> = {
  selection: true,
};

const TOOLTIP_PROPS = { cursorPointer: true };

const GroupLoginsTable = (props: IGroupLoginsTableProps) => {
  const {
    logins: preLogins,
    isLoading,
    total,
    localization,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    columnOrderBy,
    onColumnOrderByChange,
    columnOrderDirection,
    onColumnOrderDirectionChange,
    selectedLogins,
    onRowSelectionChange,
    createColumns,
  } = props;

  const { t, currentLanguage } = useTranslations();
  const navigate = useNavigate();

  const logins = useMemo(() => preLogins.map((login) => ({
    ...login,
    lastActivity: getTimeFromNow({
      date: login.activityAt,
      locale: currentLanguage,
    }),
  })), [preLogins, currentLanguage]);

  const columns = useMemo(() => createColumns([
    {
      title: t('loginsTab.table.columns.loginName'),
      field: 'name',
      render: (rowData) => (
        <Grid container flexWrap="nowrap" alignItems="center">
          <LoginAvatar src={rowData.iconUrl} />
          <TypographyWithTooltip variant="body2">
            {rowData.name}
          </TypographyWithTooltip>
        </Grid>
      ),
      width: '30%',
    },
    {
      title: t('loginsTab.table.columns.userName'),
      field: 'username',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.username}
        </TypographyWithTooltip>
      ),
      width: '25%',
    },
    {
      title: (
        <TypographyWithTooltip
          variant="subtitle1"
          tooltipProps={TOOLTIP_PROPS}
          lineClamp={5}
          title={t('loginsTab.table.columns.securityScore.columnTooltip')}
        >
          {t('loginsTab.table.columns.securityScore.columnTitle')}
        </TypographyWithTooltip>
      ),
      field: 'securityScore',
      render: (rowData) => (
        <ColumnWithSecurityScore
          securityScore={rowData.securityScore}
          strength={rowData.strength}
          password2FaStatus={rowData.password2FaStatus}
          hasReusedPassword={rowData.isReusedByOrganization || rowData.isReusedByUser}
          isReusedByUser={rowData.isReusedByUser}
          isReusedByOrganization={rowData.isReusedByOrganization}
        />
      ),
      width: '15%',
    },
    {
      title: t('loginsTab.table.columns.2fa'),
      field: 'password2FaStatus',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2" color={TWO_FA_COLORS[rowData.password2FaStatus]}>
          {t(TWO_FA_TRANSLATION_KEYS[rowData.password2FaStatus])}
        </TypographyWithTooltip>
      ),
      width: '15%',
    },
    {
      title: (
        <TypographyWithTooltip
          variant="subtitle1"
          tooltipProps={TOOLTIP_PROPS}
          lineClamp={5}
          title={t('loginsTab.table.columns.lastActivity.columnTooltip')}
        >
          {t('loginsTab.table.columns.lastActivity')}
        </TypographyWithTooltip>
      ),
      field: 'activityAt',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.lastActivity}
        </TypographyWithTooltip>
      ),
      width: '15%',
    },
  ]), [createColumns, t]);

  const handleLoginRowClick = useCallback<
    NonNullable<IMaterialTableProps<IEmployeeGroupLoginsTableRow>['onRowClick']>
  >((event, login) => {
    navigate(`${PageRouteEnum.AllSecuredData}/logins/${login!.vaultId}`);
  }, [navigate]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={logins}
      isLoading={isLoading}
      options={TABLE_OPTIONS}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      columnOrderBy={columnOrderBy}
      onColumnOrderByChange={onColumnOrderByChange}
      columnOrderDirection={columnOrderDirection}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedLogins}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      onRowClick={handleLoginRowClick}
      rowSelectByKey="vaultId"
      rowHeight={51}
    />
  );
};

export default memo(GroupLoginsTable);
