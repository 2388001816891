import getPartnerKeysOperation from './getPartnerKeysOperation';
import pairSupporterOperation from './pairSupporterOperation';
import refreshSupportTokensOperation from './refreshSupportTokensOperation';
import decryptOrganizationPrivateOperation from './decryptOrganizationPrivateOperation';
import getMobileTokensOperation from './getMobileTokensOperation';
import createTrustedPortalOperation from './createTrustedPortalOperation';
import createTrustedActionPayloadOperation from './createTrustedActionPayloadOperation';
import verifyTrustedActionOperation from './verifyTrustedActionOperation';

export default {
  getPartnerKeysOperation,
  pairSupporterOperation,
  refreshSupportTokensOperation,
  decryptOrganizationPrivateOperation,
  getMobileTokensOperation,
  createTrustedPortalOperation,
  createTrustedActionPayloadOperation,
  verifyTrustedActionOperation,
};
