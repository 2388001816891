import type { Either } from '@uniqkey-frontend/shared-app';
import type { OkResult } from '@uniqkey-backend-organization-web/api-client';
import {
  SupportJumpCreateTrustedPortalError,
  type TSupportJumpCreateTrustedPortalOperationErrors,
} from './errors';
import {
  SupportJumpAPIIncorrectAxiosInstanceError,
  SupportJumpMissingStoreDataError,
} from '../../common/errors';
import APIClientsProvider from '../../apiClientsProvider';
import SupportJumpStoreActions from '../../store/actions';
import { dataExtractor } from '../../../../helpers/apiClients';

interface ICreateTrustedPortalOperationParams {
  payload: string;
}
const createTrustedPortalOperation = async (
  params: ICreateTrustedPortalOperationParams,
): Promise<Either<OkResult, TSupportJumpCreateTrustedPortalOperationErrors>> => {
  try {
    const { payload } = params;

    const instance = SupportJumpStoreActions.getAxiosInstance();
    if (!instance) {
      return new SupportJumpAPIIncorrectAxiosInstanceError();
    }

    const companionApplicationId = SupportJumpStoreActions.getCompanionApplicationId();
    if (!companionApplicationId) {
      return new SupportJumpMissingStoreDataError();
    }

    const result = await APIClientsProvider.getSupporterAPIClient(instance)
      .apiV1SupporterCreateTrustedPortalPost({ sessionId: companionApplicationId, payload })
      .then(dataExtractor<OkResult>());
    return result;
  } catch (e) {
    return new SupportJumpCreateTrustedPortalError();
  }
};

export default createTrustedPortalOperation;
