import { useCallback } from 'react';
import {
  GetPartnerSupportRequestsResponse,
  BulkApprovePartnerSupportRequestsRequest,
  BulkApprovePartnerSupportRequestsResponse,
  BulkRejectPartnerSupportRequestsRequest,
  BulkRejectPartnerSupportRequestsResponse,
} from '@uniqkey-backend-organization-web/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import { TGetPartnerAccessRequestsMethod } from './interfaces';
import { dataExtractor } from '../../helpers/apiClients';

const usePartnerSupportRequestsAPI = () => {
  const { partnerSupportRequestsAPIClient } = useApiClients();

  const getPartnerAccessRequests = useCallback<
    TGetPartnerAccessRequestsMethod
  >((params, axiosRequestConfig) => {
    const {
      page,
      pageLength,
      searchQuery = undefined,
      statusFilter = undefined,
      orderPropertyName = undefined,
      isDescending = undefined,
    } = params;
    return partnerSupportRequestsAPIClient.apiV1PartnerSupportRequestsGet(
      page,
      pageLength,
      searchQuery,
      statusFilter,
      orderPropertyName,
      isDescending,
      axiosRequestConfig,
    ).then(dataExtractor<GetPartnerSupportRequestsResponse>());
  }, [partnerSupportRequestsAPIClient]);

  const approvePartnerAccessRequest = useCallback(
    (bulkApprovePartnerSupportRequestsRequest?: BulkApprovePartnerSupportRequestsRequest): Promise<
      BulkApprovePartnerSupportRequestsResponse
    > => partnerSupportRequestsAPIClient
      .apiV1PartnerSupportRequestsBulkApprovePost(bulkApprovePartnerSupportRequestsRequest)
      .then(dataExtractor<BulkApprovePartnerSupportRequestsResponse>()),
    [partnerSupportRequestsAPIClient],
  );

  const rejectPartnerAccessRequest = useCallback(
    (bulkRejectPartnerSupportRequestsRequest?: BulkRejectPartnerSupportRequestsRequest): Promise<
      BulkRejectPartnerSupportRequestsResponse
    > => partnerSupportRequestsAPIClient
      .apiV1PartnerSupportRequestsBulkRejectPost(bulkRejectPartnerSupportRequestsRequest)
      .then(dataExtractor<BulkRejectPartnerSupportRequestsResponse>()),
    [partnerSupportRequestsAPIClient],
  );

  return {
    getPartnerAccessRequests,
    approvePartnerAccessRequest,
    rejectPartnerAccessRequest,
  };
};
export default usePartnerSupportRequestsAPI;
