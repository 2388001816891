import {
  decryptAsymmetric,
  decryptSymmetric,
  derivePasswordKey,
  type Either,
} from '@uniqkey-frontend/shared-app';
import {
  SupportJumpFailedToDecryptError,
  SupportJumpInvalidPasswordError,
  type TSupportJumpDecryptOrganizationPrivateOperationErrors,
} from './errors';
import { SupportJumpMissingStoreDataError } from '../../common/errors';
import SupportJumpStoreActions from '../../store/actions';

interface IDecryptOrganizationPrivateOperationParams {
  password: string;
}
const decryptOrganizationPrivateOperation = async (
  params: IDecryptOrganizationPrivateOperationParams,
): Promise<Either<string, TSupportJumpDecryptOrganizationPrivateOperationErrors>> => {
  const { password } = params;

  const partnerUserId = SupportJumpStoreActions.getPartnerUserId();
  const partnerKeys = SupportJumpStoreActions.getPartnerKeys();
  const partnerUserKeys = SupportJumpStoreActions.getPartnerUserKeys();
  const encryptedOrganizationPrivateKey = SupportJumpStoreActions
    .getEncryptedOrganizationPrivateKey();

  if (!partnerUserId || !partnerKeys || !partnerUserKeys || !encryptedOrganizationPrivateKey) {
    return new SupportJumpMissingStoreDataError();
  }

  let decryptedPartnerUserPrivateKey;

  try {
    const derivedMasterPassword = await derivePasswordKey(password, partnerUserId);

    decryptedPartnerUserPrivateKey = decryptSymmetric({
      cipher: partnerUserKeys.privateKey,
      key: derivedMasterPassword,
    });

    if (!decryptedPartnerUserPrivateKey) {
      return new SupportJumpInvalidPasswordError();
    }
  } catch (e) {
    return new SupportJumpInvalidPasswordError();
  }

  try {
    const decryptedPartnerPrivateKey = await decryptAsymmetric({
      cipher: partnerKeys.privateKey,
      publicKey: partnerUserKeys.publicKey,
      privateKey: decryptedPartnerUserPrivateKey,
    });

    const decryptedOrganizationPrivateKey = await decryptAsymmetric({
      cipher: encryptedOrganizationPrivateKey,
      publicKey: partnerKeys.publicKey,
      privateKey: decryptedPartnerPrivateKey,
    });

    return decryptedOrganizationPrivateKey;
  } catch (e) {
    return new SupportJumpFailedToDecryptError();
  }
};

export default decryptOrganizationPrivateOperation;
