import { type AxiosRequestConfig } from 'axios';
import {
  type GetAllSupportedUILanguagesResponse,
} from '@uniqkey-backend-organization-web/api-client';
import { publicAxiosInstance } from '../../axios';
import { dataExtractor } from '../../helpers/apiClients';
import ApiClientsProvider from '../../common/apiClientsProvider';

export const fetchSupportedLanguages = async (
  params: AxiosRequestConfig = {},
) => ApiClientsProvider.getSupportedUILanguagesAPIClient(publicAxiosInstance)
  .apiV1SupportedUILanguagesGetAllGet(params)
  .then(dataExtractor<GetAllSupportedUILanguagesResponse>());
