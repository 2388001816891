import { type AxiosInstance } from 'axios';
import {
  SupportedUILanguagesApi,
  TrustedActionsApi,
  UserApi,
} from '@uniqkey-backend-organization-web/api-client';
import config from '../../config';

const API_BASE_URL = config.getWebApiUrl();

const getTrustedActionsAPIClient = (instance: AxiosInstance) => new TrustedActionsApi(
  undefined,
  API_BASE_URL,
  instance,
);

export const getSupportedUILanguagesAPIClient = (
  instance: AxiosInstance,
) => new SupportedUILanguagesApi(
  undefined,
  API_BASE_URL,
  instance,
);

export const getUserAPIClient = (instance: AxiosInstance) => new UserApi(
  undefined,
  API_BASE_URL,
  instance,
);

export default {
  getTrustedActionsAPIClient,
  getSupportedUILanguagesAPIClient,
  getUserAPIClient,
};
