/* eslint-disable max-len */
import { throttle } from 'lodash';
import { type QueryClient } from 'react-query';
import { resetReactQuery } from '@uniqkey-frontend/shared-app';
import DataSynchronizationChangeTypeEnum from '../enums/DataSynchronizationChangeTypeEnum';
import { triggerTokensRefresh } from '../services/tokensManager';
import {
  REACT_QUERY_ORGANIZATION_SECURITY_SETTINGS_KEY,
  REACT_QUERY_CURRENT_EMPLOYEE_KEY,
  REACT_QUERY_CURRENT_USER_KEY,
  REACT_QUERY_ORGANIZATION_KEY,
  REACT_QUERY_EMPLOYEE_KEY,
  REACT_QUERY_EMPLOYEE_SECURITY_SCORE_KEY,
  REACT_QUERY_GROUP_KEY,
  REACT_QUERY_LOGIN_KEY,
} from '../hooks/reactQuery';
import { REACT_QUERY_EMPLOYEES_KEY } from '../hooks/tables/useEmployeesTable';
import {
  REACT_QUERY_PARTNER_ACCESS_REQUESTS_KEY,
} from '../hooks/tables/usePartnerAccessRequestsTable';
import { REACT_QUERY_SERVICE_EMPLOYEES_KEY } from '../hooks/tables/useServiceEmployeesTable';
import { REACT_QUERY_GROUPS_KEY } from '../hooks/tables/useGroupsTable';
import { REACT_QUERY_GROUP_RESTRICTIONS_KEY } from '../hooks/tables/useGroupRestrictionsTable';
import { REACT_QUERY_CREDIT_CARDS_KEY } from '../hooks/tables/useEmployeeGroupCreditCardsTable';
import { REACT_QUERY_LOGINS_KEY } from '../hooks/tables/useEmployeeGroupLoginsTable';
import { REACT_QUERY_SECURE_NOTES_KEY } from '../hooks/tables/useEmployeeGroupSecureNotesTable';
import {
  REACT_QUERY_ORGANIZATION_LOGIN_EMPLOYEES_TO_EMPLOYEES_KEY,
} from '../hooks/tables/useOrganizationLoginEmployeesToEmployeesTable';
import {
  REACT_QUERY_ORGANIZATION_LOGIN_EMPLOYEES_TO_GROUPS_KEY,
} from '../hooks/tables/useOrganizationLoginEmployeesToGroupsTable';
import {
  REACT_QUERY_ORGANIZATION_LOGIN_GROUPS_KEY,
} from '../hooks/tables/useOrganizationLoginGroupsTable';
import { REACT_QUERY_SERVICES_KEY } from '../hooks/tables/useServicesTable';
import { REACT_QUERY_VERIFIED_DOMAINS_KEY } from '../hooks/tables/useVerifiedDomainsTable';

export interface IOrganizationPortalSynchronizationPayload {
  changeType: DataSynchronizationChangeTypeEnum;
}

const THROTTLE_WAIT = 1000;

export const hasDataSynchronizationChangeType = (
  currChangeType: DataSynchronizationChangeTypeEnum,
  changeTypeToCompare: DataSynchronizationChangeTypeEnum,
// eslint-disable-next-line no-bitwise
): boolean => (currChangeType & changeTypeToCompare) > 0;

const throttledEmployeeUpdate = throttle((queryClient: QueryClient) => {
  resetReactQuery(queryClient, REACT_QUERY_EMPLOYEES_KEY);
  resetReactQuery(queryClient, REACT_QUERY_EMPLOYEE_KEY);
  resetReactQuery(queryClient, REACT_QUERY_EMPLOYEE_SECURITY_SCORE_KEY);
  resetReactQuery(queryClient, REACT_QUERY_PARTNER_ACCESS_REQUESTS_KEY);
  resetReactQuery(queryClient, REACT_QUERY_SERVICE_EMPLOYEES_KEY);
}, THROTTLE_WAIT);

const throttledGroupUpdate = throttle((queryClient: QueryClient) => {
  resetReactQuery(queryClient, REACT_QUERY_GROUPS_KEY);
  resetReactQuery(queryClient, REACT_QUERY_GROUP_KEY);
  resetReactQuery(queryClient, REACT_QUERY_ORGANIZATION_LOGIN_EMPLOYEES_TO_GROUPS_KEY);
  resetReactQuery(queryClient, REACT_QUERY_ORGANIZATION_LOGIN_GROUPS_KEY);
}, THROTTLE_WAIT);

const throttledVaultUpdate = throttle((queryClient: QueryClient) => {
  resetReactQuery(queryClient, REACT_QUERY_LOGINS_KEY);
  resetReactQuery(queryClient, REACT_QUERY_CREDIT_CARDS_KEY);
  resetReactQuery(queryClient, REACT_QUERY_SECURE_NOTES_KEY);
  resetReactQuery(queryClient, REACT_QUERY_ORGANIZATION_LOGIN_EMPLOYEES_TO_EMPLOYEES_KEY);
  resetReactQuery(queryClient, REACT_QUERY_ORGANIZATION_LOGIN_EMPLOYEES_TO_GROUPS_KEY);
  resetReactQuery(queryClient, REACT_QUERY_ORGANIZATION_LOGIN_GROUPS_KEY);
  resetReactQuery(queryClient, REACT_QUERY_SERVICES_KEY);
  resetReactQuery(queryClient, REACT_QUERY_LOGIN_KEY);
}, THROTTLE_WAIT);

const throttledVerifiedDomainsUpdate = throttle((queryClient: QueryClient) => {
  resetReactQuery(queryClient, REACT_QUERY_VERIFIED_DOMAINS_KEY);
}, THROTTLE_WAIT);

const throttledSecuritySettingsUpdate = throttle((queryClient: QueryClient) => {
  resetReactQuery(queryClient, REACT_QUERY_ORGANIZATION_SECURITY_SETTINGS_KEY);
}, THROTTLE_WAIT);

const throttledOrganizationDetailsUpdate = throttle((queryClient: QueryClient) => {
  triggerTokensRefresh();
  resetReactQuery(queryClient, REACT_QUERY_ORGANIZATION_KEY);
}, THROTTLE_WAIT);

const throttledGroupRestrictionsUpdate = throttle((queryClient: QueryClient) => {
  resetReactQuery(queryClient, REACT_QUERY_GROUP_RESTRICTIONS_KEY);
}, THROTTLE_WAIT);

const throttledCurrentUserUpdate = throttle((queryClient: QueryClient) => {
  resetReactQuery(queryClient, REACT_QUERY_CURRENT_USER_KEY);
  resetReactQuery(queryClient, REACT_QUERY_CURRENT_EMPLOYEE_KEY);
}, THROTTLE_WAIT);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const handleDataSynchronization = (queryClient: QueryClient) => (payload: IOrganizationPortalSynchronizationPayload) => {
  const { changeType } = payload;
  if (hasDataSynchronizationChangeType(changeType, DataSynchronizationChangeTypeEnum.Employee)) {
    throttledEmployeeUpdate(queryClient);
  }
  if (hasDataSynchronizationChangeType(changeType, DataSynchronizationChangeTypeEnum.Group)) {
    throttledGroupUpdate(queryClient);
  }
  if (hasDataSynchronizationChangeType(changeType, DataSynchronizationChangeTypeEnum.Vault)) {
    throttledVaultUpdate(queryClient);
  }
  if (hasDataSynchronizationChangeType(changeType, DataSynchronizationChangeTypeEnum.VerifiedDomains)) {
    throttledVerifiedDomainsUpdate(queryClient);
  }
  if (hasDataSynchronizationChangeType(changeType, DataSynchronizationChangeTypeEnum.SecuritySettings)) {
    throttledSecuritySettingsUpdate(queryClient);
  }
  if (hasDataSynchronizationChangeType(changeType, DataSynchronizationChangeTypeEnum.OrganizationDetails)) {
    throttledOrganizationDetailsUpdate(queryClient);
  }
  if (hasDataSynchronizationChangeType(changeType, DataSynchronizationChangeTypeEnum.GroupRestrictions)) {
    throttledGroupRestrictionsUpdate(queryClient);
  }
  if (hasDataSynchronizationChangeType(changeType, DataSynchronizationChangeTypeEnum.CurrentUserChanged)) {
    throttledCurrentUserUpdate(queryClient);
  }
};
