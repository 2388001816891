import {
  BaseEitherError,
  createDeferred as sharedCreateDeferred,
  TimeEnum,
} from '@uniqkey-frontend/shared-app';
import {
  type GetPartnerKeysResponse,
  type TokenSet,
} from '@uniqkey-backend-organization-web/api-client';
import PubSub from 'pubsub-js';
import {
  initWebSockets,
  destroyWebSocketConnection,
  subscribeToRealtimeAPIEvent,
  type TSubscribeToRealtimeAPIEventCallback,
  type TSubscribeToRealtimeAPIEventEvent,
} from '../../../services/webSocketsManager';
import { logoutMe, logout } from '../../../services/authService';
import type { TSupportJumpDeferredValue } from '../common/interfaces';
import type { ISupportJumpStore } from '../store';
import SubscriptionTypeEnum from '../enums/SubscriptionTypeEnum';
import Axios from '../axios';
import SupportJumpStoreActions from '../store/actions';

const getCurrentEmployeeToken = (employeeTokens: TokenSet['employeeTokens']) => {
  if (!employeeTokens) {
    return null;
  }
  const organizationId = SupportJumpStoreActions.getOrganizationId();
  const employeeToken = employeeTokens.find(
    (token) => token.organizationId === organizationId,
  ) ?? null;
  return employeeToken;
};

const setTokens = (tokens: TokenSet) => {
  const { userToken, employeeTokens } = tokens;
  SupportJumpStoreActions.setUserToken(userToken);
  SupportJumpStoreActions.setEmployeeTokens(employeeTokens);
  const currentEmployeeToken = getCurrentEmployeeToken(employeeTokens);
  SupportJumpStoreActions.setEmployeeToken(currentEmployeeToken);
};

interface IInitStoreParams extends GetPartnerKeysResponse {
  organizationId: NonNullable<ISupportJumpStore['organizationId']>;
  companionApplicationId: NonNullable<ISupportJumpStore['companionApplicationId']>;
  symmetricKey: NonNullable<ISupportJumpStore['symmetricKey']>;
}
const initStore = (params: IInitStoreParams) => {
  const {
    organizationId,
    companionApplicationId,
    symmetricKey,
    organizationTokens,
    partnerUserId,
    partnerPublicKey,
    partnerPrivateKey,
    partnerUserPublicKey,
    partnerUserPrivateKey,
    organizationPrivateKey,
  } = params;
  SupportJumpStoreActions.setOrganizationId(organizationId);
  SupportJumpStoreActions.setCompanionApplicationId(companionApplicationId);
  SupportJumpStoreActions.setSymmetricKey(symmetricKey);
  SupportJumpStoreActions.setPartnerUserId(partnerUserId);
  setTokens(organizationTokens);
  SupportJumpStoreActions.setPartnerKeys({
    publicKey: partnerPublicKey,
    privateKey: partnerPrivateKey,
  });
  SupportJumpStoreActions.setPartnerUserKeys({
    publicKey: partnerUserPublicKey,
    privateKey: partnerUserPrivateKey,
  });
  SupportJumpStoreActions.setEncryptedOrganizationPrivateKey(organizationPrivateKey);
};

const createAxiosInstance = () => {
  const instance = Axios.createAxiosInstance();
  SupportJumpStoreActions.setAxiosInstance(instance);
};

const createDeferred = () => {
  const deferred = sharedCreateDeferred<TSupportJumpDeferredValue>();
  SupportJumpStoreActions.setDeferred(deferred);
  return deferred;
};

const handleError = (error: BaseEitherError) => {
  let deferred = SupportJumpStoreActions.getDeferred();
  if (!deferred) {
    deferred = createDeferred();
  }
  deferred.resolve(error);
  SupportJumpStoreActions.setIsInProgress(false);
};

const createWebSocketConnection = async (guid: string) => {
  const wsConnectionID = SupportJumpStoreActions.getWSConnectionID();
  await initWebSockets(guid, wsConnectionID);
};

const createWebSocketSubscription = <TPayload>(
  event: TSubscribeToRealtimeAPIEventEvent,
  cb: TSubscribeToRealtimeAPIEventCallback<TPayload>,
) => {
  const wsConnectionID = SupportJumpStoreActions.getWSConnectionID();
  const unsubscribeCallback = subscribeToRealtimeAPIEvent<TPayload>(event, cb, wsConnectionID);
  SupportJumpStoreActions.setSubscriptionUnsubscribeHandler({
    type: SubscriptionTypeEnum.WebSocket,
    unsubscribeCallback,
  });
};

const createPubSubSubscription = (...rest: Parameters<typeof PubSub.subscribe>) => {
  const unsubscribeToken = PubSub.subscribe(...rest);
  SupportJumpStoreActions.setSubscriptionUnsubscribeHandler({
    type: SubscriptionTypeEnum.PubSub,
    unsubscribeToken,
  });
};

const unsubscribeSubscriptions = () => {
  const unsubscribeHandlers = SupportJumpStoreActions.getSubscriptionUnsubscribeHandlers();
  unsubscribeHandlers.forEach((unsubscribeHandler) => {
    if (unsubscribeHandler.type === SubscriptionTypeEnum.WebSocket) {
      unsubscribeHandler.unsubscribeCallback();
    } else {
      PubSub.unsubscribe(unsubscribeHandler.unsubscribeToken);
    }
  });
};

const destroyWSConnection = () => {
  const wsConnectionID = SupportJumpStoreActions.getWSConnectionID();
  destroyWebSocketConnection(wsConnectionID);
};

const logoutPreviousUser = async () => {
  await logoutMe();
  logout({ showMessage: false });
};

const startTimer = (error: BaseEitherError, timeoutDelay = TimeEnum.OneMinute) => {
  const timeoutID = setTimeout(() => {
    handleError(error);
    SupportJumpStoreActions.setTimeoutID(null);
  }, timeoutDelay);
  SupportJumpStoreActions.setTimeoutID(timeoutID);
};

const clearTimer = () => {
  const timeoutID = SupportJumpStoreActions.getTimeoutID();
  if (timeoutID) {
    clearTimeout(timeoutID);
    SupportJumpStoreActions.setTimeoutID(null);
  }
};

export default {
  setTokens,
  initStore,
  createAxiosInstance,
  createDeferred,
  handleError,
  createWebSocketConnection,
  createWebSocketSubscription,
  createPubSubSubscription,
  unsubscribeSubscriptions,
  destroyWSConnection,
  logoutPreviousUser,
  startTimer,
  clearTimer,
};
