import { useCallback, useState } from 'react';
import PubSub from 'pubsub-js';
import { useForm } from 'react-hook-form';
import {
  Button,
  ClosedEyeIcon,
  EyeIcon,
  Grid,
  IconButton,
  InputAdornment,
  ReactHookFormTextField,
  usePubSub,
  useTranslations,
  wait,
} from '@uniqkey-frontend/shared-app';
import SupportJumpPubSubEventEnum
  from '../../../../modules/SupportJumpModule/enums/PubSubEventEnum';

interface IFormValue {
  password: string;
}

const DEFAULT_VALUES = {
  password: '',
};

const HIDDEN_USERNAME_ELEMENT = ( // for the extension autofill functionality
  <input
    name="username"
    style={{ visibility: 'hidden', position: 'absolute', top: -1000 }}
  />
);

const SupportJumpEnterPasswordForm = () => {
  const { t } = useTranslations();

  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const {
    handleSubmit,
    formState: { isDirty, isSubmitted, errors },
    control,
    setError,
    reset,
    setFocus,
  } = useForm<IFormValue>({
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  });

  const handleShowPassword = useCallback(() => {
    setIsPasswordShown((prev) => !prev);
  }, []);

  const handleOnSubmit = useCallback((value: IFormValue) => {
    const { password } = value;
    PubSub.publish(SupportJumpPubSubEventEnum.PASSWORD_PROVIDED, password);
  }, []);

  const handleIncorrectPasswordEvent = useCallback(async () => {
    reset(DEFAULT_VALUES, { keepDirtyValues: true });
    setError('password', {
      type: 'incorrectPassword',
      message: t('supportJumpPage.steps.enterPassword.error.wrongPassword'),
    });
    await wait(); // Wait until the password field becomes enabled so that we can focus it
    setFocus('password');
  }, [reset, setError, setFocus, t]);
  usePubSub(SupportJumpPubSubEventEnum.INCORRECT_PASSWORD, handleIncorrectPasswordEvent);

  const isSubmitButtonDisabled = !isDirty || !!errors.password;

  return (
    <Grid container item flexDirection="column" mt={2}>
      <form onSubmit={handleSubmit(handleOnSubmit)} autoComplete="off">
        <Grid container justifyContent="center" rowGap={6}>
          <Grid item xs={12}>
            {HIDDEN_USERNAME_ELEMENT}
            <ReactHookFormTextField
              id="password"
              name="password"
              control={control}
              fullWidth
              autoFocus
              type={isPasswordShown ? 'text' : 'password'}
              label={t('supportJumpPage.steps.enterPassword.label')}
              placeholder={t('supportJumpPage.steps.enterPassword.placeholder')}
              error={!!errors.password}
              helperText={errors.password?.message}
              disabled={isSubmitted}
              rules={{
                required: t('validation.required'),
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={handleShowPassword}>
                      {isPasswordShown ? <ClosedEyeIcon /> : <EyeIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              isLoading={isSubmitted}
              disabled={isSubmitButtonDisabled}
              type="submit"
              fullWidth
            >
              {t('supportJumpPage.steps.enterPassword.submitButtonLabel')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default SupportJumpEnterPasswordForm;
