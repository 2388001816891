import {
  useCallback, useEffect, useMemo, useState, memo,
} from 'react';
import {
  Grid,
  S1,
  Spinner,
  Tooltip,
  TrustIcon,
  Typography,
  useSnackbar,
  useTranslations,
  WarningIcon,
  Collapse,
} from '@uniqkey-frontend/shared-app';
import TrustedPortalToggle from '../TrustedPortalToggle';
import UnprocessedEventsModal from '../UnprocessedEventsModal';
import { useUser } from '../../../../../contexts/UserContext';
import useQueueAPI from '../../../../../hooks/useQueueAPI';
import { getActiveOrganizationId } from '../../../../../services/organizationService';
import {
  getShouldShowUnprocessedEventsWarningByOrganizationId,
  setShouldShowUnprocessedEventsWarningByOrganizationId,
} from '../../../../../services/authService';
import { logException } from '../../../../../services/sentryService';
import {
  subscribeToRealtimeAPIEvent,
} from '../../../../../services/webSocketsManager';
import RealtimeAPIEventTypeEnum from '../../../../../enums/RealtimeAPIEventTypeEnum';
import {
  useGetOrganizationSecuritySettings, useGetUnprocessedInfo,
} from '../../../../../hooks/reactQuery';
import { useTrustedPortalStore } from '../../../../../modules/TrustedPortalModule/store';
import QueueEventEnum from '../../../../../enums/QueueEventEnum';
import useMobileRequestOverlay from '../../../../../hooks/useMobileRequestOverlay';
import MobileRequestOverlay from '../../../../../components/MobileRequestOverlay';
import { listenMobileAction } from '../../../../../helpers/webSockets';

interface ITrustedPortalRowProps {
  isMenuCollapsed: boolean;
}

interface IBuildSXParams {
  isMenuCollapsed: boolean;
}

const COLLAPSE_TIMEOUT = { enter: 1000 };
const buildSX = ({ isMenuCollapsed }: IBuildSXParams) => ({
  marginTop: 2,
  paddingLeft: isMenuCollapsed ? 3 : 1,
  color: S1,
});

const TrustedPortalRow = (props: ITrustedPortalRowProps) => {
  const { isMenuCollapsed } = props;
  const [isUnprocessedEventsModalOpen, setIsUnprocessedEventsModalOpen] = useState<boolean>(false);
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState<boolean>(false);
  const { t } = useTranslations();
  const { showSuccess, showError } = useSnackbar();
  const { activateTrustedPortal, restartTrustedPortalEvents } = useQueueAPI();
  const { isSupporter } = useUser();
  const activeOrganizationId = getActiveOrganizationId();
  const { data: organizationSecuritySettings } = useGetOrganizationSecuritySettings();
  const { trustedPortalAllowed } = organizationSecuritySettings ?? {};

  const isTrustedPortalEnabled = useTrustedPortalStore.useIsEnabledByOrganizationId()[
    activeOrganizationId!
  ] ?? false;
  const isTrustedPortalInitialized = useTrustedPortalStore.useIsInitialized();
  const isTrustedPortalProcessing = useTrustedPortalStore.useIsProcessingByOrganizationId()[
    activeOrganizationId!
  ] ?? false;

  const {
    data: unprocessedInfo,
    isFetchedAfterMount: isUnprocessedInfoFetchedAfterMount,
    remove: removeUnprocessedInfoFromCache,
  } = useGetUnprocessedInfo(
    { activeOrganizationId },
    {
      onError: () => {
        showError({ text: t('common.somethingWentWrong') });
      },
      enabled: isTrustedPortalInitialized && !isTrustedPortalEnabled,
    },
  );
  const { totalCount = 0, eventTypes = [] } = unprocessedInfo ?? {};
  const hasUnprocessedEvents = !!totalCount;

  const {
    isMobileRequestOverlayOpen,
    mobileRequestOverlayQueueMessageId,
    openMobileRequestOverlay,
    closeMobileRequestOverlay,
    clearMobileRequestOverlayTimeout,
  } = useMobileRequestOverlay();

  const handleUnprocessedEventsModalClose = useCallback(() => {
    if (!activeOrganizationId) return;
    setShouldShowUnprocessedEventsWarningByOrganizationId(activeOrganizationId, false);
    setIsUnprocessedEventsModalOpen(false);
  }, [activeOrganizationId]);

  const handleDeactivateModalOpen = useCallback(() => setIsDeactivateModalOpen(true), []);
  const handleDeactivateModalClose = useCallback(() => setIsDeactivateModalOpen(false), []);

  const handleRestartTrustedPortal = useCallback(async () => {
    try {
      clearMobileRequestOverlayTimeout();
      if (isTrustedPortalEnabled) {
        handleDeactivateModalOpen();
        return;
      }
      if (isUnprocessedEventsModalOpen) {
        handleUnprocessedEventsModalClose();
      }
      const { queueMessageId: id } = await activateTrustedPortal();
      openMobileRequestOverlay(id);
    } catch (e) {
      logException(e, { message: 'TrustedPortalRow/handleRestartTrustedPortal exception' });
      showError({
        text: t('common.somethingWentWrong'),
      });
    }
  }, [
    handleDeactivateModalOpen,
    clearMobileRequestOverlayTimeout,
    openMobileRequestOverlay,
    isTrustedPortalEnabled,
    isUnprocessedEventsModalOpen,
    handleUnprocessedEventsModalClose,
    activateTrustedPortal,
    showError,
    t,
  ]);

  const isWarningIconShown = useMemo(
    () => hasUnprocessedEvents && !isTrustedPortalEnabled,
    [hasUnprocessedEvents, isTrustedPortalEnabled],
  );

  useEffect(() => {
    if (!mobileRequestOverlayQueueMessageId) {
      return undefined;
    }
    const unsubscribe = listenMobileAction(
      RealtimeAPIEventTypeEnum.QueueMessageResultNotification,
      mobileRequestOverlayQueueMessageId,
      QueueEventEnum.trustedPortalActivated,
      async () => {
        try {
          closeMobileRequestOverlay();
          showSuccess({ text: t('mobileRequestNotifications.trustPortalActivated') });
          await restartTrustedPortalEvents();
          removeUnprocessedInfoFromCache();
        } catch (e) {
          showError({ text: t('common.somethingWentWrong') });
          logException(e, {
            message: 'TrustedPortalRow/listenMobileAction/trustedPortalActivated exception',
          });
        }
      },
    );
    return () => {
      unsubscribe();
    };
  }, [
    mobileRequestOverlayQueueMessageId,
    closeMobileRequestOverlay,
    restartTrustedPortalEvents,
    removeUnprocessedInfoFromCache,
    showSuccess,
    showError,
    t,
  ]);

  useEffect(() => {
    const unsubscribe = subscribeToRealtimeAPIEvent(
      RealtimeAPIEventTypeEnum.TrustedPortalRequestRejected,
      () => {
        closeMobileRequestOverlay();
        showError({
          text: t('mobileRequestNotifications.trustPortalRejected'),
        });
      },
    );
    return () => {
      unsubscribe();
    };
  }, [showError, closeMobileRequestOverlay, t]);

  useEffect(() => {
    if (!activeOrganizationId) {
      return;
    }
    if (!isUnprocessedInfoFetchedAfterMount) {
      return;
    }
    if (!hasUnprocessedEvents) {
      setShouldShowUnprocessedEventsWarningByOrganizationId(activeOrganizationId, false);
      return;
    }
    // eslint-disable-next-line max-len
    const shouldShowUnprocessedEventsWarning = getShouldShowUnprocessedEventsWarningByOrganizationId(activeOrganizationId);
    if (!shouldShowUnprocessedEventsWarning) {
      return;
    }
    setIsUnprocessedEventsModalOpen(true);
  }, [
    activeOrganizationId,
    hasUnprocessedEvents,
    isUnprocessedInfoFetchedAfterMount,
  ]);

  const showComponent = isTrustedPortalInitialized && (isSupporter || trustedPortalAllowed);
  if (!showComponent) {
    return null;
  }

  return (
    <Grid container columnGap={1} sx={buildSX({ isMenuCollapsed })}>
      <Collapse
        in={isMenuCollapsed}
        orientation="horizontal"
        unmountOnExit
        mountOnEnter
        timeout={COLLAPSE_TIMEOUT}
      >
        <Grid container flexWrap="nowrap" columnGap={1}>
          <Grid item>
            <TrustIcon />
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" noWrap>
              {t('navigation.trustedPortal')}
            </Typography>
          </Grid>
        </Grid>
      </Collapse>
      <Grid item>
        <TrustedPortalToggle
          onClick={handleRestartTrustedPortal}
          isModalOpen={isDeactivateModalOpen}
          onModalClose={handleDeactivateModalClose}
          isSupporter={isSupporter}
        />
      </Grid>
      {isWarningIconShown && (
        <Grid item>
          <Tooltip title={t('navigation.unprocessedEvents')} cursorPointer>
            <WarningIcon height={24} width={24} />
          </Tooltip>
        </Grid>
      )}
      {isTrustedPortalProcessing && (
        <Grid item>
          <Tooltip title={t('navigation.processingEvents')} cursorPointer>
            <Spinner color={S1} />
          </Tooltip>
        </Grid>
      )}
      {isUnprocessedEventsModalOpen && (
        <UnprocessedEventsModal
          isOpen={isUnprocessedEventsModalOpen}
          onClose={handleUnprocessedEventsModalClose}
          onSubmit={handleRestartTrustedPortal}
          unprocessedEvents={eventTypes}
        />
      )}
      <MobileRequestOverlay
        queueMessageId={mobileRequestOverlayQueueMessageId!}
        isOpen={isMobileRequestOverlayOpen}
        onCancel={closeMobileRequestOverlay}
      />
    </Grid>
  );
};

export default memo(TrustedPortalRow);
