import { memo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Grid,
  useTranslations,
  DialogActions,
  Button,
} from '@uniqkey-frontend/shared-app';
import { CheckStatusResponseModel } from '@uniqkey-backend-organization-web/api-client';
import VaultTypeEnum from '../../../../../enums/VaultTypeEnum';
import NotMigratedDataAccordion from '../NotMigratedDataAccordion';

interface INotMigratedDataModalProps {
  notMigratedLogins?: CheckStatusResponseModel[] | null;
  notMigratedCreditCards?: CheckStatusResponseModel[] | null;
  notMigratedSecureNotes?: CheckStatusResponseModel[] | null;
  organizationName: string;
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  onExport: () => void;
}

const NotMigratedDataModal = (props: INotMigratedDataModalProps) => {
  const {
    notMigratedLogins,
    notMigratedCreditCards,
    notMigratedSecureNotes,
    organizationName,
    isOpen,
    onClose,
    isLoading,
    onExport,
  } = props;
  const { t } = useTranslations();

  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      fullWidth
      maxWidth="md"
      clickOutsideToClose={!isLoading}
    >
      <DialogTitle isLoading={isLoading} onClose={onClose}>
        {t('migration.notMigratedDataModal.title')}
      </DialogTitle>
      <DialogContent paddingTop={2}>
        <Grid container flexDirection="column" rowGap={2}>
          <Typography>
            {t('migration.notMigratedDataModal.description')}
          </Typography>
          {!!notMigratedLogins?.length && (
            <NotMigratedDataAccordion
              type={VaultTypeEnum.Password}
              data={notMigratedLogins}
              organizationName={organizationName}
            />
          )}
          {!!notMigratedCreditCards?.length && (
            <NotMigratedDataAccordion
              type={VaultTypeEnum.CreditCard}
              data={notMigratedCreditCards}
              organizationName={organizationName}
            />
          )}
          {!!notMigratedSecureNotes?.length && (
            <NotMigratedDataAccordion
              type={VaultTypeEnum.SecureNote}
              data={notMigratedSecureNotes}
              organizationName={organizationName}
            />
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button isLoading={isLoading} onClick={onExport}>
          {t('migration.notMigratedDataModal.button')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(NotMigratedDataModal);
