import { useCallback } from 'react';
import { SearchByUrlResponse } from '@uniqkey-backend-organization-web/api-client';
import { AxiosRequestConfig } from 'axios';
import { dataExtractor } from '../../helpers/apiClients';
import { useApiClients } from '../../contexts/APIClientsContext';

const useDomainRulesAPI = () => {
  const { domainRulesAPIClient } = useApiClients();

  const getServicesByUrl = useCallback(
    (url?: string, options?: AxiosRequestConfig)
      : Promise<SearchByUrlResponse> => domainRulesAPIClient
      .apiV1DomainRulesSearchByUrlGet(url, options)
      .then(dataExtractor<SearchByUrlResponse>()),
    [domainRulesAPIClient],
  );

  return { getServicesByUrl };
};

export default useDomainRulesAPI;
