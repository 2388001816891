import {
  useCallback, useEffect, useMemo, useState, type ReactNode,
} from 'react';
import {
  generateGUID, generateSymmetricKey, usePubSub,
} from '@uniqkey-frontend/shared-app';
import CompanionApplicationContext from '.';
import {
  getCompanionApplicationId,
  getSymmetricKey,
} from '../../services/companionApplicationService';
import PubSubEventEnum from '../../enums/PubSubEventEnum';
import LocalStorageKeyEnum from '../../enums/LocalStorageKeyEnum';

const getRealGuid = () => getCompanionApplicationId() ?? generateGUID();
const getRealSymmetricKey = () => getSymmetricKey() ?? generateSymmetricKey();

interface ICompanionApplicationProviderContextProps {
  children: ReactNode;
}
const CompanionApplicationProviderContext = (props: ICompanionApplicationProviderContextProps) => {
  const { children } = props;
  const [guid, setGuid] = useState(() => getRealGuid());
  const [symmetricKey, setSymmetricKey] = useState(() => getRealSymmetricKey());

  const handleLogin = useCallback(() => {
    setGuid(getCompanionApplicationId()!);
    setSymmetricKey(getSymmetricKey()!);
  }, []);

  const handleLogout = useCallback(() => {
    setGuid(generateGUID());
    setSymmetricKey(generateSymmetricKey());
  }, []);

  usePubSub(PubSubEventEnum.LOGIN, handleLogin);
  usePubSub(PubSubEventEnum.LOGOUT, handleLogout);

  useEffect(() => {
    const handler = (event: StorageEvent) => {
      if (event.key === LocalStorageKeyEnum.Guid) {
        setGuid(event.newValue ?? generateGUID());
      }
      if (event.key === LocalStorageKeyEnum.SymmetricKey) {
        setSymmetricKey(event.newValue ?? generateSymmetricKey());
      }
    };
    // NOTE: add listener storage - not working on the current tab
    window.addEventListener('storage', handler);
    return () => {
      window.removeEventListener('storage', handler);
    };
  }, []);

  const value = useMemo(() => ({
    guid,
    symmetricKey,
  }), [
    guid,
    symmetricKey,
  ]);

  return (
    <CompanionApplicationContext.Provider value={value}>
      {children}
    </CompanionApplicationContext.Provider>
  );
};

export default CompanionApplicationProviderContext;
