import { useCallback, memo, useState } from 'react';
import {
  useSnackbar,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import {
  GetEmployeeAccountByIdResponse,
  GetForLookupResponseModel,
} from '@uniqkey-backend-organization-web/api-client';
import EntitySelectorModal, {
  TEntitySelectorModalOnSubmit,
} from '../../../../components/EntitySelectorModal';
import useEmployeeAccountsToGroupsAPI from '../../../../hooks/useEmployeeAccountsToGroupsAPI';
import useEmployeeAccountsAPI from '../../../../hooks/useEmployeeAccountsAPI';
import { logException } from '../../../../services/sentryService';
import { getActiveOrganizationId } from '../../../../services/organizationService';
import { useTrustedPortalStore } from '../../../../modules/TrustedPortalModule/store';

interface IEmployeesSelectorModalProps {
  isOpen: boolean;
  onClose: () => void;
  groupsIds: string[];
  onSubmit?: () => void;
  groupName?: string;
}

interface IParsedEntity {
  id: GetEmployeeAccountByIdResponse['employeeAccountId'];
  label: GetEmployeeAccountByIdResponse['email'];
}

const EmployeesSelectorModal = (props: IEmployeesSelectorModalProps) => {
  const {
    isOpen, onClose, groupsIds, onSubmit, groupName,
  } = props;
  const [isEmployeesSelectorModalLoading, setIsEmployeesSelectorModalLoading] = useState(false);
  const [loadedEmployeeAccountsCount, setLoadedEmployeeAccountsCount] = useState<number>(0);
  const [
    totalLoadedEmployeeAccountsCount, setTotalLoadedEmployeeAccountsCount,
  ] = useState<number>(0);
  const [response, setResponse] = useState<GetForLookupResponseModel[]>([]);
  const { t } = useTranslations();
  const { showSuccess, showWarning, showError } = useSnackbar();
  const { getEmployeeAccountsForLookup } = useEmployeeAccountsAPI();
  const { inviteBulkToGroup } = useEmployeeAccountsToGroupsAPI();

  const activeOrganizationId = getActiveOrganizationId();
  const isTrustedPortalEnabled = useTrustedPortalStore.useIsEnabledByOrganizationId()[
    activeOrganizationId!
  ] ?? false;

  const handleAutocompleteRequest = useCallback(async (searchQuery: string) => {
    try {
      const {
        data,
        total,
      } = await getEmployeeAccountsForLookup({ page: 1, pageLength: 200, searchQuery });
      setLoadedEmployeeAccountsCount(data.length);
      setTotalLoadedEmployeeAccountsCount(total);
      setResponse(data);
      return data;
    } catch (e) {
      setLoadedEmployeeAccountsCount(0);
      setTotalLoadedEmployeeAccountsCount(0);
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'EmployeesSelectorModal/handleAutocompleteRequest exception',
      });
      return [];
    }
  }, [getEmployeeAccountsForLookup, showError, t]);

  const handleSubmitEntitySelectorModal = useCallback<
    TEntitySelectorModalOnSubmit<IParsedEntity[]>
  >(async (value) => {
    try {
      const employeeAccountsIds = value.map((el: { id: string }) => el.id);
      setIsEmployeesSelectorModalLoading(true);
      await inviteBulkToGroup({ employeeAccountsIds, groupsIds });
      if (isTrustedPortalEnabled) {
        showSuccess({
          text: t('trustedPortalSuccessNotifications.membersAddedToGroup', {
            count: employeeAccountsIds.length,
          }),
        });
      } else {
        showWarning({
          text: t('groupsPage.addingEmployeesToGroup', { count: employeeAccountsIds.length }),
        });
      }
      if (onSubmit) {
        onSubmit();
      }
      setIsEmployeesSelectorModalLoading(false);
      onClose();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      setIsEmployeesSelectorModalLoading(false);
      logException(e, {
        message: 'EmployeesSelectorModal/handleSubmitEntitySelectorModal exception',
      });
    }
  }, [
    inviteBulkToGroup,
    groupsIds,
    isTrustedPortalEnabled,
    onSubmit,
    onClose,
    showSuccess,
    showWarning,
    showError,
    t,
  ]);

  const handleResponseEntitySelectorModal = useCallback(
    (employeeAccountsToParse: GetEmployeeAccountByIdResponse[]) => employeeAccountsToParse.map(
      (employeeAccount: GetEmployeeAccountByIdResponse) => ({
        id: employeeAccount.employeeAccountId,
        label: employeeAccount.email,
      }),
    ),
    [],
  );

  const title = groupName
    ? t('addToGroupModal.title.withName', { groupName })
    : t('addToGroupModal.title.default');

  return (
    <EntitySelectorModal
      dataKey="employeeAccountId"
      data={response}
      isOpen={isOpen}
      title={title}
      placeholder={t('addToGroupModal.selectEmployeePlaceholder')}
      onSubmit={handleSubmitEntitySelectorModal}
      request={handleAutocompleteRequest}
      responseParser={handleResponseEntitySelectorModal}
      onClose={onClose}
      isLoading={isEmployeesSelectorModalLoading}
      loadedOptionsCount={loadedEmployeeAccountsCount}
      totalLoadedOptionsCount={totalLoadedEmployeeAccountsCount}
    />
  );
};

export default memo(EmployeesSelectorModal);
