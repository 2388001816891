import { memo } from 'react';
import {
  Typography,
  Grid,
  S5,
  S6,
  WidgetContainer,
  List,
  ListItem,
  Box,
  SecurityScoreWidget,
  useTranslations,
  ShieldImageV2,
} from '@uniqkey-frontend/shared-app';
import { isNumber } from 'lodash';
import {
  useGetEmployeeAverageSecurityScore, useGetEmployeeAccountById,
} from '../../../../hooks/reactQuery';
import UniqkeyAccessScoreStatisticsWidget from '../UniqkeyAccessScoreStatisticsWidget';

interface IUniqkeyAccessScoreWidgetProps {
  employeeAccountId: string;
}

const LIST_SX = { listStyleType: 'disc', paddingLeft: 3 };
const LIST_ITEM_SX = { display: 'list-item' };

const UniqkeyAccessScoreWidget = (props: IUniqkeyAccessScoreWidgetProps) => {
  const { employeeAccountId } = props;
  const { t } = useTranslations();
  const {
    data: employeeAverageSecurityScore,
    isLoading: isEmployeeAverageSecurityScoreLoading,
    isError: isEmployeeAverageSecurityScoreError,
  } = useGetEmployeeAverageSecurityScore({ employeeAccountId });
  const { uniqkeyAccessScore } = employeeAverageSecurityScore ?? {};

  const {
    data: employeeAccount, isLoading: isEmployeeAccountLoading, isError: isEmployeeAccountError,
  } = useGetEmployeeAccountById({ employeeAccountId });
  const {
    hasReusedPasswords, isMasterPasswordReused, masterPasswordStrength,
  } = employeeAccount ?? {};

  const isLoading = isEmployeeAverageSecurityScoreLoading || isEmployeeAccountLoading;
  const isError = isEmployeeAverageSecurityScoreError || isEmployeeAccountError;

  if (isError) {
    return null;
  }

  if (!isNumber(uniqkeyAccessScore)) {
    return (
      <WidgetContainer withShadow isLoading={isLoading}>
        <Typography variant="subtitle1" color={S6}>
          {t('uniqkeyAccessScoreStatisticsWidget.title')}
        </Typography>
        <Typography variant="caption" color={S5}>
          {t('uniqkeyAccessScoreStatisticsWidget.noScore')}
        </Typography>
      </WidgetContainer>
    );
  }

  return (
    <WidgetContainer withShadow isLoading={isLoading}>
      <Grid item container justifyContent="space-between" wrap="nowrap">
        <Grid item>
          <Typography variant="subtitle1" color={S6}>
            {t('uniqkeyAccessScoreStatisticsWidget.title')}
          </Typography>
          <Typography variant="caption" color={S5}>
            {t('uniqkeyAccessScoreStatisticsWidget.calculatedBased')}
            :
          </Typography>
          <List sx={LIST_SX}>
            <ListItem sx={LIST_ITEM_SX}>
              <Typography variant="body2" color={S5}>
                {t('uniqkeyAccessScoreStatisticsWidget.masterPasswordStrength')}
              </Typography>
            </ListItem>
            <ListItem sx={LIST_ITEM_SX}>
              <Typography variant="body2" color={S5}>
                {t('uniqkeyAccessScoreStatisticsWidget.masterPasswordIsReused')}
              </Typography>
            </ListItem>
            <ListItem sx={LIST_ITEM_SX}>
              <Typography variant="body2" color={S5}>
                {t('uniqkeyAccessScoreStatisticsWidget.hasReusedPasswords')}
              </Typography>
            </ListItem>
          </List>
        </Grid>
        <Grid item>
          <Box pl={2} pb={2} pr={4} pt={4} display="flex" justifyContent="center">
            <SecurityScoreWidget percentage={uniqkeyAccessScore} t={t} Icon={ShieldImageV2} />
          </Box>
        </Grid>
      </Grid>
      <UniqkeyAccessScoreStatisticsWidget
        masterPasswordStrength={masterPasswordStrength ?? 0}
        isMasterPasswordReused={isMasterPasswordReused}
        hasReusedPasswords={hasReusedPasswords}
      />
    </WidgetContainer>
  );
};

export default memo(UniqkeyAccessScoreWidget);
