import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';
import {
  GetEmployeeAccountsOnBoardResponse,
  GetEmployeeActivationRateResponse,
  GetActiveEmployeesRateResponse,
  GetAverageSecurityScoreForWidgetResponse,
  GetSecurityScoreRateV2Response,
} from '@uniqkey-backend-organization-web/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import { dataExtractor } from '../../helpers/apiClients';

const useDashboardAPI = () => {
  const { dashboardAPIClient } = useApiClients();

  const getEmployeeAccountsOnBoard = useCallback(
    (options?: AxiosRequestConfig)
      : Promise<GetEmployeeAccountsOnBoardResponse> => dashboardAPIClient
      .apiV1DashboardGetEmployeeAccountsOnBoardGet(options)
      .then(dataExtractor<GetEmployeeAccountsOnBoardResponse>()),
    [dashboardAPIClient],
  );

  const getEmployeeActivationRate = useCallback(
    (dateFrom: string, dateTo: string, options?: AxiosRequestConfig)
      : Promise<GetEmployeeActivationRateResponse> => dashboardAPIClient
      .apiV1DashboardGetEmployeeActivationRateGet(dateFrom, dateTo, options)
      .then(dataExtractor<GetEmployeeActivationRateResponse>()),
    [dashboardAPIClient],
  );

  const getActiveEmployeesRate = useCallback(
    (dateFrom: string, dateTo: string, options?: AxiosRequestConfig)
      : Promise<GetActiveEmployeesRateResponse> => dashboardAPIClient
      .apiV1DashboardGetActiveEmployeesRateGet(dateFrom, dateTo, options)
      .then(dataExtractor<GetActiveEmployeesRateResponse>()),
    [dashboardAPIClient],
  );

  const getAverageSecurityScore = useCallback(
    (options?: AxiosRequestConfig)
      : Promise<GetAverageSecurityScoreForWidgetResponse> => dashboardAPIClient
      .apiV1DashboardGetAverageSecurityScoreGet(options)
      .then(dataExtractor<GetAverageSecurityScoreForWidgetResponse>()),
    [dashboardAPIClient],
  );

  const getSecurityScoreRate = useCallback(
    (dateFrom: string, dateTo: string, options?: AxiosRequestConfig)
      : Promise<GetSecurityScoreRateV2Response> => dashboardAPIClient
      .apiV2DashboardGetSecurityScoreRateGet(dateFrom, dateTo, options)
      .then(dataExtractor<GetSecurityScoreRateV2Response>()),
    [dashboardAPIClient],
  );

  return {
    getEmployeeAccountsOnBoard,
    getEmployeeActivationRate,
    getActiveEmployeesRate,
    getAverageSecurityScore,
    getSecurityScoreRate,
  };
};

export default useDashboardAPI;
