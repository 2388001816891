import { useCallback, memo } from 'react';
import {
  AO4, Grid, PromptDialog, Typography, useTranslations, WarningIcon,
} from '@uniqkey-frontend/shared-app';
import { logoutMe, logout } from '../../../../../services/authService';
import { getActiveOrganizationId } from '../../../../../services/organizationService';
import { useTrustedPortalStore } from '../../../../../modules/TrustedPortalModule/store';

interface ILogoutModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const LogoutModal = (props: ILogoutModalProps) => {
  const { isOpen, onClose } = props;
  const { t } = useTranslations();
  const activeOrganizationId = getActiveOrganizationId();
  const isTrustedPortalProcessing = useTrustedPortalStore.useIsProcessingByOrganizationId()[
    activeOrganizationId!
  ] ?? false;
  const handleLogout = useCallback(async () => {
    await logoutMe();
    logout();
  }, []);

  return (
    <PromptDialog
      open={isOpen}
      onClose={onClose}
      onSubmit={handleLogout}
      title={t('logoutModal.title')}
      approvalButtonText={isTrustedPortalProcessing ? t('logoutModal.logout') : t('common.confirm')}
      rejectionButtonText={t('common.cancel')}
      approvalButtonColor={isTrustedPortalProcessing ? 'primary' : 'error'}
    >
      {isTrustedPortalProcessing && (
        <Grid container rowGap={4}>
          <Grid item>
            <Typography>{t('logoutModal.portalIsProcessingEvents')}</Typography>
          </Grid>
          <Grid item container alignItems="center" columnGap={1}>
            <Grid item>
              <WarningIcon color={AO4} width={24} height={24} />
            </Grid>
            <Grid item>
              <Typography color={AO4}>
                {t('logoutModal.ifYouLogOut')}
              </Typography>
              <Typography color={AO4}>
                {t('logoutModal.waitTillEventsProcessed')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography>{t('logoutModal.checkForTheStatus')}</Typography>
          </Grid>
        </Grid>
      )}
    </PromptDialog>
  );
};

export default memo(LogoutModal);
