import type { Either } from '@uniqkey-frontend/shared-app';
import { JSONParseError, type TJSONParseOperationErrors } from './errors';

const jsonParseOperation = <TReturn = any>(
  text: string,
): Either<TReturn, TJSONParseOperationErrors> => {
  try {
    return JSON.parse(text);
  } catch (e) {
    return new JSONParseError();
  }
};

export default jsonParseOperation;
