import { useCallback } from 'react';
import {
  useSnackbar,
  useTable,
  useQueryTableFetch,
  useTranslations,
  useTableLocalization,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  TUseTablePreserverDecoderConfigs,
  TSetSearchParams,
} from '@uniqkey-frontend/shared-app';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import {
  IGetGroupRestrictionsParams,
  TGetGroupRestrictionsMethod,
} from '../../useGroupRestrictionsAPI/interfaces';
import useGroupRestrictionsAPI from '../../useGroupRestrictionsAPI';
import { TGroupRestrictionsTableRow } from '../../../components/tables/GroupRestrictionsTable';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';

export const REACT_QUERY_GROUP_RESTRICTIONS_KEY = [ReactQueryKeyEnum.GroupRestrictions];

interface IPersistentFilters extends Pick<IGetGroupRestrictionsParams, 'groupId'> {}

export interface IUseGroupRestrictionsTableParams {
  persistentFilters: IPersistentFilters;
  noDataMessageKey: string;
}

export interface IUseGroupRestrictionsTableReturn extends
  Omit<
    IUseTableReturn<TGroupRestrictionsTableRow>,
    'columnOrderBy' | 'columnOrderDirection' | 'onColumnOrderByChange'
    | 'onColumnOrderDirectionChange' | 'selectedRows' | 'resetTableMethods'
  >,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage' | 'resetSelectedRows'>,
  Omit<IUseQueryTableFetchReturn<TGetGroupRestrictionsMethod>, 'data' | 'isFetchedAfterMount'>
{
  restrictions: IUseQueryTableFetchReturn<TGetGroupRestrictionsMethod>['data'];
  selectedRestrictions: IUseTableReturn<TGroupRestrictionsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

interface ITablePreservationConfigs extends
  Omit<IGetGroupRestrictionsParams, 'page' | 'pageLength' | 'groupId'>
{
  activePage: IGetGroupRestrictionsParams['page'];
  perPage: IGetGroupRestrictionsParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
};

const useGroupRestrictionsTable = (
  params: IUseGroupRestrictionsTableParams,
): IUseGroupRestrictionsTableReturn => {
  const {
    persistentFilters,
    noDataMessageKey,
  } = params;

  const queryClient = useQueryClient();
  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getGroupRestrictions } = useGroupRestrictionsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const {
    activePage,
    perPage,
    selectedRows: selectedRestrictions,
    resetTableMethods,
    ...restTableProps
  } = useTable<TGroupRestrictionsTableRow>({
    initialActivePage,
    initialPerPage,
  });

  const {
    resetTable,
    resetActivePage,
    resetSelectedRows,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: restrictions, isLoading, isFetchedAfterMount, total, resetQuery,
  } = useQueryTableFetch({
    queryKey: REACT_QUERY_GROUP_RESTRICTIONS_KEY,
    queryClient,
    request: getGroupRestrictions,
    params: {
      page: activePage,
      pageLength: perPage,
      ...persistentFilters,
    },
    onRequestError: handleOnRequestError,
  });

  const { localization } = useTableLocalization({
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
  });

  return {
    restrictions,
    isLoading,
    total,
    resetQuery,
    activePage,
    perPage,
    selectedRestrictions,
    localization,
    resetActivePage,
    resetSelectedRows,
    ...restTableProps,
  };
};

export default useGroupRestrictionsTable;
