import axios, { type AxiosInstance } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import config from '../../../config';
import SupportJumpStoreActions from '../store/actions';
import refreshSupportTokensOperation from '../operations/refreshSupportTokensOperation';

const WEB_API_BASE_URL = config.getWebApiUrl();
const TIMEOUT = config.requestTimeout;

const axiosInstanceWithoutAuthRefresh = axios.create({
  baseURL: WEB_API_BASE_URL,
  timeout: TIMEOUT,
});

const createAxiosInstance = (): AxiosInstance => {
  const instance = axios.create({
    baseURL: WEB_API_BASE_URL,
    timeout: TIMEOUT,
  });

  instance.interceptors.request.use(async (axiosRequestConfig) => {
    const employeeToken = SupportJumpStoreActions.getEmployeeToken();
    if (axiosRequestConfig.headers && employeeToken) {
      // eslint-disable-next-line no-param-reassign
      axiosRequestConfig.headers.Authorization = `Bearer ${employeeToken.token}`;
    }
    return axiosRequestConfig;
  });

  createAuthRefreshInterceptor(
    instance,
    refreshSupportTokensOperation(axiosInstanceWithoutAuthRefresh),
  );

  return instance;
};

export default {
  createAxiosInstance,
};
