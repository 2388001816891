import { BaseEitherError, type Either } from '@uniqkey-frontend/shared-app';
import { TrustedActionType } from '@uniqkey-backend-organization-web/api-client';
import {
  hasDataSynchronizationChangeType,
  type IOrganizationPortalSynchronizationPayload,
} from '../../../../helpers/dataSynchronization';
import DataSynchronizationChangeTypeEnum from '../../../../enums/DataSynchronizationChangeTypeEnum';
import {
  SupportJumpNoTrustedActionError,
  SupportJumpTrustedActionVerificationFailedError,
  SupportJumpWrongOrganizationPortalSynchronizationEventError,
  type TSupportJumpVerifyTrustedActionOperationErrors,
} from './errors';
import {
  SupportJumpAPIIncorrectAxiosInstanceError,
  SupportJumpMissingStoreDataError,
} from '../../common/errors';
import Operations from '../../../../common/operations';
import SupportJumpStoreActions from '../../store/actions';

interface IVerifyTrustedActionOperationParams {
  eventPayload: IOrganizationPortalSynchronizationPayload;
}

const verifyTrustedActionOperation = async (
  params: IVerifyTrustedActionOperationParams,
): Promise<Either<void, TSupportJumpVerifyTrustedActionOperationErrors>> => {
  try {
    const { eventPayload } = params;
    const { changeType } = eventPayload;
    const isTrustedActionEvent = hasDataSynchronizationChangeType(
      changeType,
      DataSynchronizationChangeTypeEnum.TrustedAction,
    );
    if (!isTrustedActionEvent) {
      return new SupportJumpWrongOrganizationPortalSynchronizationEventError();
    }

    const instance = SupportJumpStoreActions.getAxiosInstance();
    if (!instance) {
      return new SupportJumpAPIIncorrectAxiosInstanceError();
    }

    const organizationId = SupportJumpStoreActions.getOrganizationId();
    const symmetricKey = SupportJumpStoreActions.getSymmetricKey();
    const companionApplicationId = SupportJumpStoreActions.getCompanionApplicationId();
    if (!organizationId || !symmetricKey || !companionApplicationId) {
      return new SupportJumpMissingStoreDataError();
    }

    const trustedPortalActionsResult = await Operations.getAllTrustedPortalActionsOperation(
      instance,
    );
    if (trustedPortalActionsResult instanceof BaseEitherError) {
      return trustedPortalActionsResult;
    }

    const { data: trustedPortalActions } = trustedPortalActionsResult;

    const trustedPortalAction = trustedPortalActions.find((currentAction) => (
      currentAction.organizationId === organizationId
      && currentAction.companionApplicationId === companionApplicationId
      && currentAction.trustedActionType === TrustedActionType.TrustedPortal
    ));
    if (!trustedPortalAction) {
      return new SupportJumpNoTrustedActionError();
    }

    const { payload } = trustedPortalAction;
    const decryptTrustedActionPayloadResult = Operations.decryptTrustedActionPayloadOperation({
      payload,
      symmetricKey,
    });

    if (decryptTrustedActionPayloadResult instanceof BaseEitherError) {
      return decryptTrustedActionPayloadResult;
    }

    return undefined;
  } catch (e) {
    return new SupportJumpTrustedActionVerificationFailedError();
  }
};

export default verifyTrustedActionOperation;
