import { useMemo, memo } from 'react';
import {
  Table,
  useTranslations,
  IUseTableReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  TypographyWithTooltip,
  IMaterialTableOptions,
  formatDate,
  DateTimeFormatEnum,
} from '@uniqkey-frontend/shared-app';
import {
  GetPartnersWithContractResponseModel,
} from '@uniqkey-backend-organization-web/api-client';
import { TGetPartnersAccessMethod } from '../../../hooks/useOrganizationSettingsAPI/interfaces';

export interface IPartnersWithAccessTableRow extends GetPartnersWithContractResponseModel {
  processedDate: string | null;
}

interface IPartnersWithAccessTableProps extends
  Omit<IUseTableReturn<IPartnersWithAccessTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Omit<
    IUseQueryTableFetchReturn<TGetPartnersAccessMethod>,
    'data' | 'resetQuery' | 'isFetchedAfterMount'
  >
{
  partners: IUseQueryTableFetchReturn<TGetPartnersAccessMethod>['data'];
  selectedPartners: IUseTableReturn<IPartnersWithAccessTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  options?: IMaterialTableOptions<IPartnersWithAccessTableRow>;
}

const PartnersWithAccessTable = (props: IPartnersWithAccessTableProps) => {
  const {
    partners: prePartners,
    isLoading,
    total,
    localization,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    columnOrderBy,
    onColumnOrderByChange,
    columnOrderDirection,
    onColumnOrderDirectionChange,
    selectedPartners,
    onRowSelectionChange,
    createColumns,
    options,
  } = props;

  const { t } = useTranslations();

  const partners = useMemo(() => prePartners.map((partner) => ({
    ...partner,
    processedDate: formatDate({ date: partner.accessDate }, DateTimeFormatEnum.FULL),
  })), [prePartners]);

  const columns = useMemo(() => createColumns([
    {
      title: t('settingsPage.partnersWithAccessTab.table.columns.name'),
      field: 'partnerName',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.partnerName}
        </TypographyWithTooltip>
      ),
      width: '80%',
    },
    {
      title: t('settingsPage.partnersWithAccessTab.table.columns.accessDate'),
      field: 'accessDate',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.processedDate}
        </TypographyWithTooltip>
      ),
      width: '20%',
      sorting: false,
    },
  ]), [createColumns, t]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={partners}
      isLoading={isLoading}
      options={options}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      columnOrderBy={columnOrderBy}
      onColumnOrderByChange={onColumnOrderByChange}
      columnOrderDirection={columnOrderDirection}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedPartners}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      rowSelectByKey="partnerId"
      rowHeight={51}
    />
  );
};

export default memo(PartnersWithAccessTable);
