import {
  type AxiosInstance,
  type AxiosRequestConfig,
} from 'axios';
import { BaseEitherError } from '@uniqkey-frontend/shared-app';
import {
  type GetQueueMessagesResponse,
  type OrganizationKeysResponse,
} from '@uniqkey-backend-organization-web/api-client';
import {
  type FailQueueRequest,
  type FailQueueResponse,
} from '@uniqkey-backend-organization-mobile/api-client';
import { employeeAxiosInstance, userAxiosInstance } from '../../../axios';
import Operations from '../../../common/operations';
import { dataExtractor } from '../../../helpers/apiClients';
import Helpers from '../helpers';
import APIClientsProvider from '../apiClientsProvider';

const getAllTrustedPortalActions = async () => {
  const result = await Operations.getAllTrustedPortalActionsOperation(employeeAxiosInstance);
  if (result instanceof BaseEitherError) {
    return result;
  }
  const { data } = result;
  return data;
};

const getAllOrganizationPublicKeys = async () => {
  const { data } = await APIClientsProvider.Web.getOrganizationKeysAPIClient(userAxiosInstance)
    .apiV1OrganizationKeysGet()
    .then(dataExtractor<OrganizationKeysResponse>());
  return data;
};

interface IGetQueueMessagesParams {
  quantity: number;
  activeOrganizationId: string;
  options?: AxiosRequestConfig;
}
const getQueueMessages = async (params: IGetQueueMessagesParams) => {
  const { quantity, activeOrganizationId, options } = params;
  const employeeAccountId = Helpers.getEmployeeAccountId({
    activeOrganizationId,
    throwErrors: true,
  }) as string;
  const { data } = await APIClientsProvider.Web.getQueueAPIClient(employeeAxiosInstance)
    .apiV1QueueGetQueueMessagesGet(quantity, employeeAccountId, options)
    .then(dataExtractor<GetQueueMessagesResponse>());
  return data;
};

const sendQueueCommonFail = (
  instance: AxiosInstance,
  failQueueRequest?: FailQueueRequest,
  options?: AxiosRequestConfig,
) => APIClientsProvider.Mobile.getQueueAPIClient(instance)
  .apiV1QueueQueueCommonFailPost(failQueueRequest, options)
  .then(dataExtractor<FailQueueResponse>());

export default {
  getAllTrustedPortalActions,
  getAllOrganizationPublicKeys,
  getQueueMessages,
  sendQueueCommonFail,
};
