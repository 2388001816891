import { useCallback, useState } from 'react';
import {
  CopyToClipboardButton,
  Divider,
  generateRandomString,
  Grid,
  IconButton,
  ScrollablePanelContent,
  RefreshIcon,
  Typography,
  useSnackbar,
  useTranslations,
  S4,
  S2,
  AB3,
  DeleteIcon,
  PromptDialog,
  Tooltip,
} from '@uniqkey-frontend/shared-app';
import { UpdateScimSettingsRequest } from '@uniqkey-backend-organization-web/api-client';
import SCIMIntegrationWidget from './SCIMIntegrationWidget';
import {
  type TUseUpdateOrganizationScimSettingsOptions,
  useGetOrganizationScimSettings,
  useUpdateOrganizationScimSettings,
} from '../../../../hooks/reactQuery';
import SCIMIntegrationDetail from './SCIMIntegrationDetail';
import config from '../../../../config';

const SCIMIntegrationTab = () => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isRegenerateTokenDialogOpen, setIsRegenerateTokenDialogOpen] = useState(false);
  const handleDeleteDialogOpen = useCallback(() => setIsDeleteDialogOpen(true), []);
  const handleDeleteDialogClose = useCallback(() => setIsDeleteDialogOpen(false), []);
  const handleRegenerateTokenDialogOpen = useCallback(
    () => setIsRegenerateTokenDialogOpen(true),
    [],
  );
  const handleRegenerateTokenDialogClose = useCallback(
    () => setIsRegenerateTokenDialogOpen(false),
    [],
  );

  const { t } = useTranslations();
  const { showError, showSuccess } = useSnackbar();
  const {
    data: scim, isLoading, isError,
  } = useGetOrganizationScimSettings({
    onError: () => {
      showError({ text: t('common.somethingWentWrong') });
    },
  });
  const {
    mutate,
    isLoading: isUpdating,
  } = useUpdateOrganizationScimSettings({ useOptimisticUpdates: true });

  const handleSettingChange = useCallback((
    scimSettings: UpdateScimSettingsRequest,
    onSuccess?: TUseUpdateOrganizationScimSettingsOptions['onSuccess'],
  ) => {
    mutate(scimSettings, {
      onError: () => showError({ text: t('common.somethingWentWrong') }),
      onSuccess,
    });
  }, [mutate, showError, t]);

  const handleSecretTokenCreate = useCallback(() => {
    if (isUpdating) {
      return;
    }
    const token = generateRandomString(64);
    handleSettingChange({ ...scim!, secretToken: token });
    handleRegenerateTokenDialogClose();
  }, [isUpdating, handleSettingChange, handleRegenerateTokenDialogClose, scim]);

  const handleSecretTokenDelete = useCallback(async () => {
    if (isUpdating) {
      return;
    }
    handleSettingChange(
      { ...scim!, secretToken: null },
      () => {
        showSuccess({ text: t('settingsPage.scimIntegrationTab.secretTokenDeleted') });
        handleDeleteDialogClose();
      },
    );
  }, [isUpdating, handleSettingChange, scim, handleDeleteDialogClose, showSuccess, t]);

  const handleCopyToClipboard = useCallback(
    () => showSuccess({ text: t('common.copiedToClipboard') }),
    [showSuccess, t],
  );

  if (isError) {
    return null;
  }

  const tenantUrl = config.getScimIntegrationUrl() ?? '';
  const { secretToken } = scim ?? {};

  return (
    <ScrollablePanelContent p={3}>
      <SCIMIntegrationWidget
        title={t('settingsPage.scimIntegrationTab.title')}
        description={t('settingsPage.scimIntegrationTab.description')}
        isSCIM={!!secretToken}
        isLoading={isLoading}
      >
        <SCIMIntegrationDetail title={t('settingsPage.scimIntegrationTab.copyTenantUrl')}>
          <Grid item xs={9}>
            <Typography noWrap variant="subtitle1" color={S4} padding="4px 16px">
              {tenantUrl}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Tooltip title={t('settingsPage.scimIntegrationTab.copy')}>
              <CopyToClipboardButton
                onCopy={handleCopyToClipboard}
                text={tenantUrl}
              />
            </Tooltip>
          </Grid>
        </SCIMIntegrationDetail>
        <SCIMIntegrationDetail
          title={t('settingsPage.scimIntegrationTab.generateCopyToken')}
          description={t('settingsPage.scimIntegrationTab.ifSecretTokenIsEmpty')}
        >
          <Grid item xs={9}>
            <Typography
              noWrap
              variant="subtitle1"
              backgroundColor={S2}
              color={AB3}
              padding="4px 16px"
              borderRadius={2}
            >
              {secretToken}
            </Typography>
          </Grid>
          <Grid container item xs={3} flexWrap="nowrap">
            <Grid item>
              <Tooltip title={t('settingsPage.scimIntegrationTab.generate')}>
                <IconButton
                  onClick={secretToken ? handleRegenerateTokenDialogOpen : handleSecretTokenCreate}
                >
                  <RefreshIcon color={AB3} />
                </IconButton>
              </Tooltip>
            </Grid>
            {!!secretToken && (
              <>
                <Grid item my={1}>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item>
                  <Tooltip title={t('settingsPage.scimIntegrationTab.copy')}>
                    <CopyToClipboardButton
                      onCopy={handleCopyToClipboard}
                      text={secretToken}
                    />
                  </Tooltip>
                </Grid>
                <Grid item my={1}>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item>
                  <Tooltip title={t('common.remove')}>
                    <IconButton onClick={handleDeleteDialogOpen}>
                      <DeleteIcon color={AB3} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </>
            )}
          </Grid>
        </SCIMIntegrationDetail>
      </SCIMIntegrationWidget>
      {isDeleteDialogOpen && (
        <PromptDialog
          open={isDeleteDialogOpen}
          onClose={handleDeleteDialogClose}
          onSubmit={handleSecretTokenDelete}
          title={t('settingsPage.scimIntegrationTab.youAreAboutToDeleteToken')}
          approvalButtonText={t('common.delete')}
          rejectionButtonText={t('common.cancel')}
        >
          <Typography>
            {t('settingsPage.scimIntegrationTab.employeeAndGroupsWillNoLongerBeSynced')}
          </Typography>
        </PromptDialog>
      )}
      {isRegenerateTokenDialogOpen && (
        <PromptDialog
          open={isRegenerateTokenDialogOpen}
          onClose={handleRegenerateTokenDialogClose}
          onSubmit={handleSecretTokenCreate}
          title={t('settingsPage.scimIntegrationTab.areYouSureRegenerateToken')}
          approvalButtonText={t('common.confirm')}
          rejectionButtonText={t('common.cancel')}
        >
          <Typography>
            {t('settingsPage.scimIntegrationTab.thisWillRemoveCurrentScim')}
          </Typography>
        </PromptDialog>
      )}
    </ScrollablePanelContent>
  );
};

export default SCIMIntegrationTab;
