import { BaseEitherError } from '@uniqkey-frontend/shared-app';
import {
  SupportJumpAPIIncorrectAxiosInstanceError,
  SupportJumpMissingStoreDataError,
} from '../../common/errors';

export class SupportJumpPairSupporterError extends BaseEitherError {}

export type TSupportJumpPairSupporterOperationErrors = SupportJumpPairSupporterError
  | SupportJumpMissingStoreDataError
  | SupportJumpAPIIncorrectAxiosInstanceError;
