import {
  ReactNode, useCallback, useState, useMemo,
} from 'react';
import { Grid } from '@uniqkey-frontend/shared-app';
import Side from './Side';
import { setIsSideMenuCollapsed, getIsSideMenuCollapsed } from '../../services/sideMenuService';

interface AuthorizedLayoutProps {
  children: ReactNode;
}

const TRANSITION = 'width 0.3s ease-in-out';

const AuthorizedLayout = (props: AuthorizedLayoutProps) => {
  const { children } = props;
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(() => getIsSideMenuCollapsed());
  const toggleExpandCollapseMenu = useCallback(() => {
    setIsSideMenuCollapsed(!isMenuCollapsed);
    setIsMenuCollapsed(!isMenuCollapsed);
  }, [isMenuCollapsed]);

  const sideWidth = useMemo(() => (isMenuCollapsed ? 106 : 270), [isMenuCollapsed]);
  const childrenWidth = useMemo(() => `calc(100% - ${sideWidth}px)`, [sideWidth]);

  const sideSX = useMemo(() => ({
    width: sideWidth,
    transition: TRANSITION,
  }), [sideWidth]);

  const childrenSX = useMemo(() => ({
    width: childrenWidth,
    transition: TRANSITION,
  }), [childrenWidth]);

  return (
    <Grid container className="min-height-100-vh" columnSpacing={2} p={2}>
      <Grid container flexDirection="column" item sx={sideSX}>
        <Side isMenuCollapsed={isMenuCollapsed} onExpandCollapse={toggleExpandCollapseMenu} />
      </Grid>
      <Grid item sx={childrenSX}>
        {children}
      </Grid>
    </Grid>
  );
};

export default AuthorizedLayout;
