import { useMemo, useCallback, ReactNode } from 'react';
import {
  Divider,
  Grid,
  S6,
  Typography,
  TypographyWithHelp,
  useSnackbar,
  useTranslations,
  WidgetContainer,
  TFunction,
  RenderIf,
  SecurityScoreNameEnum,
  SecurityScoreWidget,
  AR1,
  S4,
  formatDate,
  DateTimeFormatEnum,
} from '@uniqkey-frontend/shared-app';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { EmployeeAccountStatus } from '@uniqkey-backend-organization-web/api-client';
import { useGetAverageSecurityScore } from '../../../../../../hooks/reactQuery';
import PageRouteEnum from '../../../../../../enums/PageRouteEnum';
import { parseSecurityScore } from '../../../../../../helpers/employee';

const DEFAULT_VALUES = {
  averagePasswordSecurityScore: 0,
  averageSecurityScore: 0,
  lowSecurityScorePasswordPercent: 0,
  lowSecurityScoreEmployeePercent: 0,
  noData: false,
  createdAt: undefined,
  noActivePasswords: false,
};

interface IWrapperProps {
  children: ReactNode;
  isLoading: boolean;
  t: TFunction;
  updatedAt?: string | null;
}

const CONTAINER_SX = { height: '100%', minHeight: 270 };

const TITLE_TYPOGRAPHY_PROPS = { variant: 'caption', noWrap: true };

const Wrapper = (props: IWrapperProps) => {
  const {
    children, isLoading, t, updatedAt,
  } = props;

  return (
    <WidgetContainer container withShadow isLoading={isLoading} sx={CONTAINER_SX}>
      <Grid container alignContent="space-between" justifyContent="flex-start">
        <Grid item flexDirection="column">
          <Grid item>
            <TypographyWithHelp
              helperText={t('dashboardAverageSecurityScoreWidget.tooltip')}
              color={S6}
            >
              {t('dashboardAverageSecurityScoreWidget.title')}
            </TypographyWithHelp>
          </Grid>
          {updatedAt && (
            <Grid item mt={-0.5}>
              <Typography variant="caption" color={S4}>
                {t('dashboardAverageSecurityScoreWidget.updatedAt', { updatedAt })}
              </Typography>
            </Grid>
          )}
        </Grid>
        {children}
      </Grid>
    </WidgetContainer>
  );
};

const DashboardAverageSecurityScoreWidget = () => {
  const { t, currentLanguage } = useTranslations();
  const navigate = useNavigate();
  const { showError } = useSnackbar();
  const { data: averageSecurityScoreData, isLoading, isError } = useGetAverageSecurityScore({
    onError: () => {
      showError({ text: t('common.somethingWentWrong') });
    },
  });

  const handleLowPasswordSecurityScoreLinkClick = useCallback(() => {
    const search = createSearchParams({ securityScoreName: SecurityScoreNameEnum.Low }).toString();
    navigate({
      pathname: PageRouteEnum.AllSecuredData,
      search,
    });
  }, [navigate]);

  const handleLowEmployeeSecurityScoreLinkClick = useCallback(() => {
    const search = createSearchParams({
      securityScoreName: SecurityScoreNameEnum.Low, statusFilter: EmployeeAccountStatus.Active,
    }).toString();
    navigate({
      pathname: PageRouteEnum.Employees,
      search,
    });
  }, [navigate]);

  const {
    lowSecurityScorePasswordPercent,
    lowSecurityScoreEmployeePercent,
    averagePasswordSecurityScore,
    noData,
    createdAt,
    noActivePasswords,
  } = averageSecurityScoreData ?? DEFAULT_VALUES;

  const updatedAt = useMemo(
    () => formatDate({
      date: createdAt,
      locale: currentLanguage,
    }, DateTimeFormatEnum.FULL),
    [currentLanguage, createdAt],
  );

  if (isError) {
    return null;
  }

  if (noActivePasswords) {
    return (
      <Wrapper isLoading={isLoading} t={t}>
        <Grid item container justifyContent="center">
          <Typography>
            {t('dashboardAverageSecurityScoreWidget.noActivePasswords')}
          </Typography>
        </Grid>
        <Grid item container />
      </Wrapper>
    );
  }

  if (noData) {
    return (
      <Wrapper isLoading={isLoading} t={t}>
        <Grid item container justifyContent="center">
          <Typography>
            {t('dashboardAverageSecurityScoreWidget.noData')}
          </Typography>
        </Grid>
        <Grid item container />
      </Wrapper>
    );
  }

  return (
    <Wrapper isLoading={isLoading} t={t} updatedAt={updatedAt}>
      <Grid item container justifyContent="center">
        <SecurityScoreWidget percentage={parseSecurityScore(averagePasswordSecurityScore)} t={t} />
      </Grid>
      <Grid item container rowGap={1}>
        <RenderIf condition={!!lowSecurityScoreEmployeePercent}>
          <Grid item container justifyContent="space-between">
            <Grid item container columnGap={1} xs>
              <Grid item>
                <Typography variant="caption" color={AR1}>
                  {parseSecurityScore(lowSecurityScoreEmployeePercent)}
                  %
                </Typography>
              </Grid>
              <Grid item>
                <TypographyWithHelp
                  TypographyProps={TITLE_TYPOGRAPHY_PROPS}
                  helperText={t(
                    'dashboardAverageSecurityScoreWidget.lowEmployeeSecurityScore.tooltip',
                  )}
                >
                  {t('dashboardAverageSecurityScoreWidget.lowEmployeeSecurityScore.subtitle')}
                </TypographyWithHelp>
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                asLink
                variant="subtitle1"
                onClick={handleLowEmployeeSecurityScoreLinkClick}
              >
                {t('dashboardAverageSecurityScoreWidget.viewUsers')}
              </Typography>
            </Grid>
          </Grid>
        </RenderIf>
        <RenderIf
          condition={!!lowSecurityScoreEmployeePercent && !!lowSecurityScorePasswordPercent}
        >
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </RenderIf>
        <RenderIf condition={!!lowSecurityScorePasswordPercent}>
          <Grid item container justifyContent="space-between">
            <Grid item container columnGap={1} xs>
              <Grid item>
                <Typography variant="caption" color={AR1}>
                  {parseSecurityScore(lowSecurityScorePasswordPercent)}
                  %
                </Typography>
              </Grid>
              <Grid item>
                <TypographyWithHelp
                  TypographyProps={TITLE_TYPOGRAPHY_PROPS}
                  helperText={t(
                    'dashboardAverageSecurityScoreWidget.lowPasswordSecurityScore.tooltip',
                  )}
                >
                  {t('dashboardAverageSecurityScoreWidget.lowPasswordSecurityScore.subtitle')}
                </TypographyWithHelp>
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                asLink
                variant="subtitle1"
                onClick={handleLowPasswordSecurityScoreLinkClick}
              >
                {t('dashboardAverageSecurityScoreWidget.viewLogins')}
              </Typography>
            </Grid>
          </Grid>
        </RenderIf>
      </Grid>
    </Wrapper>
  );
};

export default DashboardAverageSecurityScoreWidget;
