/* eslint-disable max-classes-per-file */
import { BaseEitherError } from '@uniqkey-frontend/shared-app';
import type {
  TSupportJumpGetPartnerKeysOperationErrors,
} from '../operations/getPartnerKeysOperation/errors';
import type {
  TSupportJumpRefreshSupportTokensOperationErrors,
} from '../operations/refreshSupportTokensOperation/errors';
import type {
  TSupportJumpGetMobileTokensOperationErrors,
} from '../operations/getMobileTokensOperation/errors';
import type {
  TSupportJumpDecryptOrganizationPrivateOperationErrors,
} from '../operations/decryptOrganizationPrivateOperation/errors';
import type {
  TSupportJumpCreateTrustedPortalOperationErrors,
} from '../operations/createTrustedPortalOperation/errors';
import type {
  TSupportJumpCreateTrustedActionPayloadOperationErrors,
} from '../operations/createTrustedActionPayloadOperation/errors';
import type {
  TSupportJumpVerifyTrustedActionOperationErrors,
} from '../operations/verifyTrustedActionOperation/errors';
import type {
  TSupportJumpPairSupporterOperationErrors,
} from '../operations/pairSupporterOperation/errors';

export class SupportJumpUnknownError extends BaseEitherError {}

export class SupportJumpAPIIncorrectAxiosInstanceError extends BaseEitherError {}

export class SupportJumpMissingStoreDataError extends BaseEitherError {}

export class SupportJumpGetMobileTokensTimeoutError extends BaseEitherError {}

export class SupportJumpCreateTrustedPortalTimeoutError extends BaseEitherError {}

export type TSupportJumpErrors = SupportJumpUnknownError
  | SupportJumpMissingStoreDataError
  | TSupportJumpGetPartnerKeysOperationErrors
  | TSupportJumpPairSupporterOperationErrors
  | TSupportJumpRefreshSupportTokensOperationErrors
  | TSupportJumpDecryptOrganizationPrivateOperationErrors
  | TSupportJumpGetMobileTokensOperationErrors
  | TSupportJumpCreateTrustedPortalOperationErrors
  | TSupportJumpCreateTrustedActionPayloadOperationErrors
  | TSupportJumpVerifyTrustedActionOperationErrors
  | SupportJumpGetMobileTokensTimeoutError
  | SupportJumpCreateTrustedPortalTimeoutError;
