import { type AxiosRequestConfig } from 'axios';
import { difference, keyBy, orderBy } from 'lodash';
import { BaseEitherError } from '@uniqkey-frontend/shared-app';
import {
  TrustedActionType,
  type GetAllTrustedActionsResponseData,
  type OrganizationKeysData,
  type QueueMessagesInfo,
} from '@uniqkey-backend-organization-web/api-client';
import type { Dictionary } from '../../../types/common';
import type { ITrustedPortalAction } from '../interfaces';
import { getActiveOrganizationId } from '../../../services/organizationService';
import { getEmployeesTokens } from '../../../services/authService';
import { logException } from '../../../services/sentryService';
import Operations from '../../../common/operations';
import Constants from '../constants';
import TrustedPortalStoreActions from '../store/actions';
import WorkerManager from '../managers/WorkerManager';

export interface INormalizedTrustedPortalActions {
  organizationIds: string[];
  byOrganizationId: Dictionary<ITrustedPortalAction>;
}

const decryptTrustedAction = (
  trustedAction: GetAllTrustedActionsResponseData,
  symmetricKey: string,
  organizationsPublicKeysByOrganizationId: Dictionary<OrganizationKeysData>,
): ITrustedPortalAction => {
  const decryptTrustedActionPayloadResult = Operations.decryptTrustedActionPayloadOperation({
    payload: trustedAction.payload,
    symmetricKey,
  });

  if (decryptTrustedActionPayloadResult instanceof BaseEitherError) {
    throw decryptTrustedActionPayloadResult;
  }

  const { organizationPrivateKey, mobileToken, refreshToken } = decryptTrustedActionPayloadResult;

  const {
    organizationPublicKey,
  } = organizationsPublicKeysByOrganizationId[trustedAction.organizationId!];

  return {
    ...trustedAction,
    parsedPayload: {
      organizationPrivateKey,
      organizationPublicKey,
      token: mobileToken,
      refreshToken,
    },
  };
};

export interface ITrustedPortalNormalizeTrustedActionsReturn
  extends INormalizedTrustedPortalActions {
  organizationIdsToDestroy: string[];
  organizationIdsToCreate: string[];
}

const normalizeTrustedActions = (
  trustedActions: GetAllTrustedActionsResponseData[],
  symmetricKey: string,
  organizationsPublicKeys: OrganizationKeysData[],
): ITrustedPortalNormalizeTrustedActionsReturn => {
  const organizationsPublicKeysByOrganizationId = keyBy(organizationsPublicKeys, 'organizationId');
  const { organizationIds, byOrganizationId } = trustedActions.reduce<
    INormalizedTrustedPortalActions
  >((acc, trustedPortalAction) => {
    const { organizationId, trustedActionType } = trustedPortalAction;
    if (trustedActionType !== TrustedActionType.TrustedPortal || !organizationId) {
      return acc;
    }
    try {
      const decryptedTrustedAction = decryptTrustedAction(
        trustedPortalAction,
        symmetricKey,
        organizationsPublicKeysByOrganizationId,
      );
      acc.organizationIds.push(organizationId);
      acc.byOrganizationId[organizationId] = decryptedTrustedAction;
    } catch (e) {
      logException(e, {
        message: 'TrustedPortal/normalizeTrustedActions exception',
        data: {
          trustedPortalAction,
        },
      });
    }
    return acc;
  }, { organizationIds: [], byOrganizationId: {} });

  const prevWorkerOrganizationIds = WorkerManager.getAllKeys();
  const nextWorkerOrganizationIds = organizationIds;

  const organizationIdsToDestroy = difference(
    prevWorkerOrganizationIds,
    nextWorkerOrganizationIds,
  );
  const organizationIdsToCreate = difference(
    nextWorkerOrganizationIds,
    prevWorkerOrganizationIds,
  );
  return {
    organizationIdsToDestroy,
    organizationIdsToCreate,
    organizationIds,
    byOrganizationId,
  };
};

const isTrustedPortalEnabled = () => {
  const activeOrganizationId = getActiveOrganizationId();
  if (!activeOrganizationId) {
    return false;
  }
  return TrustedPortalStoreActions.getIsEnabledByOrganizationId(activeOrganizationId);
};

interface IGetEmployeeAccountIdParams {
  activeOrganizationId?: string;
  throwErrors?: boolean;
}
const getEmployeeAccountId = (params: IGetEmployeeAccountIdParams = {}) => {
  const {
    activeOrganizationId = getActiveOrganizationId(),
    throwErrors = false,
  } = params;
  if (!activeOrganizationId) {
    if (throwErrors) {
      throw new Error('No active organization id found');
    }
    return null;
  }

  const employeeTokens = getEmployeesTokens();
  if (!employeeTokens) {
    if (throwErrors) {
      throw new Error('No employee tokens found');
    }
    return null;
  }

  const currEmployee = employeeTokens[activeOrganizationId];
  if (!currEmployee) {
    if (throwErrors) {
      throw new Error('No current employee found');
    }
    return null;
  }

  return currEmployee.employeeAccountId;
};

const setTrustedPortalSessionAxiosHeader = (axiosRequestConfig: AxiosRequestConfig) => {
  if (!axiosRequestConfig.headers) {
    return;
  }
  if (!isTrustedPortalEnabled()) {
    return;
  }
  const employeeAccountId = getEmployeeAccountId();
  if (!employeeAccountId) {
    return;
  }
  // eslint-disable-next-line no-param-reassign
  axiosRequestConfig.headers[Constants.TRUSTED_PORTAL_SESSION_HEADER] = employeeAccountId;
};

interface ITPLogMessageParams {
  message?: string;
  messages?: any[];
  color?: string;
}
const tpLogMessage = (params: ITPLogMessageParams) => {
  let logsPrefix = localStorage.getItem('TP_LOGS_PREFIX');
  if (!logsPrefix) {
    return;
  }

  if (!logsPrefix.startsWith('[')) logsPrefix = `[${logsPrefix}`;
  if (!logsPrefix.endsWith(']')) logsPrefix = `${logsPrefix}]`;

  const { message, messages, color = 'black' } = params;
  if (!message && !messages) {
    // eslint-disable-next-line no-console
    console.warn('tpLogMessage: Either provide \'message\' or \'messages\'');
  }
  const processedMessage = message ?? messages?.join?.(' ') ?? '';

  // eslint-disable-next-line no-console
  console.log(`%c${logsPrefix}${processedMessage}`, `color: ${color}`);
};

const orderQueueMessagesByPriority = (
  queueMessages: QueueMessagesInfo[],
): QueueMessagesInfo[] => orderBy(queueMessages, 'priority', 'desc');

export default {
  normalizeTrustedActions,
  getEmployeeAccountId,
  setTrustedPortalSessionAxiosHeader,
  tpLogMessage,
  orderQueueMessagesByPriority,
};
