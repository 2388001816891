import { memo, useMemo } from 'react';
import {
  Typography, useTranslations, useTable, Table, Grid, LoginAvatar, S5, generateGUID,
} from '@uniqkey-frontend/shared-app';
import { CheckStatusResponseModel, Ownership } from '@uniqkey-backend-organization-web/api-client';
import { noop } from 'lodash';
import VaultTypeEnum from '../../../../../enums/VaultTypeEnum';
import VaultTypeNameEnum from '../../../../../enums/VaultTypeNameEnum';

interface INotMigratedDataTableRow extends CheckStatusResponseModel {
  id: string;
}

interface INotMigratedDataTableProps {
  type: VaultTypeEnum;
  data: CheckStatusResponseModel[];
  dataLength: number;
  organizationName: string;
}

const NotMigratedDataTable = (props: INotMigratedDataTableProps) => {
  const {
    type, data: preData, dataLength, organizationName,
  } = props;
  const { t } = useTranslations();
  const { createColumns } = useTable<INotMigratedDataTableRow>();

  const vaultType = VaultTypeNameEnum[VaultTypeEnum[type] as keyof typeof VaultTypeEnum];

  const data = useMemo(() => preData.map((value) => ({
    ...value,
    id: generateGUID(),
  })), [preData]);

  const columns = useMemo(() => {
    if (type === VaultTypeEnum.Password) {
      return createColumns([
        {
          title: t('migration.notMigratedDataModal.columns.service'),
          field: 'service',
          render: (rowData) => (
            <Grid container flexWrap="nowrap" alignItems="center">
              <LoginAvatar src={rowData.iconUrl} />
              <Typography color={S5}>
                {rowData.service}
              </Typography>
            </Grid>
          ),
          width: '25%',
          sorting: false,
        },
        {
          title: t(`migration.notMigratedDataModal.columns.${vaultType}`),
          field: 'name',
          render: (rowData) => (
            <Typography color={S5}>
              {rowData.name}
            </Typography>
          ),
          width: '25%',
          sorting: false,
        },
        {
          title: t('migration.notMigratedDataModal.columns.managedBy'),
          field: 'ownershipEntityName',
          render: (rowData) => (
            <Typography color={S5}>
              {(rowData.ownership === Ownership.Unmanaged
                ? t('ownership.unmanaged')
                : rowData.ownershipEntityName
              )}
            </Typography>
          ),
          width: '25%',
          sorting: false,
        },
        {
          title: t('migration.notMigratedDataModal.columns.dataOwner'),
          field: 'isPrivate',
          render: (rowData) => (
            <Typography color={S5}>
              {rowData.isPrivate ? t('migration.notMigratedDataModal.private') : organizationName}
            </Typography>
          ),
          width: '25%',
          sorting: false,
        },
      ]);
    }

    return createColumns([
      {
        title: t(`migration.notMigratedDataModal.columns.${vaultType}`),
        field: 'name',
        render: (rowData) => (
          <Typography color={S5}>
            {rowData.name}
          </Typography>
        ),
        width: '40%',
        sorting: false,
      },
      {
        title: t('migration.notMigratedDataModal.columns.managedBy'),
        field: 'ownershipEntityName',
        render: (rowData) => (
          <Typography color={S5}>
            {(rowData.ownership === Ownership.Unmanaged
              ? t('ownership.unmanaged')
              : rowData.ownershipEntityName
            )}
          </Typography>
        ),
        width: '35%',
        sorting: false,
      },
      {
        title: t('migration.notMigratedDataModal.columns.dataOwner'),
        field: 'isPrivate',
        render: (rowData) => (
          <Typography color={S5}>
            {rowData.isPrivate ? t('migration.notMigratedDataModal.private') : organizationName}
          </Typography>
        ),
        width: '25%',
        sorting: false,
      },
    ]);
  }, [createColumns, organizationName, t, type, vaultType]);

  return (
    <Table
      t={t}
      data={data}
      activePage={1}
      columns={columns}
      onActivePageChange={noop}
      onPerPageChange={noop}
      perPage={dataLength}
      totalItems={dataLength}
      rowHeight={51}
      hidePagination
      hasBottomShadow={false}
      stretchTableVertically={false}
      rowSelectByKey="id"
    />
  );
};

export default memo(NotMigratedDataTable);
