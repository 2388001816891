import {
  BrowserRouter, Navigate, Route, Routes,
} from 'react-router-dom';
import { useSnackbar } from '@uniqkey-frontend/shared-app';
import { useUser } from './contexts/UserContext';
import useRealtimeAPIGlobalSubscriptions from './hooks/useRealtimeAPIGlobalSubscriptions';
import PageRouteEnum from './enums/PageRouteEnum';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';
import PublicRoute from './components/PublicRoute';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import EmployeesPage from './pages/EmployeesPage';
import EmployeePage from './pages/EmployeePage';
// import OnboardingPage from './pages/OnboardingPage';
import GroupsPage from './pages/GroupsPage';
import GroupPage from './pages/GroupPage';
// import ReportsPage from './pages/ReportsPage';
// import EmailsPage from './pages/EmailsPage';
import ServicesPage from './pages/ServicesPage';
// import SecureDataPage from './pages/SecureDataPage';
import ProfilePage from './pages/ProfilePage';
import SettingsPage from './pages/SettingsPage';
import ServicePage from './pages/ServicePage';
import AllSecuredDataPage, { AllSecuredDataPageTabEnum } from './pages/AllSecuredDataPage';
import OrganizationLoginPage from './pages/OrganizationLoginPage';
import AuditLogsPage from './pages/AuditLogsPage';
import EmployeeModule, {
  EmployeeModuleTabEnum,
} from './pages/EmployeePage/components/EmployeeModule';
import GroupModule, { GroupModuleTabEnum } from './pages/GroupPage/components/GroupModule';
import OrganizationLoginModule, {
  OrganizationLoginModuleTabEnum,
} from './pages/OrganizationLoginPage/components/OrganizationLoginModule';
import AuditLogsModule, {
  AuditLogsModuleTabEnum,
} from './pages/AuditLogsPage/components/AuditLogsModule';
import SettingsModule, {
  SettingsModuleTabEnum,
} from './pages/SettingsPage/components/SettingsModule';
import ServiceModule, { ServiceModuleTabEnum } from './pages/ServicePage/components/ServiceModule';
import RequestsPage from './pages/RequestsPage';
import RequestsModule, {
  RequestsModuleTabEnum,
} from './pages/RequestsPage/components/RequestsModule';
import SupportJumpPage from './pages/SupportJumpPage';

const DEFAULT_AUTHORIZED_ROUTE = PageRouteEnum.Dashboard;

const ROUTER_FUTURE_CONFIG = { future: { v7_startTransition: true } }; // TODO: NEXTGEN-6652

const Router = () => {
  const { getSnackbar } = useSnackbar();
  const { isAuthenticated } = useUser();

  useRealtimeAPIGlobalSubscriptions();

  return (
    <BrowserRouter {...ROUTER_FUTURE_CONFIG}>
      <Routes>
        <Route element={(
          <UnauthenticatedRoute
            isAuthenticated={isAuthenticated}
            defaultAuthorizedRoute={DEFAULT_AUTHORIZED_ROUTE}
          />
        )}
        >
          <Route path={PageRouteEnum.Login} element={<LoginPage />} />
        </Route>
        <Route element={<AuthenticatedRoute isAuthenticated={isAuthenticated} withLayout />}>
          <Route path={PageRouteEnum.Dashboard} element={<DashboardPage />} />
          <Route path={PageRouteEnum.Employees}>
            <Route index element={<EmployeesPage />} />
            <Route path={PageRouteEnum.Employee} element={<EmployeePage />}>
              <Route
                index
                element={<EmployeeModule activeTab={EmployeeModuleTabEnum.Details} />}
              />
              <Route
                path={EmployeeModuleTabEnum.Details}
                element={<EmployeeModule activeTab={EmployeeModuleTabEnum.Details} />}
              />
              <Route
                path={EmployeeModuleTabEnum.Logins}
                element={<EmployeeModule activeTab={EmployeeModuleTabEnum.Logins} />}
              />
              <Route
                path={EmployeeModuleTabEnum.CreditCards}
                element={<EmployeeModule activeTab={EmployeeModuleTabEnum.CreditCards} />}
              />
              <Route
                path={EmployeeModuleTabEnum.SecureNotes}
                element={<EmployeeModule activeTab={EmployeeModuleTabEnum.SecureNotes} />}
              />
              <Route
                path={EmployeeModuleTabEnum.Groups}
                element={<EmployeeModule activeTab={EmployeeModuleTabEnum.Groups} />}
              />
              <Route
                path="*"
                element={<Navigate to={EmployeeModuleTabEnum.Details} />}
              />
            </Route>
          </Route>
          <Route path={PageRouteEnum.Groups}>
            <Route index element={<GroupsPage />} />
            <Route path={PageRouteEnum.Group} element={<GroupPage />}>
              <Route index element={<GroupModule activeTab={GroupModuleTabEnum.Details} />} />
              <Route
                path={GroupModuleTabEnum.Details}
                element={<GroupModule activeTab={GroupModuleTabEnum.Details} />}
              />
              <Route
                path={GroupModuleTabEnum.Logins}
                element={<GroupModule activeTab={GroupModuleTabEnum.Logins} />}
              />
              <Route
                path={GroupModuleTabEnum.CreditCards}
                element={<GroupModule activeTab={GroupModuleTabEnum.CreditCards} />}
              />
              <Route
                path={GroupModuleTabEnum.SecureNotes}
                element={<GroupModule activeTab={GroupModuleTabEnum.SecureNotes} />}
              />
              <Route
                path={GroupModuleTabEnum.Members}
                element={<GroupModule activeTab={GroupModuleTabEnum.Members} />}
              />
              <Route
                path={GroupModuleTabEnum.Restrictions}
                element={<GroupModule activeTab={GroupModuleTabEnum.Restrictions} />}
              />
              <Route
                path={GroupModuleTabEnum.Settings}
                element={<GroupModule activeTab={GroupModuleTabEnum.Settings} />}
              />
              <Route
                path="*"
                element={<Navigate to={GroupModuleTabEnum.Details} />}
              />
            </Route>
          </Route>
          <Route path={PageRouteEnum.Services}>
            <Route index element={<ServicesPage />} />
            <Route path={PageRouteEnum.Service} element={<ServicePage />}>
              <Route
                index
                element={<ServiceModule activeTab={ServiceModuleTabEnum.Details} />}
              />
              <Route
                path={ServiceModuleTabEnum.Details}
                element={<ServiceModule activeTab={ServiceModuleTabEnum.Details} />}
              />
              <Route
                path={ServiceModuleTabEnum.Employees}
                element={<ServiceModule activeTab={ServiceModuleTabEnum.Employees} />}
              />
              <Route
                path={ServiceModuleTabEnum.Logins}
                element={<ServiceModule activeTab={ServiceModuleTabEnum.Logins} />}
              />
              <Route
                path="*"
                element={<Navigate to={ServiceModuleTabEnum.Employees} />}
              />
            </Route>
          </Route>
          <Route path={PageRouteEnum.AllSecuredData}>
            <Route
              index
              element={<AllSecuredDataPage activeTab={AllSecuredDataPageTabEnum.Logins} />}
            />
            <Route
              path={AllSecuredDataPageTabEnum.Logins}
              element={<AllSecuredDataPage activeTab={AllSecuredDataPageTabEnum.Logins} />}
            />
            <Route
              path={AllSecuredDataPageTabEnum.CreditCards}
              element={
                <AllSecuredDataPage activeTab={AllSecuredDataPageTabEnum.CreditCards} />
              }
            />
            <Route
              path={AllSecuredDataPageTabEnum.SecureNotes}
              element={
                <AllSecuredDataPage activeTab={AllSecuredDataPageTabEnum.SecureNotes} />
              }
            />
            <Route path={PageRouteEnum.OrganizationLogin} element={<OrganizationLoginPage />}>
              <Route
                index
                element={(
                  <OrganizationLoginModule activeTab={OrganizationLoginModuleTabEnum.Details} />
                )}
              />
              <Route
                path={OrganizationLoginModuleTabEnum.Details}
                element={(
                  <OrganizationLoginModule activeTab={OrganizationLoginModuleTabEnum.Details} />
                )}
              />
              <Route
                path={OrganizationLoginModuleTabEnum.Employees}
                element={(
                  <OrganizationLoginModule activeTab={OrganizationLoginModuleTabEnum.Employees} />
                )}
              />
              <Route
                path={OrganizationLoginModuleTabEnum.Groups}
                element={(
                  <OrganizationLoginModule activeTab={OrganizationLoginModuleTabEnum.Groups} />
                )}
              />
              <Route
                path="*"
                element={<Navigate to={OrganizationLoginModuleTabEnum.Details} />}
              />
            </Route>
            <Route
              path="*"
              element={<Navigate to={AllSecuredDataPageTabEnum.Logins} />}
            />
          </Route>
          <Route path={PageRouteEnum.Audit} element={<AuditLogsPage />}>
            <Route
              index
              element={<Navigate to={AuditLogsModuleTabEnum.AuditLogs} />}
            />
            <Route
              path={AuditLogsModuleTabEnum.AuditLogs}
              element={<AuditLogsModule activeTab={AuditLogsModuleTabEnum.AuditLogs} />}
            />
            <Route
              path="*"
              element={<Navigate to={AuditLogsModuleTabEnum.AuditLogs} />}
            />
          </Route>
          <Route path={PageRouteEnum.Requests} element={<RequestsPage />}>
            <Route
              index
              element={<Navigate to={RequestsModuleTabEnum.AccountRecovery} />}
            />
            <Route
              path={RequestsModuleTabEnum.AccountRecovery}
              element={<RequestsModule activeTab={RequestsModuleTabEnum.AccountRecovery} />}
            />
            <Route
              path={RequestsModuleTabEnum.PartnerAccess}
              element={<RequestsModule activeTab={RequestsModuleTabEnum.PartnerAccess} />}
            />
            <Route
              path="*"
              element={<Navigate to={RequestsModuleTabEnum.AccountRecovery} />}
            />
          </Route>
          <Route path={PageRouteEnum.Settings} element={<SettingsPage />}>
            <Route
              index
              element={<Navigate to={SettingsModuleTabEnum.Details} />}
            />
            <Route
              path={SettingsModuleTabEnum.Details}
              element={<SettingsModule activeTab={SettingsModuleTabEnum.Details} />}
            />
            <Route
              path={SettingsModuleTabEnum.Security}
              element={<SettingsModule activeTab={SettingsModuleTabEnum.Security} />}
            />
            <Route
              path={SettingsModuleTabEnum.SCIMIntegration}
              element={<SettingsModule activeTab={SettingsModuleTabEnum.SCIMIntegration} />}
            />
            <Route
              path={SettingsModuleTabEnum.VerifiedDomains}
              element={<SettingsModule activeTab={SettingsModuleTabEnum.VerifiedDomains} />}
            />
            <Route
              path={SettingsModuleTabEnum.PartnerUsersWithAccess}
              element={<SettingsModule activeTab={SettingsModuleTabEnum.PartnerUsersWithAccess} />}
            />
            <Route
              path={SettingsModuleTabEnum.PartnersWithAccess}
              element={<SettingsModule activeTab={SettingsModuleTabEnum.PartnersWithAccess} />}
            />
            <Route
              path="*"
              element={<Navigate to={SettingsModuleTabEnum.Details} />}
            />
          </Route>
          <Route path={PageRouteEnum.Profile} element={<ProfilePage />} />
          {/* <Route path={PageRouteEnum.Onboarding} element={<OnboardingPage />} />
          <Route path={PageRouteEnum.Reports} element={<ReportsPage />} />
          <Route path={PageRouteEnum.Emails} element={<EmailsPage />} />
          <Route path={PageRouteEnum.SecureData} element={<SecureDataPage />} /> */}
        </Route>
        <Route element={<PublicRoute withLayout />}>
          <Route
            path={PageRouteEnum.SupportJump}
            element={<SupportJumpPage authorizedRoute={DEFAULT_AUTHORIZED_ROUTE} />}
          />
        </Route>
        <Route
          path="*"
          element={(
            <Navigate to={isAuthenticated ? DEFAULT_AUTHORIZED_ROUTE : PageRouteEnum.Login} />
          )}
        />
      </Routes>
      {getSnackbar()}
    </BrowserRouter>
  );
};

export default Router;
