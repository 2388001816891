import { Link } from 'react-router-dom';
import {
  Typography,
  Grid,
  S5,
  AB3,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import PageRouteEnum from '../../../../enums/PageRouteEnum';

const SupportJumpErrorBlock = () => {
  const { t } = useTranslations();

  return (
    <Grid container item flexDirection="column" alignItems="center" rowGap={3} mt={3}>
      <Grid item textAlign="center">
        <Typography variant="body1" color={S5}>
          {t('supportJumpPage.error.message')}
        </Typography>
      </Grid>
      <Grid item>
        <Link to={PageRouteEnum.Login} replace>
          <Typography variant="subtitle1" color={AB3}>
            {t('supportJumpPage.error.link')}
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
};

export default SupportJumpErrorBlock;
