import { memo } from 'react';
import {
  Grid,
  LinearStepper,
  RenderIf,
  Typography,
  useTranslations,
  S5,
} from '@uniqkey-frontend/shared-app';
import SupportJumpPageStepEnum from '../../../../enums/SupportJumpPageStepEnum';
import SupportJumpEnterPasswordForm from '../SupportJumpEnterPasswordForm';

interface ISupportJumpContentBlockProps {
  activeStep: SupportJumpPageStepEnum;
}

const STEPPER_SX = { sx: { mb: 3 } };

const SupportJumpContentBlock = (props: ISupportJumpContentBlockProps) => {
  const { activeStep } = props;

  const { t } = useTranslations();

  const topMargin = activeStep === SupportJumpPageStepEnum.ENTER_PASSWORD ? 6 : 0;

  return (
    <Grid container item justifyContent="center" mt={topMargin}>
      <LinearStepper activeStep={activeStep} numberOfSteps={3} gridProps={STEPPER_SX} />
      <RenderIf condition={activeStep === SupportJumpPageStepEnum.VERIFICATION}>
        <Grid item>
          <Typography variant="body1" color={S5}>
            {t('supportJumpPage.steps.verification.message')}
          </Typography>
        </Grid>
      </RenderIf>
      <RenderIf condition={activeStep === SupportJumpPageStepEnum.ENTER_PASSWORD}>
        <SupportJumpEnterPasswordForm />
      </RenderIf>
      <RenderIf condition={activeStep === SupportJumpPageStepEnum.ACTIVATION}>
        <Grid item>
          <Typography variant="body1" color={S5}>
            {t('supportJumpPage.steps.activation.message')}
          </Typography>
        </Grid>
      </RenderIf>
    </Grid>
  );
};

export default memo(SupportJumpContentBlock);
