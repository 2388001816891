import { memo } from 'react';
import {
  AR1,
  Box,
  Grid,
  PasswordStrengthEnum,
  SecurityScoreIcon,
  Tooltip,
  useTranslations,
  WarningIcon,
} from '@uniqkey-frontend/shared-app';
import { isNumber } from 'lodash';
import { Password2FaStatus } from '@uniqkey-backend-organization-web/api-client';
import SecurityScoreStatisticsTooltipContent from '../SecurityScoreStatisticsTooltipContent';

interface IColumnWithSecurityScoreParams {
  securityScore?: number | null;
  strength?: number | null;
  isReusedByUser?: boolean;
  isReusedByOrganization?: boolean;
  password2FaStatus: Password2FaStatus;
  hasReusedPassword: boolean;
}

interface IGetTooltipTextProps {
  isReusedByOrganization?: boolean;
  isReusedByUser?: boolean;
  isPasswordWeak?: boolean;
}

const getTooltipTitleText = (props: IGetTooltipTextProps) => {
  const { isReusedByOrganization, isReusedByUser, isPasswordWeak } = props;
  if (isReusedByOrganization) {
    return 'reusedPassword.tooltipText.isReusedByOrganization';
  }
  if (isReusedByUser) {
    return 'reusedPassword.tooltipText.isReusedByUser';
  }
  if (isPasswordWeak) {
    return 'reusedPassword.tooltipText.isPasswordWeak';
  }
  return '';
};

const ColumnWithSecurityScore = (params: IColumnWithSecurityScoreParams) => {
  const {
    securityScore,
    strength,
    password2FaStatus,
    hasReusedPassword,
    isReusedByUser,
    isReusedByOrganization,
  } = params;
  const { t } = useTranslations();

  if (!isNumber(securityScore)) {
    return t('common.noScore');
  }

  const isPasswordWeak = strength! <= PasswordStrengthEnum.Weak;
  const isNotValid = isReusedByUser || isReusedByOrganization || isPasswordWeak;
  const tooltipTitleText = getTooltipTitleText({
    isReusedByOrganization, isReusedByUser, isPasswordWeak,
  });

  return (
    <Grid container>
      <Tooltip
        cursorPointer
        title={(
          <SecurityScoreStatisticsTooltipContent
            strength={strength ?? 0}
            password2FaStatus={password2FaStatus}
            hasReusedPassword={hasReusedPassword}
          />
        )}
      >
        <SecurityScoreIcon percentage={Math.floor(securityScore)} />
      </Tooltip>
      <Box ml={2} />
      {isNotValid && (
        <Tooltip title={t(tooltipTitleText)} cursorPointer>
          <WarningIcon width={24} height={24} color={AR1} />
        </Tooltip>
      )}
    </Grid>
  );
};

export default memo(ColumnWithSecurityScore);
