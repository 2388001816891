import { memo } from 'react';
import {
  AG3, AR1, ArrowDownIcon, ArrowUpIcon, Grid, S5, Typography, usePasswordStrength, useTranslations,
} from '@uniqkey-frontend/shared-app';

interface IUniqkeyAccessScoreStatisticsTooltipContentProps {
  masterPasswordStrength: number;
  isMasterPasswordReused?: boolean;
  hasReusedPasswords?: boolean;
}

const UniqkeyAccessScoreStatisticsTooltipContent = (
  props: IUniqkeyAccessScoreStatisticsTooltipContentProps,
) => {
  const {
    masterPasswordStrength, isMasterPasswordReused, hasReusedPasswords,
  } = props;
  const { t } = useTranslations();
  const { color } = usePasswordStrength({ strength: masterPasswordStrength });

  return (
    <Grid container>
      <Grid item container justifyContent="space-between">
        <Grid item py={0.5}>
          <Typography variant="caption" color={S5}>
            {t('uniqkeyAccessScoreStatisticsTooltipContent.masterPasswordStrength')}
            :&nbsp;
            <Typography variant="caption" component="span" color={color}>
              {masterPasswordStrength}
              %
            </Typography>
          </Typography>
        </Grid>
        <Grid item>
          {masterPasswordStrength ? (
            <>
              <ArrowUpIcon color={AG3} height={11} />
              <Typography color={AG3} component="span">+</Typography>
              <Typography variant="caption" color={S5}>
                {masterPasswordStrength}
                %
              </Typography>
            </>
          ) : (
            <Typography variant="caption" color={S5}>0%</Typography>
          )}
        </Grid>
      </Grid>
      <Grid item container justifyContent="space-between">
        <Grid item py={0.5}>
          <Typography variant="caption" color={S5}>
            {t('uniqkeyAccessScoreStatisticsTooltipContent.masterPasswordIsReused')}
            :&nbsp;
            <Typography
              variant="caption"
              component="span"
              color={isMasterPasswordReused ? AR1 : AG3}
            >
              {t(isMasterPasswordReused ? 'common.yes' : 'common.no')}
            </Typography>
          </Typography>
        </Grid>
        <Grid item>
          {isMasterPasswordReused && (
            <>
              <ArrowDownIcon color={AR1} />
              <Typography color={AR1} component="span">-</Typography>
              <Typography variant="caption" color={S5}>100%</Typography>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item container justifyContent="space-between">
        <Grid item py={0.5}>
          <Typography variant="caption" color={S5}>
            {t('uniqkeyAccessScoreStatisticsTooltipContent.hasReusedPasswords')}
            :&nbsp;
            <Typography
              variant="caption"
              component="span"
              color={hasReusedPasswords ? AR1 : AG3}
            >
              {t(hasReusedPasswords ? 'common.yes' : 'common.no')}
            </Typography>
          </Typography>
        </Grid>
        <Grid item>
          {hasReusedPasswords && (
            <>
              <ArrowDownIcon color={AR1} />
              <Typography color={AR1} component="span">-</Typography>
              <Typography variant="caption" color={S5}>40%</Typography>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(UniqkeyAccessScoreStatisticsTooltipContent);
