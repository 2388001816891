import {
  Dispatch, SetStateAction, useCallback, useState,
} from 'react';
import { upperFirst } from 'lodash';
import {
  useSnackbar,
  useTable,
  useQueryTableFetch,
  useTranslations,
  useTableLocalization,
  useFilterButton,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  useTablePreserverDecoderCustomConfigMerger,
  usePubSub,
  buildTablePreserverDecoderConfigItem,
  TablePreserverCommonInitialTableValues,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  IUseFilterButtonReturn,
  TUseTablePreserverDecoderConfigs,
  TablePreserverDecoderConfigItemTypeEnum,
  TUseTablePreserverDecoderCustomConfig,
  TSetSearchParams,
  TSecurityScoreName,
  getSecurityScoreVaultsStrength,
  getPasswordStrengthValues,
  TPasswordStrengthName,
} from '@uniqkey-frontend/shared-app';
import {
  GetVaultPasswordsResponseModel,
  VaultOrderProperty,
  Password2FaStatus,
} from '@uniqkey-backend-organization-web/api-client';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import {
  IGetVaultPasswordsParams,
  TGetVaultPasswordsMethod,
} from '../../useVaultsAPI/interfaces';
import {
  IEmployeeLoginsTabFilterSubmitResult,
// eslint-disable-next-line max-len
} from '../../../pages/EmployeePage/components/EmployeeLoginsTab/components/EmployeeLoginsTabFilter';
import {
  IGroupLoginsTabFilterSubmitResult,
} from '../../../pages/GroupPage/components/GroupLoginsTab/components/GroupLoginsTabFilter';
import useVaultsAPI from '../../useVaultsAPI';
import tablePreservationConfigs from '../../../helpers/tablePreservation/configs';
import { TReusedPassword } from '../../../helpers/filters/interfaces';
import ReusedPasswordEnum from '../../../enums/ReusedPasswordEnum';

export const REACT_QUERY_LOGINS_KEY = [ReactQueryKeyEnum.Logins];

interface IPersistentFilters extends Pick<
  IGetVaultPasswordsParams, 'groupId' | 'employeeAccountId'
> {}

export interface IUseEmployeeGroupLoginsTableParams {
  persistentFilters?: IPersistentFilters;
  customPreservationConfig?: ICustomPreservationConfig;
  noDataMessageKey: string;
}

export interface IEmployeeGroupLoginsTableRow extends GetVaultPasswordsResponseModel {
  lastActivity: string | null;
}

export interface IUseEmployeeGroupLoginsTableReturn extends
  Omit<IUseTableReturn<IEmployeeGroupLoginsTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage' | 'resetSelectedRows'>,
  Omit<IUseQueryTableFetchReturn<TGetVaultPasswordsMethod>, 'data' | 'isFetchedAfterMount'>,
  IUseFilterButtonReturn
{
  logins: IUseQueryTableFetchReturn<TGetVaultPasswordsMethod>['data'];
  selectedLogins: IUseTableReturn<IEmployeeGroupLoginsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  searchQuery: IGetVaultPasswordsParams['searchQuery'];
  setSearchQuery: Dispatch<SetStateAction<IGetVaultPasswordsParams['searchQuery']>>;
  filterValues: IEmployeeLoginsTabFilterSubmitResult | IGroupLoginsTabFilterSubmitResult;
  setFilterValues: Dispatch<SetStateAction<
    IEmployeeLoginsTabFilterSubmitResult | IGroupLoginsTabFilterSubmitResult
  >>;
}

interface ITablePreservationConfigs extends
  Omit<IGetVaultPasswordsParams,
    'page' | 'pageLength' | 'groupId' | 'employeeAccountId' | 'orderPropertyName' | 'isDescending'
    | 'minStrength' | 'maxStrength' | 'minSecurityScore' | 'maxSecurityScore' | 'isReusedByUser'
    | 'isReusedByOrganization' | 'isPasswordEmpty'
  >,
  Pick<IUseTableReturn<IEmployeeGroupLoginsTableRow>, 'columnOrderBy' | 'columnOrderDirection'>
{
  activePage: IGetVaultPasswordsParams['page'];
  perPage: IGetVaultPasswordsParams['pageLength'];
  securityScoreName: TSecurityScoreName;
  passwordStrengthName: TPasswordStrengthName;
  reusedPassword: TReusedPassword;
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  searchQuery: true,
  columnOrderBy: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: VaultOrderProperty },
  ),
  columnOrderDirection: true,
  securityScoreName: true,
  passwordStrengthName: true,
  ownership: tablePreservationConfigs.ownershipWithoutUnmanaged,
  applicationName: true,
  password2FAStatus: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: Password2FaStatus },
  ),
  reusedPassword: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: ReusedPasswordEnum },
  ),
};

interface ICustomPreservationConfig extends TUseTablePreserverDecoderCustomConfig<
  ITablePreservationConfigs, 'ownership'
> {}

const useEmployeeGroupLoginsTable = (
  params: IUseEmployeeGroupLoginsTableParams,
): IUseEmployeeGroupLoginsTableReturn => {
  const {
    persistentFilters,
    customPreservationConfig,
    noDataMessageKey,
  } = params;

  const queryClient = useQueryClient();
  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getVaultPasswords } = useVaultsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const preservationConfig = useTablePreserverDecoderCustomConfigMerger<
    ITablePreservationConfigs, ICustomPreservationConfig
  >(PRESERVATION_CONFIGS, customPreservationConfig);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    searchQuery: initialSearchQuery,
    columnOrderBy: initialColumnOrderBy,
    columnOrderDirection: initialColumnOrderDirection,
    securityScoreName: initialSecurityScoreName,
    passwordStrengthName: initialPasswordStrengthName,
    ownership: initialOwnership,
    applicationName: initialApplicationName,
    password2FAStatus: initialPassword2FAStatus,
    reusedPassword: initialReusedPassword,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, preservationConfig);

  const [searchQuery, setSearchQuery] = useState<
    IGetVaultPasswordsParams['searchQuery']
  >(() => initialSearchQuery);
  const [filterValues, setFilterValues] = useState<
    IEmployeeLoginsTabFilterSubmitResult | IGroupLoginsTabFilterSubmitResult
  >({
    ownership: initialOwnership,
    applicationName: initialApplicationName,
    securityScoreName: initialSecurityScoreName,
    passwordStrengthName: initialPasswordStrengthName,
    password2FAStatus: initialPassword2FAStatus,
    reusedPassword: initialReusedPassword,
  });

  const { isFilterActive, numberOfActiveFilters } = useFilterButton(filterValues);

  const {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedRows: selectedLogins,
    resetTableMethods,
    ...restTableProps
  } = useTable<IEmployeeGroupLoginsTableRow>({
    initialActivePage,
    initialPerPage,
    initialColumnOrderBy,
    initialColumnOrderDirection,
  });

  const {
    resetTable,
    resetActivePage,
    resetSelectedRows,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
    setSearchQuery(TablePreserverCommonInitialTableValues.searchQuery);
    setFilterValues({
      ownership: undefined,
      applicationName: undefined,
      securityScoreName: TablePreserverCommonInitialTableValues.securityScoreName,
      passwordStrengthName: TablePreserverCommonInitialTableValues.passwordStrengthName,
      password2FAStatus: undefined,
      reusedPassword: undefined,
    });
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: logins, isLoading, isFetchedAfterMount, total, resetQuery,
  } = useQueryTableFetch({
    queryKey: REACT_QUERY_LOGINS_KEY,
    queryClient,
    request: getVaultPasswords,
    params: {
      page: activePage,
      pageLength: perPage,
      ownership: (filterValues as IEmployeeLoginsTabFilterSubmitResult).ownership,
      applicationName: filterValues.applicationName,
      ...getSecurityScoreVaultsStrength(filterValues.securityScoreName),
      ...getPasswordStrengthValues(filterValues.passwordStrengthName),
      searchQuery,
      orderPropertyName: VaultOrderProperty[
        upperFirst(columnOrderBy) as keyof typeof VaultOrderProperty
      ],
      isDescending: columnOrderDirection ? columnOrderDirection === 'desc' : undefined,
      password2FAStatus: filterValues.password2FAStatus,
      isReusedByOrganization: filterValues.reusedPassword === ReusedPasswordEnum
        .IsReusedByOrganization || undefined,
      isReusedByUser: filterValues.reusedPassword === ReusedPasswordEnum
        .IsReusedByUser || undefined,
      ...persistentFilters,
    },
    onRequestError: handleOnRequestError,
  });

  const { localization } = useTableLocalization({
    searchQuery,
    isFilterActive,
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    searchQuery,
    columnOrderBy,
    columnOrderDirection,
    ownership: (filterValues as IEmployeeLoginsTabFilterSubmitResult).ownership,
    applicationName: filterValues.applicationName,
    securityScoreName: filterValues.securityScoreName,
    passwordStrengthName: filterValues.passwordStrengthName,
    password2FAStatus: filterValues.password2FAStatus,
    reusedPassword: filterValues.reusedPassword,
  });

  return {
    logins,
    isLoading,
    total,
    resetQuery,
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedLogins,
    localization,
    searchQuery,
    setSearchQuery,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetActivePage,
    resetSelectedRows,
    ...restTableProps,
  };
};

export default useEmployeeGroupLoginsTable;
