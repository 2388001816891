import { BaseEitherError } from '@uniqkey-frontend/shared-app';
import { SupportJumpMissingStoreDataError } from '../../common/errors';

export class SupportJumpInvalidPasswordError extends BaseEitherError {}

export class SupportJumpFailedToDecryptError extends BaseEitherError {}

export type TSupportJumpDecryptOrganizationPrivateOperationErrors = SupportJumpMissingStoreDataError
  | SupportJumpInvalidPasswordError
  | SupportJumpFailedToDecryptError;
