import { ReactNode } from 'react';
import {
  Grid,
  Typography,
  S5,
  S4,
} from '@uniqkey-frontend/shared-app';

interface ISCIMIntegrationDetailProps {
  title: string;
  description?: string;
  children: ReactNode;
}

const SCIMIntegrationDetail = (props: ISCIMIntegrationDetailProps) => {
  const {
    title, description, children,
  } = props;

  return (
    <Grid container>
      <Grid item>
        <Typography variant="caption" mb={2} color={S5}>{title}</Typography>
      </Grid>
      <Grid item container alignItems="center" height={41}>
        {children}
      </Grid>
      {!!description && (
      <Grid item>
        <Typography variant="caption" mb={2} color={S4}>{description}</Typography>
      </Grid>
      )}
    </Grid>
  );
};

export default SCIMIntegrationDetail;
