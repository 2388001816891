import {
  useCallback, useEffect, useState, memo,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  B1,
  Grid,
  LogoIcon,
  Panel,
  RenderIf,
  Spinner,
  usePubSub,
  useSnackbar,
  useTranslations,
  BaseEitherError,
} from '@uniqkey-frontend/shared-app';
import PageRouteEnum from '../../enums/PageRouteEnum';
import SupportJumpPageStepEnum from '../../enums/SupportJumpPageStepEnum';
import SupportJumpPubSubEventEnum from '../../modules/SupportJumpModule/enums/PubSubEventEnum';
import { login } from '../../services/authService';
import { setActiveOrganizationId } from '../../services/organizationService';
import { logException } from '../../services/sentryService';
import SupportJumpModule from '../../modules/SupportJumpModule';
import { SupportJumpUnknownError } from '../../modules/SupportJumpModule/common/errors';
import {
  SupportJumpGetPartnerKeysFetchError,
} from '../../modules/SupportJumpModule/operations/getPartnerKeysOperation/errors';
import {
  SupportJumpRefreshSupportTokensFetchError,
} from '../../modules/SupportJumpModule/operations/refreshSupportTokensOperation/errors';
import useQueueAPI from '../../hooks/useQueueAPI';
import SupportJumpErrorBlock from './components/SupportJumpErrorBlock';
import SupportJumpContentBlock from './components/SupportJumpContentBlock';

interface ISupportJumpPageProps {
  authorizedRoute: PageRouteEnum;
}

const SupportJumpPage = (props: ISupportJumpPageProps) => {
  const { authorizedRoute } = props;

  const params = useParams();
  const { organizationId, token } = params;

  const { t } = useTranslations();
  const navigate = useNavigate();
  const { showError } = useSnackbar();
  const { restartTrustedPortalEvents } = useQueueAPI();

  const [isSpinnerShown, setIsSpinnerShown] = useState(true);
  const [isError, setIsError] = useState(false);
  const [activeStep, setActiveStep] = useState<SupportJumpPageStepEnum>(
    SupportJumpPageStepEnum.VERIFICATION,
  );

  const handleError = useCallback((error?: BaseEitherError, from?: string) => {
    SupportJumpModule.cleanup();
    setIsSpinnerShown(false);
    setIsError(true);
    showError({ text: t('common.somethingWentWrong') });
    if (!error) {
      return;
    }
    const isPartnerKeysFetchError = error instanceof SupportJumpGetPartnerKeysFetchError;
    const isTokenRefreshError = error instanceof SupportJumpRefreshSupportTokensFetchError;
    if (isPartnerKeysFetchError || isTokenRefreshError) { // skip these exceptions
      return;
    }
    if (error instanceof SupportJumpUnknownError) {
      logException(error, { message: `SupportJumpPage/${from} unknown exception` });
      return;
    }
    logException(error, { message: `SupportJumpPage/${from} exception` });
  }, [showError, t]);

  const handleProvidePasswordEvent = useCallback(() => {
    setIsSpinnerShown(false);
    setActiveStep(SupportJumpPageStepEnum.ENTER_PASSWORD);
  }, []);
  usePubSub(SupportJumpPubSubEventEnum.PROVIDE_PASSWORD, handleProvidePasswordEvent);

  const handlePasswordVerifiedEvent = useCallback(() => {
    setIsSpinnerShown(true);
    setActiveStep(SupportJumpPageStepEnum.ACTIVATION);
  }, []);
  usePubSub(SupportJumpPubSubEventEnum.PASSWORD_VERIFIED, handlePasswordVerifiedEvent);

  useEffect(() => {
    if (isError) {
      return;
    }
    if (!organizationId || !token) {
      handleError();
      return;
    }
    if (SupportJumpModule.isInProgress()) { // is used for the development mode
      return;
    }

    const run = async () => {
      try {
        const result = await SupportJumpModule.start({
          organizationId,
          token,
        });
        if (result instanceof BaseEitherError) {
          handleError(result, 'run');
          return;
        }
        const { tokens, companionApplicationId, symmetricKey } = result;
        setActiveOrganizationId(organizationId);
        login({
          tokens,
          guid: companionApplicationId,
          symmetricKey,
        });
        await restartTrustedPortalEvents();
        SupportJumpModule.cleanup();
        navigate(authorizedRoute, { replace: true });
      } catch (e: any) {
        handleError(e, 'run/catch');
      }
    };
    run();
  }, [
    isError,
    organizationId,
    token,
    handleError,
    restartTrustedPortalEvents,
    navigate,
    authorizedRoute,
  ]);

  return (
    <Grid
      container
      className="min-height-100-vh"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={5} minWidth={520}>
        <Panel>
          <Grid container flexDirection="column" alignItems="center" p={6}>
            <Grid container item justifyContent="center">
              <LogoIcon width={216} height={64} color={B1} />
            </Grid>
            <RenderIf condition={isSpinnerShown}>
              <Grid container item justifyContent="center" mt={1} mb={2} height={24}>
                <Spinner />
              </Grid>
            </RenderIf>
            <RenderIf condition={!isError}>
              <SupportJumpContentBlock activeStep={activeStep} />
            </RenderIf>
            <RenderIf condition={isError}>
              <SupportJumpErrorBlock />
            </RenderIf>
          </Grid>
        </Panel>
      </Grid>
    </Grid>
  );
};

export default memo(SupportJumpPage);
