import { type AxiosInstance } from 'axios';
import { type Either } from '@uniqkey-frontend/shared-app';
import { type GetAllTrustedActionsResponse } from '@uniqkey-backend-organization-web/api-client';
import { dataExtractor } from '../../../helpers/apiClients';
import {
  GetAllTrustedPortalActionsFetchError,
  type TGetAllTrustedPortalActionsOperationErrors,
} from './errors';
import ApiClientsProvider from '../../apiClientsProvider';

const getAllTrustedPortalActionsOperation = async (
  instance: AxiosInstance,
): Promise<Either<GetAllTrustedActionsResponse, TGetAllTrustedPortalActionsOperationErrors>> => {
  try {
    const result = await ApiClientsProvider.getTrustedActionsAPIClient(instance)
      .apiV1TrustedActionsGetAllGet()
      .then(dataExtractor<GetAllTrustedActionsResponse>());
    return result;
  } catch (e) {
    return new GetAllTrustedPortalActionsFetchError();
  }
};

export default getAllTrustedPortalActionsOperation;
