import type { Either } from '@uniqkey-frontend/shared-app';
import type { PairSupporterResponse } from '@uniqkey-backend-organization-web/api-client';
import {
  SupportJumpPairSupporterError,
  type TSupportJumpPairSupporterOperationErrors,
} from './errors';
import {
  SupportJumpAPIIncorrectAxiosInstanceError,
  SupportJumpMissingStoreDataError,
} from '../../common/errors';
import APIClientsProvider from '../../apiClientsProvider';
import SupportJumpStoreActions from '../../store/actions';
import { dataExtractor } from '../../../../helpers/apiClients';

const pairSupporterOperation = async (): Promise<
  Either<PairSupporterResponse, TSupportJumpPairSupporterOperationErrors>
> => {
  try {
    const companionApplicationId = SupportJumpStoreActions.getCompanionApplicationId();
    if (!companionApplicationId) {
      return new SupportJumpMissingStoreDataError();
    }

    const instance = SupportJumpStoreActions.getAxiosInstance();
    if (!instance) {
      return new SupportJumpAPIIncorrectAxiosInstanceError();
    }

    const result = await APIClientsProvider.getSupporterAPIClient(instance)
      .apiV1SupporterPairSupporterPost({ companionApplicationId })
      .then(dataExtractor<PairSupporterResponse>());
    return result;
  } catch (e) {
    return new SupportJumpPairSupporterError();
  }
};

export default pairSupporterOperation;
