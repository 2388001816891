import { useMemo, memo, useCallback } from 'react';
import {
  Table,
  Grid,
  useTranslations,
  IUseTableReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  TypographyWithTooltip,
  IMaterialTableOptions,
  IMaterialTableProps,
} from '@uniqkey-frontend/shared-app';
import { RestrictionType } from '@uniqkey-backend-organization-web/api-client';
import {
  TGetGroupRestrictionsMethod,
  TRestriction,
} from '../../../hooks/useGroupRestrictionsAPI/interfaces';
import {
  generateRestrictionDescription,
  generateRestrictionName,
} from '../../../helpers/restrictions';
import ColumnWithSwitch, { IColumnWithSwitchParams } from '../ColumnWithSwitch';
import AvatarWithIcon from '../../AvatarWithIcon';

export type TGroupRestrictionsTableRow = TRestriction & {
  processedName: string;
  processedDescription: string;
}

export interface IGroupRestrictionsTableProps extends
  Omit<IUseTableReturn<TGroupRestrictionsTableRow>,
    'columnOrderBy' | 'columnOrderDirection' | 'onColumnOrderByChange'
    | 'onColumnOrderDirectionChange' | 'selectedRows' | 'resetTableMethods'
  >,
  Omit<
    IUseQueryTableFetchReturn<TGetGroupRestrictionsMethod>,
    'data' | 'resetQuery' | 'isFetchedAfterMount'
  >
{
  restrictions: IUseQueryTableFetchReturn<TGetGroupRestrictionsMethod>['data'];
  selectedRestrictions: IUseTableReturn<TGroupRestrictionsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  onStatusChange: IColumnWithSwitchParams['onChange'];
  onRestrictionRowClick: (type: RestrictionType, restriction: TRestriction) => void;
}

const TABLE_OPTIONS: IMaterialTableOptions<TGroupRestrictionsTableRow> = {
  selection: true,
};

const GroupRestrictionsTable = (props: IGroupRestrictionsTableProps) => {
  const {
    restrictions: preRestrictions,
    isLoading,
    total,
    localization,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    selectedRestrictions,
    onRowSelectionChange,
    createColumns,
    onStatusChange,
    onRestrictionRowClick,
  } = props;

  const { t } = useTranslations();

  const restrictions = useMemo(() => preRestrictions.map((restriction) => ({
    ...restriction,
    processedName: generateRestrictionName(t, restriction),
    processedDescription: generateRestrictionDescription(t, restriction),
  })), [preRestrictions, t]);

  const columns = useMemo(() => createColumns([
    {
      title: t('groupPage.restrictionsTab.table.columns.name'),
      render: (rowData) => (
        <Grid container flexWrap="nowrap" alignItems="center">
          <AvatarWithIcon
            type={rowData.restrictionType}
            isEnabled={rowData.isEnabled}
            avatarSize={26}
            iconSize={20}
          />
          <TypographyWithTooltip variant="body2">
            {rowData.processedName}
          </TypographyWithTooltip>
        </Grid>
      ),
      sorting: false,
      width: '45%',
    },
    {
      title: t('groupPage.restrictionsTab.table.columns.description'),
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.processedDescription}
        </TypographyWithTooltip>
      ),
      sorting: false,
      width: '40%',
    },
    {
      title: t('groupPage.restrictionsTab.table.columns.status'),
      render: (rowData) => (
        <ColumnWithSwitch
          checked={rowData.isEnabled}
          dataId={rowData.groupRestrictionId}
          onChange={onStatusChange}
          checkedTranslationKey="groupPage.restrictionsTab.table.status.enabled"
          uncheckedTranslationKey="groupPage.restrictionsTab.table.status.disabled"
        />
      ),
      sorting: false,
      width: '15%',
    },
  ]), [createColumns, onStatusChange, t]);

  const handleRestrictionRowClick = useCallback<
    NonNullable<IMaterialTableProps<TGroupRestrictionsTableRow>['onRowClick']>
  >((event, restriction) => {
    onRestrictionRowClick(restriction!.restrictionType, restriction!);
  }, [onRestrictionRowClick]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={restrictions}
      isLoading={isLoading}
      options={TABLE_OPTIONS}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      selectedRows={selectedRestrictions}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      onRowClick={handleRestrictionRowClick}
      rowSelectByKey="groupRestrictionId"
      rowHeight={51}
    />
  );
};

export default memo(GroupRestrictionsTable);
