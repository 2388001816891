import { createStore } from 'zustand';
import type { AxiosInstance } from 'axios';
import type { IKeyPair } from '@uniqkey-frontend/shared-app';
import type { EmployeeTokenInfo, TokenSet } from '@uniqkey-backend-organization-web/api-client';
import type {
  TSupportJumpDeferred,
  TSupportJumpSubscriptionUnsubscribeHandler,
} from '../common/interfaces';

type IKeys = Required<IKeyPair>;

export interface ISupportJumpStore {
  isInProgress: boolean;
  axiosInstance: AxiosInstance | null;
  deferred: TSupportJumpDeferred | null;
  organizationId: string | null;
  companionApplicationId: string | null;
  symmetricKey: string | null;
  partnerUserId: string | null;
  userToken: TokenSet['userToken'] | null;
  employeeTokens: TokenSet['employeeTokens'] | null;
  employeeToken: EmployeeTokenInfo | null;
  partnerKeys: IKeys | null;
  partnerUserKeys: IKeys | null;
  encryptedOrganizationPrivateKey: string | null;
  organizationPrivateKey: string | null;
  wsConnectionID: string;
  unsubscribeHandlers: TSupportJumpSubscriptionUnsubscribeHandler[];
  timeoutID: ReturnType<typeof setTimeout> | null;
}

export const INITIAL_SUPPORT_JUMP_STORE_STATE: ISupportJumpStore = {
  isInProgress: false,
  axiosInstance: null,
  deferred: null,
  organizationId: null,
  companionApplicationId: null,
  symmetricKey: null,
  partnerUserId: null,
  userToken: null,
  employeeTokens: null,
  employeeToken: null,
  partnerKeys: null,
  partnerUserKeys: null,
  encryptedOrganizationPrivateKey: null,
  organizationPrivateKey: null,
  wsConnectionID: 'SUPPORT_JUMP_CONNECTION_ID',
  unsubscribeHandlers: [],
  timeoutID: null,
};

export const SupportJumpStore = createStore<ISupportJumpStore>()(
  () => INITIAL_SUPPORT_JUMP_STORE_STATE,
);
