import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';
import {
  GetVerifiedDomainsResponse,
  AddVerifiedDomainRequest,
  OkResult,
  DeleteBulkVerifiedDomainsRequest,
  DeleteBulkVerifiedDomainsResponse,
} from '@uniqkey-backend-organization-web/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import { TGetVerifiedDomainsMethod } from './interfaces';
import { dataExtractor } from '../../helpers/apiClients';

const useVerifiedDomainsAPI = () => {
  const { verifiedDomainsAPIClient } = useApiClients();

  const getVerifiedDomains = useCallback<TGetVerifiedDomainsMethod>(
    (params, axiosRequestConfig) => {
      const {
        page,
        pageLength,
      } = params;
      return verifiedDomainsAPIClient.apiV1VerifiedDomainsGet(
        page,
        pageLength,
        axiosRequestConfig,
      ).then(dataExtractor<GetVerifiedDomainsResponse>());
    },
    [verifiedDomainsAPIClient],
  );

  const createVerifiedDomain = useCallback(
    async (
      params: AddVerifiedDomainRequest,
      options?: AxiosRequestConfig,
    ): Promise<OkResult> => verifiedDomainsAPIClient
      .apiV1VerifiedDomainsPost(params, options)
      .then(dataExtractor<OkResult>()),
    [verifiedDomainsAPIClient],
  );

  const deleteVerifiedDomains = useCallback(
    (
      deleteBulkVerifiedDomainsRequest: DeleteBulkVerifiedDomainsRequest,
      options?: AxiosRequestConfig,
    ): Promise<DeleteBulkVerifiedDomainsResponse> => verifiedDomainsAPIClient
      .apiV1VerifiedDomainsDelete(deleteBulkVerifiedDomainsRequest, options)
      .then(dataExtractor<DeleteBulkVerifiedDomainsResponse>()),
    [verifiedDomainsAPIClient],
  );

  return {
    getVerifiedDomains,
    createVerifiedDomain,
    deleteVerifiedDomains,
  };
};

export default useVerifiedDomainsAPI;
