import {
  useCallback, useMemo, useState, memo,
} from 'react';
import {
  Button,
  Divider,
  Grid,
  PanelContent,
  PlusIcon,
  SearchableTextField,
  FilterButton,
  usePopper,
  useTranslations,
  Tooltip,
  ActionButton,
  useSnackbar,
  ErrorIcon,
  useMapKeyValueExtractor,
  PromptModal,
  ISearchableTextFieldProps,
  IFilterButtonProps,
} from '@uniqkey-frontend/shared-app';
import { EmployeeAccountStatus } from '@uniqkey-backend-organization-web/api-client';
import useGroupsTable from '../../../../hooks/tables/useGroupsTable';
import GroupsTable, { IGroupsTableRow } from '../../../../components/tables/GroupsTable';
import EmployeeGroupsTabFilter, {
  IEmployeeGroupsTabFilterSubmitResult,
} from './components/EmployeeGroupsTabFilter';
import GroupsSelectorModal from '../../../EmployeesPage/components/GroupsSelectorModal';
import {
  useDetachBulkEmployeeAccountsToGroups,
  useGetOrganizationScimSettings,
} from '../../../../hooks/reactQuery';
import { generateTooltipTitle } from '../../../../helpers/tooltips';

interface IEmployeeGroupsTabProps {
  employeeAccountId: string;
  employeeAccountStatus: EmployeeAccountStatus;
}

const EmployeeGroupsTab = (props: IEmployeeGroupsTabProps) => {
  const { employeeAccountId, employeeAccountStatus } = props;
  const { t } = useTranslations();

  const {
    isOpen: isFilterOpen,
    anchorEl: filterAnchorEl,
    setPopperIsOpen: setIsFilterOpen,
  } = usePopper();
  const toggleIsFilterOpen = useCallback<NonNullable<IFilterButtonProps['onChange']>>(
    (event) => setIsFilterOpen(!isFilterOpen, event),
    [setIsFilterOpen, isFilterOpen],
  );
  const handleFilterClose = useCallback(() => setIsFilterOpen(false), [setIsFilterOpen]);

  const [isAddToGroupModalOpen, setIsAddToGroupModalOpen] = useState(false);
  const [isGroupDeleteModalOpen, setIsGroupDeleteModalOpen] = useState(false);
  const [isGroupDeleteLoading, setIsGroupDeleteLoading] = useState(false);
  const handleAddToGroupModalOpen = useCallback(() => setIsAddToGroupModalOpen(true), []);
  const handleAddToGroupModalClose = useCallback(() => setIsAddToGroupModalOpen(false), []);
  const handleGroupDeleteModalOpen = useCallback(() => setIsGroupDeleteModalOpen(true), []);
  const handleGroupDeleteModalClose = useCallback(() => setIsGroupDeleteModalOpen(false), []);
  const addToGroupModalSelectedEmployees = useMemo(() => [employeeAccountId], [employeeAccountId]);

  const {
    selectedGroups,
    searchQuery,
    setSearchQuery,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetActivePage,
    resetSelectedRows,
    isLoading: areGroupsLoading,
    ...restTableProps
  } = useGroupsTable({
    persistentFilters: { employeeAccountId },
    customPreservationConfig: { employeeAccount: false },
    noDataMessageKey: 'table.noData.default',
  });

  const {
    data: scim,
    isLoading: isGetOrganizationScimSettingsLoading,
    isError: isGetOrganizationScimSettingsError,
  } = useGetOrganizationScimSettings({
    onError: () => {
      showError({ text: t('common.somethingWentWrong') });
    },
  });
  const { secretToken } = scim ?? {};

  const { mutate } = useDetachBulkEmployeeAccountsToGroups();
  const { showSuccess, showError } = useSnackbar();
  const {
    values: selectedGroupsAsObjects, keys: selectedGroupsIds,
  } = useMapKeyValueExtractor<IGroupsTableRow>(selectedGroups);

  const handleSearchChange = useCallback<ISearchableTextFieldProps['onChange']>(
    (debouncedValue) => {
      setSearchQuery(debouncedValue);
      resetActivePage();
    },
    [setSearchQuery, resetActivePage],
  );

  const handleFilterSubmit = useCallback((updatedValues: IEmployeeGroupsTabFilterSubmitResult) => {
    setFilterValues(updatedValues);
    resetActivePage();
  }, [setFilterValues, resetActivePage]);

  const handleDeleteGroup = useCallback(async () => {
    setIsGroupDeleteLoading(true);
    const requests = selectedGroupsIds.map((groupId) => (
      { groupId, employeeAccountId }
    ));
    mutate({
      requests,
    }, {
      onSuccess: ({ failCount, successCount }) => {
        if (successCount) {
          showSuccess({
            text: t('deleteGroupModal.successMessage', { count: successCount }),
          });
        }
        if (failCount) {
          showError({
            text: t('deleteGroupModal.errorMessage', { count: failCount }),
          });
        }
        handleGroupDeleteModalClose();
        resetSelectedRows();
        resetActivePage();
      },
      onError: () => showError({ text: t('common.somethingWentWrong') }),
      onSettled: () => setIsGroupDeleteLoading(false),
    });
  }, [
    selectedGroupsIds,
    mutate,
    handleGroupDeleteModalClose,
    employeeAccountId,
    resetSelectedRows,
    resetActivePage,
    showError,
    showSuccess,
    t,
  ]);

  const removeTooltipTitle = useMemo(() => generateTooltipTitle({
    selectedDataLength: selectedGroups.size,
    t,
    isDisabled: false,
    key: 'employeePage.groupsTab.remove',
  }), [selectedGroups.size, t]);

  const isAddToGroupShown = useMemo(() => (
    employeeAccountStatus !== EmployeeAccountStatus.Archived
  ), [employeeAccountStatus]);

  if (isGetOrganizationScimSettingsError) {
    return null;
  }

  return (
    <PanelContent p={0}>
      <Grid container justifyContent="space-between" alignItems="stretch" p={1}>
        <Grid item xs={4} container flexWrap="nowrap" spacing={1}>
          <Grid item>
            <Tooltip title={t('common.filter')}>
              <FilterButton
                isFilterActive={isFilterActive}
                numberOfActiveFilters={numberOfActiveFilters}
                selected={isFilterOpen}
                onChange={toggleIsFilterOpen}
              />
            </Tooltip>
          </Grid>
          <Grid item my={0.5}>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item alignSelf="center">
            <Tooltip title={removeTooltipTitle}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleGroupDeleteModalOpen}
                disabled={!selectedGroups.size}
              >
                <ErrorIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
          <Grid item my={0.5}>
            <Divider orientation="vertical" />
          </Grid>
        </Grid>
        <Grid item xs={8} container justifyContent="flex-end" flexWrap="nowrap">
          <Grid item>
            <SearchableTextField
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder={t('common.search')}
            />
          </Grid>
          {isAddToGroupShown && (
            <Grid item ml={3}>
              <Button
                icon={<PlusIcon />}
                onClick={handleAddToGroupModalOpen}
              >
                {t('employeePage.groupsTab.addToGroupButton')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Divider />
      <GroupsTable
        selectedGroups={selectedGroups}
        isSecretTokenEmpty={!secretToken}
        isLoading={areGroupsLoading || isGetOrganizationScimSettingsLoading}
        {...restTableProps}
      />
      <EmployeeGroupsTabFilter
        isOpen={isFilterOpen}
        anchorEl={filterAnchorEl}
        onSubmit={handleFilterSubmit}
        onClose={handleFilterClose}
        initialValues={filterValues}
      />
      {isAddToGroupModalOpen && (
        <GroupsSelectorModal
          isOpen={isAddToGroupModalOpen}
          onClose={handleAddToGroupModalClose}
          employeeAccountsIds={addToGroupModalSelectedEmployees}
        />
      )}
      {isGroupDeleteModalOpen && (
        <PromptModal
          open={isGroupDeleteModalOpen}
          onClose={handleGroupDeleteModalClose}
          onSubmit={handleDeleteGroup}
          title={t('deleteGroupModal.title', { count: selectedGroups.size })}
          description={t('deleteGroupModal.description')}
          list={selectedGroupsAsObjects}
          renderField="name"
          renderKey="groupId"
          isLoading={isGroupDeleteLoading}
        />
      )}
    </PanelContent>
  );
};

export default memo(EmployeeGroupsTab);
