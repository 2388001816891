import { decryptAsymmetric } from '@uniqkey-frontend/shared-app';
import {
  type GetMasterPasswordBackupResponse,
} from '@uniqkey-backend-organization-mobile/api-client';
import {
  type ApproveMasterPasswordResetRequestPayload,
} from '@uniqkey-backend-organization-web/api-client';
import type { ITrustedPortalHandlerParams } from '../../../../interfaces';
import type { IDecryptedEmployeeMasterPasswordKDFBackupsMapPerOrganization } from '..';
import APIClientsProvider from '../../../../apiClientsProvider';
import { dataExtractor } from '../../../../../../helpers/apiClients';

type TParsedPayload = ITrustedPortalHandlerParams<
  ApproveMasterPasswordResetRequestPayload
>['parsedEvent']['parsedPayload'];

interface IGetEmployeeMasterPasswordKDFBackupsV1Params extends Pick<
  ITrustedPortalHandlerParams<ApproveMasterPasswordResetRequestPayload>,
  'axiosInstance' | 'organizationPrivateKey' | 'organizationPublicKey'
>{
  employeeAccountId: NonNullable<TParsedPayload['employeeAccountId']>;
}

const getEmployeeMasterPasswordKDFBackupsV1 = async (
  params: IGetEmployeeMasterPasswordKDFBackupsV1Params,
): Promise<IDecryptedEmployeeMasterPasswordKDFBackupsMapPerOrganization | null> => {
  try {
    const {
      axiosInstance,
      organizationPrivateKey,
      organizationPublicKey,
      employeeAccountId,
    } = params;

    const { masterPasswordKekMap } = await APIClientsProvider.Mobile
      .getEmployeeAccountsAPIClient(axiosInstance)
      .apiV1EmployeeAccountsGetMasterPasswordBackupEmployeeAccountIdGet(employeeAccountId)
      .then(dataExtractor<GetMasterPasswordBackupResponse>());

    const decryptedMasterPasswordKekMap = await decryptAsymmetric({
      publicKey: organizationPublicKey,
      privateKey: organizationPrivateKey,
      cipher: masterPasswordKekMap,
    });

    return JSON.parse(decryptedMasterPasswordKekMap);
  } catch (e: any) {
    if (e?.message.includes('404')) {
      return null;
    }
    throw e;
  }
};

export default getEmployeeMasterPasswordKDFBackupsV1;
