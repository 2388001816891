import { TFunction } from '@uniqkey-frontend/shared-app';
import { Password2FaStatus } from '@uniqkey-backend-organization-web/api-client';
import { TWO_FA_FILTER_TRANSLATION_KEYS } from '../../../constants';
import { IPassword2FAStatusOption, IReusedPasswordOption } from '../interfaces';
import ReusedPasswordEnum from '../../../enums/ReusedPasswordEnum';

export const getPassword2FAStatusOptions = (t: TFunction): IPassword2FAStatusOption[] => [
  {
    label: t(TWO_FA_FILTER_TRANSLATION_KEYS[Password2FaStatus.On]),
    value: Password2FaStatus.On,
  },
  {
    label: t(TWO_FA_FILTER_TRANSLATION_KEYS[Password2FaStatus.Off]),
    value: Password2FaStatus.Off,
  },
  {
    label: t(TWO_FA_FILTER_TRANSLATION_KEYS[Password2FaStatus.Na]),
    value: Password2FaStatus.Na,
  },
];

export const getReusedPasswordOptions = (t: TFunction): IReusedPasswordOption[] => [
  {
    label: t('reusedPassword.withinUsersLogins'),
    value: ReusedPasswordEnum.IsReusedByUser,
  },
  {
    label: t('reusedPassword.withinOrganization'),
    value: ReusedPasswordEnum.IsReusedByOrganization,
  },
];
