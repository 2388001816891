import { BaseEitherError } from '@uniqkey-frontend/shared-app';
import {
  SupportJumpAPIIncorrectAxiosInstanceError,
  SupportJumpMissingStoreDataError,
} from '../../common/errors';

export class SupportJumpCreateTrustedPortalError extends BaseEitherError {}

export type TSupportJumpCreateTrustedPortalOperationErrors = SupportJumpCreateTrustedPortalError
  | SupportJumpAPIIncorrectAxiosInstanceError
  | SupportJumpMissingStoreDataError;
