import {
  useCallback, useMemo, useState, SyntheticEvent,
} from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Autocomplete,
  Grid,
  Button,
  useTranslations,
  Typography,
  TAutocompleteDataSourceRequest,
  TAutocompleteDataSourceResponseParser,
  TAutocompleteOption,
  IAutocompleteProps,
} from '@uniqkey-frontend/shared-app';
import { keyBy } from 'lodash';

export type TEntitySelectorModalOnSubmit<T extends TAutocompleteOption = any> = (value: T) => void;

interface IEntitySelectorModalProps<TData> {
  isOpen: boolean;
  title: string;
  placeholder: NonNullable<IAutocompleteProps['placeholder']>;
  onSubmit: TEntitySelectorModalOnSubmit;
  request: TAutocompleteDataSourceRequest;
  responseParser: TAutocompleteDataSourceResponseParser;
  onClose: () => void;
  isLoading: boolean;
  dialogContentText?: string;
  submitButtonText?: string;
  multiple?: IAutocompleteProps['multiple'];
  loadedOptionsCount: NonNullable<IAutocompleteProps['loadedOptionsCount']>;
  totalLoadedOptionsCount: NonNullable<IAutocompleteProps['totalLoadedOptionsCount']>;
  optionKeyField?: IAutocompleteProps['optionKeyField'];
  data: TData[];
  dataKey: string;
  onAutocompleteValueChange?: (value: TAutocompleteValue) => void;
}

export type TAutocompleteValue = string | null | Array<TAutocompleteOption>;

const AUTOCOMPLETE_COMPONENTS_PROPS = {
  popper: {
    modifiers: [{
      name: 'flip',
      enabled: false,
    }],
  },
};

const EntitySelectorModal = <TData extends object>(props: IEntitySelectorModalProps<TData>) => {
  const {
    isOpen,
    title,
    onSubmit,
    request,
    placeholder,
    onClose,
    responseParser,
    isLoading,
    dialogContentText,
    submitButtonText = 'entitySelectorModal.add',
    multiple = true,
    loadedOptionsCount: preLoadedOptionsCount,
    totalLoadedOptionsCount,
    optionKeyField = 'id',
    data,
    dataKey,
    onAutocompleteValueChange,
  } = props;
  const { t } = useTranslations();
  const [autocompleteValue, setAutocompleteValue] = useState<
    TAutocompleteValue
  >(multiple ? [] : null);
  const handleAutocompleteValue = useCallback((
    event: SyntheticEvent,
    value: TAutocompleteValue,
  ) => {
    setAutocompleteValue(value);
    if (onAutocompleteValueChange) {
      onAutocompleteValueChange(value);
    }
  }, [onAutocompleteValueChange]);
  const isSubmitDisabled = useMemo(() => (
    multiple && Array.isArray(autocompleteValue) ? !autocompleteValue.length : !autocompleteValue
  ), [multiple, autocompleteValue]);

  const loadedOptionsCount = useMemo(() => {
    if (multiple && Array.isArray(autocompleteValue) && !!data.length) {
      const autocompleteDictionaryById = keyBy(autocompleteValue, optionKeyField);
      const existingAutocompleteValues = data
        .filter((item) => !!autocompleteDictionaryById[item[dataKey as keyof object]]);
      return preLoadedOptionsCount - existingAutocompleteValues.length;
    }
    if (autocompleteValue) {
      return preLoadedOptionsCount - 1;
    }
    return preLoadedOptionsCount;
  }, [autocompleteValue, dataKey, multiple, preLoadedOptionsCount, data, optionKeyField]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      clickOutsideToClose={!isLoading}
      scroll="body"
    >
      <DialogTitle isLoading={isLoading} onClose={onClose}>{title}</DialogTitle>
      <DialogContent>
        {dialogContentText && <Typography pb={3}>{dialogContentText}</Typography>}
        <Autocomplete
          useCustomPopperPreventOverflowModifier
          componentsProps={AUTOCOMPLETE_COMPONENTS_PROPS}
          fetchOnEmptyQuery
          loadedOptionsCount={loadedOptionsCount}
          totalLoadedOptionsCount={totalLoadedOptionsCount}
          autoFocus
          t={t}
          placeholder={placeholder}
          dataSourceRequest={request}
          dataSourceResponseParser={responseParser}
          multiple={multiple}
          value={autocompleteValue}
          onChange={handleAutocompleteValue}
          optionKeyField={optionKeyField}
        />
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              disabled={isSubmitDisabled}
              fullWidth
              type="submit"
              isLoading={isLoading}
              onClick={() => onSubmit(autocompleteValue)}
            >
              {t(submitButtonText)}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth variant="outlined" disabled={isLoading} onClick={onClose}>
              {t('entitySelectorModal.cancel')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default EntitySelectorModal;
