import { AuthenticationType } from '@uniqkey-backend-organization-web/api-client';

interface IBuildDesktopApplicationLabelParams {
  authenticationType?: AuthenticationType;
  desktopApplicationName?: string;
}

// eslint-disable-next-line import/prefer-default-export
export const buildDesktopApplicationLabel = (params: IBuildDesktopApplicationLabelParams) => {
  const { authenticationType, desktopApplicationName } = params;
  if (!desktopApplicationName) {
    return '';
  }
  return authenticationType === AuthenticationType.Basic
    ? desktopApplicationName
    : `${desktopApplicationName} ${authenticationType}`;
};
