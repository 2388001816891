enum PubSubEventEnum {
  LOGIN = 'login',
  LOGOUT = 'logout',
  RESET_TABLE = 'RESET_TABLE',
  RESET_FILTER = 'RESET_FILTER',
  TRIGGER_TOKENS_REFRESH = 'TRIGGER_TOKENS_REFRESH',
  TOKENS_REFRESHED = 'TOKENS_REFRESHED',
}

export default PubSubEventEnum;
