import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  TUseQueryOptions,
  TUseMutationOptions,
  TUseMutationContext,
} from '@uniqkey-frontend/shared-app';
import {
  GetCurrentOrganizationResponse,
  Operation,
  NoContentResult,
} from '@uniqkey-backend-organization-web/api-client';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import { IReplacePatchOperation, parseReplacePatchOperation } from '../../../helpers/apiClients';
import useOrganizationAPI from '../../useOrganizationAPI';

export const REACT_QUERY_ORGANIZATION_KEY = [ReactQueryKeyEnum.Organization];

export const useGetCurrentOrganization = (
  options: TUseQueryOptions<GetCurrentOrganizationResponse> = {},
) => {
  const { getCurrentOrganization } = useOrganizationAPI();
  return useQuery<GetCurrentOrganizationResponse>(
    REACT_QUERY_ORGANIZATION_KEY,
    ({ signal }) => getCurrentOrganization({ signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

interface IUsePatchCurrentOrganizationParams {
  useOptimisticUpdates?: boolean;
}
export const usePatchCurrentOrganization = (
  params: IUsePatchCurrentOrganizationParams,
  options: TUseMutationOptions<
    NoContentResult,
    unknown,
    Operation[],
    TUseMutationContext<GetCurrentOrganizationResponse>
  > = {},
) => {
  const { useOptimisticUpdates = false } = params;
  const queryClient = useQueryClient();
  const { patchCurrentOrganization } = useOrganizationAPI();
  return useMutation(
    REACT_QUERY_ORGANIZATION_KEY,
    (operations) => patchCurrentOrganization(operations),
    {
      onMutate: async (operations) => {
        if (!useOptimisticUpdates) {
          return null;
        }
        const updatedParts = operations.reduce((acc, operation) => ({
          ...acc,
          ...parseReplacePatchOperation(operation as IReplacePatchOperation),
        }), {});
        await queryClient.cancelQueries(REACT_QUERY_ORGANIZATION_KEY);
        const previousValue = queryClient.getQueryData<
        GetCurrentOrganizationResponse
      >(REACT_QUERY_ORGANIZATION_KEY);
        queryClient.setQueryData<GetCurrentOrganizationResponse>(
          REACT_QUERY_ORGANIZATION_KEY,
          (oldOrganization) => ({
            ...oldOrganization,
            ...updatedParts as GetCurrentOrganizationResponse,
          }),
        );
        return { previousValue: previousValue as GetCurrentOrganizationResponse };
      },
      onError: (err, organization, context) => {
        if (context?.previousValue) {
          queryClient.setQueryData<GetCurrentOrganizationResponse>(
            REACT_QUERY_ORGANIZATION_KEY,
            context.previousValue,
          );
        }
      },
      ...options,
    },
  );
};
