import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';
import {
  CreateVaultForGroupRequest,
  CreateVaultResponse,
  DeleteBulkVaultToGroupRequest,
  DeleteBulkVaultToGroupResponse,
  CreateVaultSecureNoteForGroupRequest,
  AddVaultsToGroupsRequest,
  AddVaultsToGroupsResponse,
  CreateVaultCreditCardForGroupRequest,
  OkResult,
} from '@uniqkey-backend-organization-web/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import { dataExtractor } from '../../helpers/apiClients';

const useVaultsToGroupsAPI = () => {
  const { vaultsToGroupsAPIClient } = useApiClients();

  const createVaultsToGroups = useCallback(
    (
      createVaultForGroupRequest: CreateVaultForGroupRequest,
      options?: AxiosRequestConfig,
    ): Promise<CreateVaultResponse> => vaultsToGroupsAPIClient
      .apiV1VaultsToGroupsCreateVaultPasswordPost(createVaultForGroupRequest, options)
      .then(dataExtractor<CreateVaultResponse>()),
    [vaultsToGroupsAPIClient],
  );

  const deleteVaultsToGroups = useCallback(
    (
      deleteBulkVaultToGroupRequest: DeleteBulkVaultToGroupRequest,
      options?: AxiosRequestConfig,
    ): Promise<DeleteBulkVaultToGroupResponse> => vaultsToGroupsAPIClient
      .apiV1VaultsToGroupsDeleteBulkPost(deleteBulkVaultToGroupRequest, options)
      .then(dataExtractor<DeleteBulkVaultToGroupResponse>()),
    [vaultsToGroupsAPIClient],
  );

  const createVaultsSecureNoteToGroups = useCallback(
    (
      createVaultSecureNoteForGroupRequest: CreateVaultSecureNoteForGroupRequest,
      options?: AxiosRequestConfig,
    ): Promise<CreateVaultResponse> => vaultsToGroupsAPIClient
      .apiV1VaultsToGroupsCreateVaultSecureNotePost(createVaultSecureNoteForGroupRequest, options)
      .then(dataExtractor<CreateVaultResponse>()),
    [vaultsToGroupsAPIClient],
  );

  const createVaultCreditCardForGroup = useCallback(
    (
      createVaultCreditCardForGroupRequest: CreateVaultCreditCardForGroupRequest,
      options?: AxiosRequestConfig,
    ): Promise<OkResult> => vaultsToGroupsAPIClient
      .apiV1VaultsToGroupsCreateVaultCreditCardPost(
        createVaultCreditCardForGroupRequest,
        options,
      )
      .then(dataExtractor<OkResult>()),
    [vaultsToGroupsAPIClient],
  );

  const shareVaultsToGroup = useCallback(
    (
      addVaultsToGroupsRequest: AddVaultsToGroupsRequest,
      options?: AxiosRequestConfig,
    ): Promise<AddVaultsToGroupsResponse> => vaultsToGroupsAPIClient
      .apiV1VaultsToGroupsAddPost(addVaultsToGroupsRequest, options)
      .then(dataExtractor<AddVaultsToGroupsResponse>()),
    [vaultsToGroupsAPIClient],
  );

  return {
    createVaultsToGroups,
    deleteVaultsToGroups,
    createVaultsSecureNoteToGroups,
    createVaultCreditCardForGroup,
    shareVaultsToGroup,
  };
};

export default useVaultsToGroupsAPI;
