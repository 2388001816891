import { useCallback, memo } from 'react';
import {
  Divider,
  Grid,
  PanelContent,
  useTranslations,
  SearchableTextField,
  ISearchableTextFieldProps,
} from '@uniqkey-frontend/shared-app';
import useOrganizationLoginEmployeesToEmployeesTable
  from '../../../../hooks/tables/useOrganizationLoginEmployeesToEmployeesTable';
import OrganizationLoginEmployeesToEmployeesTable
  from '../../../../components/tables/OrganizationLoginEmployeesToEmployeesTable';

interface IOrganizationLoginEmployeesToEmployeesTabProps {
  vaultId: string;
}

const OrganizationLoginEmployeesToEmployeesTab = (
  props: IOrganizationLoginEmployeesToEmployeesTabProps,
) => {
  const { vaultId } = props;
  const { t } = useTranslations();

  const {
    searchQuery,
    setSearchQuery,
    resetActivePage,
    ...restTableProps
  } = useOrganizationLoginEmployeesToEmployeesTable({
    persistentFilters: { vaultId },
  });

  const handleSearchChange = useCallback<ISearchableTextFieldProps['onChange']>(
    (debouncedValue) => {
      setSearchQuery(debouncedValue);
      resetActivePage();
    },
    [setSearchQuery, resetActivePage],
  );

  return (
    <PanelContent p={0}>
      <Grid container justifyContent="space-between" alignItems="stretch" p={1}>
        <Grid item xs={4} container flexWrap="nowrap" />
        <Grid item xs={8} container justifyContent="flex-end" flexWrap="nowrap">
          <Grid item>
            <SearchableTextField
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder={t('common.search')}
            />
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <OrganizationLoginEmployeesToEmployeesTable {...restTableProps} />
    </PanelContent>
  );
};

export default memo(OrganizationLoginEmployeesToEmployeesTab);
