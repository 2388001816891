import { createContext, useContext } from 'react';
import {
  EmployeeTokenInfo, GetUserProfileResponse, MeResponse,
} from '@uniqkey-backend-organization-web/api-client';
import { Dictionary } from '../../types/common';

export interface ICurrentUser extends GetUserProfileResponse {}
export interface ICurrentEmployee extends MeResponse {}

export interface IUserContext {
  currentUser: ICurrentUser | null;
  currentEmployee: ICurrentEmployee | null;
  isAuthenticated: boolean;
  isCurrentUserLoading: boolean;
  employeeTokens: Dictionary<EmployeeTokenInfo> | null;
  isSupporter: boolean;
}

const defaultContext: IUserContext = {
  currentUser: null,
  currentEmployee: null,
  isAuthenticated: false,
  isCurrentUserLoading: true,
  employeeTokens: null,
  isSupporter: false,
};

const UserContext = createContext<IUserContext>(defaultContext);

UserContext.displayName = 'UserContext';

export default UserContext;

export const useUser = (): IUserContext => useContext<IUserContext>(UserContext);
