import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';
import {
  GetGroupRestrictionsResponse,
  GetIpGroupRestrictionByIdResponse,
  GetTimeOfDayGroupRestrictionByIdResponse,
  GetGeolocationGroupRestrictionByIdResponse,
  ToggleGroupRestrictionRequest,
  NoContentResult,
  CreateTimeOfDayGroupRestrictionRequest,
  CreateTimeOfDayGroupRestrictionResponse,
  PatchTimeOfDayGroupRestrictionRequest,
  CreateIpGroupRestrictionRequest,
  CreateIpGroupRestrictionResponse,
  PatchIpGroupRestrictionRequest,
  DeleteBulkGroupRestrictionRequest,
  DeleteBulkGroupRestrictionResponse,
  CreateGeolocationGroupRestrictionRequest,
  CreateGeolocationGroupRestrictionResponse,
  PatchGeolocationGroupRestrictionRequest,
} from '@uniqkey-backend-organization-web/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import { TGetGroupRestrictionsMethod } from './interfaces';
import {
  dataExtractor,
  restrictionContentExtractor,
} from '../../helpers/apiClients';

const useGroupRestrictionsAPI = () => {
  const {
    groupRestrictionsAPIClient,
    ipGroupRestrictionsAPIClient,
    timeOfDayGroupRestrictionsAPIClient,
    geolocationGroupRestrictionsAPIClient,
  } = useApiClients();

  const getGroupRestrictions = useCallback<TGetGroupRestrictionsMethod>(
    (params, axiosRequestConfig) => {
      const {
        page,
        pageLength,
        groupId,
      } = params;
      return groupRestrictionsAPIClient
        .apiV1GroupRestrictionsGetGet(
          page,
          pageLength,
          groupId,
          axiosRequestConfig,
        )
        .then(dataExtractor<GetGroupRestrictionsResponse>())
        .then((response) => ({
          ...response,
          data: response.data.map(((el) => restrictionContentExtractor(el))),
        }));
    },
    [groupRestrictionsAPIClient],
  );

  const getIPGroupRestrictionById = useCallback(
    (groupRestrictionId: string, options?: AxiosRequestConfig)
      : Promise<GetIpGroupRestrictionByIdResponse> => ipGroupRestrictionsAPIClient
      .apiV1IpGroupRestrictionsGroupRestrictionIdGet(groupRestrictionId, options)
      .then(dataExtractor<GetIpGroupRestrictionByIdResponse>()),
    [ipGroupRestrictionsAPIClient],
  );

  const createIPGroupRestriction = useCallback(
    (createIpGroupRestrictionRequest: CreateIpGroupRestrictionRequest, options?: AxiosRequestConfig)
      : Promise<CreateIpGroupRestrictionResponse> => ipGroupRestrictionsAPIClient
      .apiV1IpGroupRestrictionsPost(createIpGroupRestrictionRequest, options)
      .then(dataExtractor<CreateIpGroupRestrictionResponse>()),
    [ipGroupRestrictionsAPIClient],
  );

  const patchIPGroupRestriction = useCallback(
    (
      groupRestrictionId: string,
      patchIpGroupRestrictionRequest: PatchIpGroupRestrictionRequest,
      options?: AxiosRequestConfig,
    ) : Promise<NoContentResult> => ipGroupRestrictionsAPIClient
      .apiV1IpGroupRestrictionsGroupRestrictionIdPatch(
        groupRestrictionId,
        patchIpGroupRestrictionRequest,
        options,
      )
      .then(dataExtractor<NoContentResult>()),
    [ipGroupRestrictionsAPIClient],
  );

  const getTimeOfDayGroupRestrictionById = useCallback(
    (groupRestrictionId: string, options?: AxiosRequestConfig)
      : Promise<GetTimeOfDayGroupRestrictionByIdResponse> => timeOfDayGroupRestrictionsAPIClient
      .apiV1TimeOfDayGroupRestrictionsGroupRestrictionIdGet(groupRestrictionId, options)
      .then(dataExtractor<GetTimeOfDayGroupRestrictionByIdResponse>()),
    [timeOfDayGroupRestrictionsAPIClient],
  );

  const createTimeOfDayGroupRestriction = useCallback(
    (
      createTimeOfDayGroupRestrictionRequest: CreateTimeOfDayGroupRestrictionRequest,
      options?: AxiosRequestConfig,
    ): Promise<CreateTimeOfDayGroupRestrictionResponse> => timeOfDayGroupRestrictionsAPIClient
      .apiV1TimeOfDayGroupRestrictionsPost(createTimeOfDayGroupRestrictionRequest, options)
      .then(dataExtractor<CreateTimeOfDayGroupRestrictionResponse>()),
    [timeOfDayGroupRestrictionsAPIClient],
  );

  const patchTimeOfDayGroupRestriction = useCallback(
    (
      groupRestrictionId: string,
      patchTimeOfDayGroupRestrictionRequest: PatchTimeOfDayGroupRestrictionRequest,
      options?: AxiosRequestConfig,
    ) : Promise<NoContentResult> => timeOfDayGroupRestrictionsAPIClient
      .apiV1TimeOfDayGroupRestrictionsGroupRestrictionIdPatch(
        groupRestrictionId,
        patchTimeOfDayGroupRestrictionRequest,
        options,
      )
      .then(dataExtractor<NoContentResult>()),
    [timeOfDayGroupRestrictionsAPIClient],
  );

  const getGeolocationGroupRestrictionById = useCallback(
    (groupRestrictionId: string, options?: AxiosRequestConfig)
      : Promise<GetGeolocationGroupRestrictionByIdResponse> => geolocationGroupRestrictionsAPIClient
      .apiV1GeolocationGroupRestrictionsGroupRestrictionIdGet(groupRestrictionId, options)
      .then(dataExtractor<GetGeolocationGroupRestrictionByIdResponse>()),
    [geolocationGroupRestrictionsAPIClient],
  );

  const createGeolocationGroupRestriction = useCallback(
    (
      createGeolocationGroupRestrictionRequest: CreateGeolocationGroupRestrictionRequest,
      options?: AxiosRequestConfig,
    ): Promise<CreateGeolocationGroupRestrictionResponse> => geolocationGroupRestrictionsAPIClient
      .apiV1GeolocationGroupRestrictionsPost(createGeolocationGroupRestrictionRequest, options)
      .then(dataExtractor<CreateGeolocationGroupRestrictionResponse>()),
    [geolocationGroupRestrictionsAPIClient],
  );

  const patchGeolocationGroupRestriction = useCallback(
    (
      groupRestrictionId: string,
      patchGeolocationGroupRestrictionRequest: PatchGeolocationGroupRestrictionRequest,
      options?: AxiosRequestConfig,
    ) : Promise<NoContentResult> => geolocationGroupRestrictionsAPIClient
      .apiV1GeolocationGroupRestrictionsGroupRestrictionIdPatch(
        groupRestrictionId,
        patchGeolocationGroupRestrictionRequest,
        options,
      )
      .then(dataExtractor<NoContentResult>()),
    [geolocationGroupRestrictionsAPIClient],
  );

  const toggleGroupRestriction = useCallback(
    (toggleGroupRestrictionRequest?: ToggleGroupRestrictionRequest, options?: AxiosRequestConfig)
      : Promise<NoContentResult> => groupRestrictionsAPIClient
      .apiV1GroupRestrictionsTogglePost(toggleGroupRestrictionRequest, options)
      .then(dataExtractor<NoContentResult>()),
    [groupRestrictionsAPIClient],
  );

  const deleteGroupRestrictions = useCallback(
    (
      deleteBulkGroupRestrictionRequest: DeleteBulkGroupRestrictionRequest,
      options?: AxiosRequestConfig,
    ): Promise<DeleteBulkGroupRestrictionResponse> => groupRestrictionsAPIClient
      .apiV1GroupRestrictionsDeleteBulkPost(deleteBulkGroupRestrictionRequest, options)
      .then(dataExtractor<DeleteBulkGroupRestrictionResponse>()),
    [groupRestrictionsAPIClient],
  );

  return {
    getGroupRestrictions,
    getIPGroupRestrictionById,
    getTimeOfDayGroupRestrictionById,
    getGeolocationGroupRestrictionById,
    toggleGroupRestriction,
    createTimeOfDayGroupRestriction,
    createIPGroupRestriction,
    createGeolocationGroupRestriction,
    deleteGroupRestrictions,
    patchIPGroupRestriction,
    patchTimeOfDayGroupRestriction,
    patchGeolocationGroupRestriction,
  };
};

export default useGroupRestrictionsAPI;
