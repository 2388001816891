import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  TUseQueryOptions,
  TUseMutationOptions,
  TUseMutationContext,
} from '@uniqkey-frontend/shared-app';
import {
  GetScimSettingsResponse,
  UpdateScimSettingsRequest,
  NoContentResult,
} from '@uniqkey-backend-organization-web/api-client';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import useOrganizationScimSettingsAPI from '../../useOrganizationScimSettingsAPI';

export const REACT_QUERY_ORGANIZATION_SCIM_SETTINGS_KEY = [
  ReactQueryKeyEnum.OrganizationScimSettings,
];

export const useGetOrganizationScimSettings = (
  options: TUseQueryOptions<GetScimSettingsResponse> = {},
) => {
  const { getOrganizationScimSettings } = useOrganizationScimSettingsAPI();
  return useQuery<GetScimSettingsResponse>(
    REACT_QUERY_ORGANIZATION_SCIM_SETTINGS_KEY,
    ({ signal }) => getOrganizationScimSettings({ signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};

interface IUseUpdateOrganizationScimSettingsParams {
  useOptimisticUpdates?: boolean;
}
export type TUseUpdateOrganizationScimSettingsOptions = TUseMutationOptions<
  NoContentResult,
  unknown,
  UpdateScimSettingsRequest,
  TUseMutationContext<UpdateScimSettingsRequest>
>;
export const useUpdateOrganizationScimSettings = (
  params: IUseUpdateOrganizationScimSettingsParams,
  options: TUseUpdateOrganizationScimSettingsOptions = {},
) => {
  const { useOptimisticUpdates = false } = params;
  const queryClient = useQueryClient();
  const { updateOrganizationScimSettings } = useOrganizationScimSettingsAPI();
  return useMutation(
    REACT_QUERY_ORGANIZATION_SCIM_SETTINGS_KEY,
    (scimSettings) => updateOrganizationScimSettings(scimSettings),
    {
      onMutate: async (newScimSettings: UpdateScimSettingsRequest) => {
        if (!useOptimisticUpdates) {
          return null;
        }
        await queryClient.cancelQueries(REACT_QUERY_ORGANIZATION_SCIM_SETTINGS_KEY);
        const previousValue = queryClient.getQueryData<
        UpdateScimSettingsRequest
      >(REACT_QUERY_ORGANIZATION_SCIM_SETTINGS_KEY);
        queryClient.setQueryData<UpdateScimSettingsRequest>(
          REACT_QUERY_ORGANIZATION_SCIM_SETTINGS_KEY,
          (oldScimSettings) => ({
            ...oldScimSettings,
            ...newScimSettings,
          }),
        );
        return { previousValue: previousValue as UpdateScimSettingsRequest };
      },
      onError: (err, scimSettings, context) => {
        if (context?.previousValue) {
          queryClient.setQueryData<
          UpdateScimSettingsRequest
        >(REACT_QUERY_ORGANIZATION_SCIM_SETTINGS_KEY, context.previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(REACT_QUERY_ORGANIZATION_SCIM_SETTINGS_KEY);
      },
      ...options,
    },
  );
};
