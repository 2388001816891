import { createContext, useContext } from 'react';
import {
  EmployeeAccountsApi,
  EmployeeAccountsToGroupsApi,
  GroupsApi,
  OrganizationApi,
  VaultsApi,
  SecuritySettingsApi,
  ScimSettingsApi,
  VaultsToEmployeeAccountsApi,
  VaultsToGroupsApi,
  VerifiedDomainsApi,
  ApplicationsApi,
  GroupRestrictionsApi,
  IpGroupRestrictionsApi,
  TimeOfDayGroupRestrictionsApi,
  GeolocationGroupRestrictionsApi,
  AuditLogsApi,
  DashboardApi,
  MasterPasswordResetRequestsApi,
  UserApi,
  PairingCodesApi,
  DomainRulesApi,
  DesktopApplicationsApi,
  QueueApi,
  TrustedActionsApi,
  SupportedUILanguagesApi,
  MigrationApi,
  PartnerSupportRequestsApi,
  OrganizationSettingsApi,
} from '@uniqkey-backend-organization-web/api-client';
import { AxiosInstance } from 'axios';
import {
  employeeAxiosInstance,
  publicAxiosInstance,
  userAxiosInstance,
} from '../../axios';
import config from '../../config';
import ApiClientsProvider from '../../common/apiClientsProvider';

export interface IAPIClientsContext {
  userAxiosInstance: AxiosInstance;
  employeeAxiosInstance: AxiosInstance;
  employeeAccountsAPIClient: EmployeeAccountsApi;
  employeeGroupsAPIClient: GroupsApi;
  employeeAccountsToGroupsAPIClient: EmployeeAccountsToGroupsApi;
  organizationAPIClient: OrganizationApi;
  vaultsAPIClient: VaultsApi,
  organizationSecuritySettingsAPIClient: SecuritySettingsApi,
  organizationScimSettingsAPIClient: ScimSettingsApi,
  vaultsToEmployeeAccountsAPIClient: VaultsToEmployeeAccountsApi,
  vaultsToGroupsAPIClient: VaultsToGroupsApi,
  verifiedDomainsAPIClient: VerifiedDomainsApi;
  applicationsAPIClient: ApplicationsApi;
  groupRestrictionsAPIClient: GroupRestrictionsApi;
  ipGroupRestrictionsAPIClient: IpGroupRestrictionsApi;
  timeOfDayGroupRestrictionsAPIClient: TimeOfDayGroupRestrictionsApi;
  geolocationGroupRestrictionsAPIClient: GeolocationGroupRestrictionsApi;
  auditLogsAPIClient: AuditLogsApi;
  dashboardAPIClient: DashboardApi;
  userAPIClient: UserApi;
  masterPasswordResetRequestsAPIClient: MasterPasswordResetRequestsApi;
  pairingCodesAPIClient: PairingCodesApi;
  domainRulesAPIClient: DomainRulesApi;
  desktopApplicationsAPIClient: DesktopApplicationsApi;
  queueAPIClient: QueueApi;
  trustedActionsAPIClient: TrustedActionsApi;
  supportedUILanguagesApi: SupportedUILanguagesApi;
  migrationAPIClient: MigrationApi;
  partnerSupportRequestsAPIClient: PartnerSupportRequestsApi;
  organizationSettingsAPIClient: OrganizationSettingsApi;
}

const WEB_API_BASE_URL = config.getWebApiUrl();

export const APIClientDefaultContext: IAPIClientsContext = {
  employeeAxiosInstance,
  userAxiosInstance,
  employeeAccountsAPIClient: new EmployeeAccountsApi(
    undefined,
    WEB_API_BASE_URL,
    employeeAxiosInstance,
  ),
  employeeGroupsAPIClient: new GroupsApi(
    undefined,
    WEB_API_BASE_URL,
    employeeAxiosInstance,
  ),
  employeeAccountsToGroupsAPIClient: new EmployeeAccountsToGroupsApi(
    undefined,
    WEB_API_BASE_URL,
    employeeAxiosInstance,
  ),
  organizationAPIClient: new OrganizationApi(
    undefined,
    WEB_API_BASE_URL,
    employeeAxiosInstance,
  ),
  vaultsAPIClient: new VaultsApi(
    undefined,
    WEB_API_BASE_URL,
    employeeAxiosInstance,
  ),
  organizationSecuritySettingsAPIClient: new SecuritySettingsApi(
    undefined,
    WEB_API_BASE_URL,
    employeeAxiosInstance,
  ),
  organizationScimSettingsAPIClient: new ScimSettingsApi(
    undefined,
    WEB_API_BASE_URL,
    employeeAxiosInstance,
  ),
  vaultsToEmployeeAccountsAPIClient: new VaultsToEmployeeAccountsApi(
    undefined,
    WEB_API_BASE_URL,
    employeeAxiosInstance,
  ),
  vaultsToGroupsAPIClient: new VaultsToGroupsApi(
    undefined,
    WEB_API_BASE_URL,
    employeeAxiosInstance,
  ),
  verifiedDomainsAPIClient: new VerifiedDomainsApi(
    undefined,
    WEB_API_BASE_URL,
    employeeAxiosInstance,
  ),
  applicationsAPIClient: new ApplicationsApi(
    undefined,
    WEB_API_BASE_URL,
    employeeAxiosInstance,
  ),
  groupRestrictionsAPIClient: new GroupRestrictionsApi(
    undefined,
    WEB_API_BASE_URL,
    employeeAxiosInstance,
  ),
  ipGroupRestrictionsAPIClient: new IpGroupRestrictionsApi(
    undefined,
    WEB_API_BASE_URL,
    employeeAxiosInstance,
  ),
  timeOfDayGroupRestrictionsAPIClient: new TimeOfDayGroupRestrictionsApi(
    undefined,
    WEB_API_BASE_URL,
    employeeAxiosInstance,
  ),
  geolocationGroupRestrictionsAPIClient: new GeolocationGroupRestrictionsApi(
    undefined,
    WEB_API_BASE_URL,
    employeeAxiosInstance,
  ),
  auditLogsAPIClient: new AuditLogsApi(
    undefined,
    WEB_API_BASE_URL,
    employeeAxiosInstance,
  ),
  dashboardAPIClient: new DashboardApi(
    undefined,
    WEB_API_BASE_URL,
    employeeAxiosInstance,
  ),
  userAPIClient: ApiClientsProvider.getUserAPIClient(userAxiosInstance),
  masterPasswordResetRequestsAPIClient: new MasterPasswordResetRequestsApi(
    undefined,
    WEB_API_BASE_URL,
    employeeAxiosInstance,
  ),
  pairingCodesAPIClient: new PairingCodesApi(
    undefined,
    WEB_API_BASE_URL,
    publicAxiosInstance,
  ),
  domainRulesAPIClient: new DomainRulesApi(
    undefined,
    WEB_API_BASE_URL,
    employeeAxiosInstance,
  ),
  desktopApplicationsAPIClient: new DesktopApplicationsApi(
    undefined,
    WEB_API_BASE_URL,
    employeeAxiosInstance,
  ),
  queueAPIClient: new QueueApi(
    undefined,
    WEB_API_BASE_URL,
    employeeAxiosInstance,
  ),
  trustedActionsAPIClient: new TrustedActionsApi(
    undefined,
    WEB_API_BASE_URL,
    userAxiosInstance,
  ),
  supportedUILanguagesApi: ApiClientsProvider.getSupportedUILanguagesAPIClient(publicAxiosInstance),
  migrationAPIClient: new MigrationApi(
    undefined,
    WEB_API_BASE_URL,
    employeeAxiosInstance,
  ),
  partnerSupportRequestsAPIClient: new PartnerSupportRequestsApi(
    undefined,
    WEB_API_BASE_URL,
    employeeAxiosInstance,
  ),
  organizationSettingsAPIClient: new OrganizationSettingsApi(
    undefined,
    WEB_API_BASE_URL,
    employeeAxiosInstance,
  ),
};

const ApiClientsContext = createContext<IAPIClientsContext>(APIClientDefaultContext);

ApiClientsContext.displayName = 'APIClientsContext';

export default ApiClientsContext;

export const useApiClients = (): IAPIClientsContext => useContext<
  IAPIClientsContext
>(ApiClientsContext);
