import {
  SupportJumpStore,
  INITIAL_SUPPORT_JUMP_STORE_STATE,
  type ISupportJumpStore,
} from '../index';
import type { TSupportJumpSubscriptionUnsubscribeHandler } from '../../common/interfaces';

const getIsInProgress = () => SupportJumpStore.getState().isInProgress;
const setIsInProgress = (isInProgress: ISupportJumpStore['isInProgress']) => {
  SupportJumpStore.setState({ isInProgress });
};

const getAxiosInstance = () => SupportJumpStore.getState().axiosInstance;
const setAxiosInstance = (axiosInstance: ISupportJumpStore['axiosInstance']) => {
  SupportJumpStore.setState({ axiosInstance });
};

const getDeferred = () => SupportJumpStore.getState().deferred;
const setDeferred = (deferred: ISupportJumpStore['deferred']) => {
  SupportJumpStore.setState({ deferred });
};

const getOrganizationId = () => SupportJumpStore.getState().organizationId;
const setOrganizationId = (organizationId: ISupportJumpStore['organizationId']) => {
  SupportJumpStore.setState({ organizationId });
};

const getCompanionApplicationId = () => SupportJumpStore.getState().companionApplicationId;
const setCompanionApplicationId = (
  companionApplicationId: ISupportJumpStore['companionApplicationId'],
) => {
  SupportJumpStore.setState({ companionApplicationId });
};

const getSymmetricKey = () => SupportJumpStore.getState().symmetricKey;
const setSymmetricKey = (
  symmetricKey: ISupportJumpStore['symmetricKey'],
) => {
  SupportJumpStore.setState({ symmetricKey });
};

const getPartnerUserId = () => SupportJumpStore.getState().partnerUserId;
const setPartnerUserId = (
  partnerUserId: ISupportJumpStore['partnerUserId'],
) => {
  SupportJumpStore.setState({ partnerUserId });
};

const getUserToken = () => SupportJumpStore.getState().userToken;
const setUserToken = (userToken: ISupportJumpStore['userToken']) => {
  SupportJumpStore.setState({ userToken });
};

const getEmployeeTokens = () => SupportJumpStore.getState().employeeTokens;
const setEmployeeTokens = (employeeTokens: ISupportJumpStore['employeeTokens']) => {
  SupportJumpStore.setState({ employeeTokens });
};

const getEmployeeToken = () => SupportJumpStore.getState().employeeToken;
const setEmployeeToken = (employeeToken: ISupportJumpStore['employeeToken']) => {
  SupportJumpStore.setState({ employeeToken });
};

const getPartnerKeys = () => SupportJumpStore.getState().partnerKeys;
const setPartnerKeys = (partnerKeys: ISupportJumpStore['partnerKeys']) => {
  SupportJumpStore.setState({ partnerKeys });
};

const getPartnerUserKeys = () => SupportJumpStore.getState().partnerUserKeys;
const setPartnerUserKeys = (partnerUserKeys: ISupportJumpStore['partnerUserKeys']) => {
  SupportJumpStore.setState({ partnerUserKeys });
};

const getEncryptedOrganizationPrivateKey = () => SupportJumpStore
  .getState().encryptedOrganizationPrivateKey;
const setEncryptedOrganizationPrivateKey = (
  encryptedOrganizationPrivateKey: ISupportJumpStore['encryptedOrganizationPrivateKey'],
) => {
  SupportJumpStore.setState({ encryptedOrganizationPrivateKey });
};

const getOrganizationPrivateKey = () => SupportJumpStore.getState().organizationPrivateKey;
const setOrganizationPrivateKey = (
  organizationPrivateKey: ISupportJumpStore['organizationPrivateKey'],
) => {
  SupportJumpStore.setState({ organizationPrivateKey });
};

const getWSConnectionID = () => SupportJumpStore.getState().wsConnectionID;

const getSubscriptionUnsubscribeHandlers = () => SupportJumpStore.getState().unsubscribeHandlers;
const setSubscriptionUnsubscribeHandler = (
  unsubscribeHandler: TSupportJumpSubscriptionUnsubscribeHandler,
) => {
  const state = SupportJumpStore.getState();
  const unsubscribeHandlers = [...state.unsubscribeHandlers, unsubscribeHandler];
  SupportJumpStore.setState({ unsubscribeHandlers });
};

const getTimeoutID = () => SupportJumpStore.getState().timeoutID;
const setTimeoutID = (timeoutID: ISupportJumpStore['timeoutID']) => {
  SupportJumpStore.setState({ timeoutID });
};

const resetStore = () => {
  const state = SupportJumpStore.getState();
  const newState: ISupportJumpStore = {
    ...INITIAL_SUPPORT_JUMP_STORE_STATE,
    isInProgress: state.isInProgress,
  };
  SupportJumpStore.setState(newState, true);
};

export default {
  getIsInProgress,
  setIsInProgress,
  getAxiosInstance,
  setAxiosInstance,
  getDeferred,
  setDeferred,
  getOrganizationId,
  setOrganizationId,
  getCompanionApplicationId,
  setCompanionApplicationId,
  getSymmetricKey,
  setSymmetricKey,
  getPartnerUserId,
  setPartnerUserId,
  getUserToken,
  setUserToken,
  getEmployeeTokens,
  setEmployeeTokens,
  getEmployeeToken,
  setEmployeeToken,
  getPartnerKeys,
  setPartnerKeys,
  getPartnerUserKeys,
  setPartnerUserKeys,
  getEncryptedOrganizationPrivateKey,
  setEncryptedOrganizationPrivateKey,
  getOrganizationPrivateKey,
  setOrganizationPrivateKey,
  getWSConnectionID,
  getSubscriptionUnsubscribeHandlers,
  setSubscriptionUnsubscribeHandler,
  getTimeoutID,
  setTimeoutID,
  resetStore,
};
