import PubSub from 'pubsub-js';
import { BaseEitherError } from '@uniqkey-frontend/shared-app';
import {
  type TrustedPortalEventCreatedNotification,
} from '@uniqkey-backend-organization-web/api-client';
import API from './api';
import RealtimeAPIEventTypeEnum from '../../enums/RealtimeAPIEventTypeEnum';
import TrustedPortalPubSubEventEnum from './enums/PubSubEventEnum';
import TrustedPortalRealtimeAPIEventTypeEnum from './enums/RealtimeAPIEventTypeEnum';
import DataSynchronizationChangeTypeEnum from '../../enums/DataSynchronizationChangeTypeEnum';
import { type ITrustedPortalStore } from './store';
import {
  hasDataSynchronizationChangeType,
  type IOrganizationPortalSynchronizationPayload,
} from '../../helpers/dataSynchronization';
import { buildPubSubTopic, parsePubSubTopic } from '../../helpers/PubSub';
import { subscribeToRealtimeAPIEvent } from '../../services/webSocketsManager';
import { getSymmetricKey } from '../../services/companionApplicationService';
import { logException } from '../../services/sentryService';
import Axios from './axios';
import Helpers, { type ITrustedPortalNormalizeTrustedActionsReturn } from './helpers';
import WorkerManager from './managers/WorkerManager';
import TrustedPortalStoreActions from './store/actions';

const LOG_COLOR = 'darkblue';

const fetchAndNormalizeTrustedActions = async () => {
  const [
    newTrustedPortalActionsResult,
    organizationsPublicKeys,
  ] = await Promise.all([
    API.getAllTrustedPortalActions(),
    API.getAllOrganizationPublicKeys(),
  ]);

  if (newTrustedPortalActionsResult instanceof BaseEitherError) {
    throw newTrustedPortalActionsResult;
  }

  const symmetricKey = getSymmetricKey();
  if (!symmetricKey) {
    throw new Error('No symmetric key found');
  }

  return Helpers.normalizeTrustedActions(
    newTrustedPortalActionsResult,
    symmetricKey,
    organizationsPublicKeys,
  );
};

const initializeWorkers = async (
  normalizedTrustedPortalActions: ITrustedPortalNormalizeTrustedActionsReturn,
) => {
  try {
    WorkerManager.destroy(normalizedTrustedPortalActions.organizationIdsToDestroy);
    WorkerManager.create(
      normalizedTrustedPortalActions.organizationIdsToCreate,
      normalizedTrustedPortalActions.byOrganizationId,
    );
  } catch (e) {
    logException(e, { message: 'TrustedPortalModule/initializeTrustedPortal exception' });
  }
};

const resetTrustedPortal = (
  initialStoreOverrides: Partial<ITrustedPortalStore> = {},
) => {
  WorkerManager.destroyAll();
  TrustedPortalStoreActions.resetStore(initialStoreOverrides);
};

const listenEvents = () => {
  subscribeToRealtimeAPIEvent<IOrganizationPortalSynchronizationPayload>(
    RealtimeAPIEventTypeEnum.OrganizationPortalSynchronization,
    async (payload) => {
      const { changeType } = payload;
      if (hasDataSynchronizationChangeType(
        changeType,
        DataSynchronizationChangeTypeEnum.TrustedAction,
      )) {
        const normalizedTrustedPortalActions = await fetchAndNormalizeTrustedActions();
        await initializeWorkers(normalizedTrustedPortalActions);
      }
    },
  );

  subscribeToRealtimeAPIEvent<TrustedPortalEventCreatedNotification>(
    TrustedPortalRealtimeAPIEventTypeEnum.TrustedPortalEventCreatedNotification,
    (payload) => {
      const { organizationId } = payload;
      if (!organizationId) {
        return;
      }

      Helpers.tpLogMessage({
        messages: [
          '[MODULE] receive TrustedPortalEventCreatedNotification',
          `\n\torganizationId: ${organizationId}`,
        ],
        color: LOG_COLOR,
      });

      if (!TrustedPortalStoreActions.getIsEnabledByOrganizationId(organizationId)) {
        // eslint-disable-next-line no-console
        console.warn(
          'TrustedPortalModule/TrustedPortalEventCreatedNotification on disabled organization',
          organizationId,
        );
        return;
      }

      if (TrustedPortalStoreActions.getIsProcessingByOrganizationId(organizationId)) {
        return;
      }

      Helpers.tpLogMessage({
        message: '[MODULE] publish TRUSTED_PORTAL_PROVIDE_EVENT',
        color: LOG_COLOR,
      });
      PubSub.publish(
        buildPubSubTopic(TrustedPortalPubSubEventEnum.TRUSTED_PORTAL_PROVIDE_EVENT, organizationId),
      );
    },
  );

  PubSub.subscribe(TrustedPortalPubSubEventEnum.TRUSTED_PORTAL_PROVIDE_EVENT, (message) => {
    const [, organizationId] = parsePubSubTopic(message);
    if (!TrustedPortalStoreActions.getIsEnabledByOrganizationId(organizationId)) {
      // eslint-disable-next-line no-console
      console.warn(
        'TrustedPortalModule/TRUSTED_PORTAL_PROVIDE_EVENT on disabled organization',
        organizationId,
      );
      return;
    }
    Helpers.tpLogMessage({
      messages: [
        '[MODULE]',
        'receive TRUSTED_PORTAL_PROVIDE_EVENT',
        `\n\torganizationId: ${organizationId}`,
      ],
      color: LOG_COLOR,
    });
    TrustedPortalStoreActions.setIsProcessingByOrganizationId(organizationId, true);
  });

  PubSub.subscribe(TrustedPortalPubSubEventEnum.TRUSTED_PORTAL_NO_EVENTS, (message) => {
    const [, organizationId] = parsePubSubTopic(message);
    const worker = WorkerManager.getById(organizationId);
    if (!worker) {
      return;
    }
    const isExecuting = worker.isExecuting();
    Helpers.tpLogMessage({
      messages: [
        '[MODULE]',
        'receive TRUSTED_PORTAL_NO_EVENTS',
        `\n\torganizationId      : ${organizationId}`,
        `\n\tis worker executing : ${isExecuting}`,
      ],
      color: LOG_COLOR,
    });
    if (isExecuting) {
      return;
    }
    TrustedPortalStoreActions.setIsProcessingByOrganizationId(organizationId, false);
  });
};

const initModule = async () => {
  const normalizedTrustedPortalActions = await fetchAndNormalizeTrustedActions();
  if (
    // 'isInitializing' is mostly for the 'StrictMode', so the TP module is not initialized twice
    TrustedPortalStoreActions.getIsInitialized() || TrustedPortalStoreActions.getIsInitializing()
  ) {
    await initializeWorkers(normalizedTrustedPortalActions);
    return;
  }

  TrustedPortalStoreActions.setIsInitializing(true);

  Axios.attachTrustedPortalAxiosInterceptor();

  await initializeWorkers(normalizedTrustedPortalActions);

  listenEvents();

  TrustedPortalStoreActions.setIsInitialized(true);
  TrustedPortalStoreActions.setIsInitializing(false);
};

export default {
  initModule,
  resetTrustedPortal,
};
