import {
  useCallback, memo, useState, useMemo,
} from 'react';
import { useSnackbar, useTranslations } from '@uniqkey-frontend/shared-app';
import {
  GetGroupByIdResponse,
  GetVaultPasswordsResponseModel,
  GetVaultSecureNotesResponseModel,
  AddVaultsToGroupsRequestModel,
  GetVaultCreditCardsResponseModel,
  GetGroupsResponseModel,
} from '@uniqkey-backend-organization-web/api-client';
import EntitySelectorModal, { TEntitySelectorModalOnSubmit } from '../EntitySelectorModal';
import useEmployeeGroupsAPI from '../../hooks/useEmployeeGroupsAPI';
import useVaultsToGroupsAPI from '../../hooks/useVaultsToGroupsAPI';
import VaultTypeEnum from '../../enums/VaultTypeEnum';
import VaultTypeNameEnum from '../../enums/VaultTypeNameEnum';
import { logException } from '../../services/sentryService';
import { getActiveOrganizationId } from '../../services/organizationService';
import { useTrustedPortalStore } from '../../modules/TrustedPortalModule/store';

interface IShareVaultsToGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  vaults: GetVaultPasswordsResponseModel[] | GetVaultSecureNotesResponseModel[]
    | GetVaultCreditCardsResponseModel[];
  onSubmit?: () => void;
  type: VaultTypeEnum;
}

interface IGroup {
  id: string;
  label: string;
}

const ShareVaultsToGroupModal = (props: IShareVaultsToGroupModalProps) => {
  const {
    isOpen, onClose, vaults, onSubmit, type,
  } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadedGroupsCount, setLoadedGroupsCount] = useState<number>(0);
  const [totalLoadedGroupsCount, setTotalLoadedGroupsCount] = useState<number>(0);
  const [response, setResponse] = useState<GetGroupsResponseModel[]>([]);
  const { t } = useTranslations();
  const { showWarning, showError, showSuccess } = useSnackbar();
  const { getGroups } = useEmployeeGroupsAPI();
  const { shareVaultsToGroup } = useVaultsToGroupsAPI();

  const activeOrganizationId = getActiveOrganizationId();
  const isTrustedPortalEnabled = useTrustedPortalStore.useIsEnabledByOrganizationId()[
    activeOrganizationId!
  ] ?? false;

  const handleAutocompleteRequest = useCallback(async (searchQuery: string) => {
    try {
      const { data, total } = await getGroups({
        page: 1,
        pageLength: 200,
        searchQuery,
        canManageVaults: true,
      });
      setLoadedGroupsCount(data.length);
      setTotalLoadedGroupsCount(total);
      setResponse(data);
      return data;
    } catch (e) {
      setLoadedGroupsCount(0);
      setTotalLoadedGroupsCount(0);
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'ShareVaultsToGroupModal/handleAutocompleteRequest exception',
      });
      return [];
    }
  }, [getGroups, showError, t]);

  const vaultType = VaultTypeNameEnum[VaultTypeEnum[type] as keyof typeof VaultTypeEnum];

  const handleSubmitEntitySelectorModal = useCallback<
    TEntitySelectorModalOnSubmit<IGroup[]>
  >(async (groups) => {
    try {
      setIsLoading(true);
      const requests: AddVaultsToGroupsRequestModel[] = groups.reduce((
        acc: AddVaultsToGroupsRequestModel[],
        group: IGroup,
      ) => {
        vaults.forEach((vault) => acc.push({ vaultId: vault.vaultId, groupId: group.id }));
        return acc;
      }, []);
      await shareVaultsToGroup({ requests });
      if (isTrustedPortalEnabled) {
        showSuccess({
          text: t(`trustedPortalSuccessNotifications.${vaultType}.shared`),
        });
      } else {
        showWarning({
          text: t('shareEntityToGroupModal.approvalOnMobileToast'),
        });
      }
      if (onSubmit) {
        onSubmit();
      }
      setIsLoading(false);
      onClose();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'ShareVaultsToGroupModal/handleSubmitEntitySelectorModal exception',
      });
      setIsLoading(false);
    }
  }, [
    shareVaultsToGroup,
    vaults,
    onSubmit,
    onClose,
    vaultType,
    isTrustedPortalEnabled,
    showWarning,
    showError,
    showSuccess,
    t,
  ]);

  const handleResponseEntitySelectorModal = useCallback(
    (groupsToParse: GetGroupByIdResponse[]) => groupsToParse.map((group: GetGroupByIdResponse) => ({
      id: group.groupId,
      label: group.name,
    })),
    [],
  );
  const ownership = useMemo(() => {
    if (vaults.length) {
      return vaults[0].ownership;
    }
    return null;
  }, [vaults]);

  return (
    <EntitySelectorModal
      data={response}
      dataKey="groupId"
      isOpen={isOpen}
      title={t(`shareEntityToGroupModal.${vaultType}From${ownership}.title`)}
      dialogContentText={t(
        `shareEntityToGroupModal.${vaultType}From${ownership}.description`,
      )}
      placeholder={t('shareEntityToGroupModal.placeholder')}
      onSubmit={handleSubmitEntitySelectorModal}
      request={handleAutocompleteRequest}
      responseParser={handleResponseEntitySelectorModal}
      onClose={onClose}
      isLoading={isLoading}
      submitButtonText="common.share"
      loadedOptionsCount={loadedGroupsCount}
      totalLoadedOptionsCount={totalLoadedGroupsCount}
    />
  );
};

export default memo(ShareVaultsToGroupModal);
