import { memo } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ReactHookFormCheckbox,
  Typography,
  useTranslations,
  S6,
  emptyStringValidator,
  ReactHookFormTextField,
  buildInvalidFieldTranslation,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import { ICreateGroup } from '../../index';
import { useUser } from '../../../../contexts/UserContext';

interface GroupModalProps {
    isModalOpen: boolean,
    handleModalClose: () => void,
    handleGroupCreate: (params: ICreateGroup) => Promise<void>,
    isLoading: boolean,
}

const GroupModal = (props: GroupModalProps) => {
  const {
    isModalOpen, handleModalClose, handleGroupCreate, isLoading,
  } = props;
  const { t } = useTranslations();
  const { isSupporter } = useUser();
  const {
    handleSubmit, control, formState: { errors, dirtyFields },
  } = useForm<ICreateGroup>({
    mode: 'all',
    defaultValues: {
      name: '',
      description: '',
      addToGroup: !isSupporter,
    },
  });

  return (
    <Dialog
      onClose={handleModalClose}
      open={isModalOpen}
      fullWidth
      maxWidth="sm"
      clickOutsideToClose={!isLoading}
    >
      <form onSubmit={handleSubmit(handleGroupCreate)} autoComplete="off">
        <DialogTitle isLoading={isLoading} onClose={handleModalClose}>
          {t('groupModal.title')}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" rowSpacing={3}>
            <Grid item xs={12}>
              <ReactHookFormTextField
                name="name"
                control={control}
                autoFocus
                fullWidth
                error={!!errors.name?.message}
                label={`${t('groupModal.groupName.label')}*`}
                placeholder={t('groupModal.groupName.placeholder')}
                helperText={errors.name?.message}
                rules={{
                  required: t('validation.required'),
                  validate: (value) => (
                    emptyStringValidator(value as string)
                      ? buildInvalidFieldTranslation('groupModal.groupName.label') : true
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ReactHookFormTextField
                name="description"
                control={control}
                fullWidth
                multiline
                error={!!errors.description?.message}
                label={t('groupModal.description.label')}
                placeholder={t('groupModal.description.placeholder')}
                helperText={errors.description?.message}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid item container alignItems="center" textAlign="center">
            <Grid item xs={6}>
              {!isSupporter && (
                // Keep the wrapping grid so the submit button below is positioned on the right side
                <ReactHookFormCheckbox
                  name="addToGroup"
                  control={control}
                  label={(
                    <Typography variant="subtitle2" color={S6}>
                      {t('groupModal.addMeToThisGroup')}
                    </Typography>
                  )}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth type="submit" disabled={!dirtyFields.name} isLoading={isLoading}>
                {t('groupModal.button')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(GroupModal);
