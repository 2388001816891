import {
  Dispatch, SetStateAction, useCallback, useState,
} from 'react';
import { upperFirst } from 'lodash';
import {
  useSnackbar,
  useTable,
  useQueryTableFetch,
  useTranslations,
  useTableLocalization,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  buildTablePreserverDecoderConfigItem,
  TablePreserverCommonInitialTableValues,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  TUseTablePreserverDecoderConfigs,
  TablePreserverDecoderConfigItemTypeEnum,
  TSetSearchParams,
} from '@uniqkey-frontend/shared-app';
import {
  MasterPasswordResetRequestOrderProperty,
} from '@uniqkey-backend-organization-web/api-client';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import {
  IGetAccountRecoveryRequestsParams,
  TGetAccountRecoveryRequestsMethod,
} from '../../useMasterPasswordResetRequestsAPI/interfaces';
import useMasterPasswordResetRequestsAPI from '../../useMasterPasswordResetRequestsAPI';
import {
  IAccountRecoveryRequestsTableRow,
} from '../../../components/tables/AccountRecoveryRequestsTable';

export const REACT_QUERY_ACCOUNT_RECOVERY_REQUESTS_KEY = [
  ReactQueryKeyEnum.AccountRecoveryRequests,
];

export interface IUseAccountRecoveryRequestsTableParams {
  noDataMessageKey: string;
}

export interface IUseAccountRecoveryRequestsTableReturn extends
  Omit<IUseTableReturn<IAccountRecoveryRequestsTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage' | 'resetSelectedRows'>,
  Omit<IUseQueryTableFetchReturn<TGetAccountRecoveryRequestsMethod>, 'data' | 'isFetchedAfterMount'>
{
  accounts: IUseQueryTableFetchReturn<TGetAccountRecoveryRequestsMethod>['data'];
  selectedAccounts: IUseTableReturn<IAccountRecoveryRequestsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  searchQuery: IGetAccountRecoveryRequestsParams['searchQuery'];
  setSearchQuery: Dispatch<SetStateAction<IGetAccountRecoveryRequestsParams['searchQuery']>>;
}

interface ITablePreservationConfigs extends
  Omit<IGetAccountRecoveryRequestsParams,
    'page' | 'pageLength' | 'orderPropertyName' | 'isDescending'
  >,
  Pick<IUseTableReturn<IAccountRecoveryRequestsTableRow>, 'columnOrderBy' | 'columnOrderDirection'>
{
  activePage: IGetAccountRecoveryRequestsParams['page'];
  perPage: IGetAccountRecoveryRequestsParams['pageLength'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  searchQuery: true,
  columnOrderBy: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: MasterPasswordResetRequestOrderProperty },
  ),
  columnOrderDirection: true,
};

const useAccountRecoveryRequestsTable = (
  params: IUseAccountRecoveryRequestsTableParams,
): IUseAccountRecoveryRequestsTableReturn => {
  const {
    noDataMessageKey,
  } = params;

  const queryClient = useQueryClient();
  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getAccountRecoveryRequests } = useMasterPasswordResetRequestsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    searchQuery: initialSearchQuery,
    columnOrderBy: initialColumnOrderBy,
    columnOrderDirection: initialColumnOrderDirection,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const [searchQuery, setSearchQuery] = useState<
    IGetAccountRecoveryRequestsParams['searchQuery']
  >(() => initialSearchQuery);

  const {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedRows: selectedAccounts,
    resetTableMethods,
    ...restTableProps
  } = useTable<IAccountRecoveryRequestsTableRow>({
    initialActivePage,
    initialPerPage,
    initialColumnOrderBy,
    initialColumnOrderDirection,
  });

  const {
    resetTable,
    resetActivePage,
    resetSelectedRows,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
    setSearchQuery(TablePreserverCommonInitialTableValues.searchQuery);
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: accounts, isLoading, isFetchedAfterMount, total, resetQuery,
  } = useQueryTableFetch({
    queryKey: REACT_QUERY_ACCOUNT_RECOVERY_REQUESTS_KEY,
    queryClient,
    request: getAccountRecoveryRequests,
    params: {
      page: activePage,
      pageLength: perPage,
      searchQuery,
      orderPropertyName: MasterPasswordResetRequestOrderProperty[
        upperFirst(columnOrderBy) as keyof typeof MasterPasswordResetRequestOrderProperty
      ],
      isDescending: columnOrderDirection ? columnOrderDirection === 'desc' : undefined,
    },
    onRequestError: handleOnRequestError,
    autoRefetch: true,
  });

  const { localization } = useTableLocalization({
    searchQuery,
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    searchQuery,
    columnOrderBy,
    columnOrderDirection,
  });

  return {
    accounts,
    isLoading,
    total,
    resetQuery,
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedAccounts,
    localization,
    searchQuery,
    setSearchQuery,
    resetActivePage,
    resetSelectedRows,
    ...restTableProps,
  };
};

export default useAccountRecoveryRequestsTable;
