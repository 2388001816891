enum LocalStorageKeyEnum {
  EmployeesTokens = 'employeesTokens',
  UserTokens = 'userTokens',
  SymmetricKey = 'symmetricKey',
  ActiveOrganizationId = 'activeOrganizationId',
  Guid = 'guid',
  LastActivityTS = 'lastActivityTS',
  ShouldShowUnprocessedEmployeesWarning = 'shouldShowUnprocessedEmployeesWarning',
  ShouldShowUnprocessedEventsWarning = 'shouldShowUnprocessedEventsWarning',
  IsSideMenuCollapsed = 'isSideMenuCollapsed'
}

export default LocalStorageKeyEnum;
