import { useCallback, memo, SyntheticEvent } from 'react';
import {
  Panel, PanelHeader, useTranslations, Tab, Tabs, ConditionalTabWrapper,
} from '@uniqkey-frontend/shared-app';
import { Navigate, useNavigate, useOutletContext } from 'react-router-dom';
import { useGetApplicationById } from '../../../../hooks/reactQuery';
import PageRouteEnum from '../../../../enums/PageRouteEnum';
import { publishTablePreservationReset } from '../../../../helpers/tablePreservation/reset';
import ServiceEmployeesTab from '../ServiceEmployeesTab';
import ServiceLoginsTab from '../ServiceLoginsTab';
import ServiceDetailsTab from '../ServiceDetailsTab';

export enum ServiceModuleTabEnum {
  Details = 'details',
  Employees = 'employees',
  Logins = 'logins'
}

interface IServiceModuleProps {
  activeTab: ServiceModuleTabEnum;
}

interface IServiceModuleContext {
  applicationId: string;
}

const ServiceModule = (props: IServiceModuleProps) => {
  const { activeTab } = props;
  const { t } = useTranslations();
  const { applicationId } = useOutletContext<IServiceModuleContext>();
  const navigate = useNavigate();

  const { isLoading, data: service } = useGetApplicationById(
    { applicationId },
  );

  const handleTabClick = useCallback((
    event: SyntheticEvent,
    nextActiveTab: ServiceModuleTabEnum,
  ) => {
    const nextPath = `${PageRouteEnum.Services}/${applicationId}/${nextActiveTab}`;
    if (activeTab !== nextActiveTab) {
      navigate(nextPath);
      return;
    }
    navigate(nextPath, { replace: true });
    publishTablePreservationReset();
  }, [applicationId, activeTab, navigate]);

  const { userCount, vaultCount, name } = service ?? {};
  const hidePanelHeaderBottomDivider = activeTab === ServiceModuleTabEnum.Details;

  return (
    <Panel className="height-100-percent flex-column">
      <PanelHeader px={3} hideBottomDivider={hidePanelHeaderBottomDivider}>
        <Tabs value={activeTab} isLoading={isLoading}>
          <Tab
            label={t('servicePage.tabs.serviceDetails')}
            value={ServiceModuleTabEnum.Details}
            onClick={handleTabClick}
          />
          {!!userCount && (
            <Tab
              label={t('servicePage.tabs.employees')}
              value={ServiceModuleTabEnum.Employees}
              onClick={handleTabClick}
            />
          )}
          {!!vaultCount && (
            <Tab
              label={t('servicePage.tabs.logins')}
              value={ServiceModuleTabEnum.Logins}
              onClick={handleTabClick}
            />
          )}
        </Tabs>
      </PanelHeader>
      {activeTab === ServiceModuleTabEnum.Details && (
        <ServiceDetailsTab applicationId={applicationId} />
      )}
      {activeTab === ServiceModuleTabEnum.Employees && (
        <ConditionalTabWrapper
          skip={isLoading}
          condition={!!userCount}
          fallback={<Navigate to={`${PageRouteEnum.Services}/${applicationId}`} />}
        >
          <ServiceEmployeesTab applicationId={applicationId} />
        </ConditionalTabWrapper>
      )}
      {activeTab === ServiceModuleTabEnum.Logins && (
        <ConditionalTabWrapper
          skip={isLoading}
          condition={!!vaultCount}
          fallback={<Navigate to={`${PageRouteEnum.Services}/${applicationId}`} />}
        >
          <ServiceLoginsTab applicationName={name!} />
        </ConditionalTabWrapper>
      )}
    </Panel>
  );
};

export default memo(ServiceModule);
