import { useCallback, useMemo, useState } from 'react';
import {
  PanelContent,
  CircularProgress,
  Grid,
  EditableContainer,
  Typography,
  S5,
  useSnackbar,
  useTranslations,
  DetailsElements,
} from '@uniqkey-frontend/shared-app';
import {
  useGetCurrentOrganization,
  usePatchCurrentOrganization,
} from '../../../../hooks/reactQuery';
import { createReplacePatchOperation } from '../../../../helpers/apiClients';
import EditOrganizationDetailsModal, {
  TEditOrganizationDetailsModalReturn,
} from './EditOrganizationDetailsModal';

const MIN_CONTAINER_HEIGHT = 305;

const OrganizationDetailsTab = () => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const handleEditModalOpen = useCallback(() => setIsEditModalOpen(true), []);
  const handleEditModalClose = useCallback(() => setIsEditModalOpen(false), []);
  const [isEditOrganizationDetailsLoading, setIsEditOrganizationDetailsLoading] = useState(false);

  const { t } = useTranslations();
  const { showSuccess, showError } = useSnackbar();

  const {
    data: organization, isLoading, isError,
  } = useGetCurrentOrganization({
    onError: () => {
      showError({ text: t('common.somethingWentWrong') });
    },
  });

  const { mutate: mutateOrganization } = usePatchCurrentOrganization({
    useOptimisticUpdates: true,
  });

  const handleEditOrganizationDetails = useCallback((
    updatedOrganizationDetails: TEditOrganizationDetailsModalReturn,
  ) => {
    setIsEditOrganizationDetailsLoading(true);
    const operations = updatedOrganizationDetails.map(({
      key, value,
    }) => createReplacePatchOperation(key, value));
    mutateOrganization(operations, {
      onError: () => showError({ text: t('common.somethingWentWrong') }),
      onSuccess: () => {
        handleEditModalClose();
        showSuccess({ text: t('editOrganizationModal.organizationDetailsUpdated') });
      },
      onSettled: () => setIsEditOrganizationDetailsLoading(false),
    });
  }, [mutateOrganization, handleEditModalClose, showError, showSuccess, t]);

  const elements = useMemo(() => {
    const {
      name, address, vat, zip, billingEmail, country, phone, city,
    } = organization ?? {};
    return {
      name, address, vat, zip, billingEmail, country, phone, city,
    };
  }, [organization]);

  if (isError) {
    return null;
  }

  if (isLoading) {
    return (
      <PanelContent p={3} minHeight={MIN_CONTAINER_HEIGHT}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          minHeight={MIN_CONTAINER_HEIGHT}
        >
          <CircularProgress />
        </Grid>
      </PanelContent>
    );
  }

  return (
    <PanelContent p={3} minHeight={MIN_CONTAINER_HEIGHT}>
      <Grid container>
        <Grid item mb={2}>
          <Typography variant="body2" color={S5}>
            {t('settingsPage.organizationDetailsTab.header')}
          </Typography>
        </Grid>
        <EditableContainer onClick={handleEditModalOpen} container rowGap={3}>
          <DetailsElements
            hidden={!organization}
            elements={elements}
            translationKey="settingsPage.organizationDetailsTab.labels"
            t={t}
          />
        </EditableContainer>
      </Grid>
      {isEditModalOpen && (
        <EditOrganizationDetailsModal
          isOpen={isEditModalOpen}
          isLoading={isEditOrganizationDetailsLoading}
          onSubmit={handleEditOrganizationDetails}
          onClose={handleEditModalClose}
          organization={organization!}
        />
      )}
    </PanelContent>
  );
};

export default OrganizationDetailsTab;
