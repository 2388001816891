import type { Either } from '@uniqkey-frontend/shared-app';
import type { OkResult } from '@uniqkey-backend-organization-web/api-client';
import {
  SupportJumpGetMobileTokensError,
  type TSupportJumpGetMobileTokensOperationErrors,
} from './errors';
import {
  SupportJumpAPIIncorrectAxiosInstanceError,
  SupportJumpMissingStoreDataError,
} from '../../common/errors';
import APIClientsProvider from '../../apiClientsProvider';
import SupportJumpStoreActions from '../../store/actions';
import { dataExtractor } from '../../../../helpers/apiClients';

const getMobileTokensOperation = async (): Promise<
  Either<OkResult, TSupportJumpGetMobileTokensOperationErrors>
> => {
  try {
    const instance = SupportJumpStoreActions.getAxiosInstance();
    if (!instance) {
      return new SupportJumpAPIIncorrectAxiosInstanceError();
    }

    const employeeToken = SupportJumpStoreActions.getEmployeeToken();
    if (!employeeToken) {
      return new SupportJumpMissingStoreDataError();
    }

    const { employeeAccountId } = employeeToken;

    const result = await APIClientsProvider.getSupporterAPIClient(instance)
      .apiV1SupporterGetMobileTokensSessionIdGet(employeeAccountId)
      .then(dataExtractor<OkResult>());
    return result;
  } catch (e) {
    return new SupportJumpGetMobileTokensError();
  }
};

export default getMobileTokensOperation;
