import { encryptAsymmetric } from '@uniqkey-frontend/shared-app';
import {
  type ApproveMasterPasswordResetRequestPayload,
} from '@uniqkey-backend-organization-web/api-client';
import {
  type GetMasterPasswordResetTokenResponse,
} from '@uniqkey-backend-organization-mobile/api-client';
import type { ITrustedPortalHandlerParams } from '../../../interfaces';
import APIClientsProvider from '../../../apiClientsProvider';
import { dataExtractor } from '../../../../../helpers/apiClients';
import getEmployeeMasterPasswordKDFBackupsV2 from './getEmployeeMasterPasswordKDFBackupsV2';
import getEmployeeMasterPasswordKDFBackupsV1 from './getEmployeeMasterPasswordKDFBackupsV1';

const ERROR_PREFIX = 'TrustedPortal/handleApproveMasterPasswordResetRequestEvent';

export interface IDecryptedEmployeeMasterPasswordKDFBackupsMapPerOrganization {
  [organizationId: string]: string;
}

const handleApproveMasterPasswordResetRequestEvent = async (
  params: ITrustedPortalHandlerParams<ApproveMasterPasswordResetRequestPayload>,
) => {
  const {
    parsedEvent,
    axiosInstance,
    organizationPrivateKey,
    organizationPublicKey,
  } = params;

  const { parsedPayload, queueMessageId } = parsedEvent;
  const {
    employeeAccountId,
    ephemeralPublic,
    organizationId,
    privateKeyBackupSymmetricKey,
    employeeAccountPrivateKey,
  } = parsedPayload;

  if (!employeeAccountId) {
    throw new Error(`${ERROR_PREFIX} no employeeAccountId in event`);
  }

  if (!ephemeralPublic) {
    throw new Error(`${ERROR_PREFIX} no ephemeralPublic in event`);
  }

  if (!organizationId) {
    throw new Error(`${ERROR_PREFIX} no organizationId in event`);
  }

  if (!privateKeyBackupSymmetricKey) {
    throw new Error(`${ERROR_PREFIX} no privateKeyBackupSymmetricKey in event`);
  }

  if (!employeeAccountPrivateKey) {
    throw new Error(`${ERROR_PREFIX} no employeeAccountPrivateKey in event`);
  }

  const { token } = await APIClientsProvider.Mobile
    .getMasterPasswordResetRequestAPIClient(axiosInstance)
    .apiV1MasterPasswordResetRequestGetResetMasterPasswordTokenEmployeeAccountIdGet(
      employeeAccountId,
    )
    .then(dataExtractor<GetMasterPasswordResetTokenResponse>());

  if (!token) {
    throw new Error(`${ERROR_PREFIX} token not found`);
  }

  // eslint-disable-next-line max-len
  const employeeMasterPasswordKDFMapPerOrganizationV2 = await getEmployeeMasterPasswordKDFBackupsV2({
    queueMessageId,
    axiosInstance,
    organizationPrivateKey,
    organizationPublicKey,
    employeeAccountId,
    organizationId,
    privateKeyBackupSymmetricKey,
    employeeAccountPrivateKey,
  });

  // eslint-disable-next-line max-len
  const employeeMasterPasswordKDFMapPerOrganizationV1 = await getEmployeeMasterPasswordKDFBackupsV1({
    axiosInstance,
    organizationPrivateKey,
    organizationPublicKey,
    employeeAccountId,
  });

  if (
    !employeeMasterPasswordKDFMapPerOrganizationV2 && !employeeMasterPasswordKDFMapPerOrganizationV1
  ) {
    throw new Error(
      `${ERROR_PREFIX} Employee does not have master password backup for organization`,
    );
  }

  const payload = {
    token,
    employeeMasterPasswordKDFMapPerOrganizationV2,
    employeeMasterPasswordKDFMapPerOrganizationV1,
  };

  const encryptedPayload = await encryptAsymmetric({
    publicKey: ephemeralPublic,
    string: JSON.stringify(payload),
  });

  await APIClientsProvider.Mobile.getQueueAPIClient(axiosInstance)
    .apiV1QueueMasterPasswordResetRequestApprovePost({
      queueMessageId,
      payload: encryptedPayload,
    });
};

export default handleApproveMasterPasswordResetRequestEvent;
