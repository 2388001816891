import { useCallback, memo, useState } from 'react';
import {
  ActionButton,
  Button,
  Divider,
  DeleteIcon,
  Grid,
  PanelContent,
  PlusIcon,
  useTranslations,
  useSnackbar,
  Tooltip,
  PromptModal,
  useMapKeyValueExtractor,
} from '@uniqkey-frontend/shared-app';
import {
  GetIpGroupRestrictionByIdResponse,
  PatchIpGroupRestrictionRequest,
  GetTimeOfDayGroupRestrictionByIdResponse,
  PatchTimeOfDayGroupRestrictionRequest,
  GetGeolocationGroupRestrictionByIdResponse,
  PatchGeolocationGroupRestrictionRequest,
  RestrictionType,
} from '@uniqkey-backend-organization-web/api-client';
import useGroupRestrictionsAPI from '../../../../hooks/useGroupRestrictionsAPI';
import useGroupRestrictionsTable from '../../../../hooks/tables/useGroupRestrictionsTable';
import GroupRestrictionsTable, {
  IGroupRestrictionsTableProps,
  TGroupRestrictionsTableRow,
} from '../../../../components/tables/GroupRestrictionsTable';
import { TSelectionOptionType } from '../../../../components/SelectionModalOption';
import GroupRestrictionsModal, { IGroupRestrictionsModalProps } from '../GroupRestrictionsModal';
import TimeRestrictionModal, {
  ICreateTimeRestrictionModalReturnValue, IEditTimeRestrictionModalReturnValue,
} from '../TimeRestrictionModal';
import { buildRestrictionDaysAsFlags, convertTimeToString } from '../../../../helpers/restrictions';
import IpAddressRestrictionModal, {
  ICreateIpAddressRestrictionModalReturnValue,
  IEditIpAddressRestrictionModalReturnValue,
} from '../IpAddressRestrictionModal';
import LocationRestrictionModal, {
  ICreateLocationRestrictionModalReturnValue,
  IEditLocationRestrictionModalReturnValue,
} from '../LocationRestrictionModal';
import { createReplacePatchOperation } from '../../../../helpers/apiClients';
import { TRestriction } from '../../../../hooks/useGroupRestrictionsAPI/interfaces';
import { logException } from '../../../../services/sentryService';

interface IGroupRestrictionsTabProps {
  groupId: string;
}

const GroupRestrictionsTab = (props: IGroupRestrictionsTabProps) => {
  const { groupId } = props;

  const { t } = useTranslations();
  const { showError, showSuccess } = useSnackbar();
  const { toggleGroupRestriction, deleteGroupRestrictions } = useGroupRestrictionsAPI();
  const {
    createTimeOfDayGroupRestriction,
    createIPGroupRestriction,
    createGeolocationGroupRestriction,
    patchIPGroupRestriction,
    patchTimeOfDayGroupRestriction,
    patchGeolocationGroupRestriction,
  } = useGroupRestrictionsAPI();

  const [isRestrictionsModalOpen, setIsRestrictionsModalOpen] = useState(false);
  const [createUpdateRestrictionModalType, setCreateUpdateRestrictionModalType] = useState<
    TSelectionOptionType | null
  >(null);
  const [isCreateUpdateRestrictionLoading, setIsCreateUpdateRestrictionLoading] = useState(false);
  const [isDeleteRestrictionLoading, setIsDeleteRestrictionLoading] = useState(false);
  const [isDeleteRestrictionModalOpen, setIsDeleteRestrictionModalOpen] = useState(false);
  const [restrictionToUpdate, setRestrictionToUpdate] = useState<TRestriction | null>(null);
  const handleDeleteRestrictionModalOpen = useCallback(
    () => setIsDeleteRestrictionModalOpen(true),
    [],
  );
  const handleDeleteRestrictionModalClose = useCallback(
    () => setIsDeleteRestrictionModalOpen(false),
    [],
  );
  const handleRestrictionsModalOpen = useCallback(() => setIsRestrictionsModalOpen(true), []);
  const handleRestrictionsModalClose = useCallback(() => setIsRestrictionsModalOpen(false), []);
  const handleCreateRestrictionModalOpen = useCallback<
    IGroupRestrictionsModalProps['onSelect']
  >((type) => {
    setCreateUpdateRestrictionModalType(type);
    setIsRestrictionsModalOpen(false);
  }, []);
  const handleUpdateRestrictionModalOpen = useCallback<
    IGroupRestrictionsTableProps['onRestrictionRowClick']
  >((type, restriction) => {
    setCreateUpdateRestrictionModalType(type);
    setRestrictionToUpdate(restriction);
  }, []);
  const handleCreateUpdateRestrictionModalClose = useCallback(() => {
    setCreateUpdateRestrictionModalType(null);
    setRestrictionToUpdate(null);
  }, []);

  const {
    selectedRestrictions,
    resetActivePage,
    resetSelectedRows,
    ...restTableProps
  } = useGroupRestrictionsTable({
    persistentFilters: { groupId },
    noDataMessageKey: 'groupPage.restrictionsTab.table.noData',
  });

  const handleStatusChange = useCallback<IGroupRestrictionsTableProps['onStatusChange']>(
    (groupRestrictionId, isEnabled) => toggleGroupRestriction({ groupRestrictionId, isEnabled }),
    [toggleGroupRestriction],
  );

  const handleTimeRestrictionCreate = useCallback(async (
    value: ICreateTimeRestrictionModalReturnValue,
  ) => {
    setIsCreateUpdateRestrictionLoading(true);
    const {
      days,
      timezone,
      time,
    } = value;
    const [allowedFromTime, allowedToTime] = time;
    try {
      await createTimeOfDayGroupRestriction({
        groupId,
        offsetName: timezone.name,
        offset: timezone.offset,
        dayOfWeek: buildRestrictionDaysAsFlags(days),
        allowedToTime: convertTimeToString(allowedToTime),
        allowedFromTime: convertTimeToString(allowedFromTime),
      });
      showSuccess({
        text: t('groupPage.restrictionsTab.restrictionHasBeenAdded'),
      });
      handleCreateUpdateRestrictionModalClose();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'GroupRestrictionsTab/handleTimeRestrictionCreate exception',
      });
    } finally {
      setIsCreateUpdateRestrictionLoading(false);
    }
  }, [
    groupId,
    handleCreateUpdateRestrictionModalClose,
    createTimeOfDayGroupRestriction,
    showError,
    showSuccess,
    t,
  ]);

  const handleTimeRestrictionEdit = useCallback(async (
    value: IEditTimeRestrictionModalReturnValue,
  ) => {
    setIsCreateUpdateRestrictionLoading(true);
    const {
      groupRestrictionId,
      days,
      timezone,
      time,
    } = value;
    const [allowedFromTime, allowedToTime] = time;
    try {
      await patchTimeOfDayGroupRestriction(
        groupRestrictionId,
        {
          patchTimeOfDayGroupRestrictionModel: [
            createReplacePatchOperation('/offsetName', timezone.name),
            createReplacePatchOperation('/offset', timezone.offset),
            createReplacePatchOperation('/dayOfWeek', buildRestrictionDaysAsFlags(days)),
            createReplacePatchOperation('/allowedToTime', convertTimeToString(allowedToTime)),
            createReplacePatchOperation('/allowedFromTime', convertTimeToString(allowedFromTime)),
          ],
        } as PatchTimeOfDayGroupRestrictionRequest,
      );
      showSuccess({
        text: t('groupPage.restrictionsTab.restrictionHasBeenUpdated'),
      });
      handleCreateUpdateRestrictionModalClose();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'GroupRestrictionsTab/handleTimeRestrictionEdit exception',
      });
    } finally {
      setIsCreateUpdateRestrictionLoading(false);
    }
  }, [
    patchTimeOfDayGroupRestriction,
    handleCreateUpdateRestrictionModalClose,
    showError,
    showSuccess,
    t,
  ]);

  const handleIpAddressRestrictionCreate = useCallback(async (
    value: ICreateIpAddressRestrictionModalReturnValue,
  ) => {
    setIsCreateUpdateRestrictionLoading(true);
    const {
      ipAddress,
      description,
    } = value;
    try {
      await createIPGroupRestriction({
        groupId,
        ipAddress,
        description,
      });
      showSuccess({
        text: t('groupPage.restrictionsTab.restrictionHasBeenAdded'),
      });
      handleCreateUpdateRestrictionModalClose();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'GroupRestrictionsTab/handleIpAddressRestrictionCreate exception',
      });
    } finally {
      setIsCreateUpdateRestrictionLoading(false);
    }
  }, [
    createIPGroupRestriction,
    groupId,
    handleCreateUpdateRestrictionModalClose,
    showError,
    showSuccess,
    t,
  ]);

  const handleIpAddressRestrictionEdit = useCallback(async (
    value: IEditIpAddressRestrictionModalReturnValue,
  ) => {
    setIsCreateUpdateRestrictionLoading(true);
    const {
      groupRestrictionId,
      ipAddress,
      description,
    } = value;
    try {
      await patchIPGroupRestriction(
        groupRestrictionId,
        {
          patchIpGroupRestrictionModel: [
            createReplacePatchOperation('/ipAddress', ipAddress),
            createReplacePatchOperation('/description', description),
          ],
        } as PatchIpGroupRestrictionRequest,
      );
      showSuccess({
        text: t('groupPage.restrictionsTab.restrictionHasBeenUpdated'),
      });
      handleCreateUpdateRestrictionModalClose();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'GroupRestrictionsTab/handleIpAddressRestrictionEdit exception',
      });
    } finally {
      setIsCreateUpdateRestrictionLoading(false);
    }
  }, [patchIPGroupRestriction, handleCreateUpdateRestrictionModalClose, showError, showSuccess, t]);

  const handleLocationRestrictionCreate = useCallback(async (
    value: ICreateLocationRestrictionModalReturnValue,
  ) => {
    setIsCreateUpdateRestrictionLoading(true);
    const {
      locationAddress,
      locationId,
      latitude,
      longitude,
      radius,
    } = value;
    try {
      await createGeolocationGroupRestriction({
        groupId,
        locationAddress,
        locationId,
        latitude,
        longitude,
        radius,
      });
      showSuccess({
        text: t('groupPage.restrictionsTab.restrictionHasBeenAdded'),
      });
      handleCreateUpdateRestrictionModalClose();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'GroupRestrictionsTab/handleLocationRestrictionCreate exception',
      });
    } finally {
      setIsCreateUpdateRestrictionLoading(false);
    }
  }, [
    createGeolocationGroupRestriction,
    groupId,
    handleCreateUpdateRestrictionModalClose,
    showError,
    showSuccess,
    t,
  ]);

  const handleLocationRestrictionEdit = useCallback(async (
    value: IEditLocationRestrictionModalReturnValue,
  ) => {
    setIsCreateUpdateRestrictionLoading(true);
    const {
      groupRestrictionId,
      locationAddress,
      locationId,
      latitude,
      longitude,
      radius,
    } = value;
    try {
      await patchGeolocationGroupRestriction(
        groupRestrictionId,
        {
          patchGeolocationGroupRestrictionModel: [
            createReplacePatchOperation('/locationAddress', locationAddress),
            createReplacePatchOperation('/locationId', locationId),
            createReplacePatchOperation('/latitude', latitude),
            createReplacePatchOperation('/longitude', longitude),
            createReplacePatchOperation('/radius', radius),
          ],
        } as PatchGeolocationGroupRestrictionRequest,
      );
      showSuccess({
        text: t('groupPage.restrictionsTab.restrictionHasBeenUpdated'),
      });
      handleCreateUpdateRestrictionModalClose();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'GroupRestrictionsTab/handleLocationRestrictionEdit exception',
      });
    } finally {
      setIsCreateUpdateRestrictionLoading(false);
    }
  }, [
    patchGeolocationGroupRestriction,
    handleCreateUpdateRestrictionModalClose,
    showError,
    showSuccess,
    t,
  ]);

  const { keys: selectedRestrictionsIds } = useMapKeyValueExtractor<
    TGroupRestrictionsTableRow
  >(selectedRestrictions);

  const handleDeleteRestriction = useCallback(async () => {
    setIsDeleteRestrictionLoading(true);
    try {
      const { successCount, failCount } = await deleteGroupRestrictions({
        groupRestrictionIds: selectedRestrictionsIds,
      });
      if (successCount) {
        showSuccess({
          text: t('deleteGroupRestrictionsModal.successMessage', { count: successCount }),
        });
      }
      if (failCount) {
        showError({
          text: t('deleteGroupRestrictionsModal.errorMessage', { count: failCount }),
        });
      }
      handleDeleteRestrictionModalClose();
      resetSelectedRows();
      resetActivePage();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'GroupRestrictionsTab/handleDeleteRestriction exception',
      });
    } finally {
      setIsDeleteRestrictionLoading(false);
    }
  }, [
    selectedRestrictionsIds,
    deleteGroupRestrictions,
    handleDeleteRestrictionModalClose,
    resetSelectedRows,
    resetActivePage,
    showError,
    showSuccess,
    t,
  ]);

  return (
    <PanelContent p={0}>
      <Grid container justifyContent="space-between" alignItems="stretch" p={1}>
        <Grid item xs={4} container flexWrap="nowrap" spacing={1}>
          {!!selectedRestrictions.size && (
            <>
              <Grid item my={0.5}>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item alignSelf="center">
                <Tooltip title={t('common.remove')}>
                  <ActionButton
                    width={40}
                    height={40}
                    onClick={handleDeleteRestrictionModalOpen}
                  >
                    <DeleteIcon />
                  </ActionButton>
                </Tooltip>
              </Grid>
              <Grid item my={0.5}>
                <Divider orientation="vertical" />
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={8} container justifyContent="flex-end" flexWrap="nowrap">
          <Grid item>
            <Button
              icon={<PlusIcon />}
              onClick={handleRestrictionsModalOpen}
            >
              {t('groupPage.restrictionsTab.addRestrictionButton')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <GroupRestrictionsTable
        selectedRestrictions={selectedRestrictions}
        onStatusChange={handleStatusChange}
        onRestrictionRowClick={handleUpdateRestrictionModalOpen}
        {...restTableProps}
      />
      {isRestrictionsModalOpen && (
        <GroupRestrictionsModal
          isOpen={isRestrictionsModalOpen}
          onClose={handleRestrictionsModalClose}
          onSelect={handleCreateRestrictionModalOpen}
        />
      )}
      {createUpdateRestrictionModalType === RestrictionType.Time && (
        <TimeRestrictionModal
          isOpen={createUpdateRestrictionModalType === RestrictionType.Time}
          onClose={handleCreateUpdateRestrictionModalClose}
          isLoading={isCreateUpdateRestrictionLoading}
          onCreate={handleTimeRestrictionCreate}
          onEdit={handleTimeRestrictionEdit}
          restrictionToUpdate={restrictionToUpdate as GetTimeOfDayGroupRestrictionByIdResponse}
        />
      )}
      {createUpdateRestrictionModalType === RestrictionType.IpAddress && (
        <IpAddressRestrictionModal
          isOpen={createUpdateRestrictionModalType === RestrictionType.IpAddress}
          onClose={handleCreateUpdateRestrictionModalClose}
          onCreate={handleIpAddressRestrictionCreate}
          onEdit={handleIpAddressRestrictionEdit}
          isLoading={isCreateUpdateRestrictionLoading}
          restrictionToUpdate={restrictionToUpdate as GetIpGroupRestrictionByIdResponse}
        />
      )}
      {createUpdateRestrictionModalType === RestrictionType.Location && (
        <LocationRestrictionModal
          isOpen={createUpdateRestrictionModalType === RestrictionType.Location}
          onClose={handleCreateUpdateRestrictionModalClose}
          onCreate={handleLocationRestrictionCreate}
          onEdit={handleLocationRestrictionEdit}
          isLoading={isCreateUpdateRestrictionLoading}
          restrictionToUpdate={restrictionToUpdate as GetGeolocationGroupRestrictionByIdResponse}
        />
      )}
      {isDeleteRestrictionModalOpen && (
        <PromptModal
          open={isDeleteRestrictionModalOpen}
          onClose={handleDeleteRestrictionModalClose}
          onSubmit={handleDeleteRestriction}
          title={t('deleteGroupRestrictionsModal.title')}
          description={t(
            'deleteGroupRestrictionsModal.description',
            { count: selectedRestrictions.size },
          )}
          isLoading={isDeleteRestrictionLoading}
        />
      )}
    </PanelContent>
  );
};

export default memo(GroupRestrictionsTab);
