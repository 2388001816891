import type { AxiosRequestConfig } from 'axios';
import {
  type GetUserProfileResponse,
  type LogoutRequest,
  type NoContentResult,
} from '@uniqkey-backend-organization-web/api-client';
import { publicAxiosInstance, userAxiosInstanceWithoutLogout } from '../../axios';
import { dataExtractor } from '../../helpers/apiClients';
import ApiClientsProvider from '../../common/apiClientsProvider';

export const getCurrentUserProfileWithoutLogout = async () => ApiClientsProvider
  .getUserAPIClient(userAxiosInstanceWithoutLogout)
  .apiV1UserCurrentUserProfileGet()
  .then(dataExtractor<GetUserProfileResponse>());

export const logoutUser = (
  logoutRequest?: LogoutRequest,
  options?: AxiosRequestConfig,
) => ApiClientsProvider
  .getUserAPIClient(publicAxiosInstance)
  .apiV2UserLogoutPost(logoutRequest, options)
  .then(dataExtractor<NoContentResult>());
