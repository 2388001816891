import { useMemo, memo } from 'react';
import {
  Table,
  TypographyWithTooltip,
  useTranslations,
  IUseTableReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  IMaterialTableOptions,
} from '@uniqkey-frontend/shared-app';
import { IAuditLog, TGetAuditLogsMethod } from '../../../hooks/useAuditLogsAPI/interfaces';
import { prepareAuditLogRenderData } from '../../../helpers/auditLogs';

export interface IAuditLogsTableRow extends IAuditLog {
  processedDate: string | null;
  processedActionDoneBy: string | null;
  processedDescription: string;
}

interface IAuditLogsTableProps extends
  Omit<
    IUseTableReturn<IAuditLogsTableRow>,
    'columnOrderBy' | 'columnOrderDirection' | 'selectedRows' | 'resetTableMethods'
  >,
  Omit<
    IUseQueryTableFetchReturn<TGetAuditLogsMethod>,
    'data' | 'resetQuery' | 'isFetchedAfterMount'
  >
{
  auditLogs: IUseQueryTableFetchReturn<TGetAuditLogsMethod>['data'];
  selectedAuditLogs: IUseTableReturn<IAuditLogsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

const TABLE_OPTIONS: IMaterialTableOptions<IAuditLogsTableRow> = {
  selection: false,
};

const AuditLogsTable = (props: IAuditLogsTableProps) => {
  const {
    auditLogs: preAuditLogs,
    isLoading,
    total,
    localization,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    onColumnOrderByChange,
    onColumnOrderDirectionChange,
    selectedAuditLogs,
    onRowSelectionChange,
    createColumns,
  } = props;

  const { t } = useTranslations();

  const auditLogs = useMemo(
    () => preAuditLogs.map((auditLog) => prepareAuditLogRenderData(t, auditLog)),
    [t, preAuditLogs],
  );

  const columns = useMemo(() => createColumns([
    {
      title: t('auditLogsPage.auditLogsTab.date'),
      field: 'createdAt',
      render: (rowData) => (
        <TypographyWithTooltip variant="body1">
          {rowData.processedDate}
        </TypographyWithTooltip>
      ),
      width: '18%',
      sorting: false,
    },
    {
      title: t('auditLogsPage.auditLogsTab.actionDoneBy'),
      field: 'actorEmail',
      render: (rowData) => {
        const label = rowData.isActorDeleted
          ? t('auditLogsPage.auditLogsTab.actionDoneByDeleted', {
            email: rowData.processedActionDoneBy,
          })
          : rowData.processedActionDoneBy;
        return (
          <TypographyWithTooltip variant="body2">
            {label}
          </TypographyWithTooltip>
        );
      },
      width: '25%',
      sorting: false,
    },
    {
      title: t('auditLogsPage.auditLogsTab.description'),
      field: 'description',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.processedDescription}
        </TypographyWithTooltip>
      ),
      width: '57%',
      sorting: false,
    },
  ]), [createColumns, t]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={auditLogs}
      isLoading={isLoading}
      options={TABLE_OPTIONS}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      onColumnOrderByChange={onColumnOrderByChange}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedAuditLogs}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      rowSelectByKey="createdAt"
      rowHeight={51}
    />
  );
};

export default memo(AuditLogsTable);
