import {
  decryptAsymmetric,
  decryptSymmetric,
} from '@uniqkey-frontend/shared-app';
import {
  type GetMasterPasswordBackupV2Response,
  type GetPublicKeyResponse,
} from '@uniqkey-backend-organization-mobile/api-client';
import {
  type ApproveMasterPasswordResetRequestPayload,
} from '@uniqkey-backend-organization-web/api-client';
import type { ITrustedPortalHandlerParams } from '../../../../interfaces';
import type { IDecryptedEmployeeMasterPasswordKDFBackupsMapPerOrganization } from '..';
import APIClientsProvider from '../../../../apiClientsProvider';
import { dataExtractor } from '../../../../../../helpers/apiClients';
import { logException } from '../../../../../../services/sentryService';

type TParsedEvent = ITrustedPortalHandlerParams<
  ApproveMasterPasswordResetRequestPayload
>['parsedEvent']

type TParsedPayload = TParsedEvent['parsedPayload'];

interface IGetEmployeeMasterPasswordKDFBackupsV2Params extends Pick<
  ITrustedPortalHandlerParams<ApproveMasterPasswordResetRequestPayload>,
  'axiosInstance' | 'organizationPrivateKey' | 'organizationPublicKey'
>{
  queueMessageId: TParsedEvent['queueMessageId'];
  employeeAccountId: NonNullable<TParsedPayload['employeeAccountId']>;
  organizationId: NonNullable<TParsedPayload['organizationId']>;
  privateKeyBackupSymmetricKey: NonNullable<TParsedPayload['privateKeyBackupSymmetricKey']>;
  employeeAccountPrivateKey: NonNullable<TParsedPayload['employeeAccountPrivateKey']>;
}

const getEmployeeMasterPasswordKDFBackupsV2 = async (
  params: IGetEmployeeMasterPasswordKDFBackupsV2Params,
): Promise<IDecryptedEmployeeMasterPasswordKDFBackupsMapPerOrganization | null> => {
  try {
    const {
      axiosInstance,
      organizationPrivateKey,
      organizationPublicKey,
      employeeAccountId,
      organizationId,
      privateKeyBackupSymmetricKey,
      employeeAccountPrivateKey,
    } = params;

    const { backups } = await APIClientsProvider.Mobile
      .getEmployeeAccountsAPIClient(axiosInstance)
      .apiV2EmployeeAccountsGetMasterPasswordBackupEmployeeAccountIdGet(employeeAccountId)
      .then(dataExtractor<GetMasterPasswordBackupV2Response>());

    if (!backups.length) {
      return null;
    }

    const decryptedBackupSymmetricKey = await decryptAsymmetric({
      publicKey: organizationPublicKey,
      privateKey: organizationPrivateKey,
      cipher: privateKeyBackupSymmetricKey,
    });

    const employeeAccountKeyPairPrivate = decryptSymmetric({
      key: decryptedBackupSymmetricKey,
      cipher: employeeAccountPrivateKey,
    });

    const { employeeAccountKeyPairPublic } = await APIClientsProvider.Mobile
      .getEmployeeAccountsAPIClient(axiosInstance)
      .apiV1EmployeeAccountsGetPublicKeyEmployeeAccountIdGet(employeeAccountId)
      .then(dataExtractor<GetPublicKeyResponse>());

    const employeesBackupsFromApprovalOrganization = backups.filter(
      (backup) => backup.organizationId === organizationId,
    );

    const results = await Promise.all(
      employeesBackupsFromApprovalOrganization.map(async (employeeBackup) => {
        const decryptedBackup = await decryptAsymmetric({
          publicKey: employeeAccountKeyPairPublic,
          privateKey: employeeAccountKeyPairPrivate,
          cipher: employeeBackup.backup,
        });
        return {
          id: employeeBackup.employeeOrganizationId,
          decryptedBackup,
        };
      }),
    );

    const employeeMasterPasswordKDFBackupsMapPerOrganization = results.reduce<
      IDecryptedEmployeeMasterPasswordKDFBackupsMapPerOrganization
    >((acc, result) => {
      acc[result.id] = result.decryptedBackup;
      return acc;
    }, {});

    return employeeMasterPasswordKDFBackupsMapPerOrganization;
  } catch (e) {
    // do not throw errors here, only log them
    logException(e, {
      message: 'TrustedPortalModule/getEmployeeMasterPasswordKDFBackupsV2 exception',
      data: {
        queueMessageId: params.queueMessageId,
      },
    });
    return null;
  }
};

export default getEmployeeMasterPasswordKDFBackupsV2;
