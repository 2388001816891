import { createContext, useContext } from 'react';

export interface ICompanionApplicationContext {
  guid: string;
  symmetricKey: string;
}

export const CompanionApplicationDefaultContext: ICompanionApplicationContext = {
  guid: '',
  symmetricKey: '',
};

const CompanionApplicationContext = createContext<
  ICompanionApplicationContext
>(CompanionApplicationDefaultContext);

CompanionApplicationContext.displayName = 'CompanionApplicationContext';

export default CompanionApplicationContext;

export const useCompanionApplicationContext = (): ICompanionApplicationContext => useContext<
  ICompanionApplicationContext
>(CompanionApplicationContext);
