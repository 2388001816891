import { useLanguages as useLanguagesTransifex } from '@transifex/react';
import { ILanguage } from '@transifex/native';
import { useQuery } from 'react-query';
import { GetAllSupportedUILanguagesResponse } from '@uniqkey-backend-organization-web/api-client';
import { useMemo } from 'react';
import { fetchSupportedLanguages } from '../../../api/supportedLanguagesApi';
import { isLanguageCodeAllowed } from '../../../helpers/languages';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';

export const REACT_QUERY_SUPPORTED_LANGUAGES_KEY = [ReactQueryKeyEnum.SupportedLanguages];

interface IUseLanguagesParams {
  includeOnlyAllowedLanguages: boolean;
}

export const useLanguages = (params: IUseLanguagesParams) => {
  const { includeOnlyAllowedLanguages } = params;
  const transifexLanguages: ILanguage[] = useLanguagesTransifex();

  const {
    isLoading: isSupportedLanguagesRequestLoading,
    data,
  } = useQuery<GetAllSupportedUILanguagesResponse>(
    REACT_QUERY_SUPPORTED_LANGUAGES_KEY,
    ({ signal }) => fetchSupportedLanguages({ signal }),
    {
      notifyOnChangeProps: 'tracked',
    },
  );

  const isLoading = !transifexLanguages.length || isSupportedLanguagesRequestLoading;
  const languages = useMemo(() => {
    if (isLoading) return [];

    if (!data?.supportedUILanguages) return [];

    const { supportedUILanguages } = data;

    if (!supportedUILanguages?.length) return [];

    const supportedLanguagesCodes = supportedUILanguages
      .map((supportedUILanguage) => supportedUILanguage.isoCode);

    let processedSupportedLanguagesCodes = supportedLanguagesCodes;
    if (includeOnlyAllowedLanguages) {
      processedSupportedLanguagesCodes = supportedLanguagesCodes.filter(
        (supportedLanguagesCode) => (
          supportedLanguagesCode && isLanguageCodeAllowed(supportedLanguagesCode)
        ),
      );
    }

    return transifexLanguages.filter(
      (transifexLanguage) => processedSupportedLanguagesCodes.includes(transifexLanguage.code),
    );
  }, [data, transifexLanguages, isLoading, includeOnlyAllowedLanguages]);

  return {
    isLoading,
    languages,
  };
};
