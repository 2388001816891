import LocalStorageKeyEnum from '../../enums/LocalStorageKeyEnum';
import { logException } from '../sentryService';

export const setIsSideMenuCollapsed = (isMenuCollapsed: boolean) => localStorage
  .setItem(LocalStorageKeyEnum.IsSideMenuCollapsed, JSON.stringify(isMenuCollapsed));

export const getIsSideMenuCollapsed = () => {
  try {
    const isSideMenuCollapsed = localStorage.getItem(LocalStorageKeyEnum.IsSideMenuCollapsed);
    if (!isSideMenuCollapsed) return false;
    return JSON.parse(isSideMenuCollapsed);
    // TODO: AFTER SUPPORT RELEASE: replace with jsonParseOperation
  } catch (e) {
    logException(e, {
      message: 'sideMenuService/getIsSideMenuCollapsed exception',
    });
    return false;
  }
};
