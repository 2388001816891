import type { AxiosInstance } from 'axios';
import { SupporterApi } from '@uniqkey-backend-organization-web/api-client';
import config from '../../../config';

const WEB_API_BASE_URL = config.getWebApiUrl();

export const getSupporterAPIClient = (instance: AxiosInstance) => new SupporterApi(
  undefined,
  WEB_API_BASE_URL,
  instance,
);

export default {
  getSupporterAPIClient,
};
