import { useState, useCallback, useRef } from 'react';
import { useSnackbar, useTranslations } from '@uniqkey-frontend/shared-app';
import useQueueAPI from '../useQueueAPI';

const ONE_MINUTE = 1000 * 60;

type TSetTimeoutReturn = ReturnType<typeof setTimeout>;

interface IUseMobileRequestOverlayParams {
  timeoutTranslationKey?: string;
  onTimeout?: () => void;
}

const useMobileRequestOverlay = (params: IUseMobileRequestOverlayParams = {}) => {
  const { timeoutTranslationKey = 'common.mobileRequestTimedOut', onTimeout } = params;
  const timeoutId = useRef<TSetTimeoutReturn | null>(null);
  const { cancelQueueMessage } = useQueueAPI();
  const { showError } = useSnackbar();
  const { t } = useTranslations();

  const [isMobileRequestOverlayOpen, setIsMobileRequestOverlayOpen] = useState<boolean>(false);
  const [mobileRequestOverlayQueueMessageId, setMobileRequestOverlayQueueMessageId] = useState<
    string | null
  >(null);

  const clearMobileRequestOverlayTimeout = useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = null;
    }
  }, []);

  const closeMobileRequestOverlay = useCallback(() => {
    setIsMobileRequestOverlayOpen(false);
    setMobileRequestOverlayQueueMessageId(null);
    clearMobileRequestOverlayTimeout();
  }, [clearMobileRequestOverlayTimeout]);

  const handleCreateTimeout = useCallback((id: string) => {
    timeoutId.current = setTimeout(async () => {
      await cancelQueueMessage(id);
      closeMobileRequestOverlay();
      if (onTimeout) {
        onTimeout();
      }
      showError({ text: t(timeoutTranslationKey) });
    }, ONE_MINUTE);
  }, [
    showError,
    cancelQueueMessage,
    closeMobileRequestOverlay,
    onTimeout,
    timeoutTranslationKey,
    t,
  ]);

  const openMobileRequestOverlay = useCallback((id: string) => {
    setIsMobileRequestOverlayOpen(true);
    setMobileRequestOverlayQueueMessageId(id);
    handleCreateTimeout(id);
  }, [handleCreateTimeout]);

  return {
    isMobileRequestOverlayOpen,
    mobileRequestOverlayQueueMessageId,
    openMobileRequestOverlay,
    closeMobileRequestOverlay,
    clearMobileRequestOverlayTimeout,
  };
};

export default useMobileRequestOverlay;
