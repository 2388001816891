import { QueueMessageResultNotification } from '@uniqkey-backend-organization-web/api-client';
import QueueEventEnum from '../../enums/QueueEventEnum';
import {
  TSubscribeToRealtimeAPIEventEvent,
  TSubscribeToRealtimeAPIEventCallback,
  subscribeToRealtimeAPIEvent,
  TSubscribeToRealtimeAPIEventReturn,
} from '../../services/webSocketsManager';

// eslint-disable-next-line import/prefer-default-export
export const listenMobileAction = (
  event: TSubscribeToRealtimeAPIEventEvent,
  queueMessageId: string,
  queueEvent: QueueEventEnum,
  cb: TSubscribeToRealtimeAPIEventCallback<QueueMessageResultNotification>,
): TSubscribeToRealtimeAPIEventReturn => subscribeToRealtimeAPIEvent<
  QueueMessageResultNotification
>(
  event,
  (payload) => {
    if (
      payload.queueMessageId === queueMessageId
      && payload.queueEvent === queueEvent
    ) {
      cb(payload);
    }
  },
);
