import { memo } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useTranslations,
  emptyStringValidator,
  ReactHookFormTextField,
  buildInvalidFieldTranslation,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';

export interface ICreateSecureNoteReturnValue {
  name: string;
  note: string;
}

interface ICreateSecureNoteModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (params: ICreateSecureNoteReturnValue) => Promise<void>;
  isLoading: boolean;
}

const CreateSecureNoteModal = (props: ICreateSecureNoteModalProps) => {
  const {
    isOpen, onClose, onSubmit, isLoading,
  } = props;
  const { t } = useTranslations();
  const {
    control, handleSubmit, formState: { errors, dirtyFields },
  } = useForm<ICreateSecureNoteReturnValue>({
    mode: 'all',
    defaultValues: {
      name: '',
      note: '',
    },
  });

  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      fullWidth
      maxWidth="sm"
      clickOutsideToClose={!isLoading}
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogTitle isLoading={isLoading} onClose={onClose}>
          {t('createSecureNoteModal.title')}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" rowSpacing={3}>
            <Grid item xs={12}>
              <ReactHookFormTextField
                name="name"
                control={control}
                autoFocus
                fullWidth
                error={!!errors.name}
                label={`${t('createSecureNoteModal.name.label')}*`}
                placeholder={t('createSecureNoteModal.name.placeholder')}
                helperText={errors.name?.message}
                rules={{
                  required: t('validation.required'),
                  validate: (value) => (
                    emptyStringValidator(value)
                      ? buildInvalidFieldTranslation('createSecureNoteModal.name.label') : true
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ReactHookFormTextField
                minRows={9}
                maxRows={9}
                name="note"
                control={control}
                fullWidth
                multiline
                error={!!errors.note}
                label={t('createSecureNoteModal.note.label')}
                placeholder={t('createSecureNoteModal.note.placeholder')}
                helperText={errors.note?.message}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <Button
                isLoading={isLoading}
                disabled={!dirtyFields.name}
                fullWidth
                type="submit"
              >
                {t('common.create')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                disabled={isLoading}
                fullWidth
                onClick={onClose}
              >
                {t('common.cancel')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(CreateSecureNoteModal);
