import { useMemo, memo } from 'react';
import {
  Table,
  useTranslations,
  IUseTableReturn,
  IUseQueryTableFetchReturn,
  TypographyWithTooltip,
  IMaterialTableOptions,
  IUseTableLocalizationReturn,
  formatDate,
  DateTimeFormatEnum,
} from '@uniqkey-frontend/shared-app';
import {
  MasterPasswordResetRequestResponseModel,
} from '@uniqkey-backend-organization-web/api-client';
import {
  TGetAccountRecoveryRequestsMethod,
} from '../../../hooks/useMasterPasswordResetRequestsAPI/interfaces';

export interface IAccountRecoveryRequestsTableRow extends MasterPasswordResetRequestResponseModel {
  processedDate: string | null;
}

interface IAccountRecoveryRequestsTableProps extends
  Omit<IUseTableReturn<IAccountRecoveryRequestsTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Omit<
    IUseQueryTableFetchReturn<TGetAccountRecoveryRequestsMethod>,
    'data' | 'resetQuery' | 'isFetchedAfterMount'
  >
{
  accounts: IUseQueryTableFetchReturn<TGetAccountRecoveryRequestsMethod>['data'];
  selectedAccounts: IUseTableReturn<IAccountRecoveryRequestsTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
}

const TABLE_OPTIONS: IMaterialTableOptions<IAccountRecoveryRequestsTableRow> = {
  selection: true,
};

const AccountRecoveryRequestsTable = (props: IAccountRecoveryRequestsTableProps) => {
  const {
    accounts: preAccounts,
    isLoading,
    total,
    localization,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    columnOrderBy,
    onColumnOrderByChange,
    columnOrderDirection,
    onColumnOrderDirectionChange,
    selectedAccounts,
    onRowSelectionChange,
    createColumns,
  } = props;

  const { t } = useTranslations();

  const accounts = useMemo(() => preAccounts.map((account) => ({
    ...account,
    processedDate: formatDate({ date: account.createdAt }, DateTimeFormatEnum.FULL),
  })), [preAccounts]);

  const columns = useMemo(() => createColumns([
    {
      title: t('auditLogsPage.accountRecoveryRequestsTab.email'),
      field: 'email',
      width: '40%',
      render: (rowData) => (
        <TypographyWithTooltip variant="body1">
          {rowData.email}
        </TypographyWithTooltip>
      ),
    },
    {
      title: t('auditLogsPage.accountRecoveryRequestsTab.name'),
      field: 'name',
      width: '42%',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.name}
        </TypographyWithTooltip>
      ),
    },
    {
      title: t('auditLogsPage.accountRecoveryRequestsTab.requestDate'),
      field: 'createdAt',
      width: '18%',
      render: (rowData) => (
        <TypographyWithTooltip variant="body1">
          {rowData.processedDate}
        </TypographyWithTooltip>
      ),
    },
  ]), [createColumns, t]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={accounts}
      isLoading={isLoading}
      options={TABLE_OPTIONS}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      columnOrderBy={columnOrderBy}
      onColumnOrderByChange={onColumnOrderByChange}
      columnOrderDirection={columnOrderDirection}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedAccounts}
      localization={localization}
      onSelectionChange={onRowSelectionChange}
      rowSelectByKey="masterPasswordResetRequestId"
      rowHeight={51}
    />
  );
};

export default memo(AccountRecoveryRequestsTable);
